import { Box, Grid, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'

import { GridStaticRowText } from './GridStaticRowText'

export interface GridStaticRowProps {
    image: string
    title: string
    description: string
    rightSide?: boolean
    bigSize?: boolean
}

export const GridStaticRow: React.FC<GridStaticRowProps> = ({
    image,
    title,
    description,
    rightSide,
    bigSize,
}) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))

    return (
        <Grid container>
            {!isMobile && (
                <>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        display={'flex'}
                        alignItems={'center'}
                    >
                        {rightSide && (
                            <GridStaticRowText
                                title={title}
                                description={description}
                                bigSize={bigSize}
                            />
                        )}
                        {!rightSide && (
                            <Box
                                src={image}
                                alt={title}
                                component={'img'}
                                bgcolor={'grey.50'}
                                sx={{
                                    width: '100%',
                                    height: 'auto',
                                    my: {
                                        xs: 5,
                                        md: 0,
                                    },
                                    borderRadius: '15px',
                                }}
                            />
                        )}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        display={'flex'}
                        alignItems={'center'}
                    >
                        {!rightSide && (
                            <GridStaticRowText
                                title={title}
                                description={description}
                                bigSize={bigSize}
                            />
                        )}
                        {rightSide && (
                            <Box
                                src={image}
                                alt={title}
                                component={'img'}
                                bgcolor={'grey.50'}
                                sx={{
                                    width: '100%',
                                    height: 'auto',
                                    my: {
                                        xs: 5,
                                        md: 0,
                                    },
                                    borderRadius: '15px',
                                }}
                            />
                        )}
                    </Grid>
                </>
            )}

            {isMobile && (
                <>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        display={'flex'}
                        alignItems={'center'}
                    >
                        <Box
                            src={image}
                            alt={title}
                            component={'img'}
                            bgcolor={'grey.50'}
                            sx={{
                                width: '100%',
                                height: 'auto',
                                my: {
                                    xs: 5,
                                    md: 0,
                                },
                                borderRadius: '15px',
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        display={'flex'}
                        alignItems={'center'}
                    >
                        <GridStaticRowText
                            title={title}
                            description={description}
                            bigSize={bigSize}
                        />
                    </Grid>
                </>
            )}
        </Grid>
    )
}
