import { Box, Typography } from '@mui/material'
import React from 'react'

import { OrderDetailProps } from '.'

export const OrderDetailSummary: React.FC<OrderDetailProps> = ({ cart }) => {
    return (
        <Box
            display="flex"
            justifyContent="space-between"
            borderBottom="1px solid #DEDEDE"
            pb="20px"
        >
            <Typography
                variant="h5"
                fontWeight="medium"
                textTransform="uppercase"
            >
                Итого
            </Typography>

            <Typography
                variant="h5"
                fontWeight="medium"
                textTransform="uppercase"
            >
                {cart.attributes.display_total}
            </Typography>
        </Box>
    )
}
