import { Box, Button, Typography } from '@mui/material'
import { Modal } from 'app/components/Modal'
import React, { useState } from 'react'
import { IVariant } from 'types/IVariant'

import { SizeItemVariant } from './SizeItemVariant'
import { SizeSelectionGuide } from './SizeSelectionGuide'

export function getVarianSize(variant: IVariant) {
    return (variant.attributes.options_text.split('Размер: ').pop() || '0')
        .split('-')
        .map((val) =>
            isNaN(Number.parseFloat(val.replace(',', '.')))
                ? val
                : Number.parseFloat(val.replace(',', '.'))
        )
        .join('-')
}

export interface VariantsListProps {
    variants: IVariant[]
    selectedVariant: string | null
    setSelectedVariant: (newValue: string) => void
}

export const VariantsList: React.FC<VariantsListProps> = ({
    variants,
    selectedVariant,
    setSelectedVariant,
}) => {
    const [tooltipIsOpen, setTooltipOpen] = useState<boolean>(false)

    const handleCloseTooltip = () => {
        setTooltipOpen(false)
    }
    const handleOpenTooltip = () => {
        setTooltipOpen(true)
    }

    if (variants.length === 0) return null
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Typography
                    variant="h6"
                    sx={{ fontSize: '18px', fontWeight: 'medium' }}
                >
                    Размер
                </Typography>

                <Button onClick={handleOpenTooltip} sx={{ px: 0 }}>
                    <Typography
                        variant="body2"
                        sx={{ textTransform: 'none', fontWeight: 'medium' }}
                    >
                        Как определить размер?
                    </Typography>
                </Button>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    gap: { xs: '2.5vw', sm: '10px' },
                    mt: { xs: '14px' },
                    mr: { xs: '-2.5vw', sm: '-10px' },
                    flexWrap: 'wrap',
                }}
            >
                {variants
                    .sort(
                        (a, b) =>
                            Number.parseFloat(
                                getVarianSize(a).split('-').shift() || '0'
                            ) -
                            Number.parseFloat(
                                getVarianSize(b).split('-').shift() || '0'
                            )
                    )
                    .map((variant, index) => (
                        <React.Fragment key={index}>
                            <SizeItemVariant
                                {...{
                                    variant,
                                    selectedVariant,
                                    setSelectedVariant,
                                }}
                            />
                        </React.Fragment>
                    ))}
            </Box>
            <Modal
                open={tooltipIsOpen}
                title={'подбор размера'}
                handleClose={handleCloseTooltip}
            >
                <SizeSelectionGuide handleCloseTooltip={handleCloseTooltip} />
            </Modal>
        </>
    )
}
