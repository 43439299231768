import { useMediaQuery, useTheme } from '@mui/material'
import React from 'react'

import { MobileExperienceISBlock } from './MobileExperienceISBlock'

export const ExperienceISBlock: React.FC = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'lg'))

    return <>{isMobile && <MobileExperienceISBlock />}</>
}
