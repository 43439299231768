import { Box, Container } from '@mui/material'
import GridStaticRow, { GridStaticRowProps } from 'app/components/GridStaticRow'
import { TopBanner } from 'app/components/TopBanner'
import React from 'react'

export const BrandedPackaging: React.FC = () => {
    const grids: GridStaticRowProps[] = [
        {
            image: '/assets/images/image_69.jpg',
            title: 'Лучший подарок',
            description:
                'Бархатная коробочка будет наилучшим дополнением для вашего подарка',
        },
        {
            image: '/assets/images/image_70.jpg',
            title: 'Премиальный дизайн',
            description:
                'Фирменная коробочка сделана из высококачественных материалов, придаёт большей ценности вашему подарку, изделия смотрятся благороднее',
        },
        {
            image: '/assets/images/image156.jpg',
            title: 'еще у нас есть фирменный холдер',
            description:
                'Для каждого кольца, серёжек, браслетов и подвесок предусмотрены крепления и специальные отделения. Все твои любимые украшения — в идеальном месте и готовы создавать твоё настроение каждый день.<br /><br />Приятный на ощупь холдер представлен в широкой линейке цветов: зелёный, лиловый, розовый, чёрный, синий, голубой, тиффани',
        },
        {
            image: '/assets/images/Frame2087_1.jpg',
            title: 'подарочные сертификаты',
            description:
                'Подарочный сертификат — идеальный подарок близкому человеку. Приобретая подарок в виде сертификата, вы дарите возможность самостоятельно выбрать украшение на свой вкус.<br /><br />У нас представлено несколько видов подарочных сертификатов:<br />- Бумажный сертификат<br />- Электронный сертификат<br />- Эксклюзивный сертификат в бархатном конверте<br />- Эксклюзивный сертификат в бархатной коробке с теснением Island Soul в комплекте с конвертом с правилами использования и сам сертификат из орг.стекла с сургучной печатью',
        },
    ]

    return (
        <Container
            sx={{
                mt: { md: 25, lg: 17, xl: 17 },
            }}
        >
            <TopBanner
                image={'/assets/images/DSC06929.jpg'}
                title={'Фирменная бархатная коробочка — премиальный дизайн'}
            />

            <Box mt={11}>
                {grids.map((item, index) => (
                    <GridStaticRow
                        key={index}
                        image={item.image}
                        title={item.title}
                        description={item.description}
                        rightSide={index % 2 === 1}
                    />
                ))}
            </Box>
        </Container>
    )
}
