import { Box, Typography } from '@mui/material'
import React from 'react'

interface ProductsFilterKindProps {
    name: string
}

export const ProductsFilterKind: React.FC<ProductsFilterKindProps> = ({
    name,
    children,
}) => {
    return (
        <Box
            pt={{ xs: '30px', md: '0px' }}
            pb={{ xs: '30px', md: '40px' }}
            px={{ xs: '16px', md: '0px' }}
            sx={{ borderBottom: { xs: '1px solid #DEDEDE', md: 'none' } }}
        >
            <Typography
                noWrap
                fontWeight="medium"
                sx={{
                    pb: { xs: '16px' },
                }}
            >
                {name}
            </Typography>

            {children}
        </Box>
    )
}
