import {
    ChevronRight as ChevronRightIcon,
    Logout as LogoutIcon,
} from '@mui/icons-material'
import {
    Box,
    Button,
    Container,
    Divider,
    Drawer,
    Typography,
    useTheme,
} from '@mui/material'
import { ContactBlock } from 'app/components/ContactBlock'
import { MobileMenuItem } from 'app/components/MobileMenuItem'
import { ExperienceISBlock } from 'app/components/StaticPagesNavigation/ExperienceISBlock'
import { UniverseISBlock } from 'app/components/StaticPagesNavigation/UniverseISBlock'
import { ReactComponent as GiftIcon } from 'app/modules/Account/assets/gift_icon.svg'
import { ReactComponent as HeartIcon } from 'app/modules/Account/assets/heart_icon.svg'
import { ReactComponent as ShoppingBagIcon } from 'app/modules/Account/assets/shopping_bag_icon.svg'
import { ReactComponent as UserIcon } from 'app/modules/Account/assets/user_icon.svg'
import { AccountInfo } from 'app/modules/Account/components/AccountInfo'
import { accountActions } from 'app/modules/Account/slice'
import { selectDisabled } from 'app/modules/Account/slice/selectors'
import { authActions } from 'app/modules/Auth/slice'
import { selectAuthStatus } from 'app/modules/Auth/slice/selectors'
import { cartActions } from 'app/modules/Cart/slice'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'

export const MobileAccountModal: React.FC = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { url } = useRouteMatch()

    const theme = useTheme()

    const { notAuthorized, isAuthorized } = useSelector(selectAuthStatus)
    const isDisabled = useSelector(selectDisabled)

    const openSignin = () => {
        dispatch(authActions.setReferrerUrl(''))
        dispatch(authActions.resetPhone())
        dispatch(authActions.setModalStatus('phone'))
    }

    const handleLink = (route: string) => {
        if (notAuthorized) {
            openSignin()
        }
        if (isAuthorized) {
            history.push(route)
        }
    }

    const handleLogout = () => {
        history.push('/')
        dispatch(authActions.logout())
        dispatch(accountActions.logout())
        dispatch(cartActions.deleteCartState())
        dispatch(cartActions.deleteCartToken())
        dispatch(cartActions.createCart())
    }

    useEffect(() => {
        dispatch(accountActions.activeAccountModal())
    }, [url])

    return (
        <Drawer
            open={url === '/profile' && !isDisabled}
            anchor="bottom"
            transitionDuration={100}
            PaperProps={{
                sx: {
                    height: '100%',
                    pb: 12.75,
                },
            }}
        >
            {notAuthorized && (
                <Box bgcolor="grey.100" pt={2.5} pb={2}>
                    <Container>
                        <Box display={'flex'} alignItems={'center'}>
                            <UserIcon stroke={theme.palette.grey[400]} />

                            <Typography
                                variant="body2"
                                fontWeight={500}
                                sx={{
                                    textTransform: 'uppercase',
                                    ml: 1.5,
                                }}
                            >
                                Войдите
                            </Typography>
                        </Box>

                        <Typography
                            color="grey.600"
                            variant="body2"
                            mt={2}
                            mb={3}
                        >
                            Для совершения покупок, отслеживания заказов и
                            многого другого
                        </Typography>

                        <Button
                            fullWidth
                            variant="contained"
                            onClick={openSignin}
                        >
                            ВОЙТИ
                        </Button>
                    </Container>
                </Box>
            )}

            {isAuthorized && (
                <>
                    <Container>
                        <Box mt={3}>
                            <AccountInfo />
                        </Box>

                        <Box mt={4.75}>
                            <MobileMenuItem
                                icon={
                                    <ShoppingBagIcon
                                        stroke={theme.palette.grey[400]}
                                    />
                                }
                                text={'Заказы'}
                                endContent={<ChevronRightIcon />}
                                onClick={() => handleLink('/profile/orders')}
                            />

                            <MobileMenuItem
                                icon={
                                    <HeartIcon
                                        stroke={theme.palette.grey[400]}
                                    />
                                }
                                text={'Избранное'}
                                endContent={<ChevronRightIcon />}
                                onClick={() => handleLink('/profile/favorites')}
                            />

                            <MobileMenuItem
                                icon={
                                    <GiftIcon
                                        stroke={theme.palette.grey[400]}
                                    />
                                }
                                text={'бонусная программа'}
                                endContent={<ChevronRightIcon />}
                                onClick={() => handleLink('/profile/gift')}
                            />

                            <MobileMenuItem
                                icon={
                                    <UserIcon
                                        stroke={theme.palette.grey[400]}
                                    />
                                }
                                text={'личные данные'}
                                endContent={<ChevronRightIcon />}
                                onClick={() => handleLink('/profile/personal')}
                            />

                            <MobileMenuItem
                                icon={<LogoutIcon color="error" />}
                                text={'Выйти'}
                                color="error"
                                onClick={() => handleLogout()}
                            />
                        </Box>
                    </Container>

                    <Divider sx={{ my: 1.5 }} />
                </>
            )}

            <UniverseISBlock />

            <ExperienceISBlock />

            <Divider sx={{ my: 1.5 }} />

            <ContactBlock />
        </Drawer>
    )
}
