import { Box, Typography } from '@mui/material'
import React from 'react'

import { ShopCardList } from '../components/ShopCardList'
import { ShopCitySelect } from '../components/ShopInfoBlock/ShopCitySelect'
import { YandexMap } from '../components/YandexMap'

export const Shops: React.FC = () => (
    <Box
        sx={{
            mt: { md: 30, lg: 20, xl: 20 },
        }}
    >
        <YandexMap />

        <ShopCitySelect />

        <ShopCardList />

        <Box
            mt={21.25}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
        >
            <Box
                src="/assets/images/bg-is-stamp-loop2.png"
                alt={'bg-is-stamp-loop'}
                component={'img'}
            />

            <Typography
                variant="h4"
                fontWeight={500}
                mt={4.125}
                sx={{
                    textTransform: 'uppercase',
                }}
            >
                семья Island soul – более 70 магазинов
            </Typography>
        </Box>
    </Box>
)
