import { Box, Typography } from '@mui/material'
import React from 'react'

import { CartItemProps } from '../CartItem'

export const CartItemPrice: React.FC<CartItemProps> = ({ item }) => {
    return (
        <Box
            display={'flex'}
            sx={{ flexDirection: { xs: 'row', md: 'column' } }}
        >
            <Typography
                sx={{
                    fontWeight: { xs: 'regular', md: 'medium' },
                    fontSize: { xs: '14px', md: '18px' },
                }}
                variant="body2"
            >
                {item.attributes.display_total}
            </Typography>

            {item.attributes.sale_percent && (
                <Typography
                    variant="body2"
                    color="grey.400"
                    sx={{
                        textDecoration: 'line-through',
                        fontSize: { xs: '14px', md: '18px' },
                        ml: { xs: '8px', md: '0px' },
                        fontWeight: 'light',
                    }}
                >
                    {item.attributes.display_compare_at_amount}
                </Typography>
            )}
        </Box>
    )
}
