import { Box, Button } from '@mui/material'
import { selectAccountBonuses } from 'app/modules/Account/slice/selectors'
import { checkoutActions } from 'app/modules/Checkout/slice'
import { selectLoyaltyData } from 'app/modules/Checkout/slice/selectors'
import { LoyaltyPromoType } from 'app/modules/Checkout/slice/types'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { BonusesControl } from './BonusesControl'
import { LoyaltyInfo } from './LoyaltyInfo'
import { PromocodeControl } from './PromocodeControl/PromocodeControl'
import { ICartProps, IControlActive } from './types'
import { useOnlySales } from './useOnlySales'

const ControlActiveConstants = {
    activated: { isActive: true },
    otherTypeIsActivated: {
        isActive: false,
        reason: 'Нельзя использовать б.р. и промокод вместе',
    },
    onlySales: {
        isActive: false,
        reason: 'Нельзя применять б.р. и промокод к товарам со скидкой',
    },
} as const

interface LoyaltyDetailProps {
    handleClose: () => void
}

export const LoyaltyDetail: React.FC<LoyaltyDetailProps & ICartProps> = ({
    handleClose,
    cart,
}) => {
    const dispatch = useDispatch()

    const [isBonusesControlActive, setBonusesControlActive] =
        useState<IControlActive>(ControlActiveConstants.activated)
    const [isPromocodeControlActive, setPromocodeControlActive] =
        useState<IControlActive>(ControlActiveConstants.activated)

    const { data } = useSelector(selectLoyaltyData)
    const maxAmount = data.available_bonuses

    const maxBonuses = useSelector(selectAccountBonuses)

    const onlySales = useOnlySales()

    useEffect(() => {
        if (maxAmount === 0) {
            setBonusesControlActive({
                isActive: false,
                reason: 'У вас нет бонусных рублей',
            })
            return
        }

        if (onlySales) {
            setBonusesControlActive(ControlActiveConstants.onlySales)
            return
        }

        if (data.bonuses !== 0) {
            setPromocodeControlActive(
                ControlActiveConstants.otherTypeIsActivated
            )
            return
        }

        if (
            cart?.attributes.mindbox_data?.coupon_discount !== null &&
            Number(cart?.attributes.mindbox_data?.coupon_discount) !== 0
        ) {
            setBonusesControlActive(ControlActiveConstants.otherTypeIsActivated)
            return
        }

        setPromocodeControlActive(ControlActiveConstants.activated)
        setBonusesControlActive(ControlActiveConstants.activated)
    }, [cart, data, onlySales])

    useEffect(() => {
        // if (cart.attributes.mindbox_data.spent_bonuses !== 0) {
        //     dispatch(checkoutActions.applyBonuses(0))
        // }
        dispatch(checkoutActions.setPromocodeError(''))
    }, [])

    return (
        <Box p={{ xs: '16px', md: '30px' }}>
            <LoyaltyInfo
                maxAmount={maxAmount}
                maxBonuses={maxBonuses}
                max_online_percent={15}
            />

            <Box mt="40px">
                <BonusesControl
                    controlActive={isBonusesControlActive}
                    maxBonusesAmount={maxAmount}
                    cart={cart}
                />
            </Box>
            <Box mt="40px">
                <PromocodeControl
                    controlActive={isPromocodeControlActive}
                    cart={cart}
                />
            </Box>
            <Box mt="60px">
                <Button
                    variant="contained"
                    sx={{
                        height: '60px',
                        width: '100%',
                        '&.Mui-disabled': {
                            backgroundColor: 'grey.400',
                            color: 'white',
                        },
                    }}
                    onClick={handleClose}
                    disabled={
                        (data.promocode_total === 0 && data.bonuses === 0) ||
                        data.whichPromoUsed === LoyaltyPromoType.none
                    }
                >
                    получить скидку
                </Button>
            </Box>
        </Box>
    )
}
