import { getListOfMasks } from './get-list-of-masks'

export const getMatchedMask = (
    valueToMatch: string,
    masks: string[] = getListOfMasks()
) => {
    const testNumber = /[0-9]/
    const anyNumber = '#'

    const maskValue = valueToMatch.replace(/[^0-9]/g, '')

    if (!maskValue.length) return false

    for (const mask of masks) {
        let valueIndex = 0
        let maskIndex = 0

        for (; valueIndex < maskValue.length && maskIndex < mask.length; ) {
            const maskChar: string = mask.charAt(maskIndex)
            const valueChar = maskValue.charAt(valueIndex)

            if (!(testNumber.test(maskChar) || maskChar === anyNumber)) {
                maskIndex++
                continue
            }

            if (
                (maskChar === anyNumber && testNumber.test(valueChar)) ||
                valueChar == maskChar
            ) {
                valueIndex++
                maskIndex++
            } else {
                break
            }
        }

        if (valueIndex == maskValue.length) {
            return mask.replace(
                new RegExp([testNumber.source, anyNumber].join('|'), 'g'),
                '9'
            )
        }
    }

    return false
}
