import { useMediaQuery, useTheme } from '@mui/material'
import { selectImageById } from 'app/modules/Images/slice/selectors'
import React from 'react'
import { useSelector } from 'react-redux'
import { ICategory } from 'types/ICategory'

import { DesktopCategoryCard } from './DesktopCategoryCard'
import { MobileCategoryCard } from './MobileCategoryCard'

interface CategoryCardProps {
    category: ICategory
}

export const CategoryCard: React.FC<CategoryCardProps> = ({ category }) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

    const getImage = useSelector(selectImageById)

    const image = getImage(category.relationships.image.data?.id)
    const miniImage = getImage(category.relationships.mini_image.data?.id)

    return (
        <>
            {isMobile && (
                <MobileCategoryCard category={category} image={miniImage} />
            )}

            {isDesktop && (
                <DesktopCategoryCard
                    category={category}
                    image={miniImage || image}
                />
            )}
        </>
    )
}
