import { Box } from '@mui/material'
import { useCategoryPageContext } from 'app/modules/Products/components/ProductsFilter/hooks'
import React from 'react'

export const CategoryDescription: React.FC = () => {
    const { category } = useCategoryPageContext()

    if (!category.attributes.description) return null

    return (
        <Box
            sx={{
                width: '100%',
                mt: { md: '75px' },
                display: 'flex',
                pt: { lg: '80px' },

                px: { xs: '24px', lg: '0px' },

                ml: { lg: '293px' },
                mb: { md: '-40px' },
            }}
        >
            <div
                dangerouslySetInnerHTML={{
                    __html: category.attributes.description,
                }}
            />
        </Box>
    )
}
