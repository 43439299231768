import { Box, Typography } from '@mui/material'
import { SizeSelectionGuide } from 'app/modules/Products/components/ProductDetail/VariantsList/SizeSelectionGuide'
import React from 'react'

export const RingSize: React.FC = () => {
    return (
        <Box
            sx={{
                mt: { md: 25, lg: 17, xl: 17 },
                px: { md: '100px' },
                pt: { md: '100px' },
            }}
        >
            <Typography
                variant="h1"
                fontSize={{ xs: '24px', md: '35px' }}
                textTransform="uppercase"
                fontWeight={500}
                textAlign={{ xs: 'center', md: 'left' }}
                mb={{ md: '20px' }}
            >
                подбор размера
            </Typography>
            <SizeSelectionGuide staticPage />
        </Box>
    )
}
