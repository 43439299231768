import {
    createEntityAdapter,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit'
import { IImage, ImageTypes } from 'types/IImage'
import { ResourceObject, Response } from 'utils/json-api'
import { readonlyArrayIncludes } from 'utils/readonlyArrayincIudes'

import { ImagesState } from './types'

export const imagesAdapter = createEntityAdapter<IImage>()

const slice = createSlice({
    name: 'images',
    initialState: imagesAdapter.getInitialState<ImagesState>({
        ids: [],
        entities: {},
    }),
    reducers: {
        imagesAdded(
            state,
            action: PayloadAction<
                Response<
                    ResourceObject | ResourceObject[],
                    (IImage | ResourceObject)[]
                >
            >
        ) {
            const images = action.payload.included.filter(
                (item): item is IImage =>
                    readonlyArrayIncludes(ImageTypes, item.type)
            )

            imagesAdapter.setMany(state, images)
        },
    },
})

export const { actions: imagesActions, reducer: imagesReducer } = slice
