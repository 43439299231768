import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { BannerContent } from 'app/modules/MainPage/components/BannerContent'
import { chunk } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import { EStatus } from 'types'
import { IBanner } from 'types/IBanner'
import { IProduct } from 'types/IProduct'

import { selectCategoryProductsStatus } from '../../slice/selectors'
import { ProductCard } from '../ProductCard'
import { useCategoryPageContext } from '../ProductsFilter/hooks'

interface ProductCardGridChildProps {
    products: (IProduct | undefined)[]
    banners: IBanner[]
}

const MobileProductCardGrid: React.FC<ProductCardGridChildProps> = ({
    products,
    banners,
}) => {
    const chunkSize = 5

    const leftProducts: typeof products = []
    const rightProducts: typeof products = []

    products.forEach((product, index) => {
        if (index % 2 === 0) leftProducts.push(product)
        else rightProducts.push(product)
    })

    const leftProductsChunks = chunk(leftProducts, chunkSize)
    const rightProductsChunks = chunk(rightProducts, chunkSize)

    return (
        <Box
            sx={{
                display: 'flex',
            }}
        >
            <Box mr="12px">
                {leftProductsChunks.map((productsChunk, chunkIndex) => {
                    const showBanner = banners[chunkIndex * 2 + 1]

                    return (
                        <Grid
                            key={chunkIndex}
                            spacing="24px"
                            container
                            mb="24px"
                        >
                            {productsChunk
                                .slice(0, 3)
                                .map((product, productIndex) => (
                                    <Grid item xs={12} key={productIndex}>
                                        <ProductCard product={product} />
                                    </Grid>
                                ))}

                            {showBanner && (
                                <Grid item xs={12}>
                                    <ProductCardGridBanner
                                        showBanner={showBanner}
                                    />
                                </Grid>
                            )}

                            {productsChunk
                                .slice(3)
                                .map((product, productIndex) => (
                                    <Grid item xs={12} key={productIndex}>
                                        <ProductCard product={product} />
                                    </Grid>
                                ))}
                        </Grid>
                    )
                })}
            </Box>
            <Box>
                {rightProductsChunks.map((productsChunk, chunkIndex) => {
                    const showBanner = banners[chunkIndex * 2]

                    return (
                        <Grid
                            key={chunkIndex}
                            spacing="24px"
                            container
                            mb="24px"
                        >
                            {showBanner && (
                                <Grid item xs={12}>
                                    <ProductCardGridBanner
                                        showBanner={showBanner}
                                    />
                                </Grid>
                            )}

                            {productsChunk.map((product, productIndex) => (
                                <Grid item xs={12} key={productIndex}>
                                    <ProductCard product={product} />
                                </Grid>
                            ))}
                        </Grid>
                    )
                })}
            </Box>
        </Box>
    )
}
const TabletProductCardGrid: React.FC<ProductCardGridChildProps> = ({
    products,
    banners,
}) => {
    const { totalProductsCount } = useCategoryPageContext()

    const lastPage = totalProductsCount === products.length

    const productsChunks = (() => {
        const result: {
            products: typeof products
            showBanner: IBanner | false
        }[] = []

        /** Нераспределенные продукты */
        const tmpProducts = [...products]

        while (tmpProducts.length) {
            const showBanner =
                (result.length % 2 === 1 &&
                    tmpProducts.length > 1 &&
                    banners[(result.length - 1) / 2]) ||
                false

            if (showBanner) {
                if (tmpProducts.length < 5 && !lastPage) break
                result.push({ products: tmpProducts.splice(0, 5), showBanner })
            } else {
                if (tmpProducts.length < 6 && !lastPage) break
                result.push({ products: tmpProducts.splice(0, 6), showBanner })
            }
        }

        return result
    })()

    return (
        <Box>
            {productsChunks.map((productsChunk, chunkIndex) => {
                const gridTemplateAreas = (() => {
                    if (!productsChunk.showBanner) return undefined

                    if (chunkIndex % 4 === 1)
                        return `". Banner Banner" ". Banner Banner" ". . ."`
                    else return `"Banner Banner ." "Banner Banner ." ". . ."`
                })()

                return (
                    <Box
                        key={chunkIndex}
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(3, 1fr)',
                            gridTemplateAreas,
                            rowGap: '24px',
                            mb: '24px',
                            columnGap: '12px',
                        }}
                    >
                        {productsChunk.products.map((product, productIndex) => (
                            <ProductCard key={productIndex} product={product} />
                        ))}

                        <ProductCardGridBanner
                            showBanner={productsChunk.showBanner}
                        />
                    </Box>
                )
            })}
        </Box>
    )
}
const DesktopProductCardGrid: React.FC<ProductCardGridChildProps> = ({
    products,
    banners,
}) => {
    const chunkSize = 8
    const productsChunks = chunk(products, chunkSize)

    return (
        <Box>
            {productsChunks.map((productsChunk, chunkIndex) => {
                const showBanner =
                    (chunkIndex % 2 === 1 &&
                        productsChunk.length >= 3 &&
                        banners[(chunkIndex - 1) / 2]) ||
                    false

                const gridTemplateAreas = (() => {
                    if (!showBanner) return undefined

                    if (chunkIndex % 4 === 1)
                        return `". . Banner Banner" ". . Banner Banner" ". . . ."`
                    else
                        return `"Banner Banner . ." "Banner Banner . ." ". . . ."`
                })()

                return (
                    <Box
                        key={chunkIndex}
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(4, 1fr)',
                            gridTemplateAreas,
                            gap: '30px',
                            mb: '30px',
                        }}
                    >
                        {productsChunk.map((product, productIndex) => (
                            <ProductCard key={productIndex} product={product} />
                        ))}

                        <ProductCardGridBanner showBanner={showBanner} />
                    </Box>
                )
            })}
        </Box>
    )
}

const ProductCardGridBanner: React.FC<{
    showBanner: false | IBanner
}> = ({ showBanner }) => {
    if (!showBanner) return null

    return (
        <Box
            width="100%"
            height="100%"
            sx={{
                gridArea: 'Banner',
                borderRadius: { xs: '16px', lg: '28px' },
                overflow: 'hidden',
                position: 'relative',
            }}
        >
            <Box position={{ md: 'absolute' }} width="100%" height="100%">
                <BannerContent content={showBanner} />
            </Box>
        </Box>
    )
}

export const ProductCardGrid: React.FC = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))
    const isTablet = useMediaQuery(theme.breakpoints.between('md', 'lg'))
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

    const productsStatusIsFinished =
        useSelector(selectCategoryProductsStatus) === EStatus.FINISHED

    const {
        products,
        banners,
        curentPage,
        totalProductsCount,
        totalPages,
        perPage,
    } = useCategoryPageContext()
    const productsIsEmpty = !products.length

    const expectedProductsLength =
        curentPage === totalPages ? totalProductsCount : curentPage * perPage

    if (productsIsEmpty && productsStatusIsFinished) {
        return (
            <Box mt={10}>
                <Typography variant="h5">ТОВАРОВ НЕ НАЙДЕНО</Typography>
                <Typography variant="body2">
                    Измените параметры поиска, чтобы найти товары
                </Typography>
            </Box>
        )
    }

    const childProps: ProductCardGridChildProps = {
        products: products.concat(
            ...new Array(expectedProductsLength - products.length)
        ),
        banners,
    }

    return (
        <Box mr={{ xs: '16px', lg: '45px' }} ml={{ xs: '16px', lg: '0px' }}>
            {isMobile && <MobileProductCardGrid {...childProps} />}

            {isTablet && <TabletProductCardGrid {...childProps} />}

            {isDesktop && <DesktopProductCardGrid {...childProps} />}
        </Box>
    )
}
