import { authActions } from 'app/modules/Auth/slice'
import {
    selectAuthStatus,
    selectAuthTokenData,
} from 'app/modules/Auth/slice/selectors'
import { Auth } from 'app/modules/Auth/templates/Auth'
import { cartActions } from 'app/modules/Cart/slice'
import { Cart } from 'app/modules/Cart/templates/Cart'
import { categoriesActions } from 'app/modules/Categories/slice'
import { citiesActions } from 'app/modules/Cities/slice'
import { selectCurrentCityID } from 'app/modules/Cities/slice/selectors'
import { productsActions } from 'app/modules/Products/slice'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import ym from 'react-yandex-metrika'
import {
    googleAnalyticsEvent,
    googleAnalyticsEvents,
} from 'utils/googleAnalytics'
import { topMailEvent, TopMailEventGoals } from 'utils/topMailMetrica'

import { Header } from '../components/Header'
import HeaderContextProvider from '../components/Header/HeaderContextProvider'
import Navigation from '../components/Navigation'
import { IHeaderContextProps } from '../types/IHeaderContext'

interface MainLayoutProps {
    headerContextProps: IHeaderContextProps
}

export const MainLayout: React.FC<MainLayoutProps> = ({
    headerContextProps,
    children,
}) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const location = useLocation()

    const { isAuthorized } = useSelector(selectAuthStatus)
    const token = useSelector(selectAuthTokenData)
    const currentCityID = useSelector(selectCurrentCityID)

    // const params = new URLSearchParams(location.search)
    // const orderNumber = params.get('order_number')

    useEffect(() => {
        dispatch(categoriesActions.loadCategories())
        dispatch(citiesActions.loadCities())
    }, [token.created_at])

    // useEffect(() => {
    //     if (orderNumber && isAuthorized) {
    //         history.push('/profile/orders')
    //     }
    // }, [orderNumber && isAuthorized])

    function AddToCart1C(variant_id_1c: string) {
        topMailEvent(TopMailEventGoals.basket)
        ym('reachGoal', 'Basket')
        googleAnalyticsEvent(googleAnalyticsEvents.add_cart)

        dispatch(
            cartActions.addItem1C({
                variant_id_1c,
                quantity: 1,
            })
        )
    }

    const addToFavorites = (variant_id_1c: string) => {
        if (!isAuthorized) {
            dispatch(authActions.setModalStatus('phone'))
        }
        if (isAuthorized) {
            dispatch(productsActions.productToggleFavorite1C(variant_id_1c))
        }
    }

    window.AddToCart = AddToCart1C
    window.addToCart = AddToCart1C
    window.addToFavorites = addToFavorites

    return (
        <Auth>
            <React.Fragment key={currentCityID}>
                <Cart />

                <HeaderContextProvider headerContextProps={headerContextProps}>
                    <Header />

                    {children}

                    <Navigation />
                </HeaderContextProvider>
            </React.Fragment>
        </Auth>
    )
}
