import {
    Grid,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import React from 'react'

import { PhoneField } from '../../../../components/PhoneField'
import { CustomerInformationChildProps } from './CustomerInformationForm'

export const CustomerData: React.FC<CustomerInformationChildProps> = ({
    formik,
}) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))

    return (
        <>
            <Typography
                variant="h6"
                sx={{ fontSize: { xs: '14px', md: '18px' } }}
            >
                ДАННЫЕ ПОЛУЧАТЕЛЯ
            </Typography>
            <Grid container spacing={{ xs: '12px', md: '40px' }} pt="16px">
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        variant="standard"
                        label="Имя"
                        name="order.ship_address_attributes.firstname"
                        value={
                            formik.values.order.ship_address_attributes
                                .firstname
                        }
                        error={
                            !!formik.errors.order?.ship_address_attributes
                                ?.firstname
                        }
                        onChange={formik.handleChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        variant="standard"
                        label="Фамилия"
                        name="order.ship_address_attributes.lastname"
                        value={
                            formik.values.order.ship_address_attributes.lastname
                        }
                        error={
                            !!formik.errors.order?.ship_address_attributes
                                ?.lastname
                        }
                        onChange={formik.handleChange}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={{ xs: '12px', md: '40px' }} pt="16px">
                <Grid item xs={6}>
                    <PhoneField
                        fullWidth
                        variant="standard"
                        name="order.ship_address_attributes.phone"
                        label="Номер телефона"
                        value={
                            formik.values.order.ship_address_attributes.phone
                        }
                        error={
                            !!formik.errors.order?.ship_address_attributes
                                ?.phone
                        }
                        onChange={formik.handleChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        variant="standard"
                        label={
                            isMobile
                                ? 'Email для чека'
                                : 'Email для получения чека'
                        }
                        name="order.email"
                        value={formik.values.order.email}
                        error={!!formik.errors.order?.email}
                        onChange={formik.handleChange}
                    />
                </Grid>
            </Grid>
        </>
    )
}
