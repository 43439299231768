import { put } from 'redux-saga/effects'
import { IAddress } from 'types/IAddress'
import { ICartItemResponse } from 'types/ICart'
import { ICustomerInformation } from 'types/ICustomerInformation'
import { IShipment } from 'types/IShipment'
import { IShippingRates } from 'types/IShippingRates'

import { checkoutActions } from '.'

export function* setShipmentsAttributes(response: ICartItemResponse) {
    const shipment = response.included.find(
        (item): item is IShipment => item.type === 'shipment'
    )

    const shipping_rate = response.included.find(
        (item): item is IShippingRates =>
            item.type === 'shipping_rate' && item.attributes.selected
    )

    if (shipment && shipping_rate) {
        yield put(
            checkoutActions.setShipmentsAttributes({
                id: shipment.id,
                start_period: shipping_rate.attributes.start_period,
                end_period: shipping_rate.attributes.end_period,
                display_final_price: shipping_rate.attributes.display_cost,
                selected_shipping_rate_id: shipping_rate.id,
                shipping_method_id: shipping_rate.attributes.shipping_method_id,
            })
        )
    }
}

export function* setCustomerInformation(response: ICartItemResponse) {
    const customerInformation = response.included
        .filter((item): item is IAddress => item.type === 'address')
        .map((item): ICustomerInformation => {
            return {
                order: {
                    email: response.data.attributes.email,
                    delivery_state: response.data.attributes.delivery_state,
                    special_instructions:
                        response.data.attributes.special_instructions,
                    ship_address_attributes: {
                        firstname: item.attributes.firstname,
                        lastname: item.attributes.lastname,
                        phone: item.attributes.phone,
                        // address1: item.attributes.address1,
                        address1: '',
                        city: item.attributes.city,
                        zipcode: item.attributes.zipcode,
                    },
                },
            }
        })

    yield put(
        checkoutActions.setCustomerInformation(
            customerInformation && customerInformation.length > 0
                ? customerInformation[0]
                : undefined
        )
    )

    yield put(
        checkoutActions.setCustomerInformationDeliveryState(
            response.data.attributes.delivery_state
        )
    )
}
