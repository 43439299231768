import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Collapse, Container, Typography } from '@mui/material'
import { MobileMenuItem } from 'app/components/MobileMenuItem'
import React, { useState } from 'react'

export const QualityAssuranceCollapse: React.FC = () => {
    const [isOpen, setOpen] = useState<boolean>(false)

    return (
        <Container
            onClick={() => setOpen(!isOpen)}
            sx={{
                px: { xs: 0 },
                cursor: 'pointer',
            }}
        >
            <MobileMenuItem
                text={
                    <Typography
                        sx={{
                            fontWeight: 'medium',
                            textTransform: 'none',
                        }}
                        variant="body1"
                    >
                        Гарантия качества
                    </Typography>
                }
                endContent={isOpen ? <ExpandLess /> : <ExpandMore />}
            />

            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <Typography variant="body2">
                    Каждое наше изделие сочетает в себе высочайшее качество и
                    оригинальную идею дизайна, являясь уникальным произведением
                    ювелирного искусства. Покупая драгоценности в Island Soul,
                    Вы можете не сомневаться в правильности своего решения.
                    Гарантия на все украшения составляет 6 месяцев со дня
                    покупки. Если вы столкнулись с производственным браком, мы
                    быстро это исправим. Для этого Вам необходимо обратиться с
                    паспортом в наш магазин.
                </Typography>
            </Collapse>
        </Container>
    )
}
