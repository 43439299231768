import {
    createEntityAdapter,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit'
import { EStatus } from 'types'
import { IPage, IPageCollectionResponse, IPageItemResponse } from 'types/IPage'

import { IPagesState } from './types'

export const pageAdapter = createEntityAdapter<IPage>()

const slice = createSlice({
    name: 'pages',
    initialState: pageAdapter.getInitialState<IPagesState>({
        ids: [],
        entities: {},
        status: EStatus.INITIAL,
    }),
    reducers: {
        loadPages(state, _action: PayloadAction<number>) {
            state.status = EStatus.PENDING
        },
        pagesLoaded(state, action: PayloadAction<IPageCollectionResponse>) {
            pageAdapter.setAll(state, action.payload.data)
        },
        loadPage(state, _action: PayloadAction<string>) {
            state.status = EStatus.PENDING
        },
        pageLoaded(state, action: PayloadAction<IPageItemResponse>) {
            pageAdapter.setOne(state, action.payload.data)
        },
        statusError(state) {
            state.status = EStatus.ERROR
        },
    },
})

export const { actions: pagesActions, reducer: pagesReducer } = slice
