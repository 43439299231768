import { Close as ClearIcon } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
    Box,
    Container,
    Grid,
    IconButton,
    TextField,
    Typography,
    useTheme,
} from '@mui/material'
import { SearchIcon } from 'app/components/Icons/SearchIcon'
import { MindboxTarget } from 'app/components/MinboxContactForm'
import { CategoryCardsList } from 'app/modules/Categories/templates/CategoryCardsList'
import { ProductCard } from 'app/modules/Products/components/ProductCard'
import { selectSearchPageData } from 'app/modules/Products/slice/selectors'
import { useFormik } from 'formik'
import useOnScreen from 'hooks/useOnScreen'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EStatus } from 'types'
import * as yup from 'yup'

import { useHandleSearch, useSearchQuery } from '../../hooks/useSearch'
import { searchActions } from '../../slice'
import { selectStatus } from '../../slice/selectors'

export const DesktopSearchList: React.FC = () => {
    const { handleSeach } = useHandleSearch()
    const { query } = useSearchQuery()
    const dispatch = useDispatch()
    const ref = useRef()
    const isVisible = useOnScreen(ref)

    const data = {
        query,
    }

    const {
        totalProductsCount: total,
        totalPages,
        products,
    } = useSelector(selectSearchPageData)
    const status = useSelector(selectStatus)

    const [page, setPage] = useState<number>(1)

    const count = products.length

    const theme = useTheme()

    const validationSchema = yup.object({
        query: yup.string().required(),
    })

    const formik = useFormik({
        validationSchema,
        initialValues: data,
        validateOnBlur: false,
        validateOnChange: false,
        enableReinitialize: true,
        onSubmit: (values) => {
            handleSeach(values.query)
        },
    })

    const handleClickShowMore = () => {
        if (page >= totalPages) {
            return
        }

        dispatch(
            searchActions.search({
                page: page + 1,
                perPage: 27,
                searchQuery: query,
            })
        )
        setPage(page + 1)
    }

    const handleClearSearch = () => {
        formik.setValues({ query: '' })
    }

    useEffect(() => {
        setPage(1)
        dispatch(
            searchActions.search({
                page: 1,
                perPage: 27,
                searchQuery: query,
            })
        )
    }, [query])

    // useEffect(() => {
    //     if (isVisible && status === EStatus.FINISHED && page < totalPages) {
    //         handleClickShowMore()
    //     }
    // }, [isVisible])

    return (
        <>
            <Container
                sx={{
                    mt: { md: 35, lg: 17, xl: 17 },
                }}
            >
                <TextField
                    sx={{ mt: { lg: 5.625 } }}
                    fullWidth
                    variant="outlined"
                    placeholder="Поиск по товарам"
                    name="query"
                    value={formik.values.query}
                    error={!!formik.errors.query}
                    onChange={formik.handleChange}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            formik.handleSubmit()
                        }
                    }}
                    InputProps={{
                        startAdornment: (
                            <>
                                <Box mr={1.25}>
                                    <SearchIcon
                                        color={theme.palette.grey[400]}
                                    />
                                </Box>

                                <IconButton
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,
                                        m: 1,
                                        color: theme.palette.grey[400],
                                        '&:hover': {
                                            color: theme.palette.primary.main,
                                        },
                                    }}
                                    onClick={handleClearSearch}
                                >
                                    <ClearIcon />
                                </IconButton>
                            </>
                        ),
                    }}
                />

                {total !== 0 && (
                    <>
                        <Box
                            mt={5}
                            mb={11}
                            display={'flex'}
                            alignItems={'flex-end'}
                        >
                            <Typography
                                variant="h5"
                                fontWeight={500}
                                sx={{ textTransform: 'uppercase' }}
                            >
                                {status === EStatus.PENDING && page === 1
                                    ? `ИЩЕМ ПО ЗАПРОСУ «${query}»`
                                    : total !== 0
                                    ? `НАЙДЕНО ПО ЗАПРОСУ «${query}»`
                                    : `ПО ЗАПРОСУ «${query}» ничего не найдено`}
                            </Typography>

                            <Typography
                                ml={1.25}
                                variant="body2"
                                color="grey.600"
                            >
                                {status === EStatus.PENDING && page === 1
                                    ? ''
                                    : total !== 0
                                    ? `${total} товаров`
                                    : 'Попробуйте найти нужные товары в рубриках'}
                            </Typography>
                        </Box>

                        <Grid
                            container
                            rowSpacing={{ xs: 3, md: 8.75 }}
                            columnSpacing={{ xs: 1.5, md: 2 }}
                        >
                            {(!products.length && status !== EStatus.FINISHED
                                ? Array.from(new Array(3))
                                : products
                            ).map((product, index) => (
                                <Grid item key={index} xs={6} lg={2.4}>
                                    <ProductCard product={product} />
                                </Grid>
                            ))}
                        </Grid>
                    </>
                )}

                <Box ref={ref}>
                    {!!products.length &&
                        totalPages > 1 &&
                        (page < totalPages || status !== EStatus.FINISHED) && (
                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                mt={10}
                            >
                                <Typography variant="body2">{`Показано ${count} из ${total}`}</Typography>

                                <LoadingButton
                                    sx={{ mt: 2 }}
                                    variant="banner"
                                    color="dark"
                                    loading={status !== EStatus.FINISHED}
                                    onClick={handleClickShowMore}
                                >
                                    Показать ещё
                                </LoadingButton>
                            </Box>
                        )}
                </Box>

                {total === 0 && (
                    <>
                        <Box
                            mt={5}
                            mb={11}
                            display={'flex'}
                            flexDirection={'column'}
                        >
                            <Typography
                                variant="h5"
                                fontWeight={500}
                                sx={{ textTransform: 'uppercase' }}
                            >
                                {status === EStatus.PENDING
                                    ? `ИЩЕМ ПО ЗАПРОСУ «${query}»`
                                    : `ПО ЗАПРОСУ «${query}» ничего не найдено`}
                            </Typography>

                            <Typography variant="body2" color="grey.600">
                                {status === EStatus.PENDING
                                    ? ''
                                    : 'Попробуйте найти нужные товары в рубриках'}
                            </Typography>
                        </Box>

                        {status === EStatus.FINISHED && (
                            <Box sx={{ mt: { lg: 10, xs: 7 } }}>
                                <CategoryCardsList />
                            </Box>
                        )}
                    </>
                )}
            </Container>
            <MindboxTarget
                sx={{
                    mt: { xs: '40px', md: '50px' },
                    mb: { xs: -5, md: -20 },
                }}
                popmechanicId="57914"
            />
        </>
    )
}
