import { useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import { IShop } from 'types/IShop'

import { DesktopShopCard } from './DesktopShopCard'
import { MobileShopCard } from './MobileShopCard'

interface ShopCardProps {
    shop: IShop
}

export const ShopCard: React.FC<ShopCardProps> = ({ shop }) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'lg'))
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

    return (
        <>
            {isDesktop && <DesktopShopCard shop={shop} />}

            {isMobile && <MobileShopCard shop={shop} />}
        </>
    )
}
