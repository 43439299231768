import { Box, Button, Container, Grid, Typography } from '@mui/material'
import { pagesActions } from 'app/modules/Articles/slice'
import { selectPages } from 'app/modules/Articles/slice/selectors'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { ArticleCard } from '../components/ArticleCard'

export const MainArticleCardsList: React.FC = () => {
    const dispatch = useDispatch()
    const pages = useSelector(selectPages)
    const history = useHistory()

    useEffect(() => {
        dispatch(pagesActions.loadPages(2))
    }, [])

    const handleAllArticles = () => {
        history.push('/journal')
    }

    return (
        <Container>
            <Typography
                gutterBottom
                sx={{ textTransform: 'uppercase' }}
                variant="h4"
                textAlign="center"
                fontWeight="medium"
            >
                Soul Life
            </Typography>

            <Typography variant="subtitle1" textAlign="center">
                Журнал о жизни, любви, энергии и cчастье &mdash; об Island Soul
            </Typography>

            <Grid container mt={{ md: 3.75 }} columnSpacing={3.75}>
                {pages.slice(0, 2).map((page, index) => (
                    <Grid key={index} item xs={12} md={6}>
                        <ArticleCard page={page} photoLayout={'horizontal'} />
                    </Grid>
                ))}
            </Grid>

            <Box display="flex" justifyContent="center" mt={4}>
                <Button
                    variant="banner"
                    color="dark"
                    onClick={handleAllArticles}
                >
                    Показать все
                </Button>
            </Box>
        </Container>
    )
}
