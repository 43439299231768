import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'types'

import { imagesAdapter } from '.'

const { selectById } = imagesAdapter.getSelectors()

const selectDomain = (state: RootState) => state.imagesForArticles

export const selectImageForArticlesById = createSelector(
    [selectDomain],
    (state) => (id?: string, width?: string, height?: string) => {
        if (!id) return ''

        const image = selectById(state, id)

        if (!image) return ''

        if (width && height) {
            const sizedImage = image.attributes.styles.find(
                (value) => value.width === width && value.height === height
            )?.url

            if (sizedImage) return sizedImage
        }

        return image.attributes.original_url
    }
)
