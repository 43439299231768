import { Box, Button } from '@mui/material'
import React from 'react'

export type HeaderProfileButtonProps = {
    handleClick: () => void
    iconColor: string
}

export const HeaderButton: React.FC<HeaderProfileButtonProps> = ({
    handleClick,
    iconColor,
    children,
}) => {
    return (
        <Button
            variant="text"
            onClick={handleClick}
            sx={{
                color: iconColor,
                pt: '5px',
                textTransform: 'capitalize',
                mx: '-10px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '1px',
                }}
            >
                {children}
            </Box>
        </Button>
    )
}
