import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'

export interface SaleBadgetProps {
    salePercent: number
}

export const SaleBadget: React.FC<SaleBadgetProps> = ({ salePercent }) => {
    if (!salePercent) return null

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))

    return (
        <Box
            sx={{
                px: {
                    xs: 1,
                    md: 1.5,
                },
                height: {
                    xs: 23,
                    md: 35,
                },
                borderRadius: 35,
                backgroundColor: '#F15C3E',
                display: 'flex',
                alignItems: 'center',
                width: 'fit-content',
            }}
        >
            <Typography
                variant={isMobile ? 'body3' : 'body1'}
                color="white"
                sx={{ fontSize: { xs: '10px', lg: 'unset' }, fontWeight: 400 }}
            >{`-${salePercent}%`}</Typography>
        </Box>
    )
}
