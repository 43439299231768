import { Box, Container, Grid, Typography } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import {
    GreyIconInfoBlock,
    GreyIconInfoBlockItem,
} from 'app/components/GreyIconInfoBlock'
import GiftAdviceIcon from 'assets/icons/gift_advice.svg'
import MaintenanceRepairProductsIcon from 'assets/icons/maintenance_and_repair_of_products.svg'
import PersonalizationIcon from 'assets/icons/Personalization.svg'
import SizeGuideIcon from 'assets/icons/size_guide.svg'
import React from 'react'

const useStyles = makeStyles(() =>
    createStyles({
        image: {
            width: '100%',
            height: 'auto',
        },
    })
)

export const ServiceDepartmentClients: React.FC = () => {
    const classes = useStyles()

    const listItems: GreyIconInfoBlockItem[] = [
        {
            icon: PersonalizationIcon,
            title: 'ПЕРСОНАЛИЗАЦИЯ',
            description:
                'Мы предлагаем различные способы оставить ваш след на изделии Island soul, от гравировки до тиснения.',
        },
        {
            icon: MaintenanceRepairProductsIcon,
            title: 'уход и ремонт изделий',
            description:
                'Сохраните красоту украшений и аксессуаров Island soul с помощью чистки, ухода и ремонта.',
        },
        {
            icon: GiftAdviceIcon,
            title: 'Консультация по подаркам',
            description:
                'Мы будем рады помочь вам в поиске идеального подарка, от классических украшений до причудливых предметов декора.',
        },
        {
            icon: SizeGuideIcon,
            title: 'гид по размерам',
            href: '/static/ring_size#ring_size_table',
            description:
                'Найдите идеальный размер с помощью нашего гида по размерам украшений Island soul',
        },
    ]

    return (
        <Container
            sx={{
                mt: { md: 30, lg: 20, xl: 20 },
            }}
        >
            <Grid container>
                <Grid
                    item
                    xs={12}
                    lg={6}
                    display={'flex'}
                    alignItems={'center'}
                >
                    <Box>
                        <Box pr={12}>
                            <img
                                src="/assets/images/bg-is-stamp-loop2.png"
                                alt={'bg-is-stamp-loop'}
                            />
                            <Typography
                                variant="h4"
                                fontWeight={500}
                                mt={7}
                                sx={{
                                    textTransform: 'uppercase',
                                }}
                            >
                                Island soul Experience до глубин души
                            </Typography>
                            <Typography variant="body2" mt={4}>
                                Для экспертов отдела обслуживания клиентов
                                IslandSoul не бывает маловажных вопросов или
                                чрезмерно сложных просьб. Мы всегда к вашим
                                услугам, от выбора помолвочного кольца или
                                подарка до персональных консультаций или ухода и
                                ремонта изделий.
                            </Typography>
                        </Box>
                        <Box mt={3.75} pr={3.75}>
                            {listItems.map((item, index) => (
                                <GreyIconInfoBlock key={index} item={item} />
                            ))}
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <img
                        className={classes.image}
                        src="/assets/images/Rectangle1167.jpg"
                        alt={'Island soul Experience до глубин души'}
                    />
                </Grid>
            </Grid>
        </Container>
    )
}
