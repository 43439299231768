import { FormGroup, Typography } from '@mui/material'
import { selectCurrentCity } from 'app/modules/Cities/slice/selectors'
import React from 'react'
import { useSelector } from 'react-redux'

import { useCategoryPageContext } from '../../hooks'
import { ProductsFilterKind } from '../../ProductsFilterKind'
import { ProductsFilterFormControlLabel } from './ProductsFilterFormControlLabel'

const SOCHI_IDS = ['22', '31']

export const ProductsFilterOnlyInStock: React.FC = () => {
    const { filterReadAdapter, handleAvailability } = useCategoryPageContext()

    const city = useSelector(selectCurrentCity)

    const labelText = SOCHI_IDS.includes(city?.id ?? '')
        ? 'Только в наличии'
        : 'В наличии на центральном складе'

    return (
        <ProductsFilterKind name="Наличие">
            <FormGroup>
                <ProductsFilterFormControlLabel
                    key="availability"
                    labelText={
                        <Typography
                            variant="body2"
                            sx={{
                                fontSize: { xs: '14px', lg: '14px' },
                                lineHeight: { lg: '23px' },
                            }}
                        >
                            {labelText}
                        </Typography>
                    }
                    checked={
                        filterReadAdapter.available_in_current_city || false
                    }
                    onChange={() => handleAvailability()}
                />
            </FormGroup>
        </ProductsFilterKind>
    )
}
