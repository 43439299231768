import { selectLineItemById } from 'app/modules/LineItems/slice/selectors'
import { selectVariantById } from 'app/modules/Variants/slice/selectors'
import { useSelector } from 'react-redux'
import { ILineItem } from 'types/ILineItem'
import { IVariant } from 'types/IVariant'

import { selectCart } from '../slice/selectors'

export const useCartItemsOutOfStock = () => {
    const cart = useSelector(selectCart)
    const getLineItem = useSelector(selectLineItemById)
    const getVariant = useSelector(selectVariantById)

    const listItems =
        cart?.relationships.line_items.data.reduce(
            (acc: { lineItem: ILineItem; variant: IVariant }[], value) => {
                const lineItem = getLineItem(value.id)
                const variant = getVariant(
                    lineItem?.relationships.variant.data?.id
                )

                if (lineItem && variant) {
                    return [...acc, { lineItem, variant }]
                }

                return acc
            },
            []
        ) || []

    const priceDiff = listItems.reduce((capasitor, product) => {
        const inCart = product.lineItem.attributes.quantity
        const inStock = product.variant.attributes.total_on_hand

        if (inCart > inStock) {
            return (
                capasitor +
                Number(product.lineItem.attributes.price) * (inCart - inStock)
            )
        }
        return capasitor
    }, 0)

    const emptyCart = (cart?.attributes.item_count ?? 1) === 0

    return { outOfStock: Boolean(priceDiff) || emptyCart, priceDiff }
}
