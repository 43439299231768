import { PayloadAction } from '@reduxjs/toolkit'
import {
    AddProductLists,
    mindboxAddToList,
    mindboxRemoveFromList,
    RemoveProductLists,
} from 'app/features/MindBox'
import { selectAccountWholesale } from 'app/modules/Account/slice/selectors'
import { imagesActions } from 'app/modules/Images/slice'
import { multiverseActions } from 'app/modules/Multiverse/slice'
import { variantsActions } from 'app/modules/Variants/slice'
import { videosActions } from 'app/modules/Videos/slice'
import { useLocation } from 'react-router-dom'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import {
    IProductCollectionResponse,
    IProductItemResponse,
    ProductFilterParams,
    ProductSortVariants,
} from 'types/IProduct'
import { request } from 'utils/request'

import { productsActions } from '.'
import { IProductsRequest } from './types'

export function* getProduct(action: PayloadAction<string>) {
    try {
        const response: IProductItemResponse = yield call(
            request,
            `/api/v2/storefront/products/${action.payload}`,
            {
                params: {
                    include: [
                        'images',
                        'variants',
                        'primary_variant',
                        'videos.preview',
                        'taxons',
                    ].toString(),
                },
            }
        )

        yield put(imagesActions.imagesAdded(response))
        yield put(videosActions.videosAdded(response))
        yield put(variantsActions.variantsLoaded(response))
        yield put(productsActions.productLoaded(response.data))
    } catch (error: any) {
        yield put(productsActions.statusProductError())
    }
}

export function* loadCategoryPageProducts(
    action: PayloadAction<IProductsRequest>
) {
    try {
        const {
            sort,
            segments,
            blacklist,
            perPage: per_page,
            filter,
            page,
        } = action.payload

        const wholesale: boolean = yield select(selectAccountWholesale)

        const response: IProductCollectionResponse = yield call(
            request,
            '/api/v2/storefront/products',
            {
                params: {
                    page,
                    sort,
                    blacklist: blacklist?.join(', '),
                    segments: segments?.length ? segments[0] : '',
                    per_page,
                    filter: {
                        ...filter,
                        price: filter.price?.join(','),
                        available_in_current_city: !wholesale,
                    },
                    include: ['images', 'videos.preview'].join(','),
                },
            }
        )

        const total = response.meta.total_count
        const totalPages = response.meta.total_pages

        yield put(imagesActions.imagesAdded(response))
        yield put(videosActions.videosAdded(response))

        yield put(productsActions.setFilterParams(response))

        yield put(
            productsActions.categoryPageProductsLoaded({
                products: response.data,
                totalPages: totalPages,
                totalProductsCount: total,
                addProducts: page > 1,
            })
        )
    } catch (error: any) {
        yield put(productsActions.statusProductsError())
    }
}

export function* getSaleProducts(action: PayloadAction<IProductsRequest>) {
    try {
        const { sort, perPage: per_page, filter } = action.payload
        const response: IProductCollectionResponse = yield call(
            request,
            '/api/v2/storefront/products',
            {
                params: {
                    page: 1,
                    sort,
                    per_page,
                    filter: {
                        ...filter,
                        price: filter.price?.join(','),
                    },
                    include: ['images'].join(','),
                },
            }
        )

        yield put(imagesActions.imagesAdded(response))
        yield put(productsActions.saleProductsLoaded(response.data))
    } catch (error: any) {
        yield put(productsActions.statusProductsError())
    }
}

export function* getFavorites(action: PayloadAction<number>) {
    try {
        const page = action.payload
        const requestURL = `/api/v2/storefront/account/favorites`

        const response: IProductCollectionResponse = yield call(
            request,
            requestURL,
            {
                params: {
                    page,
                    per_page: 8,
                    include: ['images', 'videos.preview'].join(','),
                },
            }
        )

        const total = response.meta.total_count
        const totalPages = response.meta.total_pages

        yield put(imagesActions.imagesAdded(response))
        yield put(videosActions.videosAdded(response))

        yield put(productsActions.setFilterParams(response))

        yield put(
            productsActions.favoriteProductsLoaded({
                products: response.data,
                totalPages: totalPages,
                totalProductsCount: total,
                addProducts: page > 1,
            })
        )
    } catch (error: any) {
        yield put(productsActions.statusProductsError())
    }
}

export function* productToggleFavorite(action: PayloadAction<string>) {
    try {
        const response: IProductItemResponse = yield call(
            request,
            `/api/v2/storefront/products/${action.payload}/toggle_favorite`
        )

        const wholesale: boolean = yield select(selectAccountWholesale)

        if (response.data.attributes.favorite_for_user) {
            mindboxAddToList(
                response.data,
                AddProductLists.addToFavorites,
                wholesale
            )
        } else {
            mindboxRemoveFromList(
                response.data,
                RemoveProductLists.removeFromFavorites,
                wholesale
            )
        }

        yield put(productsActions.productLoaded(response.data))
    } catch (error: any) {
        yield put(productsActions.statusProductError())
    }
}

export function* productToggleFavorite1C(action: PayloadAction<string>) {
    try {
        const response: IProductItemResponse = yield call(
            request,
            `/api/v2/storefront/products/${action.payload}/toggle_favorite_1c`,
            {
                method: 'POST',
            }
        )

        const wholesale: boolean = yield select(selectAccountWholesale)

        if (response.data.attributes.favorite_for_user) {
            mindboxAddToList(
                response.data,
                AddProductLists.addToFavorites,
                wholesale
            )
        } else {
            mindboxRemoveFromList(
                response.data,
                RemoveProductLists.removeFromFavorites,
                wholesale
            )
        }

        yield put(productsActions.productLoaded(response.data))
    } catch (error: any) {
        yield put(productsActions.statusProductError())
    }
}

export function* getPopularProducts(action: PayloadAction<IProductsRequest>) {
    try {
        const { sort, perPage: per_page, filter } = action.payload

        const response: IProductCollectionResponse = yield call(
            request,
            '/api/v2/storefront/products',
            {
                params: {
                    page: 1,
                    sort,
                    per_page,
                    filter: {
                        ...filter,
                        price: filter.price?.join(','),
                    },
                    include: ['images'].join(','),
                },
            }
        )

        const noveltyProducts = response.data

        yield put(imagesActions.imagesAdded(response))
        yield put(productsActions.popularProductsLoaded(noveltyProducts))
    } catch (error: any) {
        yield put(productsActions.statusProductsPopularError())
    }
}

export function* getNoveltyProducts(action: PayloadAction<IProductsRequest>) {
    try {
        const { sort, perPage: per_page, filter } = action.payload

        const response: IProductCollectionResponse = yield call(
            request,
            '/api/v2/storefront/products',
            {
                params: {
                    page: 1,
                    sort,
                    per_page,
                    filter: {
                        ...filter,
                        price: filter.price?.join(','),
                    },
                    include: ['images'].join(','),
                },
            }
        )

        const noveltyProducts = response.data

        yield put(imagesActions.imagesAdded(response))
        yield put(productsActions.noveltyProductsLoaded(noveltyProducts))
    } catch (error: any) {
        yield put(productsActions.statusProductsError())
    }
}

export function* getGiftProducts(
    action: PayloadAction<{ taxons: string; perPage: number }>
) {
    try {
        const { perPage: per_page, taxons } = action.payload

        const response: IProductCollectionResponse = yield call(
            request,
            '/api/v2/storefront/products',
            {
                params: {
                    page: 1,
                    sort: ProductSortVariants.CREATED_AT,
                    per_page,
                    filter: {
                        taxons,
                    },
                    include: ['images'].join(','),
                },
            }
        )

        const noveltyProducts = response.data

        yield put(imagesActions.imagesAdded(response))
        yield put(productsActions.giftProductsLoaded(noveltyProducts))
    } catch (error: any) {
        yield put(productsActions.statusProductsError())
    }
}

export function* getMultiverseProducts(action: PayloadAction<string[]>) {
    try {
        const filter: ProductFilterParams = {
            ids: action.payload.join(','),
        }

        const response: IProductCollectionResponse = yield call(
            request,
            '/api/v2/storefront/products',
            {
                params: {
                    page: 1,
                    sort: ProductSortVariants.CREATED_AT,
                    per_page: action.payload.length,
                    filter,
                    include: ['images', 'multiverse'].join(','),
                },
            }
        )

        const multiverseProducts = response.data

        yield put(imagesActions.imagesAdded(response))
        yield put(multiverseActions.multiverseOjectsAdded(response))
        yield put(productsActions.multiverseProductsLoaded(multiverseProducts))
    } catch (error: any) {
        yield put(productsActions.statusProductsError())
    }
}

export function* productsWatcher() {
    yield takeLatest(productsActions.loadProduct.type, getProduct)
    yield takeLatest(
        productsActions.loadCategoryPageProducts.type,
        loadCategoryPageProducts
    )
    yield takeLatest(
        productsActions.loadNextCategoryPageProducts.type,
        loadCategoryPageProducts
    )
    yield takeLatest(productsActions.loadFavorites.type, getFavorites)
    yield takeLatest(productsActions.loadSaleProducts.type, getSaleProducts)
    yield takeLatest(
        productsActions.productToggleFavorite.type,
        productToggleFavorite
    )
    yield takeLatest(
        productsActions.productToggleFavorite1C.type,
        productToggleFavorite1C
    )
    yield takeLatest(
        productsActions.loadPopularProducts.type,
        getPopularProducts
    )
    yield takeLatest(
        productsActions.loadNoveltyProducts.type,
        getNoveltyProducts
    )
    yield takeLatest(productsActions.loadGiftProducts.type, getGiftProducts)
    yield takeLatest(
        productsActions.getMultiverseProducts.type,
        getMultiverseProducts
    )
}
