import { ArrowBack as ArrowBackIcon } from '@mui/icons-material'
import { Box, Container, IconButton } from '@mui/material'
import { Modal } from 'app/components/Modal'
import { authActions } from 'app/modules/Auth/slice'
import { selectModalStatus } from 'app/modules/Auth/slice/selectors'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CodeForm } from '../components/CodeForm'
import { PhoneForm } from '../components/PhoneForm'

export const AuthModal: React.FC = () => {
    const dispatch = useDispatch()

    const modalStatus = useSelector(selectModalStatus)

    const handleBack = () => {
        dispatch(authActions.setModalStatus('phone'))
    }

    const handleClose = () => {
        dispatch(authActions.setModalStatus('close'))
    }

    return (
        <Modal
            open={modalStatus !== 'close'}
            title={'Вход'}
            handleClose={handleClose}
        >
            <Container
                sx={{
                    pt: 11.25,
                    flex: 1,
                    display: 'flex',
                    position: 'relative',
                }}
            >
                {modalStatus === 'code' && (
                    <>
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                mt: 2.5,
                            }}
                            onClick={handleBack}
                        >
                            <IconButton color="primary">
                                <ArrowBackIcon />
                            </IconButton>
                        </Box>

                        <CodeForm />
                    </>
                )}

                {modalStatus === 'phone' && <PhoneForm />}
            </Container>
        </Modal>
    )
}
