import 'swiper/swiper.min.css'
import 'swiper/modules/navigation/navigation.min.css' // Navigation module
import 'swiper/modules/pagination/pagination.min.css' // Pagination module
import './styles.css'

import { Box, CardMedia, IconButton } from '@mui/material'
import { AutoplayVideo } from 'app/components/AutoplayVideo'
import { AddProductLists, mindboxAddToList } from 'app/features/MindBox'
import { selectAccountWholesale } from 'app/modules/Account/slice/selectors'
import ShareIcon from 'assets/icons/Share.svg'
import React from 'react'
import { useSelector } from 'react-redux'
import SwiperCore, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { ProductProps } from '../../templates/Product/Product'

SwiperCore.use([Pagination])

export const ProductMobileVisualContent: React.FC<ProductProps> = ({
    product,
    productVisualContent,
}) => {
    const wholesale = useSelector(selectAccountWholesale)

    const handleShareClick = () => {
        if (navigator.share) {
            navigator
                .share({ url: window.location.href })
                .then(() => {
                    mindboxAddToList(
                        product,
                        AddProductLists.addToShared,
                        wholesale
                    )
                })
                .catch((error) => {
                    console.error('Something went wrong', error)
                })
        }
    }

    const renderBullet = function (index: number, className: string) {
        const selectedVisualContent = productVisualContent[index]

        return `<span class="${className} ${
            selectedVisualContent && 'videoSrc' in selectedVisualContent
                ? 'swiper-pagination-bullet-video'
                : 'swiper-pagination-bullet-image'
        }"></span>`
    }

    return (
        <Box
            sx={{
                width: '100%',
                height: '106.66vw',
            }}
        >
            <Swiper
                modules={[Pagination]}
                pagination={{
                    clickable: true,
                    dynamicBullets: true,
                    dynamicMainBullets: 3,
                    renderBullet,
                }}
            >
                {!!productVisualContent &&
                    productVisualContent.map((visualContent, index) => (
                        <SwiperSlide key={index}>
                            {'videoSrc' in visualContent ? (
                                <AutoplayVideo
                                    src={visualContent.videoSrc}
                                    poster={visualContent.placeholder}
                                    sx={{
                                        objectFit: 'cover',
                                        width: '100%',
                                        height: '106.66vw',
                                    }}
                                />
                            ) : (
                                <CardMedia
                                    sx={{
                                        objectFit: 'cover',
                                        width: '100%',
                                        height: '106.66vw',
                                    }}
                                    src={visualContent.middle}
                                    component="img"
                                />
                            )}
                        </SwiperSlide>
                    ))}
            </Swiper>

            <IconButton
                onClick={handleShareClick}
                sx={{
                    position: 'absolute',
                    top: 12,
                    right: 12,
                    backgroundColor: 'rgba(255, 255, 255, 0.4)',
                    '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.4)',
                    },
                    zIndex: 1,
                }}
            >
                <Box component="img" src={ShareIcon}></Box>
            </IconButton>
        </Box>
    )
}
