import { Box, CardMedia, IconButton } from '@mui/material'
import { AutoplayVideo } from 'app/components/AutoplayVideo'
import React, { useState } from 'react'

import { ReactComponent as ArrowDownIcon } from '../../assets/ArrowDown.svg'
import { ReactComponent as ArrowUpIcon } from '../../assets/ArrowUp.svg'
import { ReactComponent as PlayIcon } from '../../assets/Play.svg'
import { ProductProps } from '../../templates/Product/Product'

export const ProductDesktopVisualContent: React.FC<ProductProps> = ({
    productVisualContent,
}) => {
    const [imageIndex, setImageIndex] = useState(0)
    const maxStep = Math.ceil(productVisualContent.length / 5) - 1
    const [step, setStep] = useState<number>(0)

    const handleImageClick = React.useCallback(
        (index: number) => () => {
            setImageIndex(index)
        },
        []
    )

    const selectedContent = productVisualContent[imageIndex]

    return (
        <Box sx={{ display: 'flex', gap: '20px', height: 'inherit' }}>
            <Box>
                {maxStep > 0 && (
                    <Box
                        sx={{
                            ml: '45px',
                            display: 'flex',
                            width: '6.51vw',
                            justifyContent: 'center',
                            mb: '12px',
                        }}
                    >
                        <IconButton
                            sx={{
                                mt: '-8px',
                                color: step < 1 ? 'grey.400' : 'black',
                            }}
                            disabled={step < 1}
                            onClick={() => setStep((value) => value - 1)}
                        >
                            <ArrowUpIcon />
                        </IconButton>
                    </Box>
                )}

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        ml: '45px',
                        gap: '20px',
                        overflow: 'hidden',
                        height: 'calc( 32.55vw + 80px )',
                    }}
                >
                    {productVisualContent.map((visualContent, index) => {
                        return (
                            <Box
                                sx={{
                                    marginTop:
                                        index === 0
                                            ? `calc( -${32.55 * step}vw - ${
                                                  100 * step
                                              }px )`
                                            : 0,
                                    width: '6.51vw',
                                    height: '6.51vw',
                                    borderRadius: '16px',
                                    position: 'relative',
                                    transition: '0.5s',
                                }}
                                key={index}
                                onClick={handleImageClick(index)}
                            >
                                <Box
                                    sx={{
                                        width: 'inherit',
                                        height: 'inherit',
                                        borderRadius: 'inherit',
                                        objectFit: 'cover',
                                    }}
                                    src={visualContent.mini}
                                    component="img"
                                />
                                {imageIndex !== index && (
                                    <Box
                                        sx={{
                                            background:
                                                'linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5))',
                                            position: 'absolute',
                                            width: 'inherit',
                                            height: 'inherit',
                                            borderRadius: 'inherit',
                                            bottom: '0px',
                                            cursor: 'pointer',
                                        }}
                                    />
                                )}
                                {'videoSrc' in visualContent && (
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            width: 'inherit',
                                            height: 'inherit',
                                            bottom: '0px',
                                            cursor: 'pointer',
                                            justifyContent: 'center',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <PlayIcon />
                                    </Box>
                                )}
                            </Box>
                        )
                    })}
                </Box>

                {maxStep > 0 && (
                    <Box
                        sx={{
                            ml: '45px',
                            display: 'flex',
                            width: '6.51vw',
                            justifyContent: 'center',
                            mt: '20px',
                        }}
                    >
                        <IconButton
                            sx={{
                                mt: '-8px',
                                color: step >= maxStep ? 'grey.400' : 'black',
                            }}
                            disabled={step >= maxStep}
                            onClick={() => setStep((value) => value + 1)}
                        >
                            <ArrowDownIcon />
                        </IconButton>
                    </Box>
                )}
            </Box>

            {selectedContent &&
                ('videoSrc' in selectedContent ? (
                    <AutoplayVideo
                        sx={{
                            width: '55.46875vw',
                            height: 'inherit',
                            borderRadius: '28px',
                            objectFit: 'cover',
                        }}
                        src={selectedContent.videoSrc}
                    />
                ) : (
                    <CardMedia
                        sx={{
                            width: '55.46875vw',
                            height: 'inherit',
                            borderRadius: '28px',
                            objectFit: 'cover',
                        }}
                        src={selectedContent.large}
                        component="img"
                    />
                ))}
        </Box>
    )
}
