import 'react-toastify/dist/ReactToastify.min.css'

import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import ym, { YMInitializer } from 'react-yandex-metrika'

import { yandexMetricaSuccessfullPayment } from './../utils/yandexMetrica'
// import { SnowEffect } from './components/SnowEffect'
import { MAIN_PAGE_TITLE } from './constants'
import { YandexTranslateContextProvider } from './features/YandexTranslate'
import { CookieInfoDialog } from './modules/CookieInfoDialog'
import { RouterContextProvider } from './modules/Navigation/RouterContext'
import { Pages } from './pages'

export const App: React.FC = () => {
    const query = new URLSearchParams(window.location.search)
    const utm_source = query.get('utm_source')
    const utm_medium = query.get('utm_medium')
    const utm_campaign = query.get('utm_campaign')
    const utm_content = query.get('utm_content')
    const utm_term = query.get('utm_term')
    const utm_keyword = query.get('utm_keyword')

    useEffect(() => {
        if (utm_source) {
            localStorage.setItem('utm_source', utm_source)
        }
        if (utm_medium) {
            localStorage.setItem('utm_medium', utm_medium)
        }
        if (utm_campaign) {
            localStorage.setItem('utm_campaign', utm_campaign)
        }
        if (utm_content) {
            localStorage.setItem('utm_content', utm_content)
        }
        if (utm_term) {
            localStorage.setItem('utm_term', utm_term)
        }
        if (utm_keyword) {
            localStorage.setItem('utm_keyword', utm_keyword)
        }
    }, [utm_source, utm_medium, utm_campaign, utm_content, utm_term])

    useEffect(() => {
        if (yandexMetricaSuccessfullPayment()) {
            ym('reachGoal', 'successful payment')
        }
    }, [window.location.href])

    return (
        <>
            <BrowserRouter>
                <Helmet defaultTitle={MAIN_PAGE_TITLE}>
                    <meta
                        name="description"
                        content="Дизайнерские ювелирные изделия из серебра и золота, камни, украшения для дома. Интернет-магазин ювелирных изделий. Адреса магазинов партнёров"
                    />
                </Helmet>

                <YMInitializer
                    accounts={[88167866]}
                    options={{
                        clickmap: true,
                        trackLinks: true,
                        accurateTrackBounce: true,
                        webvisor: true,
                        ecommerce: 'dataLayer',
                    }}
                />

                <CookieInfoDialog />
                {/* <SnowEffect /> */}

                <RouterContextProvider>
                    <YandexTranslateContextProvider>
                        <Pages />
                    </YandexTranslateContextProvider>
                </RouterContextProvider>
            </BrowserRouter>

            <ToastContainer />
        </>
    )
}
