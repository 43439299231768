import { Button, Paper, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { EStatus } from 'types'

import { OrderItem } from '../components/OrderItem'
import { ordersActions } from '../slice'
import { selectOrders, selectStatus } from '../slice/selectors'
import { OrderComplitedDialog } from './OrderComplitedDialog'

export const OrdersList: React.FC = () => {
    const dispatch = useDispatch()
    const orders = useSelector(selectOrders)
    const status = useSelector(selectStatus)

    useEffect(() => {
        dispatch(ordersActions.loadOrders())
    }, [])

    return (
        <>
            <OrderComplitedDialog />
            <Stack spacing={{ xs: 2, md: 3.75 }}>
                {(!orders.length && status !== EStatus.FINISHED
                    ? Array.from(new Array(1))
                    : orders
                ).map((order, index) => (
                    <OrderItem key={index} order={order} />
                ))}
            </Stack>
            {!orders.length && status === EStatus.FINISHED && (
                <Paper
                    sx={{
                        width: '100%',
                        px: 6.25,
                        pb: 6.25,
                        pt: 5.625,
                    }}
                >
                    <Typography variant="body1" color="grey.600">
                        У Вас пока нет заказов
                    </Typography>
                    <Button
                        sx={{ mt: 14.125 }}
                        size="large"
                        color="primary"
                        variant="contained"
                        to={'/'}
                        component={Link}
                    >
                        Перейти на главную
                    </Button>
                </Paper>
            )}
        </>
    )
}
