import { Box, Container } from '@mui/material'
import { DESKTOP_HEADER_HEIGHT } from 'app/modules/Layout/components/Header/DesktopHeader'
import {
    LoyaltyBenefits,
    LoyaltyFAQ,
    LoyaltyMainBanner,
} from 'app/modules/LoyaltyPage'
import React from 'react'

export const LoyaltyPage: React.FC = () => {
    return (
        <Box>
            <Box
                minHeight={{
                    // Этот блок нужен для отступа сверху, так как хедер находится в состоянии roll-up
                    // А не в  fixed, но замена этого состояния изменит все другие статичные страницы
                    lg: `${DESKTOP_HEADER_HEIGHT}px`,
                }}
            />
            <Container
                sx={{
                    mt: { xs: '0px' },
                    px: { xs: '0px' },
                }}
            >
                <LoyaltyMainBanner />
            </Container>
            <Container
                sx={(theme) => ({
                    pt: { xs: '50px', md: '120px' },
                    px: { xs: '16px', md: '45px' },
                    backgroundColor: `${theme.palette.primary.main}14`,
                })}
            >
                <LoyaltyBenefits />
            </Container>
            <Container
                sx={(theme) => ({
                    py: { xs: '50px', md: '120px' },
                    px: { xs: '16px', md: 'auto' },
                    backgroundColor: `${theme.palette.primary.main}14`,
                })}
            >
                <LoyaltyFAQ />
            </Container>
        </Box>
    )
}
