import { Box, Stack, Typography } from '@mui/material'
import React from 'react'

import { CartItemProps } from '../CartItem'

export const CartItemName: React.FC<CartItemProps> = ({ item }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
            }}
        >
            <Stack spacing={0.5}>
                <Typography
                    variant="body2"
                    sx={{
                        fontWeight: 'medium',
                        fontSize: { xs: '14px', md: '18px' },
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                    }}
                >
                    {item.attributes.name}
                </Typography>

                <Typography
                    variant="body3"
                    sx={{
                        fontSize: { xs: '12px', md: '14px' },
                    }}
                >
                    {item.attributes.options_text}
                </Typography>
            </Stack>
        </Box>
    )
}
