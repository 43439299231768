import { Stack, Typography } from '@mui/material'
import { selectCart } from 'app/modules/Cart/slice/selectors'
import { selectImageById } from 'app/modules/Images/slice/selectors'
import { selectLineItemById } from 'app/modules/LineItems/slice/selectors'
import { selectVariantById } from 'app/modules/Variants/slice/selectors'
import React from 'react'
import { useSelector } from 'react-redux'
import { ILineItem } from 'types/ILineItem'

import { CartItem } from './CartItem'

export const CartItems: React.FC = () => {
    const getLineItem = useSelector(selectLineItemById)
    const getImage = useSelector(selectImageById)
    const getVariant = useSelector(selectVariantById)

    const cart = useSelector(selectCart)

    const listItems =
        cart?.relationships.line_items.data.reduce(
            (acc: ILineItem[], value) => {
                const lineItem = getLineItem(value.id)

                if (lineItem) {
                    return [...acc, lineItem]
                }

                return acc
            },
            []
        ) || []
    const items = listItems.map((item) => {
        const variant = getVariant(item.relationships.variant.data?.id)

        const image = getImage(
            variant?.relationships.images.data[0]?.id,
            '612',
            true
        )

        return {
            item,
            image,
        }
    })

    return (
        <>
            {!items.length && (
                <Typography mt={5} variant="subtitle2">
                    Корзина пустая
                </Typography>
            )}

            <Stack spacing={3.75}>
                {items.map(({ item, image }) => (
                    <CartItem key={item.id} item={item} image={image} />
                ))}
            </Stack>
        </>
    )
}
