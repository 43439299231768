import { Box, Button, Typography } from '@mui/material'
import React from 'react'

interface ProductsFilterShowMoreFiltersButtonProps {
    collapsed: boolean
    setCollapsed: (newValue: boolean) => void
}

export const ProductsFilterShowMoreFiltersButton: React.FC<
    ProductsFilterShowMoreFiltersButtonProps
> = ({ collapsed, setCollapsed }) => {
    if (!collapsed) return null

    return (
        <Box display="flex" justifyContent="start" mt="12px">
            <Button
                variant="text"
                sx={{ p: '0px', height: '24px' }}
                onClick={() => {
                    setCollapsed(false)
                }}
            >
                <Typography
                    fontSize="14px"
                    textTransform="none"
                    fontWeight="medium"
                >
                    Показать все
                </Typography>
            </Button>
        </Box>
    )
}
