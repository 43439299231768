import { PayloadAction } from '@reduxjs/toolkit'
import { call, put, takeLatest } from 'redux-saga/effects'
import { IBannerResponse } from 'types/IBanner'
import { request } from 'utils/request'

import { bannersActions } from '.'

function* loadMainBanner() {
    try {
        const response: IBannerResponse = yield call(
            request,
            `/api/v2/storefront/banners`,
            {
                params: { 'filter[main]': 'true' },
            }
        )

        yield put(bannersActions.bannersAdded(response))
        yield put(bannersActions.mainBannerLoaded(response))
    } catch {
        //
    }
}

function* loadTaxonBanner(action: PayloadAction<{ taxonID: string }>) {
    try {
        const response: IBannerResponse = yield call(
            request,
            `/api/v2/storefront/banners`,
            {
                params: { 'filter[taxons]': action.payload.taxonID },
            }
        )

        yield put(bannersActions.bannersAdded(response))
        yield put(
            bannersActions.taxonBannerLoaded({ ...response, ...action.payload })
        )
    } catch {
        //
    }
}

export function* bannersWatcher() {
    yield takeLatest(bannersActions.loadMainBanner, loadMainBanner)
    yield takeLatest(bannersActions.loadTaxonBanner, loadTaxonBanner)
}
