import { Box, Typography } from '@mui/material'
import React from 'react'

export const LegalInfoBlock: React.FC = () => {
    return (
        <Box sx={{ mt: 2 }}>
            <Typography variant="h6">Информация о продавце</Typography>
            <Typography variant="body2">
                ИП Судорженко Дарья Викторовна
            </Typography>
            <Typography variant="body2">ИНН: 774339576937</Typography>
            <Typography variant="body2">ОГРНИП: 318774600313185</Typography>

            <Typography variant="body2">
                Телефон: <a href="tel:+78002012323">+7 800 201 23 23</a>
            </Typography>
            <Typography variant="body2">
                <a href="mailto:info@islandsouljewelry.com">
                    info@islandsouljewelry.com
                </a>
            </Typography>
        </Box>
    )
}
