import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Box, Collapse, Container, Link, Typography } from '@mui/material'
import { MobileMenuItem } from 'app/components/MobileMenuItem'
import React, { useState } from 'react'

export const ReturnExchangeCollapse: React.FC = () => {
    const [isOpen, setOpen] = useState<boolean>(false)

    return (
        <Container
            onClick={() => setOpen(!isOpen)}
            sx={{
                px: { xs: 0 },
                cursor: 'pointer',
            }}
        >
            <MobileMenuItem
                text={
                    <Typography
                        sx={{
                            fontWeight: 'medium',
                            textTransform: 'none',
                        }}
                        variant="body1"
                    >
                        Обменять или вернуть
                    </Typography>
                }
                endContent={isOpen ? <ExpandLess /> : <ExpandMore />}
            />

            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <Box>
                    <Typography variant="body2">
                        {`Вам не подошло украшение? Ничего страшного! Обменяйте или
                    верните ваши онлайн-покупки в течение 7 дней после оплаты.
                    Ознакомиться с условиями возврата и обмена можно `}
                        <Link href="https://islandsouljewelry.com/static/return_exchange">
                            здесь
                        </Link>
                    </Typography>
                </Box>
            </Collapse>
        </Container>
    )
}
