import {
    createEntityAdapter,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit'
import { ECountryCode, EStatus } from 'types'
import { ICustomer } from 'types/ICustomer'

import {
    EOfflineTradePage,
    ICustomerData,
    IOfflineTradeState,
    IOrderData,
} from './types'

export const offlineTradeAdapter = createEntityAdapter<ICustomer>()

const initialCreateCustomer: ICustomerData = {
    email: '',
    firstname: '',
    lastname: '',
    birthday: '',
    phone: '',
    city: '',
    shop_id: localStorage.getItem('is_shop_id') || '',
    code: '',
}

const slice = createSlice({
    name: 'offlineTrade',
    initialState: offlineTradeAdapter.getInitialState<IOfflineTradeState>({
        ids: [],
        entities: {},
        page: EOfflineTradePage.START,
        countryCode: ECountryCode.RU,
        forms: {
            getLoyalty: {
                status: EStatus.INITIAL,
                data: {
                    phone: '',
                },
            },
            createCustomer: {
                status: EStatus.INITIAL,
                data: initialCreateCustomer,
            },
            createOrder: {
                status: EStatus.INITIAL,
                data: {
                    customer_id: 0,
                    amount: 0,
                    promotional_amount: 0,
                    customer_firstname: '',
                    customer_lastname: '',
                    shop_id: localStorage.getItem('is_shop_id') || '',
                    bonuses: 0,
                },
            },
        },
    }),
    reducers: {
        resetPage(state) {
            state.page = EOfflineTradePage.START
            state.forms.getLoyalty.status = EStatus.INITIAL
            state.forms.getLoyalty.data.phone = ''
        },
        startPage(state) {
            state.page = EOfflineTradePage.START
            state.forms.getLoyalty.status = EStatus.INITIAL
        },
        createOrderPage(state) {
            state.page = EOfflineTradePage.CREATE_ORDER
            state.forms.createOrder.status = EStatus.INITIAL
        },
        getLoyalty(state, action: PayloadAction<string>) {
            state.forms.getLoyalty.status = EStatus.PENDING
            state.forms.getLoyalty.data.phone = action.payload
        },
        sendSms(state, _action: PayloadAction<string>) {
            state.forms.getLoyalty.status = EStatus.PENDING
        },
        changeShopId(state, action: PayloadAction<string>) {
            localStorage.setItem('is_shop_id', action.payload)
            state.forms.createCustomer.data.shop_id = action.payload
            state.forms.createOrder.data.shop_id = action.payload
        },
        loyaltyLoaded(state, action: PayloadAction<ICustomer>) {
            offlineTradeAdapter.setOne(state, {
                ...action.payload,
                id: action.payload.customer_id,
            })
            state.forms.createOrder.status = EStatus.INITIAL
            state.forms.createOrder.data.customer_id =
                action.payload.customer_id
            state.forms.createOrder.data.amount = 0
            state.forms.createOrder.data.customer_firstname =
                action.payload.customer_firstname
            state.forms.createOrder.data.customer_lastname =
                action.payload.customer_lastname
            state.forms.getLoyalty.status = EStatus.FINISHED
            state.page = EOfflineTradePage.CREATE_ORDER
        },
        loyaltyGetError(state, action: PayloadAction<string>) {
            state.forms.getLoyalty.status = EStatus.ERROR
            state.forms.createCustomer.status = EStatus.INITIAL
            state.forms.createCustomer.data = {
                ...initialCreateCustomer,
                phone: action.payload,
                shop_id: localStorage.getItem('is_shop_id') || '',
            }
            state.page = EOfflineTradePage.CREATE_CUSTOMER
        },
        createCustomer(state, _action: PayloadAction<ICustomerData>) {
            state.forms.createCustomer.status = EStatus.PENDING
        },
        customerCreated(state, action: PayloadAction<ICustomer>) {
            offlineTradeAdapter.setOne(state, {
                ...action.payload,
                id: action.payload.customer_id,
            })
            state.forms.createOrder.status = EStatus.INITIAL
            state.forms.createOrder.data.customer_id =
                action.payload.customer_id
            state.forms.createOrder.data.amount = 0
            state.forms.createCustomer.status = EStatus.FINISHED
            state.page = EOfflineTradePage.CUSTOMER_ADDED
        },
        createCustomerError(state) {
            state.forms.createCustomer.status = EStatus.ERROR
        },
        createOrder(state, _action: PayloadAction<IOrderData>) {
            state.forms.createOrder.status = EStatus.PENDING
        },
        orderCreated(state) {
            state.forms.createOrder.status = EStatus.FINISHED
            state.page = EOfflineTradePage.DONE
        },
        setCountryCode(state, action: PayloadAction<ECountryCode>) {
            state.countryCode = action.payload
        },
        createOrderError(state) {
            state.forms.createOrder.status = EStatus.ERROR
        },
    },
})

export const { actions: offlineTradeActions, reducer: offlineTradeReducer } =
    slice
