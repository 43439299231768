import { LoadingButton } from '@mui/lab'
import { Box, TextField, Typography } from '@mui/material'
import { authActions } from 'app/modules/Auth/slice'
import {
    selectCreateAccountForm,
    selectSigninForm,
} from 'app/modules/Auth/slice/selectors'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EStatus } from 'types'
import {
    googleAnalyticsEvent,
    googleAnalyticsEvents,
} from 'utils/googleAnalytics'
import * as yup from 'yup'

export const CodeForm: React.FC = () => {
    const dispatch = useDispatch()

    const [isWait, setWait] = useState<boolean>(true)
    const [currentTime, setCurrentTime] = useState<number>(
        new Date().getTime() / 1000
    )

    const { requestTime, status: signup_status } = useSelector(
        selectCreateAccountForm
    )
    const { status, data } = useSelector(selectSigninForm)

    const validationSchema = yup.object({
        code: yup.string().required().min(4).max(4),
    })

    const formik = useFormik({
        validationSchema,
        initialValues: data,
        validateOnBlur: false,
        validateOnChange: false,
        enableReinitialize: true,
        onSubmit: (values) => {
            googleAnalyticsEvent(googleAnalyticsEvents.verification_sms)

            const signinData = { ...values }

            signinData.newsletter_agreement = true
            signinData.offer_agreement = true
            signinData.personal_data = true

            dispatch(authActions.signIn(signinData))
        },
    })

    useEffect(() => {
        dispatch(authActions.resetStatus())
    }, [])

    useEffect(() => {
        if (signup_status === EStatus.FINISHED) {
            countDown()
        }
    }, [signup_status])

    const countDown = () => {
        if (Math.floor(requestTime - new Date().getTime() / 1000) > 0) {
            setCurrentTime(new Date().getTime() / 1000)
            setTimeout(() => countDown(), 1000)
        } else {
            setWait(false)
        }
    }

    const getCurrentTime = () => {
        return Math.floor(requestTime - currentTime)
    }

    const handleResend = () => {
        dispatch(
            authActions.getCode({
                requestData: {
                    user: {
                        phone: data.phone,
                    },
                },
                resend: true,
            })
        )
        setWait(true)
    }

    const enableButton = true

    return (
        <Box width="100%">
            <Box
                noValidate
                component="form"
                onSubmit={(e: React.FormEvent) => {
                    e.preventDefault()

                    formik.handleSubmit()
                }}
            >
                <Typography variant="body2">Введите код из СМС.</Typography>

                <Typography variant="body2">
                    Он отправлен на {data.phone}
                </Typography>
                <TextField
                    fullWidth
                    sx={{ mt: 3.75 }}
                    variant="standard"
                    label="Введите код"
                    name="code"
                    value={formik.values.code || ''}
                    error={!!formik.errors.code}
                    onChange={(val) => {
                        dispatch(authActions.resetStatus())
                        formik.handleChange(val)
                    }}
                />

                <LoadingButton
                    loading={signup_status === EStatus.PENDING}
                    fullWidth
                    disabled={isWait}
                    variant="text"
                    sx={{
                        mt: 6.25,
                        textTransform: 'none',
                    }}
                    onClick={handleResend}
                >
                    {isWait &&
                        `Отправить код повторно через ${getCurrentTime()} сек`}
                    {!isWait && 'Отправить код повторно'}
                </LoadingButton>

                <LoadingButton
                    loading={status === EStatus.PENDING}
                    disabled={!enableButton}
                    fullWidth
                    size="large"
                    color="primary"
                    variant="contained"
                    sx={{ my: 2.5 }}
                    onClick={() => formik.handleSubmit()}
                >
                    Продолжить
                </LoadingButton>
            </Box>
        </Box>
    )
}
