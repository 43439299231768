import { EntityState } from '@reduxjs/toolkit'
import { IImage } from 'types/IImage'

export const ImageSizeVariants = {
    '612': { width: '612', height: '612' },
    '1024': { width: '1024', height: '1024' },
    '2048': { width: '2048', height: '2048' },
} as const

export type ImagesState = EntityState<IImage>
