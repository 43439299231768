import { Box, Button, Typography } from '@mui/material'
import { ModalDialogSwicth } from 'app/components/Modal'
import React from 'react'

import { VariantsList, VariantsListProps } from '../VariantsList'

interface ProductSelectSizeModalProps {
    btnCartText: string
    btnCartDisabled: boolean
    isOpen: boolean
    handleClose: () => void
    handleApply: () => void
}

export const ProductSelectSizeModal: React.FC<
    VariantsListProps & ProductSelectSizeModalProps
> = (props) => {
    const {
        isOpen,
        handleClose,
        handleApply,
        selectedVariant,
        btnCartText,
        btnCartDisabled,
    } = props

    return (
        <ModalDialogSwicth
            open={isOpen}
            handleClose={handleClose}
            title="выберите размер"
            height="auto"
        >
            <Box
                sx={{
                    px: { xs: '16px', md: '50px' },
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box sx={{ mt: { xs: '27px' } }}>
                    <VariantsList {...props} />
                </Box>
                <Button
                    fullWidth
                    variant="contained"
                    size="large"
                    color="primary"
                    sx={{ px: 1.75, mt: 4.5 }}
                    disabled={selectedVariant ? btnCartDisabled : true}
                    onClick={handleApply}
                >
                    <Typography variant="body2" fontWeight={500}>
                        {selectedVariant ? btnCartText : 'Выберите размер'}
                    </Typography>
                </Button>
            </Box>
        </ModalDialogSwicth>
    )
}
