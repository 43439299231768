import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'types'

import { offlineTradeAdapter } from '.'

const { selectById } = offlineTradeAdapter.getSelectors()

const selectDomain = (state: RootState) => state.offlineTrade

export const selectPage = createSelector([selectDomain], (state) => state.page)

export const selectCountryCode = createSelector(
    [selectDomain],
    (state) => state.countryCode
)

export const selectLoyaltyForm = createSelector(
    [selectDomain],
    (state) => state.forms.getLoyalty
)

export const selectCreateCustomerForm = createSelector(
    [selectDomain],
    (state) => state.forms.createCustomer
)

export const selectCreateOrderForm = createSelector(
    [selectDomain],
    (state) => state.forms.createOrder
)

export const selectCustomerById = createSelector(
    [selectDomain],
    (state) => (id: number) => selectById(state, id)
)
