import { createSvgIcon } from '@mui/material'
import React from 'react'

export const ArrowDownIcon = createSvgIcon(
    <path
        d="M18 9L12 15L6 9"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
    />,
    'arrowDown'
)
