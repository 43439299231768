import { useMediaQuery, useTheme } from '@mui/material'
import React from 'react'

import { DesktopSearchList } from './DesktopSearchList'
import { MobileSearchList } from './MobileSearchList'

export const SearchList: React.FC = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

    return (
        <>
            {isMobile && <MobileSearchList />}
            {isDesktop && <DesktopSearchList />}
        </>
    )
}
