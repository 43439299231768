import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'types'

import { pageAdapter } from '.'

const { selectAll } = pageAdapter.getSelectors()

const selectDomain = (state: RootState) => state.pages

export const selectStatus = createSelector(
    [selectDomain],
    (state) => state.status
)

export const selectPages = createSelector([selectDomain], (state) =>
    selectAll(state)
)

export const selectPagesSort = createSelector([selectDomain], (state) =>
    selectAll(state).sort((a, b) =>
        parseFloat(a.id) > parseFloat(b.id) ? -1 : 1
    )
)

export const selectPage = createSelector(
    [selectDomain],
    (state) => (slug: string) =>
        selectAll(state).find((item) => item.attributes.slug === slug)
)
