import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

import { QuantityControl } from '../../QuantityControl'
import { CartItemProps } from '../CartItem'
import { CartItemDeleteButton } from '../CartItemDeleteButton'
import { CartItemLikeButton } from '../CartItemLikeButton'
import { CartItemName } from '../CartItemName/CartItemName'
import { CartItemOutOfStock } from '../CartItemOutOfStock'
import { CartItemPrice } from '../CartItemPrice'

export const MobileCartItem: React.FC<CartItemProps> = (props) => {
    const { item, image } = props

    return (
        <Grid container spacing="12px" direction="row" wrap="nowrap">
            <Grid item>
                <Box
                    sx={{
                        width: 106,
                        height: 106,
                        flexShrink: 0,
                        position: 'relative',
                    }}
                >
                    <Link to={`/products/${item.attributes.slug}`}>
                        <Box
                            sx={{
                                width: '100%',
                                height: '100%',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                objectFit: 'cover',
                                borderRadius: '16px',
                            }}
                            component="img"
                            src={image}
                        />
                        {item.attributes.sale_percent && (
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: 4,
                                    left: 4,
                                    backgroundColor: '#F15C3E',
                                    height: 23,
                                    borderRadius: 35,
                                    px: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    variant={'body3'}
                                    color="white"
                                >{`-${item.attributes.sale_percent}%`}</Typography>
                            </Box>
                        )}
                    </Link>
                </Box>
            </Grid>
            <Grid item width="100%">
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '170px',
                    }}
                >
                    <Box pb="12px">
                        <Link to={`/products/${item.attributes.slug}`}>
                            <CartItemName {...props} />
                        </Link>
                    </Box>

                    <Box pb="12px">
                        <CartItemOutOfStock {...props} />
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        }}
                    >
                        <CartItemPrice {...props} />
                        <Box
                            sx={{
                                pt: '16px',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            }}
                        >
                            <QuantityControl item={item} />
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                            >
                                <Box pr="12px">
                                    <CartItemLikeButton {...props} />
                                </Box>
                                <Box>
                                    <CartItemDeleteButton {...props} />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}
