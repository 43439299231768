import {
    Box,
    Container,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import GridStaticRow, { GridStaticRowProps } from 'app/components/GridStaticRow'
import React from 'react'

export const SustainableDevelopment: React.FC = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'lg'))

    const grids: GridStaticRowProps[] = [
        {
            image: '/assets/images/image69.jpg',
            title: 'НАШ ОТВЕТСТВЕННЫЙ ПУТЬ',
            description:
                'C 2016 Вселенная Island Soul поддерживает ценности устойчивого развития, требующие долгосрочной приверженности и работы, постоянного переосмысления и совершенствования,  ответственно подходит к ведению деятельности, охраняя окружающую среду и положительно влияя на регионы своего присутствия.<br /><br />Наши цели устойчивого развития определяют амбициозную дорожную карту, которая служит нам ориентиром к лучшему будущему для нас и наших детей.',
        },
        {
            image: '/assets/images/image68.jpg',
            title: 'ОТВЕТСТВЕННЫЙ ПОДХОД К ВЫБОРУ ИСТОЧНИКОВ СЫРЬЯ',
            description:
                'Для максимальной прозрачности происхождения наших изделий, мы стремимся гарантировать, что наши высококачественные материалы закупаются у ответственных поставщиков.',
        },
        {
            image: '/assets/images/image69.1.jpg',
            title: 'МЕРы В СВЯЗИ С ИЗМЕНЕНИЕМ КЛИМАТА',
            description:
                'Вселенная IS ежегодно увеличивает вклад  в достижение целей, определённых Парижским соглашением по климату 2015 года. 65% освещения в сети магазинов уже переведено на светодиоды, а новые объекты проектируются с учетом высоких требований к экологии.',
        },
        {
            image: '/assets/images/image69.2.jpg',
            title: 'ПОВЫШЕНИЕ ТРАНСПОРТНОЙ ЭНЕРГОЭФФЕКТИВНОСТИ ЗА СЧЁТ ПРОИЗВОДСТВА И ПЕРЕВОЗКИ ИСКЛЮЧИТЕЛЬНО НЕОБХОДИМОГО',
            description:
                'Сохранение низкого уровня объёма товара, который регулируется в режиме реального времени, позволяет повысить гибкость технологических процессов и минимизировать воздействие на окружающую среду. Это также позволяет производственным цехам добиваться максимальной экономии и избегать перепроизводства, а, следовательно, исключать выпуск устаревшей продукции.',
        },
        {
            image: '/assets/images/image64.jpg',
            title: 'ПОВЫШЕНИЕ СТЕПЕНИ ОСВЕДОМЛЕННОСТИ И УЧАСТИЯ',
            description:
                'Мы считаем важным использовать наш авторитет, чтобы говорить о о ключевых проблемах, связанных с устойчивым развитием в отрасли, и добиваться позитивных изменений. Благодаря взаимодействию с заинтересованными сторонами и партнерствам с главными игроками рынка, мы активно участвуем в решении задач устойчивого развития.',
        },
        {
            image: '/assets/images/image69.3.jpg',
            title: 'Развитие кадрового потенциала',
            description:
                'Наши сотрудники — неотъемлемая составляющая нашего успеха.<br /><br />Мы отлично знаем, что каждый сотрудник несет в себе частичку души Вселенной Island Soul, и потому считаем крайне важным проводить обучение каждого из них для максимального развития его потенциала.',
        },
        {
            image: '/assets/images/image69.4.jpg',
            title: 'Инклюзивность',
            description:
                'Мы стараемся, чтобы во всем, что мы делаем, была инклюзивность. Наша цель — стать брендом, который рад всем, и компанией, в которой каждый может полностью раскрыть свой потенциал.',
        },
    ]

    return (
        <Container
            sx={{
                mt: { md: 25, lg: 17, xl: 17 },
            }}
        >
            <Box
                display={'flex'}
                justifyContent={'flex-end'}
                sx={{
                    mt: { md: 30, lg: 20, xl: 20 },
                    backgroundImage: 'url(/assets/images/image60.jpg)',
                    backgroundRepeat: 'repeat',
                    position: 'relative',
                }}
            >
                <img
                    src={
                        isMobile
                            ? '/assets/images/image59_mobile.jpg'
                            : '/assets/images/image59.jpg'
                    }
                />
                <Box
                    display={'flex'}
                    alignItems={'center'}
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        height: '100%',
                        maxWidth: { xs: '350px', md: '512px' },
                    }}
                >
                    <Container>
                        <Typography variant="h4">
                            Be the change that you want to see in the world
                        </Typography>
                        <Typography mt={3} variant="body2">
                            Mahatma Gandhi
                        </Typography>
                    </Container>
                </Box>
            </Box>
            <Box mt={11}>
                {grids.map((item, index) => (
                    <GridStaticRow
                        key={index}
                        image={item.image}
                        title={item.title}
                        description={item.description}
                        rightSide={index % 2 === 1}
                    />
                ))}
            </Box>
        </Container>
    )
}
