import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material'
import { Box, TextField } from '@mui/material'
import { cartActions } from 'app/modules/Cart/slice'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ILineItem } from 'types/ILineItem'
import { IVariant } from 'types/IVariant'

import { QuantityControlButton } from './QuantityControlButton'

interface QuantityControlProps {
    item?: ILineItem
    variant: IVariant
    disabled?: boolean
}

export const QuantityControl: React.FC<QuantityControlProps> = ({
    variant,
    item,
    disabled,
}) => {
    const validChars = '0-9'
    const dispatch = useDispatch()

    const total_on_hand = variant.attributes.total_on_hand

    const [quantity, setQuantity] = useState<number>(
        item?.attributes.quantity || 0
    )

    useEffect(() => {
        setQuantity(item?.attributes.quantity || 0)
    }, [item])

    const handleAddCount = () => {
        if (item) {
            setQuantity(item.attributes.quantity + 1)
            dispatch(
                cartActions.updateItem({
                    line_item_id: item.id,
                    quantity: item.attributes.quantity + 1,
                    totalOnHand: total_on_hand,
                })
            )
        } else {
            setQuantity(1)
            dispatch(
                cartActions.addItem({
                    variant_id: variant.id,
                    quantity: 1,
                })
            )
        }
    }

    const handleChangeCount = (count: number) => {
        if (item) {
            if (quantity === 0) {
                dispatch(cartActions.deleteItem(item.id))
            } else {
                dispatch(
                    cartActions.updateItem({
                        line_item_id: item.id,
                        quantity: count,
                        totalOnHand: total_on_hand,
                    })
                )
            }
        } else {
            dispatch(
                cartActions.addItem({
                    variant_id: variant.id,
                    quantity: count,
                })
            )
        }
    }

    const handleSubCount = () => {
        if (item && item.attributes.quantity > 1) {
            setQuantity(item.attributes.quantity - 1)
            dispatch(
                cartActions.updateItem({
                    line_item_id: item.id,
                    quantity: item.attributes.quantity - 1,
                    totalOnHand: total_on_hand,
                })
            )
        }
        if (item && item.attributes.quantity === 1) {
            setQuantity(0)
            dispatch(cartActions.deleteItem(item.id))
        }
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <QuantityControlButton
                onClick={handleSubCount}
                disabled={disabled || !item?.attributes.quantity}
            >
                <RemoveIcon />
            </QuantityControlButton>

            <TextField
                value={quantity}
                disabled={disabled}
                sx={{
                    '& input': {
                        width: 44,
                        height: 32,
                        mx: 0.5,
                        p: 0,
                        textAlign: 'center',
                    },
                    '& div': {
                        borderRadius: 0,
                    },
                    width: 44,
                    height: 32,
                    mx: 0.5,
                    p: 0,
                    textAlign: 'center',
                    borderRadius: 0,
                }}
                onChange={(event) => {
                    if (
                        RegExp(`^[${validChars}]{0,9999}$`).test(
                            event.target.value
                        )
                    ) {
                        const count = parseFloat(event.target.value)
                        if (
                            count >= 0 &&
                            count <= variant.attributes.total_on_hand
                        ) {
                            setQuantity(count)
                            handleChangeCount(count)
                        }
                    }
                }}
            />

            <QuantityControlButton
                onClick={handleAddCount}
                disabled={
                    disabled ||
                    variant.attributes.total_on_hand === 0 ||
                    item?.attributes.quantity ===
                        variant.attributes.total_on_hand
                }
            >
                <AddIcon />
            </QuantityControlButton>
        </Box>
    )
}
