import { LoadingButton } from '@mui/lab'
import {
    Box,
    FormControl,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    TextField,
    Typography,
} from '@mui/material'
import { authActions } from 'app/modules/Auth/slice'
import { selectCreateAccountForm } from 'app/modules/Auth/slice/selectors'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EStatus } from 'types'
import {
    convertCountryCodePrice,
    convertDiscountLevel,
} from 'utils/convertUtils'
import * as yup from 'yup'

import { ReactComponent as ClockIcon } from '../assets/clock_icon.svg'
import { ReactComponent as EditIcon } from '../assets/edit_icon.svg'
import { offlineTradeActions } from '../slice'
import {
    selectCountryCode,
    selectCreateOrderForm,
    selectCustomerById,
    selectLoyaltyForm,
} from '../slice/selectors'

export const OfflineTradeAddOrder: React.FC = () => {
    // offline trade bonus
    const dispatch = useDispatch()

    const [smsSended, setSmsSended] = useState<boolean>(false)

    const countryCode = useSelector(selectCountryCode)
    const loyalty = useSelector(selectLoyaltyForm)
    const { data, status } = useSelector(selectCreateOrderForm)
    const { status: signupStatus } = useSelector(selectCreateAccountForm)
    const getCustomer = useSelector(selectCustomerById)
    const customer = getCustomer(data.customer_id)

    const validationSchema = yup.object({
        shop_id: yup.string().required(),
        code: yup.string().min(4).max(4),
        bonuses: yup
            .number()
            .min(0)
            .max(customer?.bonuses || 0),
    })

    const formik = useFormik({
        validationSchema,
        initialValues: data,
        validateOnBlur: true,
        validateOnChange: true,
        onSubmit: (values) => {
            dispatch(
                offlineTradeActions.createOrder({
                    phone: loyalty.data.phone,
                    customer_id: values.customer_id,
                    amount: values.amount,
                    promotional_amount: values.promotional_amount,
                    customer_firstname: values.customer_firstname,
                    customer_lastname: values.customer_lastname,
                    shop_id: values.shop_id,
                    bonuses: values.bonuses || undefined,
                    code: values.code || undefined,
                })
            )
        },
    })

    const getCurrentPrice = () => {
        if (
            (!formik.values.amount || !!formik.errors.amount) &&
            (!formik.values.promotional_amount ||
                !!formik.errors.promotional_amount)
        ) {
            return 'К оплате:'
        } else {
            const price = Math.floor(
                +formik.values.amount +
                    +formik.values.promotional_amount -
                    (formik.values.bonuses || 0)
            )
            return `К оплате: ${price} ${convertCountryCodePrice(countryCode)}`
        }
    }

    const handleStartPage = () => {
        dispatch(offlineTradeActions.startPage())
    }

    const handleSendSmsCode = () => {
        dispatch(
            authActions.getOfflineTradeCode({
                user: {
                    phone: loyalty.data.phone,
                },
            })
        )
    }

    const handleEditBonuses = () => {
        setSmsSended(false)
        formik.setFieldValue('code', '')
    }

    useEffect(() => {
        if (signupStatus === EStatus.FINISHED) {
            setSmsSended(true)
        }
    }, [signupStatus])

    useEffect(() => {
        setSmsSended(false)
    }, [])

    const isValidateSubmit = () => {
        if (smsSended) {
            return (
                !formik.values.code ||
                !!formik.errors.code ||
                ((!formik.values.amount || !!formik.errors.amount) &&
                    (!formik.values.promotional_amount ||
                        !!formik.errors.promotional_amount))
            )
        }
        return (
            !!formik.values.bonuses ||
            ((!formik.values.amount || !!formik.errors.amount) &&
                (!formik.values.promotional_amount ||
                    !!formik.errors.promotional_amount))
        )
    }

    const validateBonuses = (bonuses: number) => {
        const maxAmount =
            ((customer?.max_offline_percent || 0) * formik.values.amount) / 100
        const maxBonuses =
            (customer?.bonuses || 0) < maxAmount
                ? customer?.bonuses || 0
                : maxAmount

        if (bonuses > maxBonuses) {
            formik.setFieldValue('bonuses', maxBonuses)
        } else {
            formik.setFieldValue('bonuses', bonuses)
        }
    }

    return (
        <>
            <Box display={'flex'} justifyContent={'space-between'}>
                <Typography variant="body1" fontWeight={500}>
                    {`${customer?.customer_firstname} ${customer?.customer_lastname}`}
                </Typography>

                <Typography variant="body1" fontWeight={500}>
                    {`${customer?.bonuses} б`}
                </Typography>
            </Box>

            <Box display={'flex'} justifyContent={'space-between'}>
                <Box display={'flex'} alignItems={'center'}>
                    <Typography
                        sx={{ fontWeight: 500 }}
                        variant="body2"
                        mt={0.75}
                    >
                        {loyalty.data.phone}
                    </Typography>

                    <IconButton
                        size="small"
                        sx={{ width: '30px', height: '30px', ml: 0.5 }}
                        onClick={handleStartPage}
                    >
                        <EditIcon />
                    </IconButton>
                </Box>

                <Box display={'flex'} alignItems={'center'}>
                    <ClockIcon />

                    <Typography
                        variant="body1"
                        fontWeight={500}
                        color="text.disabled"
                    >
                        {`${customer?.inactive_bonuses} б`}
                    </Typography>
                </Box>
            </Box>

            <Box mt={2}>
                {!!customer?.discount && !!customer?.max_offline_percent && (
                    <Typography
                        sx={{ fontWeight: 500 }}
                        color="grey.600"
                        variant="body2"
                    >
                        {`${convertDiscountLevel(customer?.discount)} ${
                            customer?.discount
                        }%, списание до ${customer?.max_offline_percent}%`}
                    </Typography>
                )}

                <Typography
                    sx={{ fontWeight: 500 }}
                    color="grey.600"
                    variant="body2"
                >
                    {`Сумма покупок: ${customer?.orders_sum?.toLocaleString()} ${convertCountryCodePrice(
                        countryCode
                    )}`}
                </Typography>

                {!!customer?.next_level_sum && (
                    <Typography
                        sx={{ fontWeight: 500 }}
                        color="grey.600"
                        variant="body2"
                    >
                        {`До следующего уровня: ${customer?.next_level_sum.toLocaleString()} ${convertCountryCodePrice(
                            countryCode
                        )}`}
                    </Typography>
                )}
            </Box>

            <TextField
                fullWidth
                label="Сумма неакционных товаров"
                variant="standard"
                sx={{ mt: 4 }}
                name="amount"
                value={formik.values.amount || ''}
                onChange={(e) => {
                    const { value } = e.target
                    formik.setFieldValue('amount', value)
                    validateBonuses(formik.values.bonuses || 0)
                }}
                error={!!formik.errors.amount}
            />

            <TextField
                fullWidth
                label="Сумма акционных товаров"
                variant="standard"
                sx={{ mt: 2.5 }}
                name="promotional_amount"
                value={formik.values.promotional_amount || ''}
                onChange={formik.handleChange}
                error={!!formik.errors.promotional_amount}
            />

            {!!customer?.bonuses && !!customer?.max_offline_percent && (
                <FormControl
                    fullWidth
                    sx={{ mt: 2.5 }}
                    variant="standard"
                    error={!!formik.errors.bonuses}
                >
                    <InputLabel>
                        Баллов списать (максимум {customer?.max_offline_percent}
                        % от суммы неакционных товаров)
                    </InputLabel>

                    <Input
                        fullWidth
                        disabled={smsSended}
                        name="bonuses"
                        value={formik.values.bonuses || ''}
                        onChange={(e) => {
                            const { value } = e.target
                            validateBonuses(parseFloat(value))
                        }}
                        endAdornment={
                            <>
                                {smsSended && (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleEditBonuses}
                                            edge="end"
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )}
                            </>
                        }
                    />
                </FormControl>
            )}

            {!smsSended && !!formik.values.bonuses && !formik.errors.bonuses && (
                <LoadingButton
                    loading={signupStatus === EStatus.PENDING}
                    fullWidth
                    variant="outlined"
                    sx={{ mt: 1.5 }}
                    onClick={() => handleSendSmsCode()}
                >
                    ПОЛУЧИТЬ СМС-КОД ДЛЯ СПИСАНИЯ
                </LoadingButton>
            )}

            {smsSended && (
                <>
                    <TextField
                        fullWidth
                        label="СМС-код"
                        variant="standard"
                        sx={{ mt: 2.5 }}
                        name="code"
                        value={formik.values.code || ''}
                        onChange={formik.handleChange}
                        error={!!formik.errors.code}
                    />

                    <LoadingButton
                        loading={signupStatus === EStatus.PENDING}
                        variant="text"
                        sx={{
                            mt: 2,
                            textTransform: 'unset',
                        }}
                        onClick={() => handleSendSmsCode()}
                    >
                        Отправить код повторно
                    </LoadingButton>
                </>
            )}

            <TextField
                fullWidth
                label="Код магазина"
                variant="standard"
                sx={{ mt: 2 }}
                name="shop_id"
                value={formik.values.shop_id || ''}
                onChange={(e) => {
                    const { value } = e.target
                    dispatch(offlineTradeActions.changeShopId(value))
                    formik.setFieldValue('shop_id', value)
                }}
                error={!!formik.errors.shop_id}
            />

            <Typography variant="body2" fontWeight={500} sx={{ mt: 6 }}>
                {getCurrentPrice()}
            </Typography>

            <LoadingButton
                loading={status === EStatus.PENDING}
                disabled={isValidateSubmit()}
                fullWidth
                sx={{ mt: 2 }}
                variant="contained"
                onClick={() => formik.handleSubmit()}
            >
                ПРОДОЛЖИТЬ
            </LoadingButton>
        </>
    )
}
