import { useMediaQuery, useTheme } from '@mui/material'
import React from 'react'

import { DesktopProductsFilter } from './DesktopProductsFilter'
import { MobileProductsFilter } from './MobileProductsFilter'

export const ProductsFilter: React.FC = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))
    const isTablet = useMediaQuery(theme.breakpoints.between('md', 'lg'))
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

    return (
        <>
            {(isMobile || isTablet) && <MobileProductsFilter />}

            {isDesktop && <DesktopProductsFilter />}
        </>
    )
}
