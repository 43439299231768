import { citiesActions } from 'app/modules/Cities/slice'
import { selectCityOpened } from 'app/modules/Cities/slice/selectors'
import {
    IHeaderContext,
    IHeaderContextProps,
} from 'app/modules/Layout/types/IHeaderContext'
import React, {
    createContext,
    FC,
    useCallback,
    useContext,
    useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

export const HeaderContext = createContext<IHeaderContext>({} as IHeaderContext)

export function useHeaderContext(): IHeaderContext {
    const context = useContext(HeaderContext)

    if (context === undefined) {
        throw new Error(
            'useHeaderContext must be used within the HeaderContextProvider'
        )
    }

    return context
}

export const HeaderContextProvider: FC<{
    headerContextProps: IHeaderContextProps
}> = ({ children, headerContextProps }) => {
    const dispatch = useDispatch()
    const [isOpened, setOpened] = useState<boolean>(false)
    const [isMobileSearchOpened, mobileSearchChange] = useState<boolean>(false)
    const [isDesktopSearchOpened, setDesktopSearchOpened] =
        useState<boolean>(false)
    const isCityOpened = useSelector(selectCityOpened)

    const onTouchHandler = useCallback(() => {
        setOpened((isOpened) => !isOpened)
    }, [])

    const onCloseHandler = useCallback(() => {
        setOpened(false)
    }, [])

    const onSearchChange = useCallback(() => {
        mobileSearchChange((isMobileSearchOpened) => !isMobileSearchOpened)
    }, [])

    const handlerCityOpen = useCallback(() => {
        dispatch(citiesActions.openModal())
        setDesktopSearchOpened(false)
    }, [])

    const handlerCityClose = useCallback(() => {
        dispatch(citiesActions.closeModal())
    }, [])

    const handlerSearchChange = (newValue: boolean) => {
        dispatch(citiesActions.closeModal())
        setDesktopSearchOpened(newValue)
    }

    const setOpenDesktopSearchState = useCallback(
        () => handlerSearchChange(true),
        []
    )
    const setCloseDesktopSearchState = useCallback(
        () => handlerSearchChange(false),
        []
    )

    return (
        <HeaderContext.Provider
            value={{
                ...headerContextProps,
                isOpened,
                touch: onTouchHandler,
                close: onCloseHandler,
                isMobileSearchOpened,
                mobileSearchChange: onSearchChange,
                isCityOpened,
                isDesktopSearchOpened,
                setOpenDesktopSearchState,
                setCloseDesktopSearchState,
                cityOpen: handlerCityOpen,
                cityClose: handlerCityClose,
            }}
        >
            {children}
        </HeaderContext.Provider>
    )
}
