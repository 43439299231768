import { Box, Container, useMediaQuery, useTheme } from '@mui/material'
import { selectProductVariants } from 'app/modules/Products/slice/selectors'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { DolyameID } from 'utils/DolyameID'

import { ProductProps } from '../../templates/Product/Product'
import {
    CartButtonProps,
    DesktopFloatingCartButton,
    MobileFloatingCartButton,
} from '../ProductAddToCartButton'
import {
    ProductCityLink,
    ProductDescription,
    ProductDolyameBar,
    ProductName,
    ProductPrice,
    ProductSKU,
    QualityAssuranceCollapse,
    ReturnExchangeCollapse,
} from './components'
import { VariantsList } from './VariantsList'

export const ProductCustomerDetail: React.FC<ProductProps> = ({
    product,
    productVisualContent,
}) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

    const getVariants = useSelector(selectProductVariants)
    const variants = getVariants(product.id)

    const [selectedVariant, setSelectedVariant] = useState<string | null>(null)

    const cartButtonProps: CartButtonProps = {
        product,
        selectedVariant,
        variants,
        setSelectedVariant,
    }
    return (
        <>
            <DolyameID product={product} selectedVariant={selectedVariant} />

            {isMobile && <MobileFloatingCartButton {...cartButtonProps} />}

            <Container
                sx={{
                    px: { xs: '4.26vw', sm: '30px' },
                    pl: { md: '30px' },
                    pr: { md: '45px' },
                }}
            >
                {isDesktop && (
                    <Box>
                        <ProductSKU product={product} />
                    </Box>
                )}
                <Box mt={{ xs: '20px', md: '22px' }}>
                    <ProductName product={product} />
                </Box>
                <Box mt={{ xs: '10px' }}>
                    <ProductCityLink />
                </Box>
                <Box mt={{ xs: '14px', md: '19px' }}>
                    <ProductPrice
                        product={product}
                        selectedVariant={selectedVariant}
                    />
                </Box>

                {isMobile && (
                    <Box mt="20px">
                        <ProductDolyameBar {...cartButtonProps} />
                    </Box>
                )}

                <Box mt={{ xs: '30px', md: '28px' }}>
                    <VariantsList
                        {...{ variants, selectedVariant, setSelectedVariant }}
                    />
                </Box>

                {isDesktop && (
                    <Box mt="30px">
                        <ProductDolyameBar {...cartButtonProps} />
                    </Box>
                )}

                {isDesktop && (
                    <Box
                        sx={{
                            pt: { md: '30px' },
                        }}
                    >
                        <DesktopFloatingCartButton
                            {...cartButtonProps}
                            productVisualContent={productVisualContent}
                        />
                    </Box>
                )}

                <Box
                    mt={{ xs: '30px', md: 6 }}
                    sx={{ fontSize: '14px', lineHeight: '24px' }}
                >
                    <ProductDescription product={product} />
                </Box>
                {isMobile && (
                    <Box sx={{ mt: { xs: '8px' } }}>
                        <ProductSKU product={product} />
                    </Box>
                )}
                <Box mt={{ xs: '24px' }}>
                    <ReturnExchangeCollapse />
                </Box>
                <Box mt={{ xs: '8px' }}>
                    <QualityAssuranceCollapse />
                </Box>
            </Container>
        </>
    )
}
