export interface IShipmentsAttributes {
    id: string
    start_period: string
    end_period: string
    display_final_price: string
    selected_shipping_rate_id: string
    shipping_method_id: ShippingMethodId
}

export enum ShippingMethodId {
    courier = 1,
    pickup = 2,
}

export enum PaymentMethodId {
    /** Банковская карта Oнлайн */
    cardOnlile = '1',
    /** При получении */
    onPickup = '2',
    /** Система Быстрых Платежей */
    SBP = '3',
    /** Долями */
    dolyame = '4',
}

export interface IPaymentsAttributes {
    accessible: boolean
    type: string
    name: string
    description: string
    payment_method_id: PaymentMethodId
}

export interface IPaymentsShipmentsAttributes {
    order: {
        shipments_attributes: IShipmentsAttributes[]
        payments_attributes: IPaymentsAttributes[]
    }
}

export interface IPaymentsResponseAttributes {
    order: {
        payments_attributes: IPaymentsAttributes[]
    }
}
