import {
    Box,
    Button,
    Container,
    Grid,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { ModalDialogSwicth } from 'app/components/Modal'
import { cartActions } from 'app/modules/Cart/slice'
import { selectCurrentCity } from 'app/modules/Cities/slice/selectors'
import { ReactComponent as ArrowLeft16 } from 'app/modules/Products/assets/ArrowLeft16.svg'
import { ReactComponent as Dolyame32 } from 'app/modules/Products/assets/Dolyame32.svg'
import { ReactComponent as DolyameWhite32 } from 'app/modules/Products/assets/DolyameWhite32.svg'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { IProduct } from 'types/IProduct'
import { convertMonth } from 'utils/convertMonth'

import { CartButton, CartButtonProps } from '../../ProductAddToCartButton'

const MILISECONDS_IN_WEEK = 604_800_000

interface DolyamePaymentBarSegmentProps {
    date: Date
    price: number
    firstSegment?: boolean
}

const DolyamePaymentBarSegment: React.FC<DolyamePaymentBarSegmentProps> = ({
    date,
    price,
    firstSegment,
}) => {
    return (
        <Box
            sx={{
                width: { xs: '18.7vw', md: '116px' },
                maxWidth: { xs: '116px' },
            }}
        >
            <Typography
                variant="body3"
                noWrap
                fontSize={{ xs: '12px', md: '18px' }}
                color="#858585"
            >
                {firstSegment
                    ? 'Сегодня'
                    : `${date.getDate()} ${convertMonth(
                          (date.getMonth() + 1).toString().padStart(2, '0')
                      )}`}
            </Typography>
            <Typography
                variant="body2"
                fontWeight="medium"
                mt={{ xs: '4px' }}
                fontSize={{ xs: '14px', md: '18px' }}
            >
                {price} ₽
            </Typography>
            <Box
                sx={{
                    height: { xs: '4px', md: '3px' },
                    mt: { xs: '8px' },
                    backgroundColor: firstSegment ? '#54A67F' : '#DEDEDE',
                }}
            />
        </Box>
    )
}

const useDolyameBarHide = (product: IProduct) => {
    const currentCity = useSelector(selectCurrentCity)

    if (product.attributes.price < 4990) {
        return true
    }

    if (product.attributes.price > 200000) return true

    if (product.attributes.name.toUpperCase().includes('СЕРТИФИКАТ'))
        return true

    if (!currentCity) return true

    if (!currentCity.attributes.pickup) {
        return false
    }

    if (
        // Сочи ID
        currentCity.id !== '31' &&
        // Москва ID
        currentCity.id !== '1'
    ) {
        return true
    }

    return false
}

export const ProductDolyameBar: React.FC<CartButtonProps> = (props) => {
    const { product } = props
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))

    const dispatch = useDispatch()

    const [tooltipIsOpen, setTooltipOpen] = useState<boolean>(false)

    const handleCloseTooltip = () => {
        setTooltipOpen(false)
    }
    const handleOpenTooltip = () => {
        setTooltipOpen(true)
    }

    const hideDolaymeBar = useDolyameBarHide(product)

    if (hideDolaymeBar) return null

    const addToCartCallbackFunction = () => {
        handleCloseTooltip()
        dispatch(cartActions.showCart())
    }

    const displayPrice = Math.floor(product.attributes.price / 4)
    const lastPrice = product.attributes.price - displayPrice * 3
    const dateNow = new Date()

    const dolyamePlus = product.attributes.price > 30000

    return (
        <>
            <ModalDialogSwicth
                open={tooltipIsOpen}
                title={
                    <Box
                        display="flex"
                        mt={{ xs: '0px', md: '16px' }}
                        ml={{ xs: '16px', md: '0px' }}
                    >
                        <DolyameWhite32 />
                        <Typography
                            sx={{
                                textTransform: 'uppercase',
                            }}
                            variant="h5"
                            fontWeight="medium"
                            textAlign="center"
                            ml="10px"
                        >
                            долями
                        </Typography>
                    </Box>
                }
                handleClose={handleCloseTooltip}
            >
                <Container sx={{ px: { xs: '5vw', md: '50px' } }}>
                    <Box
                        sx={{
                            mt: { xs: '30px', md: '40px' },
                        }}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                    >
                        {dolyamePlus ? (
                            <Typography
                                maxWidth="510px"
                                variant="h6"
                                fontSize="18px"
                                fontWeight="regular"
                                textAlign="center"
                            >
                                Делим сумму покупки на 3 или 6 месяцев без
                                первоначального взноса, с первым платежом через
                                45 дней после оформления и с последующей оплатой
                                раз в месяц. <br /> <br />
                                Перед каждым платежом пришлем вам напоминание о
                                дате списания.
                            </Typography>
                        ) : (
                            <>
                                <Typography
                                    maxWidth="510px"
                                    variant="h6"
                                    fontSize="18px"
                                    fontWeight="regular"
                                    textAlign="center"
                                >
                                    Оплатите 25% сейчас — остальное спишется
                                    автоматически тремя равными частями с
                                    интервалом в 2 недели
                                </Typography>
                                <Grid
                                    container
                                    spacing={isMobile ? '5vw' : '22px'}
                                    pt={isMobile ? '20px' : '40px'}
                                    justifyContent="center"
                                >
                                    <Grid item>
                                        <DolyamePaymentBarSegment
                                            date={dateNow}
                                            price={displayPrice}
                                            firstSegment
                                        />
                                    </Grid>
                                    <Grid item>
                                        <DolyamePaymentBarSegment
                                            date={
                                                new Date(
                                                    dateNow.getTime() +
                                                        MILISECONDS_IN_WEEK * 2
                                                )
                                            }
                                            price={displayPrice}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <DolyamePaymentBarSegment
                                            date={
                                                new Date(
                                                    dateNow.getTime() +
                                                        MILISECONDS_IN_WEEK * 4
                                                )
                                            }
                                            price={displayPrice}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <DolyamePaymentBarSegment
                                            date={
                                                new Date(
                                                    dateNow.getTime() +
                                                        MILISECONDS_IN_WEEK * 6
                                                )
                                            }
                                            price={lastPrice}
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Box>

                    <Box mt={{ xs: '42px', md: '60px' }} height="60px">
                        <CartButton
                            {...props}
                            caption="Купить"
                            addToCartCallbackFunction={
                                addToCartCallbackFunction
                            }
                        />
                    </Box>
                    <Box mt="10px" height="60px">
                        <Link to={'/static/dolyame'}>
                            <Button
                                variant="contained"
                                color="grey"
                                fullWidth
                                sx={{
                                    height: '100%',
                                }}
                            >
                                подробнее
                            </Button>
                        </Link>
                    </Box>
                </Container>
            </ModalDialogSwicth>

            <Box
                sx={{
                    height: '60px',
                    width: '100%',
                    borderRadius: '10px',
                    backgroundColor: theme.palette.grey[100],
                    display: 'flex',
                    flexDirection: 'row',
                    px: '16px',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    cursor: 'pointer',
                }}
                onClick={handleOpenTooltip}
            >
                <Box display="flex" alignItems="center">
                    <Dolyame32 />
                    <Typography
                        component="h6"
                        sx={{ fontSize: { xs: '14px', md: '18px' } }}
                        ml={{ xs: '10px', md: '12px' }}
                    >
                        Оплата Долями
                    </Typography>
                </Box>
                {!dolyamePlus && (
                    <Box display="flex" alignItems="center">
                        <Typography
                            component="h6"
                            sx={{ fontSize: { xs: '14px', md: '18px' } }}
                            fontWeight="400"
                            mr={{ xs: '10px', md: '12px' }}
                        >
                            {displayPrice + ' ₽ × 4'}
                        </Typography>
                        <ArrowLeft16 />
                    </Box>
                )}
            </Box>
        </>
    )
}
