import React from 'react'

interface PartnerIconProps {
    color: string
}

export const PartnerIcon: React.FC<PartnerIconProps> = ({
    color = '#000000',
}) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M20.25 6H3.75C3.33579 6 3 6.3731 3 6.83333V20.1667C3 20.6269 3.33579 21 3.75 21H20.25C20.6642 21 21 20.6269 21 20.1667V6.83333C21 6.3731 20.6642 6 20.25 6Z"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M16 6V4.5C16 4.10218 15.8314 3.72064 15.5314 3.43934C15.2313 3.15804 14.8243 3 14.4 3H9.6C9.17565 3 8.76869 3.15804 8.46863 3.43934C8.16857 3.72064 8 4.10218 8 4.5V6"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M21 12C18.2646 13.3131 15.1599 14.0031 12 13.9999C8.83955 14.0068 5.73363 13.3166 3 12"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M10.75 10.167H13.25"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
