import { Box } from '@mui/material'
import React from 'react'

interface CartNavigationsButtonsProps {
    backButton: React.ReactNode
    nextButton: React.ReactNode
}

export const CartNavigationsButtons: React.FC<CartNavigationsButtonsProps> = ({
    backButton,
    nextButton,
}) => {
    return (
        <Box display="flex">
            <Box sx={{ mr: '10px' }}>{backButton}</Box>
            <Box sx={{ flexGrow: 1 }}>{nextButton}</Box>
        </Box>
    )
}
