import { selectCategoryById } from 'app/modules/Categories/slice/selectors'
import { selectLineItemById } from 'app/modules/LineItems/slice/selectors'
import { selectProductById } from 'app/modules/Products/slice/selectors'
import { selectVariantById } from 'app/modules/Variants/slice/selectors'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { ICart } from 'types/ICart'
import { ICategory } from 'types/ICategory'
import { ILineItem } from 'types/ILineItem'
import { IProduct } from 'types/IProduct'
import { IVariant } from 'types/IVariant'
import { productFieldObject } from 'types/YandexMetrica'

window.dataLayer = window.dataLayer || []

export const yandexMetricaSuccessfullPayment = () => {
    return (
        window.location.href.includes('success=true') &&
        window.location.href.includes('order_number')
    )
}

export function useYandexMetricaEcommerce() {
    const getVariant = useSelector(selectVariantById)
    const getProduct = useSelector(selectProductById)
    const getCategory = useSelector(selectCategoryById)
    const getLineItem = useSelector(selectLineItemById)

    const yandexMetricaEcommerceDetail = useCallback(
        (product: IProduct) => {
            const category = getCategory(
                product.relationships.taxons.data[0]?.id
            )
            if (!category) {
                console.log('yandexMetricaEcommerceDetail error')
                return
            }
            const productEcommerce = ProductConverter({ product, category })

            if (productEcommerce.category?.startsWith('Оптовый каталог')) {
                return
            }

            window.dataLayer.push({
                ecommerce: {
                    currencyCode: 'RUB',
                    detail: {
                        products: [productEcommerce],
                    },
                },
            })
        },
        [getCategory]
    )

    const yandexMetricaEcommerceAdd = useCallback(
        (product: IProduct, quantity: number, variant?: IVariant) => {
            const category = getCategory(
                product?.relationships.taxons.data[0]?.id
            )

            if (!(category && variant)) {
                console.log('yandexMetricaEcommerceAdd error')
                return
            }

            const productEcommerce = ProductConverter({
                product,
                variant,
                quantity,
                category,
            })

            if (productEcommerce.category?.startsWith('Оптовый каталог')) {
                return
            }

            window.dataLayer.push({
                ecommerce: {
                    currencyCode: 'RUB',
                    add: {
                        products: [productEcommerce],
                    },
                },
            })
        },
        [getVariant, getCategory]
    )

    const yandexMetricaEcommerceRemove = useCallback(
        (item: ILineItem) => {
            const variant = getVariant(item.relationships.variant.data?.id)
            const product = getProduct(variant?.relationships.product.data?.id)
            const category = getCategory(
                product?.relationships.taxons.data[0]?.id
            )

            if (!(variant && product && category)) {
                console.log('yandexMetricaEcommerceRemove error')

                return
            }

            const productEcommerce = ProductConverter({
                product,
                variant,
                category,
            })

            if (productEcommerce.category?.startsWith('Оптовый каталог')) {
                return
            }

            window.dataLayer.push({
                ecommerce: {
                    currencyCode: 'RUB',
                    remove: {
                        products: [productEcommerce],
                    },
                },
            })
        },
        [getVariant, getProduct, getCategory]
    )

    const yandexMetricaEcommercePurchase = useCallback(
        (cart: ICart) => {
            const cartItems = cart.relationships.line_items.data
                .map((lineItemResource) => {
                    const lineItem = getLineItem(lineItemResource.id)

                    const variant = getVariant(
                        lineItem?.relationships.variant.data?.id
                    )
                    const product = getProduct(
                        variant?.relationships.product.data?.id
                    )
                    const category = getCategory(
                        product?.relationships.taxons.data[0]?.id
                    )
                    if (product && category && lineItem && variant) {
                        return ProductConverter({
                            product,
                            variant,
                            quantity: lineItem.attributes.quantity,
                            category,
                        })
                    } else {
                        return null
                    }
                })
                .filter((item): item is productFieldObject => !!item)

            window.dataLayer.push({
                ecommerce: {
                    currencyCode: 'RUB',
                    purchase: {
                        actionField: { id: cart.attributes.number },
                        products: cartItems,
                    },
                },
            })
        },
        [getVariant, getProduct, getCategory, getLineItem]
    )

    return {
        yandexMetricaEcommerceDetail,
        yandexMetricaEcommerceAdd,
        yandexMetricaEcommerceRemove,
        yandexMetricaEcommercePurchase,
    }
}

const ProductConverter = ({
    product,
    variant,
    quantity,
    category,
}: {
    product: IProduct
    variant?: IVariant
    quantity?: number
    category: ICategory
}): productFieldObject => {
    return {
        id: product.id,
        name: product.attributes.name,
        brand: 'Island Soul',
        category: TransformCategory(category),
        // coupon?: string
        // position?: number
        price: product.attributes.price,
        quantity,
        variant: variant?.attributes.options_text || undefined,
    }
}

const TransformCategory = (category?: ICategory) => {
    return category && category.attributes.pretty_name.replace(/ -> /g, '/')
}
