import { ResourceObject } from 'utils/json-api'

export const ImageTypes = ['image', 'taxon_image', 'preview_image'] as const

export interface IImage extends ResourceObject {
    id: string
    type: typeof ImageTypes[number]
    attributes: {
        position: number
        alt: string
        original_url: string
        styles: [
            {
                url: string
                width: string
                height: string
            }
        ]
    }
}
