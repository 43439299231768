import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

import { QuantityControl } from '../../QuantityControl'
import { CartItemProps } from '../CartItem'
import { CartItemDeleteButton } from '../CartItemDeleteButton'
import { CartItemLikeButton } from '../CartItemLikeButton'
import { CartItemName } from '../CartItemName'
import { CartItemOutOfStock } from '../CartItemOutOfStock'
import { CartItemPrice } from '../CartItemPrice'

export const DesktopCartItem: React.FC<CartItemProps> = (props) => {
    const { item, image } = props

    return (
        <Stack spacing={2.5} direction="row">
            <Box
                sx={{
                    width: 141,
                    height: 141,
                    flexShrink: 0,
                    position: 'relative',
                }}
            >
                <Link to={`/products/${item.attributes.slug}`}>
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            objectFit: 'cover',
                            borderRadius: '16px',
                        }}
                        component="img"
                        src={image}
                    />
                    {item.attributes.sale_percent && (
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 8,
                                left: 8,
                                backgroundColor: '#F15C3E',
                                height: 23,
                                borderRadius: 35,
                                px: 1,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                variant={'body3'}
                                color="white"
                            >{`-${item.attributes.sale_percent}%`}</Typography>
                        </Box>
                    )}
                </Link>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    justifyContent: 'space-between',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                    }}
                >
                    <Stack spacing={1}>
                        <Link to={`/products/${item.attributes.slug}`}>
                            <CartItemName {...props} />
                        </Link>
                    </Stack>

                    <Box sx={{ flexShrink: 0 }}>
                        <CartItemPrice {...props} />
                    </Box>
                </Box>

                <CartItemOutOfStock {...props} />

                <Box
                    sx={{
                        mt: 2.5,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <QuantityControl item={item} />

                    <Box sx={{ m: -1 }}>
                        <CartItemLikeButton {...props} />
                    </Box>
                    <Box sx={{ m: -1 }}>
                        <CartItemDeleteButton {...props} />
                    </Box>
                </Box>
            </Box>
        </Stack>
    )
}
