import {
    Box,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import React, { useState } from 'react'
import { convertOrderState } from 'utils/convertUtils'

import { PartnerInformationForm } from './PartnerInformationForm'
import { PartnerOrderItemsList } from './PartnerOrderItemsList'

export const PartnerCreateOrderSteps: React.FC = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))

    const [activeStep, setActiveStep] = useState<number>(0)

    return (
        <Box
            sx={{
                mt: {
                    xs: 3,
                    lg: 0,
                },
                pt: {
                    xs: 0,
                    lg: 6,
                },
                pb: {
                    xs: 0,
                    lg: 4.5,
                },
                pl: {
                    xs: 0,
                    lg: 4,
                },
                pr: {
                    xs: 0,
                    lg: 7,
                },
            }}
        >
            <Typography
                fontWeight={500}
                variant={isMobile ? 'h5' : 'h4'}
                sx={{ textTransform: 'uppercase' }}
            >
                Оформление оптового заказа
            </Typography>
            <Stepper
                activeStep={activeStep}
                orientation="vertical"
                sx={{ mt: 6 }}
            >
                <Step key={convertOrderState('cart')}>
                    <StepLabel>Корзина</StepLabel>
                    <StepContent>
                        <PartnerOrderItemsList
                            onNextStep={() => setActiveStep(1)}
                        />
                    </StepContent>
                </Step>
                <Step key={convertOrderState('address')}>
                    <StepLabel>Данные получателя</StepLabel>
                    <StepContent>
                        <PartnerInformationForm
                            onPrevious={() => setActiveStep(0)}
                        />
                    </StepContent>
                </Step>
            </Stepper>
        </Box>
    )
}
