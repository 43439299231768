import {
    Favorite as FavoriteIcon,
    FavoriteBorder as FavoriteBorderIcon,
} from '@mui/icons-material'
import { Button, Typography } from '@mui/material'
import React from 'react'

import { ProductFavoriteProps } from '.'
import { useHandleToggleFavorite } from './ProductHandleToggleFavorite'

export const ProductLikeButton: React.FC<ProductFavoriteProps> = ({
    product,
}) => {
    const { isFavorite, handleToggleFavorite } =
        useHandleToggleFavorite(product)

    return (
        <Button
            sx={{
                height: '100%',
            }}
            fullWidth
            size="large"
            variant="contained"
            color="grey"
            onClick={handleToggleFavorite}
        >
            {isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
            {!!product.attributes.favorite_count && (
                <Typography
                    sx={{
                        ml: '7px',
                        fontWeight: 'medium',
                    }}
                    variant="body1"
                >
                    {product.attributes.favorite_count}
                </Typography>
            )}
        </Button>
    )
}
