import { Box, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { ShippingMethodType } from 'types/ICustomerInformation'

import { selectCartShop } from '../../slice/selectors'
import { OrderDetailDeliveryInformationProps, OrderDetailProps } from '.'

export const OrderDetailStore: React.FC<
    OrderDetailProps & OrderDetailDeliveryInformationProps
> = ({ cart, showDeliveryInformation }) => {
    const shop = useSelector(selectCartShop)

    if (
        cart.attributes.delivery_state !== ShippingMethodType.pickup ||
        !showDeliveryInformation
    )
        return null

    return (
        <Box borderBottom="1px solid #DEDEDE" py="30px">
            <Typography
                variant="body1"
                fontWeight="medium"
                sx={{ fontSize: { xs: '18px', md: '14px' } }}
            >
                Заберу из магазина
            </Typography>

            <Typography
                variant="body1"
                pt="10px"
                sx={{ fontSize: { xs: '18px', md: '14px' } }}
            >
                {shop?.attributes.address}
            </Typography>
        </Box>
    )
}
