import { Box, Card, Skeleton, Typography } from '@mui/material'
import React, { useState } from 'react'

import { ProductCardProps } from '.'
import { ProductCardImages } from './ProductICardmages'
import { ProductLink } from './ProductLink'

export const ImageSizeProps = {
    xs: 'calc((100vw - 32px - 12px ) / 2)',
    md: 'calc((100vw - 32px - 12px * 2 ) / 3)',
    lg: 'calc((100vw - 212px - 36px - 45px - 30px * 3 - 45px) / 4)',
} as const

export const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
    const [isHover, setHover] = useState<boolean>(false)

    return (
        <Card
            elevation={0}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            sx={{
                backgroundColor: 'transparent',
                overflow: 'visible',
                width: ImageSizeProps,
            }}
        >
            <ProductLink product={product}>
                <ProductCardImages product={product} isHover={isHover} />

                <Box
                    sx={{
                        pt: { xs: '12px', lg: '20px' },
                        pb: 0,
                        pl: 0,
                        pr: 0,
                    }}
                >
                    <Box sx={{ height: { xs: '48px', lg: '57px' } }}>
                        <Typography
                            sx={{
                                textAlign: 'bottom',
                                display: '-webkit-box',
                                overflow: 'hidden',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 2,
                                fontSize: { xs: '14px', lg: '18px' },
                            }}
                            fontWeight="medium"
                        >
                            {product ? product.attributes.name : <Skeleton />}
                        </Typography>
                    </Box>

                    <Box
                        display="flex"
                        sx={{
                            height: { xs: '24px', lg: '28px' },
                            mt: { lg: '4px' },
                        }}
                    >
                        <Typography
                            variant="body2"
                            sx={{
                                fontSize: { xs: '14px', lg: '18px' },
                            }}
                        >
                            {product ? (
                                product.attributes.display_price
                            ) : (
                                <Skeleton />
                            )}
                        </Typography>

                        {product?.attributes.sale_percent && (
                            <Typography
                                variant="body2"
                                color="grey.400"
                                sx={{
                                    textDecoration: 'line-through',
                                    fontSize: { xs: '14px', lg: '18px' },
                                    ml: 1.25,
                                }}
                            >
                                {product.attributes.display_compare_at_price}
                            </Typography>
                        )}
                    </Box>
                </Box>
            </ProductLink>
        </Card>
    )
}
