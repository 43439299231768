import {
    Box,
    Container,
    Grid,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { selectLineItemByVariantId } from 'app/modules/LineItems/slice/selectors'
import { selectProductVariants } from 'app/modules/Products/slice/selectors'
import { selectVariantById } from 'app/modules/Variants/slice/selectors'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import { convertMonth } from 'utils/convertMonth'
import { priceFormat } from 'utils/priceFormat'

import { ProductProps } from '../../templates/Product/Product'
import { PartnerProductItem } from '../PartnerProductItem'
import { PartnerVariantItem } from '../PartnerVariantItem'

interface ITotal {
    count: number
    price: number
}

export const ProductPartnerDetail: React.FC<ProductProps> = ({ product }) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))

    const getVariant = useSelector(selectVariantById)
    const getVariants = useSelector(selectProductVariants)
    const getLineItem = useSelector(selectLineItemByVariantId)
    const variant = getVariant(product.relationships.default_variant.data?.id)
    const lineItemProduct = getLineItem(variant?.id)

    const variants = getVariants(product.id)

    const total = variants.reduce(
        (acc: ITotal, value) => {
            const lineItem = getLineItem(value.id)

            if (lineItem) {
                return {
                    count: acc.count + lineItem.attributes.quantity,
                    price: acc.price + parseFloat(lineItem.attributes.total),
                }
            }

            return acc
        },
        { count: 0, price: 0 }
    ) || { count: 0, price: 0 }

    const totalPriceZero =
        (total.count > 0 && total.price === 0) ||
        parseFloat(variant?.attributes.price || '0') === 0

    return (
        <Container sx={{ mt: { xs: 3, lg: 0 } }}>
            <Box display="flex" justifyContent="space-between">
                <Typography
                    sx={{ textTransform: 'uppercase' }}
                    variant={isMobile ? 'body3' : 'body2'}
                    fontWeight="medium"
                >
                    Island Soul
                </Typography>

                {product.attributes.sku && (
                    <Typography
                        color="text.disabled"
                        variant={isMobile ? 'body3' : 'body2'}
                    >
                        Арт. {product.attributes.sku}
                    </Typography>
                )}
            </Box>

            <Box mt={{ xs: 1.5, md: 2 }}>
                <Typography
                    sx={(theme) => ({
                        typography: {
                            xs: {
                                ...theme.typography.h5,
                                fontWeight: '500',
                            },
                            md: {
                                ...theme.typography.h4,
                                fontSize: '2rem',
                                fontWeight: '500',
                            },
                        },
                        textTransform: 'uppercase',
                    })}
                >
                    {product.attributes.name}
                </Typography>
            </Box>

            <Typography
                color="primary.dark"
                variant={isMobile ? 'h5' : 'h4'}
                fontWeight="medium"
                mt={2}
            >
                {product.attributes.display_price}
            </Typography>

            {!!variants.length && (
                <>
                    <Grid container sx={{ mt: 3.75 }} rowSpacing={2.5}>
                        <Grid item xs={3}>
                            <Typography variant="body2" color="grey.600">
                                Размер
                            </Typography>
                        </Grid>

                        <Grid item xs={3}>
                            <Typography variant="body2" color="grey.600">
                                Наличие
                            </Typography>
                        </Grid>

                        <Grid item xs={3}>
                            <Typography variant="body2" color="grey.600">
                                Количество
                            </Typography>
                        </Grid>

                        <Grid item xs={3}>
                            <Typography
                                variant="body2"
                                color="grey.600"
                                sx={{ textAlign: 'end' }}
                            >
                                Сумма
                            </Typography>
                        </Grid>
                        {!!variants.length &&
                            variants.map((variant, index) => (
                                <PartnerVariantItem
                                    key={index}
                                    item={variant}
                                />
                            ))}
                    </Grid>

                    {!!total.count && (
                        <Box
                            display={'flex'}
                            justifyContent={'flex-end'}
                            mt={2.5}
                        >
                            <Typography
                                variant="body2"
                                fontWeight={500}
                            >{`Итого: ${total.count} шт на ${priceFormat(
                                total.price
                            )}`}</Typography>
                        </Box>
                    )}
                </>
            )}

            {!variants.length && (
                <>
                    <Grid container sx={{ mt: 3.75 }} rowSpacing={2.5}>
                        <Grid item xs={4}>
                            <Typography variant="body2" color="grey.600">
                                Наличие
                            </Typography>
                        </Grid>

                        <Grid item xs={4}>
                            <Typography variant="body2" color="grey.600">
                                Количество
                            </Typography>
                        </Grid>

                        <Grid item xs={4}>
                            <Typography
                                variant="body2"
                                color="grey.600"
                                sx={{ textAlign: 'end' }}
                            >
                                Сумма
                            </Typography>
                        </Grid>
                        {variant && <PartnerProductItem item={variant} />}
                    </Grid>

                    {!!lineItemProduct?.attributes.quantity && (
                        <Box
                            display={'flex'}
                            justifyContent={'flex-end'}
                            mt={2.5}
                        >
                            <Typography
                                variant="body2"
                                fontWeight={500}
                            >{`Итого: ${lineItemProduct.attributes.quantity} шт на ${lineItemProduct.attributes.display_total}`}</Typography>
                        </Box>
                    )}
                </>
            )}

            <Box mt={3.75}>
                <Typography variant="body2" color="grey.600">
                    {'Данные о наличии и стоимости обновлены '}
                    <b>
                        {`${moment().format('DD')} ${convertMonth(
                            moment().format('MM')
                        )} ${moment().format('yyyy')}, 00:00 МСК.`}
                    </b>
                    {
                        '. Стоимость и количество может измениться после рассмотрении вашей заявки.'
                    }
                    {totalPriceZero ? (
                        <b>
                            {`
                            Товар без розничной цены, уточните цену у Вашего
                            менеджера.`}
                        </b>
                    ) : (
                        ''
                    )}
                </Typography>
            </Box>

            <Box mt={{ xs: 5, md: 6 }}>
                <div
                    dangerouslySetInnerHTML={{
                        __html: product.attributes.description,
                    }}
                />
            </Box>
        </Container>
    )
}
