import { Box } from '@mui/material'
import React from 'react'

import { ProductsFiltersList } from '../ProductsFiltersList'

export const DesktopProductsFilter: React.FC = () => {
    return (
        <Box
            sx={{
                width: { md: '212px' },
                minWidth: { md: '212px' },
                ml: { md: '45px' },
                mr: { md: '36px' },
            }}
        >
            <ProductsFiltersList />
        </Box>
    )
}
