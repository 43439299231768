import { PayloadAction } from '@reduxjs/toolkit'
import { addressesActions } from 'app/modules/Addresses/slice'
import { imagesActions } from 'app/modules/Images/slice'
import { listItemsActions } from 'app/modules/LineItems/slice'
import { paymentsActions } from 'app/modules/Payments/slice'
import { productsActions } from 'app/modules/Products/slice'
import { promotionsActions } from 'app/modules/Promotion/slice'
import { variantsActions } from 'app/modules/Variants/slice'
import { call, put, takeLatest } from 'redux-saga/effects'
import { IAddress } from 'types/IAddress'
import { ICartCollectionResponse, ICartItemResponse } from 'types/ICart'
import { IPayment } from 'types/IPayment'
import { request } from 'utils/request'

import { ordersActions } from '.'

export function* getOrders() {
    try {
        const response: ICartCollectionResponse = yield call(
            request,
            '/api/v2/storefront/account/orders',
            {
                params: {
                    include: [
                        'variants.product',
                        'line_items',
                        'payments',
                        'billing_address',
                        'variants.images',
                        'shipments',
                        'shipping_address',
                        'promotions',
                    ].join(','),
                    sort: '-completed_at',
                },
            }
        )

        const addresses = response.included.filter(
            (value): value is IAddress => value.type === 'address'
        )

        const payments = response.included.filter(
            (value): value is IPayment => value.type === 'payment'
        )

        yield put(imagesActions.imagesAdded(response))
        yield put(productsActions.productsAddedFromInclude(response))
        yield put(variantsActions.variantsLoaded(response))
        yield put(listItemsActions.listItemsLoaded(response))
        yield put(addressesActions.addressesAdded(addresses))
        yield put(paymentsActions.paymentsAdded(payments))
        yield put(ordersActions.ordersLoaded(response.data))
        yield put(promotionsActions.promotionsLoaded(response))
    } catch (error: any) {
        //
    }
}

export function* generateOrderPaymentUrl(action: PayloadAction<string>) {
    try {
        const response: ICartItemResponse = yield call(
            request,
            '/api/v2/storefront/checkout/create_payment',
            {
                method: 'POST',
                headers: {
                    'X-Spree-Order-Token': action.payload,
                    'Content-Type': 'application/vnd.api+json',
                },
            }
        )

        yield put(ordersActions.orderUpdated(response.data))

        window.location.href = response.data.attributes.payment_url
    } catch (error: any) {
        //
    }
}

export function* ordersWatcher() {
    yield takeLatest(ordersActions.loadOrders.type, getOrders)
    yield takeLatest(
        ordersActions.generateOrderPaymentUrl.type,
        generateOrderPaymentUrl
    )
}
