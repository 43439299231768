import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useState } from 'react'

import { ContentWrapper } from './ContentWrapper'

const ButtonWrapper: React.FC = ({ children }) => (
    <Box
        display="flex"
        alignItems="flex-end"
        width={'100%'}
        sx={{
            mt: { xs: '24px', md: '12%' },
        }}
    >
        {children}
    </Box>
)

export const ServiceDepartmentClientsBanner: React.FC = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'lg'))

    const [isHoverServiceDepartmentClients, setHoverServiceDepartmentClients] =
        useState<boolean>(false)

    const backgroundImage = '/assets/images/Rectangle1162-colored.jpg'

    return (
        <Box
            onMouseEnter={() => setHoverServiceDepartmentClients(true)}
            onMouseLeave={() => setHoverServiceDepartmentClients(false)}
            sx={{ height: '100%' }}
        >
            <ContentWrapper backgroundImage={backgroundImage}>
                <Typography
                    gutterBottom
                    sx={{
                        fontWeight: 500,
                        textAlign: 'center',
                        textTransform: 'uppercase',
                    }}
                    variant={isMobile ? 'body3' : 'body2'}
                >
                    Забота, как о родных
                </Typography>

                <Typography
                    sx={{ textAlign: 'center', px: { xs: '2vw', md: 0 } }}
                    variant={isMobile ? 'body3' : 'body2'}
                >
                    Концепция консультанта, как вашего друга или подруги
                </Typography>

                {(isHoverServiceDepartmentClients || isMobile) && (
                    <ButtonWrapper>
                        <Box
                            href={'/static/service_department_clients'}
                            component={'a'}
                            width={'100%'}
                        >
                            <Button
                                fullWidth
                                variant="outlined"
                                sx={{
                                    color: 'white',
                                    borderColor: 'white',
                                    '&:hover': { borderColor: 'white' },
                                }}
                            >
                                подробнее
                            </Button>
                        </Box>
                    </ButtonWrapper>
                )}
                {!isMobile && !isHoverServiceDepartmentClients && (
                    <ButtonWrapper>
                        <Button
                            fullWidth
                            variant="text"
                            sx={{ color: 'white' }}
                        >
                            подробнее
                        </Button>
                    </ButtonWrapper>
                )}
            </ContentWrapper>
        </Box>
    )
}

export const PersonalConsultantBanner: React.FC = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'lg'))

    const [isHover, setHover] = useState<boolean>(false)

    const backgroundImage = '/assets/images/Rectangle1163-colored.jpg'

    return (
        <Box
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            sx={{ height: '100%' }}
        >
            <ContentWrapper backgroundImage={backgroundImage}>
                <Typography
                    gutterBottom
                    sx={{
                        fontWeight: 500,
                        textAlign: 'center',
                        textTransform: 'uppercase',
                    }}
                    variant={isMobile ? 'body3' : 'body2'}
                >
                    Встреча с личным консультантом
                </Typography>

                <Typography
                    sx={{ textAlign: 'center' }}
                    variant={isMobile ? 'body3' : 'body2'}
                >
                    Вы можете записаться на экскурсию в Дом Island Soul в Москве
                </Typography>

                {(isHover || isMobile) && (
                    <ButtonWrapper>
                        <Box
                            href={'https://wa.me/79035539971'}
                            target={'_blank'}
                            component={'a'}
                            width={'100%'}
                        >
                            <Button
                                fullWidth
                                variant="outlined"
                                sx={{
                                    color: 'white',
                                    borderColor: 'white',
                                    '&:hover': { borderColor: 'white' },
                                }}
                            >
                                Чат со стилистом
                            </Button>
                        </Box>
                    </ButtonWrapper>
                )}
                {!isMobile && !isHover && (
                    <ButtonWrapper>
                        <Button
                            fullWidth
                            variant="text"
                            sx={{ color: 'white' }}
                        >
                            Чат со стилистом
                        </Button>
                    </ButtonWrapper>
                )}
            </ContentWrapper>
        </Box>
    )
}

export const PackagingBanner: React.FC = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'lg'))

    const [isHoverPackaging, setHoverPackaging] = useState<boolean>(false)

    const backgroundImage = '/assets/images/Rectangle1164-colored.jpg'

    return (
        <Box
            onMouseEnter={() => setHoverPackaging(true)}
            onMouseLeave={() => setHoverPackaging(false)}
            sx={{ height: '100%' }}
        >
            <ContentWrapper backgroundImage={backgroundImage}>
                <Typography
                    gutterBottom
                    sx={{
                        fontWeight: 500,
                        textAlign: 'center',
                        textTransform: 'uppercase',
                    }}
                    variant={isMobile ? 'body3' : 'body2'}
                >
                    Фирменная упаковка с душой
                </Typography>

                <Typography
                    sx={{ textAlign: 'center' }}
                    variant={isMobile ? 'body3' : 'body2'}
                >
                    Ваше украшение будет окутано настоящим балийским теплом
                </Typography>

                {(isHoverPackaging || isMobile) && (
                    <ButtonWrapper>
                        <Box
                            href={'/static/branded_packaging'}
                            component={'a'}
                            width={'100%'}
                        >
                            <Button
                                fullWidth
                                variant="outlined"
                                sx={{
                                    color: 'white',
                                    borderColor: 'white',
                                    '&:hover': { borderColor: 'white' },
                                }}
                            >
                                подробнее
                            </Button>
                        </Box>
                    </ButtonWrapper>
                )}
                {!isMobile && !isHoverPackaging && (
                    <ButtonWrapper>
                        <Button
                            fullWidth
                            variant="text"
                            sx={{ color: 'white' }}
                        >
                            подробнее
                        </Button>
                    </ButtonWrapper>
                )}
            </ContentWrapper>
        </Box>
    )
}

export const ExchangeBanner: React.FC = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'lg'))

    const [isHoverExchange, setHoverExchange] = useState<boolean>(false)

    const backgroundImage = '/assets/images/Rectangle1165-colored.jpg'

    return (
        <Box
            onMouseEnter={() => setHoverExchange(true)}
            onMouseLeave={() => setHoverExchange(false)}
            sx={{ height: '100%' }}
        >
            <ContentWrapper backgroundImage={backgroundImage}>
                <Typography
                    gutterBottom
                    sx={{
                        fontWeight: 500,
                        textAlign: 'center',
                        textTransform: 'uppercase',
                    }}
                    variant={isMobile ? 'body3' : 'body2'}
                >
                    Гарантия и возврат
                </Typography>

                <Typography
                    sx={{ textAlign: 'center' }}
                    variant={isMobile ? 'body3' : 'body2'}
                >
                    Мы даем уникальную для ювелирного рынка гарантию на качество
                    изделий в 6 месяцев
                </Typography>
                {(isHoverExchange || isMobile) && (
                    <ButtonWrapper>
                        <Box
                            href={'/static/return_exchange'}
                            component={'a'}
                            width={'100%'}
                        >
                            <Button
                                fullWidth
                                variant="outlined"
                                sx={{
                                    color: 'white',
                                    borderColor: 'white',
                                    '&:hover': { borderColor: 'white' },
                                }}
                            >
                                подробнее
                            </Button>
                        </Box>
                    </ButtonWrapper>
                )}
                {!isMobile && !isHoverExchange && (
                    <ButtonWrapper>
                        <Button
                            fullWidth
                            variant="text"
                            sx={{ color: 'white' }}
                        >
                            подробнее
                        </Button>
                    </ButtonWrapper>
                )}
            </ContentWrapper>
        </Box>
    )
}
