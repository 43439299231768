import {
    Box,
    Divider,
    SxProps,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { Theme } from '@mui/system'
import React from 'react'

export interface LoyaltyItem {
    title: string
    subtitle: string
    details: LoyaltyItemDetails[]
}

export interface LoyaltyItemDetails {
    value: string
    bold?: boolean
    style?: SxProps<Theme>
    textStyle?: SxProps<Theme>
}

interface BoxLoyaltyProps {
    item: LoyaltyItem
    labelItem?: LoyaltyItem
    left?: boolean
}

export const MobileBoxLoyalty: React.FC<{
    item: LoyaltyItemDetails
    labelItem?: LoyaltyItemDetails
}> = ({ item, labelItem }) => {
    const { bold, value } = item

    const theme = useTheme()

    if (!item.value) return null

    return (
        <Box sx={{ mt: '16px', mb: '16px', borderTop: '1px solid #54A67F55' }}>
            <Typography
                variant={'body2'}
                color={'grey.600'}
                fontWeight={500}
                textAlign={'center'}
                mt={3}
            >
                {labelItem?.value}
            </Typography>
            <Box
                sx={{
                    px: '16px',
                }}
            >
                <Typography
                    variant={bold ? 'h6' : 'body2'}
                    fontWeight={bold ? 500 : 400}
                    textAlign={'center'}
                    color={theme.palette.text.primary}
                    fontSize="12px"
                >
                    {value}
                </Typography>
            </Box>
        </Box>
    )
}

export const BoxLoyalty: React.FC<BoxLoyaltyProps> = ({
    item,
    labelItem,
    left,
}) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'lg'))

    const oneItem = (
        text: string,
        bold?: boolean,
        sx?: SxProps<Theme>,
        textStyle?: SxProps<Theme>
    ) => (
        <Box
            sx={{
                minHeight: '75px',
                mt: {
                    xs: 0.5,
                    lg: '30px',
                },
                mb: {
                    lg: '30px',
                },
                minWidth: {
                    md: '290px',
                    lg: '110px',
                },

                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
                fontSize: '0.625vw',
                ...sx,
            }}
        >
            <Typography
                fontWeight={left ? 400 : bold ? 500 : 400}
                textAlign={'center'}
                color={
                    left
                        ? theme.palette.text.disabled
                        : theme.palette.text.primary
                }
                sx={{
                    fontSize: '0.625vw',
                    ...textStyle,
                }}
            >
                {text}
            </Typography>

            <Divider
                sx={{
                    width: '110%',
                }}
            />
        </Box>
    )

    if (isMobile && left) return null

    return (
        <Box
            sx={{
                width: '100%',
                border: {
                    xs: '2px solid #54A67F',
                    md: '2px solid transparent',
                },
                borderRadius: {
                    xs: '20px',
                    md: '20px',
                },
                '&:hover': {
                    border: {
                        md: !left ? '2px solid #54A67F' : '',
                    },
                },
            }}
        >
            <Box
                minHeight="50px"
                sx={{
                    mt: {
                        xs: '16px',
                    },
                }}
            >
                <Typography
                    variant="h6"
                    fontWeight={400}
                    textAlign={'center'}
                    sx={{ fontSize: { lg: '0.7vw' } }}
                >
                    {item.title}
                </Typography>
                <Typography
                    variant="h6"
                    fontWeight={700}
                    textAlign={'center'}
                    mb={isMobile ? 3 : 6}
                    sx={{ textTransform: 'uppercase', fontSize: { lg: '1vw' } }}
                >
                    {item.subtitle}
                </Typography>
            </Box>

            {item.details.map((row, index) => {
                return (
                    <React.Fragment key={index}>
                        {isMobile ? (
                            <MobileBoxLoyalty
                                labelItem={labelItem?.details[index]}
                                item={row}
                            />
                        ) : (
                            oneItem(
                                row.value,
                                row.bold,
                                row.style,
                                row.textStyle
                            )
                        )}
                    </React.Fragment>
                )
            })}
        </Box>
    )
}
