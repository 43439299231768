import { useMediaQuery, useTheme } from '@mui/material'
import React from 'react'

import { DesktopFooter } from './DesktopFooter'
import { MobileFooter } from './MobileFooter'

export const Footer: React.FC = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'lg'))
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

    return (
        <>
            {isMobile && <MobileFooter />}

            {isDesktop && <DesktopFooter />}
        </>
    )
}
