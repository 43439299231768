import { CardMedia, SxProps } from '@mui/material'
import { Theme } from '@mui/system'
import React, { useEffect, useRef, useState } from 'react'

interface VideoProps {
    src: string
    isMuted?: boolean
    poster?: string
    sx?: SxProps<Theme>
}

export const AutoplayVideo: React.FC<VideoProps> = ({
    src,
    isMuted = true,
    poster,
    sx,
}) => {
    const refVideo = useRef<HTMLVideoElement>(null)
    const [videoContentLoaded, setVideoContentLoaded] = useState(false)

    useEffect(() => {
        if (!refVideo.current) {
            return
        }

        if (isMuted) {
            //open bug since 2017 that you cannot set muted in video element https://github.com/facebook/react/issues/10389
            refVideo.current.defaultMuted = true
            refVideo.current.muted = true
        }

        refVideo.current.src = src
    }, [src])

    useEffect(() => {
        if (!refVideo.current) {
            return
        }

        if (videoContentLoaded) {
            refVideo.current.play()
        }
    }, [videoContentLoaded])

    return (
        <CardMedia
            component="video"
            ref={refVideo}
            loop
            autoPlay
            playsInline //FIX iOS black screen
            onLoadedData={() => {
                setVideoContentLoaded(true)
            }}
            poster={poster}
            sx={sx}
        />
    )
}
