import { PayloadAction } from '@reduxjs/toolkit'
import { accountActions } from 'app/modules/Account/slice'
import { activeStorageBlobActions } from 'app/modules/ActiveStorageBlob/slice'
import { addressesActions } from 'app/modules/Addresses/slice'
import { cartActions } from 'app/modules/Cart/slice'
import { checkoutActions } from 'app/modules/Checkout/slice'
import { citiesActions } from 'app/modules/Cities/slice'
import { toast } from 'react-toastify'
import { call, put, takeLatest } from 'redux-saga/effects'
import { IActiveStorageBlob } from 'types/IActiveStorageBlob'
import { IAddress } from 'types/IAddress'
import { IUserItemResponse } from 'types/IUser'
import { request } from 'utils/request'

import { authActions } from '.'
import { IAuthAnswer, ICreateAccount, ISignin } from './types'

export function* getCode(
    action: PayloadAction<{
        requestData: ICreateAccount
        resend?: true
    }>
) {
    try {
        const url = action.payload.resend
            ? '/api/v2/storefront/account/resend_code'
            : '/api/v2/storefront/account'

        const response: IUserItemResponse = yield call(request, url, {
            method: 'POST',
            data: action.payload.requestData,
            headers: {
                'Content-Type': 'application/vnd.api+json',
            },
            params: {
                include: [
                    'default_billing_address',
                    'default_shipping_address',
                    'avatar_blob',
                ].join(','),
            },
        })
        yield put(authActions.getCodeCompleted(response.data))

        const addresses = response.included?.filter(
            (value): value is IAddress => value.type === 'address'
        )

        const images = response.included?.filter(
            (value): value is IActiveStorageBlob =>
                value.type === 'active_storage_blob'
        )

        if (images) {
            yield put(activeStorageBlobActions.imagesAdded(images))
        }

        if (addresses) {
            yield put(addressesActions.addressesAdded(addresses))
        }

        yield put(accountActions.accountLoaded(response))
        yield put(checkoutActions.setDefaultCustomerInformation(response))
    } catch (error: any) {
        yield put(authActions.statusError())

        toast.error(
            error.data.error_description ||
                error.data.error ||
                'Что-то пошло не так',
            {
                type: 'error',
            }
        )
    }
}

export function* getOfflineTradeCode(action: PayloadAction<ICreateAccount>) {
    try {
        yield call(request, '/api/v2/storefront/account', {
            method: 'POST',
            data: action.payload,
            headers: {
                'Content-Type': 'application/vnd.api+json',
            },
            params: {
                include: [
                    'default_billing_address',
                    'default_shipping_address',
                    'avatar_blob',
                ].join(','),
            },
        })
        yield put(authActions.getOfflineTradeCodeCompleted(action.payload))
    } catch (error: any) {
        yield put(authActions.statusError())

        toast.error(
            error.data.error_description ||
                error.data.error ||
                'Что-то пошло не так',
            {
                type: 'error',
            }
        )
    }
}

export function* signIn(action: PayloadAction<ISignin>) {
    try {
        const response: IAuthAnswer = yield call(
            request,
            '/spree_oauth/token',
            {
                method: 'POST',
                data: action.payload,
            }
        )
        yield put(authActions.signInCompleted(response))
    } catch (error: any) {
        yield put(authActions.statusError())

        toast.error(
            error.data.error_description ||
                error.data.error ||
                'Что-то пошло не так',
            {
                type: 'error',
            }
        )
    }
}

export function* refreshToken(
    action: PayloadAction<Parameters<typeof authActions.refreshToken>[0]>
) {
    try {
        const response: IAuthAnswer = yield call(
            request,
            '/spree_oauth/token',
            {
                method: 'POST',
                data: {
                    grant_type: 'refresh_token',
                    refresh_token: action.payload.refresh_token,
                },
            }
        )
        yield put(authActions.signInCompleted(response))

        if (action.payload.loadAccount) {
            yield put(authActions.loadAccount())
        }
    } catch (error: any) {
        yield put(authActions.statusError())

        yield put(authActions.logout())
        yield put(cartActions.deleteCartState())
        yield put(cartActions.deleteCartToken())
        yield put(cartActions.createCart())

        toast.error(
            error.data.error_description ||
                error.data.error ||
                'Что-то пошло не так',
            {
                type: 'error',
            }
        )
    }
}

export function* loadAccount() {
    try {
        const requestURL = `/api/v2/storefront/account`

        const response: IUserItemResponse = yield call(request, requestURL, {
            params: {
                include: [
                    'default_billing_address',
                    'default_shipping_address',
                    'avatar_blob',
                ].join(','),
            },
        })

        const addresses = response.included.filter(
            (value): value is IAddress => value.type === 'address'
        )

        const images = response.included.filter(
            (value): value is IActiveStorageBlob =>
                value.type === 'active_storage_blob'
        )

        if (response.data.attributes.wholesale) {
            yield put(citiesActions.setActiveCityId('1'))
        }

        yield put(activeStorageBlobActions.imagesAdded(images))
        yield put(addressesActions.addressesAdded(addresses))
        yield put(accountActions.accountLoaded(response))
        yield put(checkoutActions.setDefaultCustomerInformation(response))
        yield put(authActions.setAuthorized())
    } catch (error: any) {
        yield put(authActions.statusError())

        yield put(authActions.logout())
        yield put(cartActions.deleteCartState())
        yield put(cartActions.deleteCartToken())
        yield put(cartActions.createCart())

        toast.error(
            error.data.error_description ||
                error.data.error ||
                'Что-то пошло не так',
            {
                type: 'error',
            }
        )
    }
}

export function* authWatcher() {
    yield takeLatest(authActions.getCode.type, getCode)
    yield takeLatest(authActions.getOfflineTradeCode.type, getOfflineTradeCode)
    yield takeLatest(authActions.signIn.type, signIn)
    yield takeLatest(authActions.refreshToken.type, refreshToken)
    yield takeLatest(authActions.loadAccount.type, loadAccount)
}
