import { Box } from '@mui/material'
import { ArticleList } from 'app/modules/Articles/templates/ArticleList'
import { ArticleView } from 'app/modules/Articles/templates/ArticleView'
import React from 'react'
import { Route, Switch } from 'react-router-dom'

export const JournalPage: React.FC = () => {
    return (
        <Box
            sx={{
                mt: { md: 25, lg: 17, xl: 17 },
            }}
        >
            {/* <ArticleTypeSwitcher /> */}
            <Switch>
                <Route exact path="/journal" component={ArticleList} />
                <Route
                    exact
                    path="/journal/:pageSlug"
                    component={ArticleView}
                />
            </Switch>
        </Box>
    )
}
