import { LoadingButton } from '@mui/lab'
import {
    Box,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Link,
    Typography,
} from '@mui/material'
import { InvisibleSmartCaptcha } from '@yandex/smart-captcha'
import { PhoneField } from 'app/components/PhoneField'
import { testPhoneNumber } from 'app/components/PhoneField/utils/validatePhoneNumber'
import { useYandexTranslateContext } from 'app/features/YandexTranslate'
import { authActions } from 'app/modules/Auth/slice'
import {
    selectCreateAccountForm,
    selectStatus,
} from 'app/modules/Auth/slice/selectors'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EStatus } from 'types'
import {
    googleAnalyticsEvent,
    googleAnalyticsEvents,
} from 'utils/googleAnalytics'
import * as yup from 'yup'

export const PhoneForm: React.FC = () => {
    const dispatch = useDispatch()
    const [capchaSuccess, setCapchaSuccess] = useState(false)
    const [visible, setVisible] = useState(false)

    const { data, status: signup_status } = useSelector(selectCreateAccountForm)
    const status = useSelector(selectStatus)

    const { yaTranslateGetCode } = useYandexTranslateContext()

    const validationSchema = yup.object({
        user: yup.object({
            phone: yup.string().required().test(testPhoneNumber),
        }),
    })

    const formik = useFormik({
        validationSchema,
        initialValues: {
            ...data,
            personal_data: false,
            offer_agreement: false,
            newsletter_agreement: false,
        },
        validateOnBlur: false,
        validateOnChange: false,
        enableReinitialize: true,
        onSubmit: (values) => {
            if (capchaSuccess) {
                googleAnalyticsEvent(googleAnalyticsEvents.input_phone)
                dispatch(
                    authActions.getCode({ requestData: { user: values.user } })
                )
            } else {
                setVisible(true)
            }
        },
    })

    useEffect(() => {
        dispatch(authActions.resetStatus())
    }, [])

    useEffect(() => {
        if (signup_status === EStatus.FINISHED) {
            dispatch(authActions.resetPhoneStatus())
        }
    }, [signup_status])

    const enableButton = Boolean(
        formik.values.personal_data &&
            formik.values.offer_agreement &&
            formik.values.newsletter_agreement
    )

    return (
        <Box
            noValidate
            component="form"
            onSubmit={(e: React.FormEvent) => {
                e.preventDefault()

                formik.handleSubmit()
            }}
            sx={{
                width: '100%',
            }}
        >
            <PhoneField
                fullWidth
                name="user.phone"
                label="Номер телефона"
                value={formik.values.user.phone || ''}
                error={!!formik.errors.user?.phone}
                onChange={formik.handleChange}
            />

            <FormGroup sx={{ mt: 6.25 }}>
                <FormControlLabel
                    sx={{
                        alignItems: 'flex-start',
                    }}
                    control={
                        <Checkbox
                            sx={{ pt: 0 }}
                            checked={formik.values.personal_data || false}
                            onChange={() => {
                                const newValue = !formik.values.personal_data
                                formik.setFieldValue('personal_data', newValue)
                            }}
                        />
                    }
                    label={
                        <Typography variant="body2">
                            Я{' '}
                            <Link
                                rel="noreferrer"
                                href="/agreements/2_Политика_обработки_персональных_данных_ИП_Судорженко_Д_В.pdf"
                                target="_blank"
                            >
                                согласен
                            </Link>{' '}
                            на обработку персональных данных
                        </Typography>
                    }
                />
                <FormControlLabel
                    sx={{
                        alignItems: 'flex-start',
                    }}
                    control={
                        <Checkbox
                            sx={{ pt: 0 }}
                            checked={formik.values.offer_agreement || false}
                            onChange={() => {
                                const newValue = !formik.values.offer_agreement
                                formik.setFieldValue(
                                    'offer_agreement',
                                    newValue
                                )
                            }}
                        />
                    }
                    label={
                        <Typography variant="body2">
                            Я ознакомлен и согласен с положениями{' '}
                            <Link
                                rel="noreferrer"
                                href="/agreements/1_Договор_оферта_ИП_Судорженко_Д_В.pdf"
                                target="_blank"
                            >
                                Оферты
                            </Link>
                        </Typography>
                    }
                />
                <FormControlLabel
                    sx={{
                        alignItems: 'flex-start',
                    }}
                    control={
                        <Checkbox
                            sx={{ pt: 0 }}
                            checked={
                                formik.values.newsletter_agreement || false
                            }
                            onChange={() => {
                                const newValue =
                                    !formik.values.newsletter_agreement

                                formik.setFieldValue(
                                    'newsletter_agreement',
                                    newValue
                                )
                            }}
                        />
                    }
                    label={
                        <Typography variant="body2">
                            Я{' '}
                            <Link
                                rel="noreferrer"
                                href="/agreements/4_Согласие_рассылка_ИП_Судорженко_Д_В.pdf"
                                target="_blank"
                            >
                                согласен
                            </Link>{' '}
                            на получение информационной рассылки
                        </Typography>
                    }
                />
            </FormGroup>

            <LoadingButton
                loading={status === EStatus.PENDING}
                disabled={!enableButton}
                fullWidth
                size="large"
                color={'primary'}
                variant="contained"
                sx={{ mt: 5 }}
                onClick={() => {
                    formik.handleSubmit()
                }}
            >
                Продолжить
            </LoadingButton>
            <div style={{ marginTop: '20px' }}>
                <InvisibleSmartCaptcha
                    sitekey="ysc1_Fe6sep3luHaRw9A2B4gIn1fUhdCBeCbOyzLcJzGu0edfb244"
                    language={yaTranslateGetCode() as 'ru' | 'en'}
                    onChallengeVisible={() => setCapchaSuccess(false)}
                    onSuccess={() => {
                        setCapchaSuccess(true)
                        setVisible(false)
                        formik.handleSubmit()
                    }}
                    visible={visible}
                />
            </div>
        </Box>
    )
}
