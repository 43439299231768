import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'

import DoneIcon from '../assets/done_icon.svg'
import { offlineTradeActions } from '../slice'

export const OfflineTradeCustomerAdded: React.FC = () => {
    const dispatch = useDispatch()

    const handleAddOrder = () => {
        dispatch(offlineTradeActions.createOrderPage())
    }

    const handleMainPage = () => {
        dispatch(offlineTradeActions.resetPage())
    }

    return (
        <>
            <Typography variant="body1" fontWeight={500} textAlign={'center'}>
                Клиент зарегистрирован в системе лояльности
            </Typography>

            <Box display={'flex'} justifyContent={'center'} mt={5.5}>
                <Box alt={'Done'} src={DoneIcon} component={'img'} />
            </Box>

            <Button
                fullWidth
                variant="contained"
                sx={{ mt: 6.5 }}
                onClick={handleAddOrder}
            >
                ДОБАВИТЬ ПОКУПКУ
            </Button>

            <Button
                fullWidth
                variant="outlined"
                sx={{ mt: 1 }}
                onClick={handleMainPage}
            >
                ВЕРНУТЬСЯ НА ГЛАВНУЮ
            </Button>
        </>
    )
}
