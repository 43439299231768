import {
    createEntityAdapter,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit'
import { EStatus } from 'types'
import { IActiveStorageBlob } from 'types/IActiveStorageBlob'

import { ActiveStorageBlobState } from './types'

export const activeStorageBlobAdapter =
    createEntityAdapter<IActiveStorageBlob>()

const slice = createSlice({
    name: 'activeStorageBlob',
    initialState:
        activeStorageBlobAdapter.getInitialState<ActiveStorageBlobState>({
            ids: [],
            entities: {},
            status: EStatus.INITIAL,
            open: false,
        }),
    reducers: {
        imagesAdded(state, action: PayloadAction<IActiveStorageBlob[]>) {
            activeStorageBlobAdapter.setMany(state, action.payload)
        },
        imageAdded(state, action: PayloadAction<IActiveStorageBlob>) {
            activeStorageBlobAdapter.setOne(state, action.payload)
        },
        openModal(state) {
            state.open = true
            state.status = EStatus.INITIAL
        },
        hideModal(state) {
            state.open = false
        },
        statusPending(state) {
            state.status = EStatus.PENDING
        },
        uploadImage(state, _action: PayloadAction<File>) {
            state.status = EStatus.PENDING
        },
        statusFinished(state) {
            state.open = false
            state.status = EStatus.FINISHED
        },
        statusError(state) {
            state.status = EStatus.ERROR
        },
    },
})

export const {
    actions: activeStorageBlobActions,
    reducer: activeStorageBlobReducer,
} = slice
