import { Close as CloseIcon } from '@mui/icons-material'
import { Grid, IconButton, useTheme } from '@mui/material'
import { SearchIcon } from 'app/components/Icons/SearchIcon'
import React from 'react'

import { ReactComponent as MapPinIcon } from '../../../assets/map_pin_icon.svg'
import { ReactComponent as MenuIcon } from '../../../assets/MobileCatalogIcon.svg'
import { Logo } from '../../Logo'

interface MobileHeaderItemsProps {
    isOpened: boolean
    toggleDrawer: () => void
    cityOpen?: () => void
    isMobileSearchOpened: boolean
    mobileSearchChange: () => void
}

export const MobileHeaderItems = React.memo<MobileHeaderItemsProps>(
    ({
        isOpened,
        toggleDrawer,
        cityOpen,
        isMobileSearchOpened,
        mobileSearchChange,
    }) => {
        const theme = useTheme()

        const handleSearch = () => {
            isOpened && toggleDrawer()
            mobileSearchChange?.()
        }

        const handleToggleDrawer = () => {
            isMobileSearchOpened && mobileSearchChange?.()
            toggleDrawer()
        }

        return (
            <>
                <Grid item xs>
                    <IconButton onClick={handleToggleDrawer}>
                        {isOpened ? <CloseIcon /> : <MenuIcon />}
                    </IconButton>
                </Grid>

                <Grid
                    item
                    xs
                    sx={{ display: 'flex', justifyContent: 'center' }}
                >
                    <Logo align="center" size="small" variant="dark" />
                </Grid>

                <Grid
                    item
                    xs
                    sx={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                    <IconButton onClick={handleSearch}>
                        <SearchIcon
                            color={
                                isMobileSearchOpened
                                    ? theme.palette.primary.main
                                    : theme.palette.getContrastText('#FFFFFF')
                            }
                        />
                    </IconButton>

                    <IconButton onClick={() => cityOpen?.()}>
                        <MapPinIcon />
                    </IconButton>
                </Grid>
            </>
        )
    }
)
