import { Grid, Typography } from '@mui/material'
import { selectLineItemByVariantId } from 'app/modules/LineItems/slice/selectors'
import React from 'react'
import { useSelector } from 'react-redux'
import { IVariant } from 'types/IVariant'

import { QuantityControl } from './QuantityControl'

interface PartnerProductItemProps {
    item: IVariant
}

export const PartnerProductItem: React.FC<PartnerProductItemProps> = ({
    item,
}) => {
    const lineItem = useSelector(selectLineItemByVariantId)(item.id)

    return (
        <>
            <Grid item xs={4} display={'flex'} alignItems={'center'}>
                <Typography variant="body2">
                    {`${item.attributes.total_on_hand} шт.`}
                </Typography>
            </Grid>

            <Grid item xs={4} display={'flex'} alignItems={'center'}>
                <QuantityControl item={lineItem} variant={item} />
            </Grid>

            <Grid
                item
                xs={4}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'flex-end'}
            >
                <Typography variant="body2" sx={{ textAlign: 'end' }}>
                    {lineItem?.attributes.display_total || '0 ₽'}
                </Typography>
            </Grid>
        </>
    )
}
