import { Box, Grid, Typography } from '@mui/material'
import { ReactComponent as CalendarIcon } from 'app/modules/Cart/assets/Calendar.svg'
import { ReactComponent as DeliveryIcon } from 'app/modules/Cart/assets/Delivery.svg'
import { ReactComponent as WalletIcon } from 'app/modules/Cart/assets/Wallet.svg'
import React from 'react'
import {
    IShipmentsAttributes,
    ShippingMethodId,
} from 'types/IPaymentsShipmentsAttributes'

interface ShippingInfoLineProps {
    Icon: React.FC
    text: string
    secondText: string
}

const ShippingInfoLine: React.FC<ShippingInfoLineProps> = ({
    Icon,
    text,
    secondText,
}) => {
    return (
        <Box display="flex">
            <Icon />

            <Typography
                variant="body2"
                ml="16px"
                sx={{ fontSize: { xs: '14px', md: '18px' } }}
                component="span"
            >
                {`${text} — `}
                <Typography
                    variant="body2"
                    sx={(theme) => ({
                        fontSize: { xs: '14px', md: '18px' },
                        color: theme.palette.primary.main,
                        fontWeight: '500',
                        display: 'inline',
                    })}
                >
                    {secondText}
                </Typography>
            </Typography>
        </Box>
    )
}

interface ShippingInfoProps {
    shipmentsAttributes: IShipmentsAttributes
}

export const ShippingInfo: React.FC<ShippingInfoProps> = ({
    shipmentsAttributes,
}) => {
    const padTo2Digits = (num: number) => {
        return num.toString().padStart(2, '0')
    }

    const formatDate = (date: Date) => {
        return [
            padTo2Digits(date.getDate()),
            padTo2Digits(date.getMonth() + 1),
            date.getFullYear(),
        ].join('.')
    }
    const currentDate = formatDate(new Date())

    const ShippingMethodSecondText = (() => {
        switch (shipmentsAttributes.shipping_method_id) {
            case ShippingMethodId.courier:
                return 'курьер'
            case ShippingMethodId.pickup:
                return 'Самовывоз из магазина'
            default:
                return ''
        }
    })()

    return (
        <Grid container direction="column" spacing="20px">
            <Grid item>
                <ShippingInfoLine
                    Icon={DeliveryIcon}
                    text="Способ получения"
                    secondText={ShippingMethodSecondText}
                />
            </Grid>

            {shipmentsAttributes.shipping_method_id !==
                ShippingMethodId.courier && (
                <Grid item>
                    <ShippingInfoLine
                        Icon={CalendarIcon}
                        text="Доступно для получения"
                        secondText={
                            currentDate === shipmentsAttributes.start_period
                                ? `сегодня`
                                : shipmentsAttributes.end_period
                        }
                    />
                </Grid>
            )}

            <Grid item>
                <ShippingInfoLine
                    Icon={WalletIcon}
                    text="Стоимость доставки"
                    secondText={
                        shipmentsAttributes.display_final_price === '0 ₽'
                            ? 'бесплатно'
                            : shipmentsAttributes.display_final_price
                    }
                />
            </Grid>
        </Grid>
    )
}
