import {
    Box,
    Card,
    CardContent,
    CardMedia,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { IPage } from 'types/IPage'

// import { selectImageById } from 'app/modules/Images/slice/selectors'
import { selectImageForArticlesById } from './../../ImagesForArticles/slice/selectors'

interface ArticleCardProps {
    page: IPage
    photoLayout: 'vertical' | 'horizontal'
}

export const ArticleCard: React.FC<ArticleCardProps> = ({
    page,
    photoLayout,
}) => {
    const [isHover, setHover] = useState<boolean>(false)
    const getImage = useSelector(selectImageForArticlesById)

    const image =
        photoLayout === 'vertical'
            ? getImage(page.relationships.preview_image.data?.id, '890', '1140')
            : getImage(page.relationships.image.data?.id, '890', '1140')

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'lg'))

    return (
        <Link to={`/journal/${page.attributes.slug}`}>
            <Card
                elevation={0}
                sx={{
                    mt: {
                        xs: 3,
                        lg: 0,
                    },
                    mb: {
                        xs: 0,
                        lg: 4,
                    },
                    backgroundColor: 'transparent',
                }}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                <Box
                    sx={{
                        height: '128%',
                        overflow: 'hidden',
                    }}
                >
                    <CardMedia
                        sx={(theme) => ({
                            transition: theme.transitions.create(
                                ['transform'],
                                {
                                    duration:
                                        theme.transitions.duration.standard,
                                }
                            ),
                            transform: isHover ? 'scale(1.05)' : 'scale(1)',
                            height: isMobile ? '407px' : `auto`,
                        })}
                        src={image}
                        // height={isMobile ? '407px' : `calc(45vh)`}
                        // minHeight={isMobile ? '407px' : `calc(45vh)`}
                        component="img"
                    />
                </Box>

                <CardContent
                    sx={{
                        pt: 2.5,
                        pb: 6.25,
                        pl: 0,
                        pr: 0,
                    }}
                >
                    <Box display={'flex'} justifyContent={'space-between'}>
                        <Typography
                            gutterBottom
                            color="primary"
                            variant={isMobile ? 'body3' : 'body2'}
                            sx={{ textTransform: 'uppercase' }}
                            fontWeight={500}
                        >
                            {page.attributes.tag}
                        </Typography>

                        <Typography
                            color="grey.400"
                            variant={isMobile ? 'body3' : 'body2'}
                            fontWeight={500}
                        >
                            {page.attributes.created_at.slice(0, 5)}
                        </Typography>
                    </Box>

                    <Typography
                        gutterBottom
                        sx={{ mt: 0.5, textTransform: 'uppercase' }}
                        variant="body1"
                        fontWeight="medium"
                    >
                        {page.attributes.title}
                    </Typography>
                </CardContent>
            </Card>
        </Link>
    )
}
