import { Box, Container, useMediaQuery, useTheme } from '@mui/material'
import { ExperienceBanner } from 'app/components/ExperienceBanner'
import { MindboxTarget } from 'app/components/MinboxContactForm'
import { MainArticleCardsList } from 'app/modules/Articles/templates/MainArticleCardsList'
import { CategoryCardsList } from 'app/modules/Categories/templates/CategoryCardsList'
import { NoveltyProductPopularListSliderContainer } from 'app/modules/Products/templates/NoveltyProductPopularListSliderContainer'
import { ProductGiftsSlider } from 'app/modules/Products/templates/ProductGiftsSlider'
import { NoveltyProductListSliderContainer } from 'app/modules/Products/templates/ProductListSliderContainer'
import { ProductSaleListSliderContainer } from 'app/modules/Products/templates/ProductSaleListSliderContainer'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'

import { selectHeaderCategories } from '../Categories/slice/selectors'
import { getCategoryMeta } from '../Categories/templates/CategoryMeta'
import { FamilyBanner } from './components/FamilyBanner'
import { MainBanner } from './components/MainBanner'

export const MainPageLayout: React.FC = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))
    const isTablet = useMediaQuery(theme.breakpoints.between('md', 'lg'))

    const categories = useSelector(selectHeaderCategories)

    const structuredData = {
        '@context': 'https://schema.org',
        '@type': 'ItemList',
        itemListElement: categories.map((category, index) => ({
            '@type': 'ListItem',
            position: index + 1,
            item: {
                '@type': 'Thing',
                name: category.attributes.name,
                description: getCategoryMeta(category.attributes.name)
                    .description,
                url: `https://islandsouljewelry.com/${category.attributes.permalink}`,
            },
        })),
    }

    return (
        <>
            <MainBanner />
            <Box
                sx={{
                    mt: { xs: 3, lg: '80px' },
                    px: { xs: 0, md: '2.34375vw' },
                }}
            >
                <NoveltyProductPopularListSliderContainer
                    showTitle
                    title={'Бестселлеры'}
                />
            </Box>
            <Box
                sx={{
                    mt: { xs: 3, lg: '80px' },
                    px: { xs: 0, md: '2.34375vw' },
                }}
            >
                <NoveltyProductListSliderContainer
                    showTitle
                    title={'Новинки'}
                />
            </Box>

            <ProductGiftsSlider />

            <Container
                sx={{
                    mx: { xs: '4.2vw', sm: 2 },
                    px: { xs: 0, sm: 0 },
                    mt: { xs: '42px' },
                    width: 'auto',
                }}
            >
                <CategoryCardsList />
            </Container>

            <Box sx={{ mt: { xs: 9, md: '80px' } }}>
                <ExperienceBanner />
            </Box>

            {!isMobile && (
                <Box sx={{ mt: 15 }}>
                    <FamilyBanner />
                </Box>
            )}
            <Box sx={{ mt: { xs: 9, lg: '80px' } }}>
                <ProductSaleListSliderContainer />
            </Box>
            <Box
                sx={{
                    mt: { xs: '40px', md: '100px' },
                }}
            >
                <MainArticleCardsList />
            </Box>
            <MindboxTarget
                popmechanicId="57321"
                sx={{
                    mt: { xs: '40px', md: '50px' },
                    mb: { xs: -5, md: -20 },
                }}
            />
        </>
    )
}
