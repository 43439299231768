import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'types'

import { cartAdapter } from '.'

const { selectAll } = cartAdapter.getSelectors()

const selectDomain = (state: RootState) => state.cart

export const selectCartOpen = createSelector(
    [selectDomain],
    (state) => state.open
)

export const selectIsCreated = createSelector([selectDomain], (state) =>
    Boolean(state.token)
)

export const selectCartState = createSelector(
    [selectDomain],
    (state) => selectAll(state).find(Boolean)?.attributes.state
)

export const selectCartDisabledDeliveryStates = createSelector(
    [selectDomain],
    (state) =>
        selectAll(state).find(Boolean)?.attributes.disabled_delivery_states ||
        []
)

export const selectCartToken = createSelector(
    [selectDomain],
    (state) => state.token
)

export const selectStatus = createSelector(
    [selectDomain],
    (state) => state.status
)

export const selectAddItemStatus = createSelector(
    [selectDomain],
    (state) => state.addItemStatus
)

export const selectCart = createSelector([selectDomain], (state) =>
    selectAll(state).find(Boolean)
)

export const selectIsCartEmpty = createSelector([selectDomain], (state) => {
    return !selectAll(state).find(Boolean)?.relationships.line_items.data.length
})

export const selectCartShop = createSelector(
    [selectDomain],
    (state) => state.currentShop
)
