import {
    createEntityAdapter,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit'
import { IShop } from 'types/IShop'

import { IShopState } from './types'

export const shopsAdapter = createEntityAdapter<IShop>()

const slice = createSlice({
    name: 'shops',
    initialState: shopsAdapter.getInitialState<IShopState>({
        ids: [],
        entities: {},
    }),
    reducers: {
        shopsAdded(state, action: PayloadAction<IShop[]>) {
            shopsAdapter.setMany(state, action.payload)
        },
    },
})

export const { actions: shopsActions, reducer: shopsReducer } = slice
