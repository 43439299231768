import { Box, Container, Fade, IconButton, Typography } from '@mui/material'
import { selectCurrentCity } from 'app/modules/Cities/slice/selectors'
import { ReactComponent as CloseSVG } from 'assets/icons/close.svg'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useHeaderContext } from '../../HeaderContextProvider'
import { HeaderLocationChildProps } from '..'
import { DesktopHeaderLocationCountriesGrid } from './DesktopHeaderLocationCountriesGrid'

export const DesktopHeaderLocation: React.FC<HeaderLocationChildProps> = ({
    countriesData,
}) => {
    const { isCityOpened, cityClose } = useHeaderContext()

    const currentCity = useSelector(selectCurrentCity)

    useEffect(() => {
        cityClose?.()
    }, [currentCity])

    return (
        <Fade in={isCityOpened}>
            <Box
                sx={(theme) => ({
                    width: '100%',
                    height: 'calc(100vh - 50px)',
                    overflow: 'auto',
                    bgcolor: theme.palette.grey[100],
                    position: 'fixed',
                    pt: 5,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    zIndex: 1,
                })}
            >
                <Container>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography
                            variant="h5"
                            fontWeight={500}
                            sx={{ textTransform: 'uppercase' }}
                        >
                            выберите город
                        </Typography>

                        <IconButton
                            size="large"
                            sx={(theme) => ({
                                mt: -1.25,
                                bgcolor: theme.palette.common.white,
                            })}
                            onClick={cityClose}
                        >
                            <CloseSVG />
                        </IconButton>
                    </Box>

                    <Typography mt={1.25} variant="body2" color="grey.600">
                        Мы доставляем по всей России, укажите свой адрес на
                        этапе оформления заказа
                    </Typography>

                    <DesktopHeaderLocationCountriesGrid
                        countriesData={countriesData}
                    />
                </Container>

                <Box
                    sx={(theme) => ({
                        mt: 9.375,
                        py: 2.25,
                        bgcolor: theme.palette.common.white,
                    })}
                >
                    <Container>
                        <Typography variant="body2">
                            Более 70 партнеров в 4-х странах мира стали частью
                            семьи IslandSoul, обрели себя и помогают людям стать
                            счастливее
                        </Typography>
                    </Container>
                </Box>
            </Box>
        </Fade>
    )
}
