import {
    Box,
    Button,
    Chip,
    ChipProps,
    Grid,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import {
    selectChildrenCategories,
    selectHeaderCategories,
} from 'app/modules/Categories/slice/selectors'
import { includes, isEqual } from 'lodash'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import { productCountText } from 'utils/productCountText'

import { ReactComponent as FilterIcon } from '../assets/filter_icon.svg'
import { useCategoryPageContext } from '../hooks'
import { ReactComponent as ViewFilterChipRemoveIcon } from './assets/ViewFilterChipRemoveIcon.svg'

const ViewFilterChip: React.FC<ChipProps> = (props) => {
    return (
        <Chip
            color="primary"
            deleteIcon={
                <Box>
                    <ViewFilterChipRemoveIcon />
                </Box>
            }
            sx={{
                ' .MuiChip-label': {
                    fontSize: '14px',
                },
                ' .MuiChip-deleteIcon': {
                    mr: '14px',
                    ml: '2px',

                    fontSize: '16px',
                    // my: 'auto',
                },
            }}
            {...props}
        />
    )
}

export const ProductsFilterTopBar: React.FC = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))
    const isTablet = useMediaQuery(theme.breakpoints.between('md', 'lg'))

    const router = useLocation()
    const history = useHistory()

    const getSubCategories = useSelector(selectChildrenCategories)

    const headerCategories = useSelector(selectHeaderCategories)
    const category = headerCategories.find(
        (i) => i.attributes.permalink === router.pathname.slice(1)
    )

    const {
        isSaleCategory,
        isPopularCategory,
        isSmyslyCategory,
        viewFilters,
        filterReadAdapter,
        handleClearFilter,
        openFilterModal,
        totalProductsCount,
    } = useCategoryPageContext()

    const productsText = productCountText(totalProductsCount)

    return (
        <Box>
            <Box
                sx={{
                    ml: { xs: '16px', lg: '45px' },
                    mr: { xs: '16px', lg: '0px' },
                    mt: { xs: '20px', lg: '30px' },
                    mb: { xs: '16px', lg: '20px' },
                }}
                display="flex"
                alignItems="end"
                justifyContent="space-between"
            >
                <Box
                    display="flex"
                    flexDirection={{ xs: 'column', lg: 'row' }}
                    sx={{ maxWidth: '100%' }}
                    alignItems="baseline"
                >
                    <Typography
                        variant="h1"
                        fontWeight="regular"
                        noWrap
                        sx={{
                            fontSize: { xs: '24px', lg: '42px' },
                            height: { xs: '36px', lg: '56px' },
                            width: '100%',
                        }}
                    >
                        {(headerCategories
                            .find(
                                (i) =>
                                    i.attributes.permalink ===
                                    router.pathname.slice(1)
                            )
                            ?.attributes.permalink.split('/')[0] ===
                            'catalog' &&
                            includes(
                                filterReadAdapter.taxons,
                                category?.attributes.filters.taxons.join(',')
                            )) ||
                        category?.attributes.filters.segments.length
                            ? headerCategories.find(
                                  (i) =>
                                      i.attributes.permalink ===
                                      router.pathname.slice(1)
                              )?.attributes.name
                            : isSaleCategory
                            ? 'Outlet'
                            : isPopularCategory
                            ? 'Бестселлеры'
                            : isSmyslyCategory
                            ? 'Подвески смыслы'
                            : 'Общий каталог'}
                    </Typography>
                    {Boolean(totalProductsCount) && (
                        <Typography
                            fontWeight="regular"
                            ml={{ lg: '12px' }}
                            mt={{ xs: '4px', md: '0px' }}
                            sx={{ fontSize: { xs: '12px', lg: '18px' } }}
                        >
                            {totalProductsCount}&nbsp;{productsText}
                        </Typography>
                    )}
                </Box>

                {(isMobile || isTablet) && (
                    <Button
                        sx={{
                            minWidth: '24px',
                            minHeight: '24px',
                            width: '24px',
                            height: '24px',
                            p: '0px',
                            ' .MuiButton-startIcon': {
                                m: '0px',
                            },
                        }}
                        startIcon={<FilterIcon />}
                        onClick={openFilterModal}
                    />
                )}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    pl: { xs: '16px', lg: '297px' },
                    minHeight: { lg: '20px' },
                }}
            >
                <Grid
                    container
                    sx={{ alignItems: 'center' }}
                    spacing={2}
                    mb={
                        viewFilters.length
                            ? { xs: '24px', lg: '30px' }
                            : undefined
                    }
                >
                    {viewFilters.map((value, index) => {
                        return (
                            <Grid item key={index} xs="auto">
                                <ViewFilterChip
                                    label={value.displayName}
                                    onDelete={value.handleDeleteFilter}
                                />
                            </Grid>
                        )
                    })}

                    {Boolean(viewFilters.length) && (
                        <Grid item xs="auto">
                            <Button
                                disableRipple
                                size="small"
                                color="secondary"
                                variant="text"
                                onClick={() => {
                                    handleClearFilter()
                                }}
                            >
                                Очистить фильтр
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Box>
        </Box>
    )
}
