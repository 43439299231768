import { ArrowBack as ArrowBackIcon } from '@mui/icons-material'
import { Grid, IconButton, Typography } from '@mui/material'
import React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

export const ProfileHeader = React.memo(() => {
    const history = useHistory()
    const { url } = useRouteMatch()

    const handleOpenProfile = () => {
        history.push('/profile')
    }

    return (
        <>
            <Grid item xs={1}>
                <IconButton onClick={handleOpenProfile}>
                    <ArrowBackIcon />
                </IconButton>
            </Grid>

            <Grid
                item
                xs={10}
                sx={{ display: 'flex', justifyContent: 'center' }}
            >
                <Typography fontWeight={500} variant="body1">
                    {url === '/profile/orders' && 'Заказы'}
                    {url === '/profile/gift' && 'Бонусная Программа'}
                    {url === '/profile/personal' && 'Личные Данные'}
                </Typography>
            </Grid>

            <Grid item xs={1} />
        </>
    )
})
