import { Box } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { convertOrderState } from 'utils/convertUtils'

import { selectCartState } from '../../slice/selectors'
import {
    OrderDetailInfoItems,
    OrderDetailProducts,
    OrderDetailProps,
    OrderDetailStore,
    OrderDetailSubSummary,
    OrderDetailSummary,
} from '.'

export const OrderDetail: React.FC<OrderDetailProps> = ({ cart }) => {
    const showDeliveryInfoStep =
        convertOrderState(useSelector(selectCartState) || 'cart') - 1

    return (
        <Box
            mt={{
                xs: '40px',
                md: '50px',
            }}
            pt={{
                xs: '40px',
                md: '0px',
            }}
        >
            <Box sx={{ px: { xs: '16px', md: '0px' } }}>
                <OrderDetailSummary cart={cart} />

                <OrderDetailSubSummary
                    cart={cart}
                    showDeliveryInformation={showDeliveryInfoStep > 0}
                />

                <OrderDetailStore
                    cart={cart}
                    showDeliveryInformation={showDeliveryInfoStep > 0}
                />

                <OrderDetailProducts cart={cart} />
            </Box>

            <OrderDetailInfoItems />
        </Box>
    )
}
