export function flattenObject(object: { [key: string]: any }) {
    const toReturn: string[] = []

    let index = 0

    for (const i in object) {
        if (typeof object[i] == 'object' && object[i] !== null) {
            const flatObject = flattenObject(object[i])
            for (const stringProperty of flatObject) {
                toReturn[index++] = stringProperty
            }
        } else {
            toReturn[index++] = `${object[i]}`
        }
    }
    return toReturn
}
