import 'swiper/swiper.min.css'
import 'swiper/modules/navigation/navigation.min.css' // Navigation module
import 'swiper/modules/pagination/pagination.min.css' // Pagination module
import './styles.css'

import { Grid } from '@mui/material'
import { Box } from '@mui/system'
import { ReactComponent as NextIcon } from 'app/modules/Shops/components/ShopCardList/DesktopShopCardList/assets/NextIcon.svg'
import { ReactComponent as PrevIcon } from 'app/modules/Shops/components/ShopCardList/DesktopShopCardList/assets/PrevIcon.svg'
import React from 'react'
import { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { IProduct } from 'types/IProduct'

import { SliderProductCard } from './SliderProductCard'

export interface IProductSliderProps {
    productsPages: IProduct[][]
    isMobile: boolean
    redirectUrl?: string
}

export const ProductSlider: React.FC<IProductSliderProps> = ({
    productsPages,
    isMobile,
    redirectUrl,
}) => {
    const navigationPrevRef = React.useRef(null)
    const navigationNextRef = React.useRef(null)

    return (
        <Swiper
            modules={[Navigation]}
            navigation={{
                enabled: !isMobile,
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
            }}
            slidesPerView={'auto'}
            onBeforeInit={(swiper) => {
                if (
                    swiper.params.navigation &&
                    typeof swiper.params.navigation !== 'boolean'
                ) {
                    swiper.params.navigation.prevEl = navigationPrevRef.current
                    swiper.params.navigation.nextEl = navigationNextRef.current
                }
            }}
        >
            {productsPages.map((page, pageKey) => (
                <SwiperSlide key={pageKey} className={'product-slide'}>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: { xs: '12px', lg: '29px' },
                            ml: { xs: 'unset', md: '6px', lg: '14.5px' },
                            mr: { xs: '12px', md: '6px', lg: '14.5px' },
                        }}
                    >
                        {page.map((product, productKey) => (
                            <Box key={productKey} sx={{ width: '50%' }}>
                                <SliderProductCard
                                    product={product}
                                    redirectUrl={
                                        (pageKey === productsPages.length - 1 &&
                                            productKey === page.length - 1 &&
                                            redirectUrl) ||
                                        undefined
                                    }
                                />
                            </Box>
                        ))}
                    </Box>
                </SwiperSlide>
            ))}
            {!isMobile && (
                <Grid
                    sx={{
                        position: 'absolute',
                        bottom: '50%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        px: { md: '6px', lg: '14.5px' },
                    }}
                >
                    <Box
                        sx={{
                            zIndex: 1,
                            height: '64px',
                            width: '64px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                        }}
                        ref={navigationPrevRef}
                    >
                        <PrevIcon />
                    </Box>
                    <Box
                        sx={{
                            zIndex: 1,
                            height: '64px',
                            width: '64px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                        }}
                        ref={navigationNextRef}
                    >
                        <NextIcon />
                    </Box>
                </Grid>
            )}
        </Swiper>
    )
}
