import { useMediaQuery, useTheme } from '@mui/material'
import React from 'react'

import { DesktopContentWrapper } from './DesktopContentWrapper'
import { MobileContentWrapper } from './MobileContentWrapper'

interface ContentWrapperProps {
    backgroundImage: string
}

export const ContentWrapper: React.FC<ContentWrapperProps> = ({
    backgroundImage,
    children,
}) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'lg'))
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

    return (
        <>
            {isMobile && (
                <MobileContentWrapper backgroundImage={backgroundImage}>
                    {children}
                </MobileContentWrapper>
            )}

            {isDesktop && (
                <DesktopContentWrapper backgroundImage={backgroundImage}>
                    {children}
                </DesktopContentWrapper>
            )}
        </>
    )
}
