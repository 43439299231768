import { Grid, TextField } from '@mui/material'
import { PhoneField } from 'app/components/PhoneField'
import { FormikProps } from 'formik'
import React from 'react'
import { IPartnerInformation } from 'types/IPartnerInformation'

interface DesktopPartnerInformationProps {
    formik: FormikProps<IPartnerInformation>
}

export const DesktopPartnerInformation: React.FC<
    DesktopPartnerInformationProps
> = ({ formik }) => (
    <>
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    variant="standard"
                    label="Имя"
                    name="order.ship_address_attributes.firstname"
                    value={
                        formik.values.order.ship_address_attributes.firstname
                    }
                    error={
                        !!formik.errors.order?.ship_address_attributes
                            ?.firstname
                    }
                    onChange={formik.handleChange}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    variant="standard"
                    label="Фамилия"
                    name="order.ship_address_attributes.lastname"
                    value={formik.values.order.ship_address_attributes.lastname}
                    error={
                        !!formik.errors.order?.ship_address_attributes?.lastname
                    }
                    onChange={formik.handleChange}
                />
            </Grid>
        </Grid>
        <Grid container spacing={2} mt={3}>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    variant="standard"
                    label="Наименование юридического лица"
                    name="order.legal_name"
                    value={formik.values.order.legal_name}
                    error={!!formik.errors.order?.legal_name}
                    onChange={formik.handleChange}
                />
            </Grid>
        </Grid>
        <Grid container spacing={2} mt={3}>
            <Grid item xs={6}>
                <PhoneField
                    fullWidth
                    variant="standard"
                    name="order.ship_address_attributes.phone"
                    label="Номер телефона"
                    value={formik.values.order.ship_address_attributes.phone}
                    error={
                        !!formik.errors.order?.ship_address_attributes?.phone
                    }
                    onChange={formik.handleChange}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    variant="standard"
                    label="Email"
                    name="order.email"
                    value={formik.values.order.email}
                    error={!!formik.errors.order?.email}
                    onChange={formik.handleChange}
                />
            </Grid>
        </Grid>
    </>
)
