import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
// import { selectImageById } from 'app/modules/Images/slice/selectors'
import { selectImageForArticlesById } from 'app/modules/ImagesForArticles/slice/selectors'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { IPage } from 'types/IPage'

interface ArticleBigCardProps {
    page: IPage
}

export const ArticleBigCard: React.FC<ArticleBigCardProps> = ({ page }) => {
    const [isHover, setHover] = useState<boolean>(false)
    const getImage = useSelector(selectImageForArticlesById)
    const image = getImage(
        page.relationships.preview_image.data?.id,
        '890',
        '1140'
    )

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'lg'))

    return (
        <Box
            sx={{
                position: {
                    md: 'sticky',
                },
                display: 'block',
                top: '24px',
            }}
        >
            <Link to={`/journal/${page.attributes.slug}`}>
                <Card
                    sx={{
                        mt: {
                            xs: 5,
                            lg: 0,
                        },
                    }}
                    elevation={0}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                >
                    <CardActionArea>
                        <Box
                            height={isMobile ? '407px' : `calc(100vh - 48px)`}
                            sx={{
                                overflow: 'hidden',
                            }}
                        >
                            <CardMedia
                                sx={(theme) => ({
                                    transition: theme.transitions.create(
                                        ['transform'],
                                        {
                                            duration:
                                                theme.transitions.duration
                                                    .standard,
                                        }
                                    ),
                                    transform: isHover
                                        ? 'scale(1.1)'
                                        : 'scale(1)',
                                    minWidth: '100%',
                                    height: '100%',
                                })}
                                src={image}
                                component="img"
                            />
                            <CardContent
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    textAlign: 'center',
                                    position: 'absolute',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-end',
                                    background:
                                        'linear-gradient(0.55deg, rgba(0, 0, 0, 0.46) 0.47%, rgba(0, 0, 0, 0) 99.53%)',
                                    bottom: 0,
                                    pb: 5,
                                    px: '15%',
                                }}
                            >
                                <Typography
                                    gutterBottom
                                    color="white"
                                    variant={isMobile ? 'body3' : 'body2'}
                                    sx={{ textTransform: 'uppercase' }}
                                    fontWeight={500}
                                >
                                    {page.attributes.tag}
                                </Typography>

                                <Typography
                                    gutterBottom
                                    color="white"
                                    mt={1}
                                    sx={{ textTransform: 'uppercase' }}
                                    variant={isMobile ? 'body1' : 'h4'}
                                    fontWeight={500}
                                >
                                    {page.attributes.title}
                                </Typography>

                                <Typography
                                    mt={1}
                                    color="white"
                                    variant="body2"
                                    fontWeight={500}
                                >
                                    {page.attributes.created_at.slice(0, 5)}
                                </Typography>
                            </CardContent>
                        </Box>
                    </CardActionArea>
                </Card>
            </Link>
        </Box>
    )
}
