import {
    createEntityAdapter,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit'
import { mindboxSetCart } from 'app/features/MindBox'
import ym from 'react-yandex-metrika'
import { EStatus } from 'types'
import { ICart, ICartItemResponse } from 'types/ICart'
import {
    ILineItemAdd1CProps,
    ILineItemAddProps,
    ILineItemUpdateProps,
} from 'types/ILineItem'
import { IShop } from 'types/IShop'

import { CartState } from './types'

export const cartAdapter = createEntityAdapter<ICart>()

const slice = createSlice({
    name: 'cart',
    initialState: cartAdapter.getInitialState<CartState>({
        ids: [],
        entities: {},
        token: localStorage.getItem('cart_token') || '',
        status: EStatus.INITIAL,
        addItemStatus: EStatus.INITIAL,
        open: false,
        currentShop: null,
        maxBonuses: 0,
    }),
    reducers: {
        showCart(state) {
            state.open = true
            ym('reachGoal', 'shopping cart')
        },
        hideCart(state) {
            state.open = false
        },
        emptyCart(state) {
            state.status = EStatus.PENDING
        },

        loadCart(state) {
            state.status = EStatus.PENDING
        },
        getLastCart(state) {
            state.status = EStatus.PENDING
        },
        cartLoaded(state, action: PayloadAction<ICartItemResponse>) {
            mindboxSetCart(
                action.payload,
                action.payload.data.attributes.wholesale
            )
            cartAdapter.setOne(state, action.payload.data)
            state.status = EStatus.FINISHED
        },
        cartCheckoutLoaded(state, action: PayloadAction<ICartItemResponse>) {
            cartAdapter.setOne(state, action.payload.data)
            state.status = EStatus.FINISHED
        },
        getCartShop() {
            //
        },
        setCartShop(state, action: PayloadAction<IShop | null>) {
            state.currentShop = action.payload
        },
        setCartMaxBonuses(state, action: PayloadAction<number>) {
            state.maxBonuses = action.payload
        },
        cartAssociate() {
            //
        },
        createCart(state) {
            state.status = EStatus.PENDING
        },
        cartCreated(state, action: PayloadAction<ICartItemResponse>) {
            cartAdapter.setOne(state, action.payload.data)
            localStorage.setItem(
                'cart_token',
                action.payload.data.attributes.token
            )
            state.token = action.payload.data.attributes.token
            state.status = EStatus.FINISHED
        },
        tokenUpdate(state, action: PayloadAction<string>) {
            localStorage.setItem('cart_token', action.payload)
            state.token = action.payload
        },
        cartUpdated(state, action: PayloadAction<ICart>) {
            cartAdapter.setOne(state, action.payload)
        },
        addItem(state, _action: PayloadAction<ILineItemAddProps>) {
            state.addItemStatus = EStatus.PENDING
        },
        itemAdded(state) {
            state.addItemStatus = EStatus.FINISHED
        },
        addItem1C(state, _action: PayloadAction<ILineItemAdd1CProps>) {
            state.addItemStatus = EStatus.PENDING
        },
        updateItem(state, _action: PayloadAction<ILineItemUpdateProps>) {
            state.status = EStatus.PENDING
        },
        deleteItem(state, _action: PayloadAction<string>) {
            state.status = EStatus.PENDING
        },
        statusError(state) {
            state.status = EStatus.ERROR
        },
        addItemStatusError(state) {
            state.addItemStatus = EStatus.ERROR
        },
        deleteCartState(state) {
            cartAdapter.removeAll(state)
        },
        deleteCartToken(state) {
            localStorage.removeItem('cart_token')
            state.token = ''
        },
        redirectToPaymentUrl(
            _state,
            action: PayloadAction<{ payment_url: string }>
        ) {
            setTimeout(function () {
                document.location.href = action.payload.payment_url
            }, 250)
        },

        setEarnedBonuses(
            state,
            action: PayloadAction<{ earned_bonuses: number }>
        ) {
            const currCart = state.entities[0]

            if (currCart)
                currCart.attributes.loyalty_data.earned_bonuses =
                    action.payload.earned_bonuses
        },
    },
})

export const { actions: cartActions, reducer: cartReducer } = slice
