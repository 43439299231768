export const errorDictionary = (errorText: string | undefined) => {
    let result = 'Ошибка ввода промокода'

    if (
        errorText ===
        'Скидочный купон не существует. Пожалуйста, попробуйте еще раз.'
    )
        result = 'Промокод не существует'

    if (errorText === 'Скидочный купон уже был применен к этому заказу')
        result = 'Промокод уже применен'

    if (
        !!errorText &&
        errorText.includes('Скидочный купон не может быть применён')
    )
        result = 'Промокод нельзя применить'

    if (!!errorText && errorText.includes('translation missing'))
        result = 'Промокод нельзя применить'

    // Нужно случайное число, чтобы триггерить useEffect
    // Если вводить последовательно неправильный промокод,
    // то useEffect не тригерился

    return result + Math.floor(Math.random() * 899 + 100)
}
