import { IGoogleAnalyticsEvents } from 'utils/googleAnalytics'
import { ITopMailEvent } from 'utils/topMailMetrica'

import { IEcommerce } from './YandexMetrica'

export type { RootState } from './RootState'

export enum EStatus {
    INITIAL = 'INITIAL',
    PENDING = 'PENDING',
    FINISHED = 'FINISHED',
    ERROR = 'ERROR',
}

export enum EAuthStatus {
    INITIAL = 'INITIAL',
    AUTHORIZED = 'AUTHORIZED',
    NOT_AUTHORIZED = 'NOT_AUTHORIZED',
}

export enum ECountryCode {
    RU = 'ru',
    KZ = 'kz',
    UZ = 'uz',
    BY = 'by',
}

export type Unit =
    | 'square_meter'
    | 'millimeter'
    | 'kilogram'
    | 'milliliter'
    | 'liter'

export type TimeUnit = 'hour' | 'minute'

export type RoleLevel = 'junior' | 'middle' | 'senior'

export type ShopKind = 'island' | 'boutique' | 'main_boutique' | 'showroom'

export type TAuthModalStatus = 'close' | 'code' | 'phone'

export enum TOrderState {
    'cart' = 'cart',
    'address' = 'address',
    'delivery' = 'delivery',
    'payment' = 'payment',
    'confirm' = 'confirm',
    'complete' = 'complete',
}

export type TGender = 'female' | 'male'

export type Nullable<T> = T | null

declare global {
    interface Window {
        dataLayer: (IEcommerce | IGoogleAnalyticsEvents)[]
        _tmr: ITopMailEvent[]
        mindbox: (
            type: 'async' | 'sync',
            params: {
                operation: string
                data: any
                onSuccess: () => void
                onError: (error: any) => void
            }
        ) => void
    }
}
