import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import { selectAccountWholesale } from 'app/modules/Account/slice/selectors'
import { selectAddressById } from 'app/modules/Addresses/slice/selectors'
import { selectPaymentById } from 'app/modules/Payments/slice/selectors'
import moment from 'moment'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ym from 'react-yandex-metrika'
import { ICart } from 'types/ICart'

import CompletedIcon from '../assets/completed_icon.svg'
import ErrorIcon from '../assets/error_icon.svg'
import WarningIcon from '../assets/warning_icon.svg'
import { ordersActions } from '../slice'

interface OrderInformationProps {
    order?: ICart
}

export const OrderInformation: React.FC<OrderInformationProps> = ({
    order,
}) => {
    const [payButtonDisabled, setPayButtonDisabled] = useState(false)
    const dispatch = useDispatch()

    const getAddress = useSelector(selectAddressById)
    const getPayment = useSelector(selectPaymentById)
    const wholesale = useSelector(selectAccountWholesale)

    const address = getAddress(order?.relationships.billing_address.data?.id)
    const payment = getPayment(order?.relationships.payments.data[0]?.id)

    const handleClickPaymentButton = (token: string) => {
        return () => {
            setPayButtonDisabled(true)
            ym('reachGoal', 'pa payment')
            dispatch(ordersActions.generateOrderPaymentUrl(token))
        }
    }

    return (
        <Stack mt={{ xs: 2.5, md: 7.5 }} spacing={{ xs: 3, md: 6.25 }}>
            {!wholesale && (
                <>
                    <Grid container spacing={1}>
                        <Grid item xs={12} lg={4}>
                            <Typography variant="body2" fontWeight="medium">
                                Статус оплаты
                            </Typography>
                        </Grid>

                        <Grid item xs={12} lg={8}>
                            {order?.attributes.shipment_state !== 'pending' && (
                                <Box display={'flex'} alignItems={'center'}>
                                    <Box
                                        mr={0.75}
                                        alt={'Оплачен'}
                                        src={CompletedIcon}
                                        component={'img'}
                                    />
                                    <Typography
                                        variant="body2"
                                        fontWeight={500}
                                        color="primary"
                                    >
                                        Оплачен
                                    </Typography>
                                </Box>
                            )}
                            {order?.attributes.shipment_state === 'pending' && (
                                <>
                                    <Box display={'flex'} alignItems={'center'}>
                                        <Box
                                            mr={0.75}
                                            alt={'Ожидается оплата'}
                                            src={
                                                payment?.attributes
                                                    .payment_method_id === 2
                                                    ? WarningIcon
                                                    : ErrorIcon
                                            }
                                            component={'img'}
                                        />
                                        <Typography
                                            variant="body2"
                                            fontWeight={500}
                                            sx={(theme) => ({
                                                color:
                                                    payment?.attributes
                                                        .payment_method_id === 2
                                                        ? theme.palette.warning
                                                              .main
                                                        : theme.palette.error
                                                              .main,
                                            })}
                                        >
                                            {!!payment &&
                                            payment.attributes
                                                .payment_method_id === 2
                                                ? 'Ожидается оплата при получении'
                                                : 'Ожидается оплата'}
                                        </Typography>
                                    </Box>
                                    {(!payment ||
                                        payment.attributes.payment_method_id ===
                                            1) && (
                                        <Grid container mt={3}>
                                            <Grid item xs={12} md={6}>
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    disabled={payButtonDisabled}
                                                    onClick={handleClickPaymentButton(
                                                        order?.attributes.token
                                                    )}
                                                >
                                                    Оплатить
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    )}
                                </>
                            )}
                        </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                        <Grid item xs={12} lg={4}>
                            <Typography variant="body2" fontWeight="medium">
                                Способ получения
                            </Typography>
                        </Grid>

                        <Grid item xs={12} lg={8}>
                            <Typography variant="body2">
                                {order?.attributes.active_shipment_name}

                                <br />

                                {address?.attributes.address1}
                            </Typography>
                        </Grid>
                    </Grid>
                </>
            )}

            <Grid container spacing={1}>
                <Grid item xs={12} lg={4}>
                    <Typography variant="body2" fontWeight="medium">
                        Получатель
                    </Typography>
                </Grid>

                <Grid item xs={12} lg={8}>
                    <Typography variant="body2">
                        {`${address?.attributes.firstname} ${address?.attributes.lastname}`}

                        <br />

                        {wholesale && order?.attributes.legal_name && (
                            <>
                                {order.attributes.legal_name}

                                <br />
                            </>
                        )}

                        {address?.attributes.phone}

                        <br />

                        {order?.attributes.email}
                    </Typography>
                </Grid>
            </Grid>

            <Grid container spacing={1}>
                <Grid item xs={12} lg={4}>
                    <Typography variant="body2" fontWeight="medium">
                        Дата заказа
                    </Typography>
                </Grid>

                <Grid item xs={12} lg={8}>
                    <Typography variant="body2">
                        {moment(order?.attributes.completed_at).format(
                            'DD MMMM YYYY, HH:mm'
                        )}
                    </Typography>
                </Grid>
            </Grid>
        </Stack>
    )
}
