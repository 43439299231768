import { Button, Grid } from '@mui/material'
import React from 'react'

import { IProductVisualContent } from '../../slice/types'

interface ProductCardImagePickerProps {
    productVisualContent: IProductVisualContent[]
    setImageIndex: (newValue: number) => void
}

export const ProductCardImagePicker: React.FC<ProductCardImagePickerProps> = ({
    productVisualContent,
    setImageIndex,
}) => {
    if (productVisualContent.length < 2) return null

    return (
        <Grid
            container
            direction="row"
            columnSpacing={{ xs: '4px', md: '6px', xl: '8px' }}
            sx={{
                justifyContent: 'center',
            }}
        >
            {productVisualContent.map((_, index) => {
                return (
                    <Grid item key={index} display="flex">
                        <Button
                            sx={{
                                color: 'black',
                                borderRadius: '50%',
                                minWidth: { xs: '6px', md: '10px', xl: '12px' },
                                width: { xs: '6px', md: '10px', xl: '12px' },
                                minHeight: {
                                    xs: '6px',
                                    md: '10px',
                                    xl: '12px',
                                },
                                height: { xs: '6px', md: '10px', xl: '12px' },
                                border: '1px solid transparent',
                                background: 'transparent',
                                p: 0,
                            }}
                            onMouseEnter={() => setImageIndex(index)}
                        />
                    </Grid>
                )
            })}
        </Grid>
    )
}
