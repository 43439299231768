import { Container, Typography, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'

import { JournalTitle } from '../JournalTitle'
import { DesktopArticleCatList } from './DesktopArticleCatList'
import { MobileArticleCatList } from './MobileArticleCatList'

interface ArticleListProps {
    title?: string
}

export const ArticleCatList: React.FC<ArticleListProps> = ({ title }) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

    return (
        <>
            <JournalTitle />

            <Container>
                {!!title && (
                    <Typography
                        variant="h5"
                        fontWeight={500}
                        textAlign={'center'}
                        mt={{ xs: 9, lg: 8 }}
                        sx={{
                            textTransform: 'uppercase',
                        }}
                    >
                        {title}
                    </Typography>
                )}

                {isDesktop && <DesktopArticleCatList />}

                {isMobile && <MobileArticleCatList />}
            </Container>
        </>
    )
}
