import { useMediaQuery, useTheme } from '@mui/material'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { ILineItem } from 'types/ILineItem'
import { useYandexMetricaEcommerce } from 'utils/yandexMetrica'

import { cartActions } from '../../slice'
import { DesktopOrderItem } from './DesktopOrderItem'
import { MobileOrderItem } from './MobileOrderItem'

interface CartItemProps {
    item: ILineItem
    image?: string
}

export const OrderItem: React.FC<CartItemProps> = ({ item, image }) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

    const dispatch = useDispatch()
    const { yandexMetricaEcommerceRemove } = useYandexMetricaEcommerce()

    const handleDelete = useCallback(() => {
        yandexMetricaEcommerceRemove(item)

        dispatch(cartActions.deleteItem(item.id))
    }, [])

    return (
        <>
            {isMobile && (
                <MobileOrderItem
                    item={item}
                    image={image}
                    handleDelete={handleDelete}
                />
            )}

            {isDesktop && (
                <DesktopOrderItem
                    item={item}
                    image={image}
                    handleDelete={handleDelete}
                />
            )}
        </>
    )
}
