import { useMediaQuery, useTheme } from '@mui/material'
import React from 'react'

import { DesktopWelcomeCityDialog } from './DesktopWelcomeCityDialog'
import { MobileWelcomeCityDialog } from './MobileWelcomeCityDialog'

interface WelcomeCityDialogProps {
    onCityChange: () => void
}

export const WelcomeCityDialog: React.FC<WelcomeCityDialogProps> = ({
    onCityChange,
}) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'lg'))
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

    return (
        <>
            {isDesktop && (
                <DesktopWelcomeCityDialog onCityChange={onCityChange} />
            )}

            {isMobile && (
                <MobileWelcomeCityDialog onCityChange={onCityChange} />
            )}
        </>
    )
}
