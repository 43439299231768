import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { NOVELTY_PRODUCTS_COUNTS } from 'app/constants'
import { chunk } from 'lodash'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ProductSortVariants } from 'types/IProduct'

import { ProductSlider } from '../components/ProductSlider'
import { productsActions } from '../slice'
import { selectPopularProducts } from '../slice/selectors'

interface NoveltyProductPopularListSliderContainerProps {
    showTitle: boolean
    title?: string
    subTitle?: string
}

export const NoveltyProductPopularListSliderContainer: React.FC<
    NoveltyProductPopularListSliderContainerProps
> = ({ showTitle, subTitle, title }) => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'lg'))

    const noveltyProducts = useSelector(selectPopularProducts)

    const isShowSlider = noveltyProducts.length >= 3

    useEffect(() => {
        dispatch(
            productsActions.loadPopularProducts({
                page: 1,
                sort: ProductSortVariants.CREATED_AT,
                filter: {
                    available_in_current_city: true,
                    popular: true,
                },
                perPage: NOVELTY_PRODUCTS_COUNTS,
            })
        )
    }, [])

    const productsPages = React.useMemo(() => {
        const chunkSize = 2
        const chunks = chunk(noveltyProducts, chunkSize)

        if (noveltyProducts.length % chunkSize === 0) {
            return chunks
        } else {
            const lastChunk = chunks.pop() || []
            const firstChunk = chunks.find(Boolean) || []

            chunks.push(
                lastChunk.concat(
                    firstChunk.slice(0, chunkSize - lastChunk.length)
                )
            )

            return chunks
        }
    }, [noveltyProducts])

    if (!isShowSlider) return null

    return (
        <>
            {showTitle && title && (
                <Box
                    sx={{
                        mb: isMobile ? '24px' : '45px',
                        mt: isMobile ? '24px' : 0,
                    }}
                >
                    <Typography
                        gutterBottom
                        sx={{ textTransform: 'uppercase' }}
                        variant={isMobile ? 'h6' : 'h4'}
                        textAlign="center"
                        fontWeight="medium"
                    >
                        {title}
                    </Typography>
                    {subTitle && (
                        <Typography variant="subtitle1" textAlign="center">
                            {subTitle}
                        </Typography>
                    )}
                </Box>
            )}

            <ProductSlider
                productsPages={productsPages}
                isMobile={isMobile}
                redirectUrl="/catalog/popular"
            />
        </>
    )
}
