import { Box } from '@mui/material'
import React from 'react'

import { ProductLikeButton } from '../ProductFavorite'
import { CartButton, CartButtonProps } from '.'

export const AddAndLikeBlock: React.FC<CartButtonProps> = (props) => {
    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
            }}
        >
            <Box sx={{ flexGrow: 1 }}>
                <CartButton {...props} />
            </Box>
            <Box sx={{ width: { xs: '72px', md: '100px' }, ml: '10px' }}>
                <ProductLikeButton product={props.product} />
            </Box>
        </Box>
    )
}
