import {
    CssBaseline,
    ThemeProvider as OriginalThemeProvider,
} from '@mui/material'
import React from 'react'

import { customTheme } from './themes'

export const ThemeProvider: React.FC = ({ children }) => (
    <OriginalThemeProvider theme={customTheme}>
        <CssBaseline />

        {React.Children.only(children)}
    </OriginalThemeProvider>
)
