import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    IconButton,
    Stack,
    Typography,
} from '@mui/material'
import { CloseIcon } from 'app/components/Icons/CloseIcon'
import { cartActions } from 'app/modules/Cart/slice'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { citiesActions } from '../slice'
import { selectForm } from '../slice/selectors'

export const ChangeCityModal: React.FC = () => {
    const dispatch = useDispatch()

    const { open, current_city_id } = useSelector(selectForm)

    const handleClose = () => {
        dispatch(citiesActions.hideCurrentCity())
    }

    const handleSave = () => {
        dispatch(citiesActions.setActiveCityId(current_city_id))
        dispatch(cartActions.emptyCart())
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '30px',
                    right: '30px',
                }}
            >
                <IconButton onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </Box>

            <Box
                sx={{
                    py: 4.25,
                    px: 3.75,
                    width: {
                        md: '550px',
                    },
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <Typography
                    sx={{ py: 2, fontWeight: 500, textTransform: 'uppercase' }}
                    variant="h5"
                >
                    внимание
                </Typography>

                <DialogContent>
                    <DialogContentText sx={{ mt: 0.5, mb: 6.5 }}>
                        При смене города корзина будет очищена
                    </DialogContentText>
                </DialogContent>

                <DialogActions
                    sx={{
                        width: '100%',
                    }}
                >
                    <Stack
                        sx={{
                            width: '100%',
                        }}
                        spacing={3.75}
                        direction={'row'}
                    >
                        <Button
                            fullWidth
                            variant="contained"
                            size="large"
                            color="primary"
                            onClick={handleSave}
                        >
                            сменить город
                        </Button>

                        <Button
                            fullWidth
                            variant="outlined"
                            size="large"
                            onClick={handleClose}
                        >
                            Отмена
                        </Button>
                    </Stack>
                </DialogActions>
            </Box>
        </Dialog>
    )
}
