import { Box } from '@mui/material'
import { selectVariantById } from 'app/modules/Variants/slice/selectors'
import React from 'react'
import { useSelector } from 'react-redux'

import { CartItemProps } from './CartItem'

export const CartItemOutOfStock: React.FC<CartItemProps> = ({ item }) => {
    const getVariant = useSelector(selectVariantById)

    const variant = getVariant(item?.relationships.variant.data?.id)

    const inCart = item.attributes.quantity
    const inStock = variant?.attributes.total_on_hand

    if (!inStock) return <Box sx={{ color: 'red' }}>Нет в наличии</Box>

    const totalInStock = inStock - inCart

    if (totalInStock >= 0) return null

    return <Box sx={{ color: 'red' }}>{`Недостаточно на складе`}</Box>
}
