import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EStatus } from 'types'

import { IProductsSearch, ISearchState } from './types'

const initialState: ISearchState = {
    status: EStatus.INITIAL,
}

const slice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        search(state, _action: PayloadAction<IProductsSearch>) {
            state.status = EStatus.PENDING
        },
        statusError(state) {
            state.status = EStatus.ERROR
        },
        searchLoaded(state) {
            state.status = EStatus.FINISHED
        },
    },
})

export const { actions: searchActions, reducer: searchReducer } = slice
