import { Container, Grid } from '@mui/material'
import { selectCurrentShopCity } from 'app/modules/Cities/slice/selectors'
import { selectShopByCityId } from 'app/modules/Shops/slice/selectors'
import React from 'react'
import { useSelector } from 'react-redux'

import { ShopCard } from '../ShopCard'

export const MobileShopCardList: React.FC = () => {
    const currentCity = useSelector(selectCurrentShopCity)
    const getShops = useSelector(selectShopByCityId)

    return (
        <Container sx={{ mt: 6 }}>
            <Grid container spacing={7.5}>
                {getShops(currentCity?.id).map((shop) => (
                    <Grid key={shop.id} item xs={12}>
                        <ShopCard shop={shop} />
                    </Grid>
                ))}
            </Grid>
        </Container>
    )
}
