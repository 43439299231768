import { Relationship, ResourceObject } from 'utils/json-api'

import { IImage } from './IImage'

export const VideoType = 'video'

export interface IVideo extends ResourceObject {
    id: string
    type: typeof VideoType
    attributes: {
        position: number
        attachment_url: string
    }
    relationships: {
        preview: Relationship<IImage>
    }
}
