import { useMediaQuery, useTheme } from '@mui/material'
import React from 'react'

import { MobileAccountModal } from './MobileAccountModal'

export const AccountModal: React.FC = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))

    if (!isMobile) return null

    return <MobileAccountModal />
}
