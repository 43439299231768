import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Modal } from 'app/components/Modal'
import { accountActions } from 'app/modules/Account/slice'
import { selectCart } from 'app/modules/Cart/slice/selectors'
import { checkoutActions } from 'app/modules/Checkout/slice'
import {
    selectActivePaymentMethodId,
    selectLoyaltyData,
} from 'app/modules/Checkout/slice/selectors'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ShippingMethodType } from 'types/ICustomerInformation'
import { PaymentMethodId } from 'types/IPaymentsShipmentsAttributes'

import { ReactComponent as Checkbox } from '../../assets/Checkbox.svg'
import { ReactComponent as VectorRight } from '../../assets/VectorRight.svg'
import { LoyaltyDetail } from './LoyaltyDetail'
import { useOnlySales } from './useOnlySales'

export interface LoyaltyDetailState {
    succses: boolean
    onlyPromo?: true
    text?: string
}

export const LoyaltyDetailContainer: React.FC = () => {
    const dispatch = useDispatch()

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))

    const [loyaltyDetailModalIsOpen, setLoyaltyDetailModalIsOpen] =
        useState(false)
    const [loyaltyDetailState, setLoyaltyDetailState] =
        useState<LoyaltyDetailState>()

    const cart = useSelector(selectCart)
    const { data } = useSelector(selectLoyaltyData)
    const paymentMethodId = useSelector(selectActivePaymentMethodId)

    const maxAmount = data.available_bonuses
    const onlySales = useOnlySales()

    const handleModalClose = () => setLoyaltyDetailModalIsOpen(false)
    const handleModalOpen = () => {
        setLoyaltyDetailModalIsOpen(true)
    }

    useEffect(() => {
        dispatch(accountActions.getLoyalty())
    }, [])

    useEffect(() => {
        if (onlySales) {
            setLoyaltyDetailState({
                succses: false,
                onlyPromo: true,
                text: 'Применить промокод',
            })
            return
        }

        if (data.bonuses !== 0) {
            setLoyaltyDetailState({
                succses: true,
                text: `Бонусных рублей к списанию: ${data.bonuses || 0}`,
            })
            return
        }

        if (Number(cart?.attributes.mindbox_data?.coupon_discount) !== 0) {
            setLoyaltyDetailState({
                succses: true,
                text: 'Промокод успешно применен',
            })
            return
        }

        setLoyaltyDetailState({ succses: false })
    }, [cart, data, onlySales])

    useEffect(() => {
        if (
            cart?.attributes.delivery_state === ShippingMethodType.pickup &&
            paymentMethodId === PaymentMethodId.onPickup
        ) {
            if (data.bonuses !== 0) dispatch(checkoutActions.applyBonuses(0))
        }
    }, [cart?.attributes.delivery_state, paymentMethodId])

    if (!cart) {
        return null
    }

    if (
        cart.attributes.delivery_state === ShippingMethodType.pickup &&
        paymentMethodId === PaymentMethodId.onPickup
    )
        return null

    return (
        <>
            <Typography
                variant="h6"
                sx={{ fontSize: { md: '18px' } }}
                textTransform={{ xs: 'none', md: 'uppercase' }}
            >
                Получите скидку
            </Typography>

            <Box
                sx={{
                    border: '1px solid #DEDEDE',
                    borderRadius: '10px',
                    height: '92px',
                    width: '100%',
                    mt: { xs: '16px', md: '20px' },
                    px: '30px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                }}
                onClick={handleModalOpen}
            >
                {loyaltyDetailState?.onlyPromo && (
                    <Box>
                        <Typography
                            variant="body1"
                            sx={{
                                fontSize: { xs: '14px', md: '18px' },
                            }}
                            display="inline"
                            fontWeight="regular"
                            whiteSpace={{ md: 'nowrap' }}
                        >
                            {`${loyaltyDetailState?.text}`}
                        </Typography>
                    </Box>
                )}

                {loyaltyDetailState?.succses && (
                    <Box display="flex" sx={{ alignItems: 'center' }}>
                        <Box mr="16px" height="32px">
                            <Checkbox />
                        </Box>

                        <Typography
                            variant="body1"
                            style={{ color: theme.palette.primary.main }}
                            display={'inline'}
                            fontWeight="medium"
                        >
                            {`${loyaltyDetailState?.text}`}
                        </Typography>
                    </Box>
                )}
                {!(
                    loyaltyDetailState?.succses || loyaltyDetailState?.onlyPromo
                ) && (
                    <Box>
                        <Typography
                            variant="body1"
                            fontWeight="medium"
                            sx={{ fontSize: { xs: '14px', md: '18px' } }}
                        >
                            {isMobile
                                ? 'Применить бонусы  или промокод'
                                : 'Применить бонусные рубли или промокод'}
                        </Typography>
                        <Typography
                            variant="body2"
                            style={{ color: '#858585' }}
                            component="span"
                        >
                            Доступно
                            <Typography
                                variant="body2"
                                style={{ color: theme.palette.primary.main }}
                                display={'inline'}
                                fontWeight="medium"
                            >
                                {` ${maxAmount} бонусных рублей. `}
                            </Typography>
                        </Typography>
                    </Box>
                )}
                <VectorRight />
            </Box>

            <Modal
                open={loyaltyDetailModalIsOpen}
                title={'Получите скидку'}
                handleClose={handleModalClose}
            >
                <LoyaltyDetail handleClose={handleModalClose} cart={cart} />
            </Modal>
        </>
    )
}
