import { combineReducers, Reducer } from '@reduxjs/toolkit'
import { giftFormReducer } from 'app/features/GiftForm/slice'
import { accountReducer } from 'app/modules/Account/slice'
import { activeStorageBlobReducer } from 'app/modules/ActiveStorageBlob/slice'
import { addressesReducer } from 'app/modules/Addresses/slice'
import { pagesReducer } from 'app/modules/Articles/slice'
import { authReducer } from 'app/modules/Auth/slice'
import { bannersReducer } from 'app/modules/Banners/slice'
import { cartReducer } from 'app/modules/Cart/slice'
import { categoriesReducer } from 'app/modules/Categories/slice'
import { checkoutReducer } from 'app/modules/Checkout/slice'
import { citiesReducer } from 'app/modules/Cities/slice'
import { countriesReducer } from 'app/modules/Countries/slice'
import { imagesReducer } from 'app/modules/Images/slice'
import { imagesForArticlesReducer } from 'app/modules/ImagesForArticles/slice'
import { listItemsReducer } from 'app/modules/LineItems/slice'
import { multiverseReducer } from 'app/modules/Multiverse/slice'
import { offlineTradeReducer } from 'app/modules/OfflineTrade/slice'
import { ordersReducer } from 'app/modules/Order/slice'
import { paymentsReducer } from 'app/modules/Payments/slice'
import { productsReducer } from 'app/modules/Products/slice'
import { promotionsReducer } from 'app/modules/Promotion/slice'
import { searchReducer } from 'app/modules/Search/slice'
import { shopsReducer } from 'app/modules/Shops/slice'
import { variantsReducer } from 'app/modules/Variants/slice'
import { videosReducer } from 'app/modules/Videos/slice'
import { InjectedReducersType } from 'utils/types/injector-typings'

export function createReducer(
    injectedReducers: InjectedReducersType = {}
): Reducer {
    return combineReducers({
        ...injectedReducers,
        account: accountReducer,
        activeStorageBlob: activeStorageBlobReducer,
        addresses: addressesReducer,
        auth: authReducer,
        banners: bannersReducer,
        cart: cartReducer,
        categories: categoriesReducer,
        cities: citiesReducer,
        countries: countriesReducer,
        giftForm: giftFormReducer,
        images: imagesReducer,
        imagesForArticles: imagesForArticlesReducer,
        listItems: listItemsReducer,
        checkout: checkoutReducer,
        offlineTrade: offlineTradeReducer,
        orders: ordersReducer,
        pages: pagesReducer,
        payments: paymentsReducer,
        products: productsReducer,
        shops: shopsReducer,
        search: searchReducer,
        variants: variantsReducer,
        videos: videosReducer,
        multiverse: multiverseReducer,
        promotions: promotionsReducer,
    })
}
