import {
    createEntityAdapter,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit'
import { BannerType, IBanner, IBannerResponse } from 'types/IBanner'
import { ResourceObject } from 'utils/json-api'

import { IBannerState } from './types'

export const isIBanner = (item: ResourceObject): item is IBanner =>
    item.type === BannerType

export const bannersAdapter = createEntityAdapter<IBanner>()

const slice = createSlice({
    name: 'banners',
    initialState: bannersAdapter.getInitialState<IBannerState>({
        ids: [],
        entities: {},
        mainBannerIDs: [],
        taxonBannersIDs: {},
    }),
    reducers: {
        bannersAdded(state, action: PayloadAction<IBannerResponse>) {
            bannersAdapter.setMany(state, action.payload.data)
        },
        loadMainBanner() {
            //
        },
        mainBannerLoaded(state, action: PayloadAction<IBannerResponse>) {
            state.mainBannerIDs = action.payload.data.map((banner) => banner.id)
        },
        loadTaxonBanner(_state, _action: PayloadAction<{ taxonID: string }>) {
            //
        },
        taxonBannerLoaded(
            state,
            action: PayloadAction<IBannerResponse & { taxonID: string }>
        ) {
            state.taxonBannersIDs[action.payload.taxonID] =
                action.payload.data.map((banner) => banner.id)
        },
    },
})

export const { actions: bannersActions, reducer: bannersReducer } = slice
