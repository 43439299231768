import InfoIcon from '@mui/icons-material/Info'
import { LoadingButton } from '@mui/lab'
import { Box, IconButton, Tooltip } from '@mui/material'
import { GiftIcon } from 'app/components/Icons/GiftIcon'
import { GiftTooltipText } from 'app/constants'
import React, { useEffect, useState } from 'react'

import { CartButtonProps } from '.'
import { AddAndLikeBlock } from './AddAndLikeBlock'

export const MobileFloatingCartButton: React.FC<CartButtonProps> = (props) => {
    const [tooltipIsOpen, setOpenTooltip] = useState(false)

    const handleCLick = () => {
        ;(window as any).PopMechanic?.show?.(76848, true)
    }

    useEffect(() => {
        const checkIfClickedOutside = () => {
            if (tooltipIsOpen) {
                setOpenTooltip(false)
            }
        }

        document.addEventListener('mousedown', checkIfClickedOutside)

        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside)
        }
    }, [tooltipIsOpen])

    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: { xs: 55, md: 'unset' },
                width: '100%',
                zIndex: 5,
                background: 'white',
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
            }}
        >
            <Box
                sx={{
                    height: '64px',

                    pl: { xs: '16px' },
                    pr: { xs: '28px' },
                    py: { xs: '8px' },
                }}
            >
                <AddAndLikeBlock {...props} />
            </Box>

            <Box
                sx={{
                    height: '64px',
                    px: { xs: '16px' },
                }}
            >
                <Tooltip title={GiftTooltipText} open={tooltipIsOpen}>
                    <LoadingButton
                        id="gift_button"
                        fullWidth
                        startIcon={<GiftIcon color="#54A67F" />}
                        endIcon={
                            <IconButton
                                color="primary"
                                onClick={(e) => {
                                    e.stopPropagation()

                                    setOpenTooltip(true)
                                }}
                            >
                                <InfoIcon />
                            </IconButton>
                        }
                        color="primary"
                        variant="outlined"
                        onClick={handleCLick}
                        data-id={props.product.id}
                        data-id-1c={props.product.attributes.id_1c}
                        data-popmechanic-call-form="76848"
                        sx={{
                            height: '48px',
                        }}
                    >
                        Намекнуть о подарке
                    </LoadingButton>
                </Tooltip>
            </Box>
        </Box>
    )
}
