import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'types'

import { ordersAdapter } from '.'

const { selectAll } = ordersAdapter.getSelectors()

const selectDomain = (state: RootState) => state.orders

export const selectStatus = createSelector(
    [selectDomain],
    (state) => state.status
)

export const selectOrders = createSelector([selectDomain], (state) =>
    selectAll(state)
)
