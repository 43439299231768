import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'types'

import { activeStorageBlobAdapter } from '.'

const { selectById } = activeStorageBlobAdapter.getSelectors()

const selectDomain = (state: RootState) => state.activeStorageBlob

export const selectOpen = createSelector([selectDomain], (state) => state.open)

export const selectStatus = createSelector(
    [selectDomain],
    (state) => state.status
)

export const selectActiveStorageBlobImageById = createSelector(
    [selectDomain],
    (state) => (id?: string) => {
        if (!id) return ''

        const image = selectById(state, id)

        if (image) {
            return image.attributes.url
        }

        return ''
    }
)
