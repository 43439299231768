import {
    createEntityAdapter,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit'
import { IVideo, VideoType } from 'types/IVideo'
import { ResourceObject, Response } from 'utils/json-api'

import { IVideosState } from './types'

export const videosAdapter = createEntityAdapter<IVideo>()

const slice = createSlice({
    name: 'videos',
    initialState: videosAdapter.getInitialState<IVideosState>({
        ids: [],
        entities: {},
    }),
    reducers: {
        videosAdded(
            state,
            action: PayloadAction<
                Response<
                    ResourceObject | ResourceObject[],
                    (IVideo | ResourceObject)[]
                >
            >
        ) {
            const videos = action.payload.included.filter(
                (item): item is IVideo => item.type === VideoType
            )

            videosAdapter.setMany(state, videos)
        },
    },
})

export const { actions: videosActions, reducer: videosReducer } = slice
