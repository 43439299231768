export const enum googleAnalyticsEvents {
    'add_cart' = 'add_cart',
    'ordering' = 'ordering',
    'input_phone' = 'input_phone',
    'verification_sms' = 'verification_sms',
    'address_entry' = 'address_entry',
    'confirmation_receipt' = 'confirmation_receipt',
    'payment_choice' = 'payment_choice',
}

export interface IGoogleAnalyticsEvents {
    event: googleAnalyticsEvents
}

export const googleAnalyticsEvent = (event: googleAnalyticsEvents) => {
    const dataLayer = window.dataLayer || (window.dataLayer = [])
    dataLayer.push({ event })
}
