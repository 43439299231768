import {
    Box,
    Stepper,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { selectCartState } from 'app/modules/Cart/slice/selectors'
import { checkoutActions } from 'app/modules/Checkout/slice'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { convertOrderState } from 'utils/convertUtils'

import { cartActions } from '../slice'
import { CreateOrderStep } from './CreateOrderStepsComponents'
import { CustomerInformationForm } from './CustomerInformationForm'
import { CustomerPaymentMethod } from './CustomerPaymentMethod'
import { CustomerShipmentsAttributes } from './CustomerShipmentsAttributes'

export const CustomerCreateOrderSteps: React.FC = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))

    const dispatch = useDispatch()

    const cartState = useSelector(selectCartState)

    const history = useHistory()

    const activeStep = (cartState && convertOrderState(cartState) - 1) || 0

    useEffect(() => {
        return () => {
            if (
                history.action === 'POP' &&
                history.location.pathname !== '/orders/create'
            ) {
                if (activeStep > 0) {
                    dispatch(checkoutActions.loadCheckoutPrevious())
                    history.push('/orders/create')
                } else {
                    dispatch(cartActions.showCart())
                }
            }
        }
    }, [history, activeStep])

    if (!cartState) {
        return null
    }

    return (
        <Box
            sx={{
                px: { xs: '16px', md: '45px' },
                pt: { xs: '24px', md: '40px' },
            }}
        >
            <Typography
                fontWeight={500}
                variant={isMobile ? 'h5' : 'h4'}
                sx={{ textTransform: 'uppercase' }}
            >
                Оформление заказа
            </Typography>
            <Stepper
                activeStep={activeStep}
                orientation="vertical"
                sx={{ mt: { xs: '35px', md: '60px' } }}
                connector={isMobile ? null : undefined}
            >
                <CreateOrderStep
                    currentactivestep={activeStep}
                    key={convertOrderState('address')}
                    text="Способ получения и данные получателя"
                >
                    <CustomerInformationForm />
                </CreateOrderStep>

                <CreateOrderStep
                    currentactivestep={activeStep}
                    key={convertOrderState('delivery')}
                    text="Подтверждение способа получения"
                >
                    <CustomerShipmentsAttributes />
                </CreateOrderStep>
                <CreateOrderStep
                    currentactivestep={activeStep}
                    key={convertOrderState('payment')}
                    text="Способ оплаты"
                >
                    <CustomerPaymentMethod />
                </CreateOrderStep>
            </Stepper>
            <Box sx={{ pt: 8 }}>
                <div data-popmechanic-embed="80319"></div>
            </Box>
        </Box>
    )
}
