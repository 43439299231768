import { Typography } from '@mui/material'
import React from 'react'

import { ProductDetailComponentProps } from '.'

export const ProductDescription: React.FC<ProductDetailComponentProps> = ({
    product,
}) =>
    product.attributes.description ? (
        <>
            <Typography
                variant="h6"
                sx={{ fontSize: { xs: '18px' }, mb: { xs: '16px' } }}
            >
                Описание
            </Typography>
            <div
                dangerouslySetInnerHTML={{
                    __html: product.attributes.description,
                }}
            />
        </>
    ) : null
