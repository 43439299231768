export enum ShippingMethodType {
    courier = 'courier',
    pickup = 'pickup',
}
export interface ICustomerInformation {
    order: {
        email: string
        roistat?: string
        delivery_state: ShippingMethodType
        special_instructions: string
        ship_address_attributes: {
            firstname: string
            lastname: string
            address1: string
            city: string
            phone: string
            zipcode: string
            country_iso?: string
            lat?: number
            lng?: number
            comment?: string
            requested_time?: string
        }
    }
}
