export const getDay = (diff: number) => {
    const date = new Date()
    date.setDate(date.getDate() + diff)
    return {
        label:
            diff !== 1
                ? `${getWeekDay(date) || ''}, ${date.getDate()} ${
                      getMonth(date) || ''
                  }`
                : 'завтра',
        value: `${getWeekDay(date) || ''}, ${date.getDate()} ${
            getMonth(date) || ''
        }`,
    }
}

export const getWeekDay = (date: Date) => {
    const days = ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб']

    return days[date.getDay()]
}

export const getMonth = (date: Date) => {
    const days = [
        'янв',
        'фев',
        'мар',
        'апр',
        'май',
        'июн',
        'июл',
        'авг',
        'сен',
        'окт',
        'ноя',
        'дек',
    ]

    return days[date.getMonth()]
}
