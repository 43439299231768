import { Box, Container, Grid, Typography } from '@mui/material'
import { useOffsetHeader } from 'hooks/useOffsetHeader'
import React, { useCallback, useEffect } from 'react'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'

import { useHeaderContext } from '../HeaderContextProvider'
import { MobileHeaderSearch } from '../HeaderSearch'
import { MOBILE_HEADER_HEIGHT } from '.'
import { MobileHeaderItems } from './MobileHeaderItems'
import { MobileHeaderMenu } from './MobileHeaderMenu'
import { ProfileHeader } from './ProfileHeader'

export const MobileHeader: React.FC = () => {
    const { url, path } = useRouteMatch()
    const history = useHistory()

    const offsetHeader = useOffsetHeader({
        headerHeight: MOBILE_HEADER_HEIGHT,
        reservedHeight: MOBILE_HEADER_HEIGHT,
    })
    const {
        isOpened,
        touch,
        close,
        isMobileSearchOpened,
        mobileSearchChange,
        cityOpen,
    } = useHeaderContext()

    const profile =
        ['/profile', '/profile/*'].includes(path) &&
        url !== '/profile/favorites'

    const toggleDrawer = useCallback(() => {
        touch()
    }, [])

    useEffect(() => {
        history.listen(() => {
            close()
        })
    }, [history])

    return (
        <Box component="header">
            <Box sx={{ width: '100%', height: MOBILE_HEADER_HEIGHT }} />
            <Box
                sx={{
                    width: '100%',
                    position: 'fixed',
                    transition: 'top 0.25s ease-in-out',
                    top: isOpened ? 0 : -offsetHeader,
                    zIndex: 2,
                }}
            >
                <Container
                    sx={{
                        backgroundColor: '#fff',
                    }}
                >
                    <Grid container sx={{ pt: 2, pb: 1, alignItems: 'center' }}>
                        {(!profile || isOpened) && (
                            <MobileHeaderItems
                                isOpened={isOpened}
                                toggleDrawer={toggleDrawer}
                                cityOpen={cityOpen}
                                isMobileSearchOpened={isMobileSearchOpened}
                                mobileSearchChange={mobileSearchChange}
                            />
                        )}

                        {profile && !isOpened && <ProfileHeader />}
                    </Grid>
                </Container>

                <MobileHeaderSearch />

                <MobileHeaderMenu isOpened={isOpened} />
            </Box>
            <Box sx={{ pb: 1 }}>
                <Typography
                    color="grey.500"
                    variant="body3"
                    fontWeight={400}
                    width="100%"
                    textAlign="center"
                    display="block"
                    component={Link}
                    to={'/static/user_agreement'}
                >
                    Оферта и Политика обработки персональных данных
                </Typography>
            </Box>
        </Box>
    )
}
