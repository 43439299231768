import { pagesActions } from 'app/modules/Articles/slice'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { ArticleCatList } from '../components/ArticleCatList/ArticleCatList'

export const ArticleList: React.FC = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(pagesActions.loadPages(100))
    }, [])

    return (
        <>
            <ArticleCatList />
            {/* <MostArticleList title={'Интервью'} /> */}
        </>
    )
}
