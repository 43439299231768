import { Grid, Typography } from '@mui/material'
import { selectStatus } from 'app/modules/Cart/slice/selectors'
import { selectLineItemByVariantId } from 'app/modules/LineItems/slice/selectors'
import React from 'react'
import { useSelector } from 'react-redux'
import { EStatus } from 'types'
import { IVariant } from 'types/IVariant'

import { QuantityControl } from './QuantityControl'

interface PartnerVariantItemProps {
    item: IVariant
}

export const PartnerVariantItem: React.FC<PartnerVariantItemProps> = ({
    item,
}) => {
    const lineItem = useSelector(selectLineItemByVariantId)(item.id)
    const status = useSelector(selectStatus)

    return (
        <>
            <Grid item xs={3} display={'flex'} alignItems={'center'}>
                <Typography variant="body2">
                    {item.attributes.options_text}
                </Typography>
            </Grid>

            <Grid item xs={3} display={'flex'} alignItems={'center'}>
                <Typography variant="body2">
                    {`${item.attributes.total_on_hand} шт.`}
                </Typography>
            </Grid>

            <Grid item xs={3} display={'flex'} alignItems={'center'}>
                <QuantityControl
                    item={lineItem}
                    variant={item}
                    disabled={status === EStatus.PENDING}
                />
            </Grid>

            <Grid
                item
                xs={3}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'flex-end'}
            >
                <Typography variant="body2" sx={{ textAlign: 'end' }}>
                    {lineItem?.attributes.display_total || '0 ₽'}
                </Typography>
            </Grid>
        </>
    )
}
