import { PayloadAction } from '@reduxjs/toolkit'
import { activeStorageBlobActions } from 'app/modules/ActiveStorageBlob/slice'
import { addressesActions } from 'app/modules/Addresses/slice'
import { checkoutActions } from 'app/modules/Checkout/slice'
import { toast } from 'react-toastify'
import { call, put, takeLeading } from 'redux-saga/effects'
import { IActiveStorageBlob } from 'types/IActiveStorageBlob'
import { IAddress } from 'types/IAddress'
import { ILoyalty } from 'types/ILoyalty'
import { IUserItemResponse, IUserParams } from 'types/IUser'
import { request } from 'utils/request'

import { accountActions } from '.'

export function* getLoyalty() {
    try {
        const requestURL = `/api/v2/storefront/account/loyalty`

        const response: ILoyalty = yield call(request, requestURL)

        yield put(accountActions.setLoyalty(response))
    } catch (error: any) {
        yield put(accountActions.loyaltyStatusError())
    }
}

export function* updateAccount(action: PayloadAction<IUserParams>) {
    try {
        const requestURL = `/api/v2/storefront/account`

        const response: IUserItemResponse = yield call(request, requestURL, {
            method: 'PATCH',
            data: {
                user: { ...action.payload },
            },
            params: {
                include: [
                    'default_billing_address',
                    'default_shipping_address',
                    'avatar_blob',
                ].join(','),
            },
        })

        const addresses = response.included.filter(
            (value): value is IAddress => value.type === 'address'
        )

        const images = response.included.filter(
            (value): value is IActiveStorageBlob =>
                value.type === 'active_storage_blob'
        )

        yield put(activeStorageBlobActions.imagesAdded(images))
        yield put(addressesActions.addressesAdded(addresses))
        yield put(accountActions.accountLoaded(response))
        yield put(checkoutActions.setDefaultCustomerInformation(response))
    } catch (error: any) {
        yield put(accountActions.statusError())

        toast.error(
            error.data.error_description ||
                error.data.error ||
                'Что-то пошло не так',
            {
                type: 'error',
            }
        )
    }
}

export function* accountWatcher() {
    yield takeLeading(accountActions.getLoyalty.type, getLoyalty)
    yield takeLeading(accountActions.updateAccount.type, updateAccount)
}
