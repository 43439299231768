import {
    createEntityAdapter,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit'
import { ICountry } from 'types/ICountry'

import { ICountriesState } from './types'

export const countryAdapter = createEntityAdapter<ICountry>()

const slice = createSlice({
    name: 'cities',
    initialState: countryAdapter.getInitialState<ICountriesState>({
        ids: [],
        entities: {},
    }),
    reducers: {
        countriesAdded(state, action: PayloadAction<ICountry[]>) {
            countryAdapter.setMany(state, action.payload)
        },
    },
})

export const { actions: countriesActions, reducer: countriesReducer } = slice
