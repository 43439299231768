export const getCookieValue = (name: string) => {
    const matches = document.cookie.match(
        new RegExp(
            '(?:^|; )' +
                name.replace(/([.$?*|{}()[]\\\/\+^])/g, '\\$1') +
                '=([^;]*)'
        )
    )

    const secondMatch = matches?.[1]

    return secondMatch ? decodeURIComponent(secondMatch) : ''
}
