import { PayloadAction } from '@reduxjs/toolkit'
// import { imagesActions } from 'app/modules/Images/slice'
import { imagesForArticlesActions } from 'app/modules/ImagesForArticles/slice'
import { call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import { IImage } from 'types/IImage'
import { IPageCollectionResponse, IPageItemResponse } from 'types/IPage'
import { request } from 'utils/request'

import { pagesActions } from '.'

export function* loadPages(action: PayloadAction<number>) {
    try {
        const response: IPageCollectionResponse = yield call(
            request,
            `/api/v2/storefront/cms_pages?include=image,preview_image&sort=-created_at`,
            {
                params: {
                    per_page: action.payload,
                },
            }
        )

        const images = response.included.filter(
            (value): value is IImage =>
                value.type === 'image' || value.type === 'preview_image'
        )

        yield put(imagesForArticlesActions.imagesForArticlesAdded(images))
        yield put(pagesActions.pagesLoaded(response))
    } catch (error: any) {
        yield put(pagesActions.statusError())
    }
}

export function* loadPage(action: PayloadAction<string>) {
    try {
        const response: IPageItemResponse = yield call(
            request,
            `/api/v2/storefront/cms_pages/${action.payload}?include=image,preview_image`
        )

        const images = response.included.filter(
            (value): value is IImage =>
                value.type === 'image' || value.type === 'preview_image'
        )

        yield put(imagesForArticlesActions.imagesForArticlesAdded(images))
        yield put(pagesActions.pageLoaded(response))
    } catch (error: any) {
        yield put(pagesActions.statusError())
    }
}

export function* pagesWatcher() {
    yield takeLeading(pagesActions.loadPages.type, loadPages)
    yield takeLatest(pagesActions.loadPage.type, loadPage)
}
