import 'react-dadata/dist/react-dadata.css'

import { TabContext, TabPanel } from '@mui/lab'
import { Box, CardMedia, Tabs, TextField, Typography } from '@mui/material'
import { RadioTab } from 'app/components/RadioTab'
import { selectCurrentCityPickup } from 'app/modules/Cities/slice/selectors'
import React, { forwardRef, useRef } from 'react'
import { AddressSuggestions } from 'react-dadata'
import { useSelector } from 'react-redux'
import { ShippingMethodType } from 'types/ICustomerInformation'
import { inMKAD } from 'utils/inMKAD'

import { selectCartDisabledDeliveryStates } from '../../slice/selectors'
import { CustomerInformationChildProps } from './CustomerInformationForm'
import { DeliveryStateTab } from './DeliveryStateTab'

export const DeliveryStateForm: React.FC<CustomerInformationChildProps> = ({
    formik,
    shop,
    shopImage,
    daDataSuggestionAddress,
    activeTime,
    activeDay,
    times,
    days,
    setDaDataSuggestionAddress,
    handleShippingMethodIdChange,
    setIsAddressChanged,
    onActiveTimeChange,
    onActiveDayChange,
}) => {
    const disabledDeliveryStates = useSelector(selectCartDisabledDeliveryStates)
    const currentCityPickup = useSelector(selectCurrentCityPickup)
    const addressFieldRef = useRef<HTMLInputElement>()

    const preciseDelivery =
        daDataSuggestionAddress?.data.city_fias_id ===
            '0c5b2444-70a0-4932-980c-b4dc0d3f02b5' &&
        inMKAD(
            Number(daDataSuggestionAddress?.data.geo_lon),
            Number(daDataSuggestionAddress?.data.geo_lat)
        )

    return (
        <TabContext value={formik.values.order.delivery_state}>
            <Tabs
                value={formik.values.order.delivery_state}
                onChange={(event, value) => {
                    handleShippingMethodIdChange(value)
                }}
                sx={{
                    border: '1px solid #DEDEDE',
                    borderRadius: '10px',
                    height: {
                        xs: '96px',
                        md: '108px',
                    },
                    '& .MuiTabs-flexContainer': {
                        height: '100%',
                    },
                    '& .MuiTabs-indicator': {
                        display: 'none',
                    },
                }}
            >
                <DeliveryStateTab
                    value={ShippingMethodType.pickup}
                    text="Самовывоз из магазина"
                    disabled={disabledDeliveryStates.includes(
                        ShippingMethodType.pickup
                    )}
                    checked={
                        formik.values.order.delivery_state ===
                        ShippingMethodType.pickup
                    }
                />
                <DeliveryStateTab
                    value={ShippingMethodType.courier}
                    text="Курьерская доставка"
                    subtext={
                        currentCityPickup ? undefined : 'Доставим из Москвы'
                    }
                    disabled={disabledDeliveryStates.includes(
                        ShippingMethodType.courier
                    )}
                    checked={
                        formik.values.order.delivery_state ===
                        ShippingMethodType.courier
                    }
                />
            </Tabs>

            <TabPanel
                value={ShippingMethodType.pickup}
                sx={{ px: 0, py: { xs: '40px', md: '60px' } }}
            >
                <Typography
                    variant="h6"
                    sx={{ fontSize: { xs: '14px', md: '18px' } }}
                >
                    ЗАБЕРУ ИЗ МАГАЗИНА
                </Typography>
                <Box display="flex" sx={{ mt: { xs: '24px', md: '20px' } }}>
                    <CardMedia
                        component="img"
                        sx={{
                            minWidth: { xs: '36.26vw', md: '240px' },
                            maxWidth: '240px',
                            minHeight: { xs: '26.93vw', md: '180px' },
                            maxHeight: '180px',
                            borderRadius: '10px',
                            objectFit: 'cover',
                        }}
                        src={shopImage}
                    />

                    <Box
                        display="flex"
                        sx={{
                            ml: '20px',
                            flexDirection: 'column',
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{ fontSize: { xs: '14px', md: '18px' } }}
                        >
                            {shop?.attributes.name}
                        </Typography>
                        <Box mt={{ xs: '8px', md: '12px' }}>
                            <Typography
                                variant="body3"
                                component="p"
                                color="grey.600"
                                sx={{
                                    lineHeight: { xs: '16px', md: '28px' },
                                    fontSize: { xs: '12px', md: '18px' },
                                }}
                            >
                                {shop?.attributes.address}
                            </Typography>
                            <Typography
                                variant="body3"
                                component="p"
                                color="grey.600"
                                mt="4px"
                                sx={{
                                    lineHeight: { xs: '16px', md: '28px' },
                                    fontSize: { xs: '12px', md: '18px' },
                                }}
                            >
                                {shop?.attributes.working_hours}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </TabPanel>
            <TabPanel
                value={ShippingMethodType.courier}
                sx={{ px: 0, py: { xs: '40px', md: '60px' } }}
            >
                <Typography
                    variant="h6"
                    sx={{ fontSize: { xs: '14px', md: '18px' } }}
                >
                    ДОСТАВИТЬ ПО АДРЕСУ
                </Typography>

                <Box
                    sx={{ height: '60px', mb: { xs: '44px', md: '50px' } }}
                    pt="24px"
                >
                    <AddressSuggestions
                        token="cad82b45299c5853695728dbf53ea46743f977fa"
                        defaultQuery={
                            formik.values.order.ship_address_attributes.address1
                        }
                        value={daDataSuggestionAddress}
                        onChange={(e) => {
                            setDaDataSuggestionAddress(e)
                            setIsAddressChanged(false)
                        }}
                        selectOnBlur
                        customInput={forwardRef((props, ref) => (
                            <TextField
                                fullWidth
                                inputRef={ref}
                                ref={addressFieldRef}
                                variant="standard"
                                label="Город, улица, дом, квартира"
                                error={
                                    !!formik.errors.order
                                        ?.ship_address_attributes?.address1
                                }
                                {...props}
                                onChange={(e) => {
                                    props.onChange(e)
                                    setIsAddressChanged(true)
                                    // инпут теряет фокус при изменении state
                                    setTimeout(() => {
                                        const inputElem =
                                            addressFieldRef.current?.getElementsByTagName(
                                                'input'
                                            )[0]
                                        if (inputElem) inputElem.focus()
                                    })
                                }}
                            />
                        ))}
                    />
                </Box>

                <TextField
                    fullWidth
                    variant="standard"
                    label="Комментарий для курьера"
                    name="order.special_instructions"
                    value={formik.values.order.special_instructions || ''}
                    error={
                        !!formik.errors.order?.ship_address_attributes?.comment
                    }
                    onChange={formik.handleChange}
                />

                {preciseDelivery && (
                    <Box sx={{ mt: { xs: '44px', md: '25px' } }}>
                        <Typography component="span">
                            Если вам необходима{' '}
                            <Typography color="red" component="span">
                                срочная
                            </Typography>{' '}
                            доставка товара, напишите нам в Jivo чат (внизу,
                            справа на сайте) и мы поможем Вам 🌿
                        </Typography>
                    </Box>
                )}
                {false && (
                    <Box sx={{ mt: { xs: '44px', md: '50px' } }}>
                        <Typography
                            variant="h6"
                            sx={{
                                fontSize: { xs: '14px', md: '18px' },
                                mb: '24px',
                            }}
                        >
                            ДАТА И ВРЕМЯ ДОСТАВКИ
                        </Typography>

                        <Box
                            sx={{
                                display: 'flex',
                                mb: '20px',
                                flexWrap: 'nowrap',
                                maxWidth: '100vw',
                                position: 'relative',
                                '::after': {
                                    content: `""`,
                                    height: '40px',
                                    width: '34px',
                                    position: 'absolute',
                                    top: '-3px',
                                    right: { xs: '-16px', md: '-45px' },
                                    background:
                                        'linear-gradient(to right, rgba(255,255,255,0), #fff, #fff)',
                                },
                                '::before': {
                                    content: `""`,
                                    height: '40px',
                                    width: '18px',
                                    position: 'absolute',
                                    top: '-3px',
                                    left: { xs: '-16px', md: '-45px' },
                                    background:
                                        'linear-gradient(to left, rgba(255,255,255,0), #fff, #fff)',
                                },
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: 1,
                                    width: '100vw',
                                    overflow: 'auto',
                                    px: { xs: '16px', md: '45px' },
                                    ml: { xs: '-16px', md: '-45px' },
                                    mr: { xs: '-16px', md: '-45px' },
                                    '::-webkit-scrollbar': {
                                        display: 'none',
                                    },
                                    '::after': {
                                        content: `""`,
                                        flex: '0 0 30px',
                                    },
                                }}
                            >
                                {days.map((value, index) => (
                                    <RadioTab
                                        key={index}
                                        value={value}
                                        active={
                                            value.value === activeDay?.value
                                        }
                                        onClick={onActiveDayChange}
                                    />
                                ))}
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                gap: 1,
                                mb: '8px',
                            }}
                        >
                            {times.map((value, index) => (
                                <RadioTab
                                    key={index}
                                    value={value}
                                    active={value.value === activeTime?.value}
                                    onClick={onActiveTimeChange}
                                />
                            ))}
                        </Box>

                        <Typography variant="caption">
                            * Менеджер подтвердит точное время доставки после
                            обработки заказа
                        </Typography>
                    </Box>
                )}
            </TabPanel>
        </TabContext>
    )
}
