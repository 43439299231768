import {
    createEntityAdapter,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit'
import { IPromotion } from 'types/IPromotion'
import { ResourceObject, Response } from 'utils/json-api'
import { isIPromotion } from 'utils/json-api/resourceCheckers'

import { IPromotionState } from './types'

export const promotionsAdapter = createEntityAdapter<IPromotion>()

const slice = createSlice({
    name: 'promotions',
    initialState: promotionsAdapter.getInitialState<IPromotionState>({
        ids: [],
        entities: {},
    }),
    reducers: {
        promotionsAdded(state, action: PayloadAction<IPromotion[]>) {
            promotionsAdapter.setMany(state, action.payload)
        },
        promotionsLoaded(
            state,
            action: PayloadAction<
                Response<
                    ResourceObject | ResourceObject[],
                    (IPromotion | ResourceObject)[]
                >
            >
        ) {
            const promotions = action.payload.included.filter(isIPromotion)

            promotionsAdapter.setMany(state, promotions)
        },
    },
})

export const { actions: promotionsActions, reducer: promotionsReducer } = slice
