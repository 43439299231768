import { Box, Divider } from '@mui/material'
import { ProductDetail } from 'app/modules/Products/components/ProductDetail'
import { ProductDesktopVisualContent } from 'app/modules/Products/components/ProductVisualContent'
import React from 'react'

import { ProductProps } from '../Product'

export const DesktopProduct: React.FC<ProductProps> = (props) => {
    return (
        <>
            <Divider sx={{ mt: '6px', mb: '40px' }}></Divider>
            <Box sx={{ display: 'flex' }}>
                <Box
                // sx={{
                //     height: '47.34375vw',
                // }}
                >
                    <ProductDesktopVisualContent {...props} />

                    <div style={{ paddingTop: '32px', width: '66vw' }}>
                        <div
                            data-popmechanic-embed="78560"
                            data-popmechanic-argument={
                                props.product.attributes.id_1c
                            }
                        ></div>
                        <div data-popmechanic-embed="80306"></div>
                    </div>
                </Box>

                <Box
                    sx={{
                        flexGrow: 1,
                        pb: '24px',
                    }}
                >
                    <ProductDetail {...props} />
                </Box>
            </Box>
        </>
    )
}
