import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'types'

import { countryAdapter } from '.'

const { selectAll } = countryAdapter.getSelectors()

const selectDomain = (state: RootState) => state.countries

export const selectCountries = createSelector([selectDomain], (state) => {
    return selectAll(state)
})
