import { Box, CardMedia } from '@mui/material'
import React from 'react'

interface DesktopContentWrapperProps {
    backgroundImage: string
}

export const DesktopContentWrapper: React.FC<DesktopContentWrapperProps> = ({
    backgroundImage,
    children,
}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                height: 'inherit',
                position: 'relative',
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    bgcolor: 'grey.500',
                    overflow: 'hidden',
                    width: 'inherit',
                    height: 'inherit',
                    zIndex: 1,
                }}
            >
                <CardMedia
                    sx={{
                        objectFit: 'cover',
                        height: 'inherit',
                    }}
                    src={backgroundImage}
                    component="img"
                />
            </Box>
            <Box
                sx={{
                    px: 6.25,
                    pb: '10%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    flexDirection: 'column',
                    height: '100%',
                    zIndex: 2,
                    color: (theme) => theme.palette.common.white,
                }}
            >
                {children}
            </Box>
        </Box>
    )
}
