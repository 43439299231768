import { Box, Divider, Grid, Paper, Typography } from '@mui/material'
import { LoyaltyTable } from 'app/components/LoyaltyTable/LoyaltyTable'
import icon from 'app/modules/Account/assets/blurred-green-palm-leaves-off-white 1.png'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { accountActions } from '../slice'
import { selectAccountLoyalty } from '../slice/selectors'

export const AccountGift: React.FC = () => {
    const dispatch = useDispatch()

    const loyalty = useSelector(selectAccountLoyalty)

    useEffect(() => {
        dispatch(accountActions.getLoyalty())
    }, [])

    return (
        <Paper
            sx={{
                width: '100%',
                pb: 7.5,
                pt: 5.625,
            }}
            elevation={0}
        >
            <Box
                sx={{
                    px: 6.25,
                }}
            >
                <Grid container>
                    <Grid item xs={12} md={4}>
                        <Box
                            sx={{
                                borderRadius: '16px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                p: 3.75,
                            }}
                        >
                            <Box
                                component={'img'}
                                src={icon}
                                sx={{
                                    position: 'absolute',
                                    maxWidth: '428px',
                                    width: { xs: '75vw', md: '22.3vw' },
                                    top: { xs: '80px', md: 'unset' },
                                }}
                            />
                            <Typography
                                variant="body1"
                                fontWeight={'normal'}
                                sx={{
                                    textTransform: 'uppercase',
                                    zIndex: 1,
                                    color: 'white',
                                }}
                            >
                                Бонусный счет
                            </Typography>

                            <Typography
                                mb={6.75}
                                variant="h2"
                                fontWeight={500}
                                sx={{
                                    textTransform: 'uppercase',
                                    zIndex: 1,
                                    color: 'white',
                                    mt: {
                                        xs: '16px',
                                        md: 5,
                                    },
                                }}
                            >
                                {loyalty.bonus.toLocaleString()}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={1} />
                    <Grid item xs={12} md={6}>
                        <Typography
                            mt={3.75}
                            fontWeight={500}
                            variant="body1"
                            sx={{
                                textTransform: 'uppercase',
                            }}
                        >
                            БОНУСНЫЕ РУБЛИ – внутренняя
                        </Typography>

                        <Typography
                            fontWeight={500}
                            variant="body1"
                            sx={{
                                textTransform: 'uppercase',
                            }}
                        >
                            валюта Вселенной Island Soul.
                        </Typography>

                        <Typography
                            mt={3.75}
                            variant="body2"
                            maxWidth="454px"
                            fontWeight={500}
                        >
                            Рады наблюдать ваш рост в системе лояльности бренда.
                            Выбирайте любимые украшения, копите ещё больше
                            баллов и переходите на новый уровень. Наслаждайтесь
                            своим путем!
                            <br />
                            <br />1 бонусный рубль = 1 ₽
                        </Typography>
                    </Grid>
                </Grid>
            </Box>

            <Divider sx={{ my: 7.5 }} />

            <Box>
                <LoyaltyTable />
            </Box>
        </Paper>
    )
}
