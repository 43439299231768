import { Box, Grid, Typography } from '@mui/material'
import { selectImageById } from 'app/modules/Images/slice/selectors'
import placeholderImage from 'assets/images/image_placeholder.jpg'
import React from 'react'
import { useSelector } from 'react-redux'
import { IShop } from 'types/IShop'
import { convertShopKind } from 'utils/convertUtils'

// import InstagramIcon from './assets/Instagram.svg'
import PhoneIcon from './assets/Phone.svg'
import WhatsAppIcon from './assets/WhatsApp.svg'

interface DesktopShopCardProps {
    shop: IShop
}

export const DesktopShopCard: React.FC<DesktopShopCardProps> = ({ shop }) => {
    const getImage = useSelector(selectImageById)

    const image = getImage(
        shop.relationships.image.data?.id,
        shop.attributes.kind === 'main_boutique' ? undefined : '1024'
    )

    return (
        <Box
            sx={{
                position: 'relative',
                pb: shop.attributes.kind === 'main_boutique' ? '53.5%' : '90%',
                overflow: 'hidden',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    height: 'auto',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                }}
                src={image || placeholderImage}
                alt={'DOM ISLAND SOUL'}
                component="img"
            />

            <Box
                sx={{
                    p: 5.6,
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    background:
                        'linear-gradient(0.55deg, rgba(0, 0, 0, 0.46) 0.47%, rgba(0, 0, 0, 0) 99.53%)',
                }}
            >
                <Grid container>
                    <Grid item xs={2} />
                    <Grid item xs={8}>
                        <Typography
                            textAlign={'center'}
                            fontWeight={500}
                            variant="body2"
                            sx={{
                                textTransform: 'uppercase',
                                color: (theme) => theme.palette.common.white,
                            }}
                        >
                            {convertShopKind(shop.attributes.kind)}
                        </Typography>

                        <Typography
                            mt={1.25}
                            textAlign={'center'}
                            fontWeight={500}
                            variant="h4"
                            sx={{
                                textTransform: 'uppercase',
                                color: (theme) => theme.palette.common.white,
                            }}
                        >
                            {shop.attributes.name}
                        </Typography>

                        {!!shop.attributes.description && (
                            <Typography
                                mt={2.5}
                                textAlign={'center'}
                                variant="body2"
                                sx={{
                                    color: (theme) =>
                                        theme.palette.common.white,
                                }}
                            >
                                {shop.attributes.description}
                            </Typography>
                        )}

                        <Typography
                            mt={5.5}
                            textAlign={'center'}
                            variant="body2"
                            sx={{
                                color: (theme) => theme.palette.common.white,
                            }}
                        >
                            {shop.attributes.address}
                        </Typography>

                        <Typography
                            textAlign={'center'}
                            variant="body2"
                            sx={{
                                color: (theme) => theme.palette.common.white,
                            }}
                        >
                            {shop.attributes.working_hours}
                        </Typography>

                        <Typography
                            mt={3}
                            textAlign={'center'}
                            variant="body2"
                            sx={{
                                color: (theme) => theme.palette.common.white,
                            }}
                        >
                            {!!shop.attributes.phone && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Box sx={{ mr: '50px', display: 'flex' }}>
                                        <Box
                                            sx={{
                                                width: '24px',
                                                height: '24px',
                                                mr: 1.25,
                                                mb: -0.75,
                                            }}
                                            src={PhoneIcon}
                                            alt={shop.attributes.phone}
                                            component="img"
                                        />

                                        <Box
                                            sx={{
                                                borderBottom:
                                                    '1px dashed #FFFFFF',
                                            }}
                                        >
                                            <Box
                                                rel="noreferrer"
                                                href={`tel:${shop.attributes.phone}`}
                                                component="a"
                                            >
                                                Позвонить
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex' }}>
                                        <Box
                                            sx={{
                                                width: '24px',
                                                height: '24px',
                                                mr: 1.25,
                                                mb: -0.75,
                                            }}
                                            src={WhatsAppIcon}
                                            alt={shop.attributes.phone}
                                            component="img"
                                        />
                                        <Box
                                            sx={{
                                                borderBottom:
                                                    '1px dashed #FFFFFF',
                                            }}
                                        >
                                            <Box
                                                rel="noreferrer"
                                                href={`https://wa.me/${shop.attributes.phone.replace(
                                                    /\D/g,
                                                    ''
                                                )}`}
                                                component="a"
                                                sx={{ borderBottom: '' }}
                                            >
                                                Написать
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            )}

                            {/* {!!shop.attributes.instagram && (
                                <>
                                    <Box
                                        sx={{
                                            width: '24px',
                                            height: '24px',
                                            mr: 1.25,
                                            mb: -0.75,
                                            ml: 3.5,
                                        }}
                                        src={InstagramIcon}
                                        alt={shop.attributes.instagram}
                                        component="img"
                                    />

                                    <a
                                        rel="noreferrer"
                                        href={`https://www.instagram.com/${shop.attributes.instagram}`}
                                        target="_blank"
                                    >
                                        {shop.attributes.instagram}
                                    </a>
                                </>
                            )} */}
                        </Typography>
                    </Grid>
                    <Grid item xs={2} />
                </Grid>
            </Box>
        </Box>
    )
}
