import { createSelector } from '@reduxjs/toolkit'
import { EAuthStatus, RootState } from 'types'

const selectDomain = (state: RootState) => state.auth

export const selectModalStatus = createSelector(
    [selectDomain],
    (state) => state.forms.modal_status
)

export const selectSigninForm = createSelector(
    [selectDomain],
    (state) => state.forms.signin
)

export const selectCreateAccountForm = createSelector(
    [selectDomain],
    (state) => state.forms.create_account
)

export const selectAuthTokenData = createSelector([selectDomain], (state) => {
    const exsist = Boolean(state.token.access_token)

    const millisecondsToExpire =
        (state.token.expires_in -
            Math.floor(Date.now() / 1000) +
            state.token.created_at) *
        1000

    return { exsist, millisecondsToExpire, ...state.token }
})

export const selectAuthStatus = createSelector([selectDomain], (state) => ({
    isAuthorized: state.auth_status === EAuthStatus.AUTHORIZED,
    notAuthorized: state.auth_status === EAuthStatus.NOT_AUTHORIZED,
    authInitial: state.auth_status === EAuthStatus.INITIAL,
}))

export const selectStatus = createSelector(
    [selectDomain],
    (state) => state.status
)
