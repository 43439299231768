import { Box, Typography } from '@mui/material'
import { selectImageById } from 'app/modules/Images/slice/selectors'
import placeholderImage from 'assets/images/image_placeholder.jpg'
import React from 'react'
import { useSelector } from 'react-redux'
import { IShop } from 'types/IShop'
import { convertShopKind } from 'utils/convertUtils'

// import InstagramIcon from './assets/Instagram.svg'
import PhoneIcon from './assets/Phone.svg'
import WhatsAppIcon from './assets/WhatsApp.svg'

interface MobileShopCardProps {
    shop: IShop
}

export const MobileShopCard: React.FC<MobileShopCardProps> = ({ shop }) => {
    const getImage = useSelector(selectImageById)

    const image = getImage(
        shop.relationships.image.data?.id,
        shop.attributes.kind === 'main_boutique' ? undefined : '1024'
    )

    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    height: 'auto',
                }}
                src={image || placeholderImage}
                alt={shop.attributes.name}
                component="img"
            />

            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    mt: 3,
                }}
            >
                <Typography
                    textAlign={'center'}
                    variant="body3"
                    sx={{ textTransform: 'uppercase' }}
                >
                    {convertShopKind(shop.attributes.kind)}
                </Typography>

                <Typography
                    mt={1}
                    textAlign={'center'}
                    fontWeight={500}
                    variant="h6"
                    sx={{ textTransform: 'uppercase' }}
                >
                    {shop.attributes.name}
                </Typography>

                {!!shop.attributes.description && (
                    <Typography mt={1.5} textAlign={'center'} variant="body2">
                        {shop.attributes.description}
                    </Typography>
                )}

                <Typography mt={1.5} textAlign={'center'} variant="body2">
                    {shop.attributes.address}
                </Typography>

                <Typography textAlign={'center'} variant="body2">
                    {shop.attributes.working_hours}
                </Typography>

                <Box mt={2.5}>
                    {!!shop.attributes.phone && (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Box sx={{ mr: '40px', display: 'flex' }}>
                                <Box
                                    sx={{
                                        width: '24px',
                                        height: '24px',
                                        mr: 1.25,
                                        mb: -0.75,
                                    }}
                                    src={PhoneIcon}
                                    alt={shop.attributes.phone}
                                    component="img"
                                />

                                <Box
                                    sx={{
                                        borderBottom: '1px dashed #000000',
                                    }}
                                >
                                    <Box
                                        rel="noreferrer"
                                        href={`tel:${shop.attributes.phone}`}
                                        component="a"
                                    >
                                        Позвонить
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex' }}>
                                <Box
                                    sx={{
                                        width: '24px',
                                        height: '24px',
                                        mr: 1.25,
                                        mb: -0.75,
                                    }}
                                    src={WhatsAppIcon}
                                    alt={shop.attributes.phone}
                                    component="img"
                                />
                                <Box
                                    sx={{
                                        borderBottom: '1px dashed #000000',
                                    }}
                                >
                                    <Box
                                        rel="noreferrer"
                                        href={`https://wa.me/${shop.attributes.phone.replace(
                                            /\D/g,
                                            ''
                                        )}`}
                                        component="a"
                                        sx={{ borderBottom: '' }}
                                    >
                                        Написать
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    )}

                    {/* {!!shop.attributes.instagram && (
                        <Typography
                            mt={1.5}
                            textAlign={'center'}
                            variant="body2"
                        >
                            <Box
                                sx={{
                                    width: '24px',
                                    height: '24px',
                                    mr: 1.25,
                                    mb: -0.75,
                                }}
                                src={InstagramIcon}
                                alt={shop.attributes.instagram}
                                component="img"
                            />
                            <a
                                rel="noreferrer"
                                href={`https://www.instagram.com/${shop.attributes.instagram}`}
                                target="_blank"
                            >
                                {shop.attributes.instagram}
                            </a>
                        </Typography>
                    )} */}
                </Box>
            </Box>
        </Box>
    )
}
