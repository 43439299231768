import {
    Box,
    Container,
    Divider,
    Grid,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { TopBanner } from 'app/components/TopBanner'
import { pagesActions } from 'app/modules/Articles/slice'
import { selectPage } from 'app/modules/Articles/slice/selectors'
import { MainArticleCardsList } from 'app/modules/Articles/templates/MainArticleCardsList'
// import { selectImageById } from 'app/modules/Images/slice/selectors'
import { selectImageForArticlesById } from 'app/modules/ImagesForArticles/slice/selectors'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            '& h2': {
                fontWeight: '500',
                fontSize: '24px',
                lineHeight: '32px',
                color: '#000000',
                marginBottom: '20px',
                marginTop: '30px',
            },
            '& h3': {
                fontWeight: '500px',
                fontSize: '24px',
                lineHeight: '36px',
                color: '#000000',
                marginBottom: '20px',
                marginTop: '0px',
            },
            '& p': {
                marginBottom: '20px',
                fontSize: '18px',
                lineHeight: '32px',
                color: '#000000',
            },
            '& .flex': {
                display: 'flex',
                marginBottom: '60px',

                '& .photo': {
                    marginLeft: '30px',

                    '&:first-child': {
                        marginLeft: 0,
                    },
                    '& img': {
                        width: '100%',
                    },
                    '& .description': {
                        fontSize: '12px',
                        lineHeight: '16px',
                        color: '#757575',
                        marginTop: '16px',
                    },
                },
            },
            '& ul': {
                marginBottom: '20px',
            },
            '& ol': {
                marginBottom: '20px',
            },
            '& li': {
                fontSize: '18px',
                lineHeight: '32px',
                marginBottom: '10px',
            },
        },
        rootMobile: {
            '& h2': {
                fontWeight: '500',
            },
            '& h3': {
                fontWeight: '500',
                fontSize: '14px',
                lineHeight: '24px',
                color: '#000000',
                marginBottom: '12px',
                marginTop: '0px',
            },
            '& p': {
                marginBottom: '32px',
                fontSize: '14px',
                lineHeight: '24px',
                color: '#000000',
            },
            '& .flex': {
                display: 'flex',
                marginBottom: '32px',

                '& .photo': {
                    '&:first-child': {
                        marginLeft: 0,
                    },
                    '& img': {
                        width: '100%',
                    },
                    '& .description': {
                        fontSize: '12px',
                        lineHeight: '16px',
                        color: '#757575',
                        marginTop: '16px',
                    },
                },
            },
        },
    })
)

export const ArticleView: React.FC = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const { pageSlug } = useParams<{ pageSlug: string }>()

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'lg'))

    const getPage = useSelector(selectPage)
    const page = getPage(pageSlug)
    const getImage = useSelector(selectImageForArticlesById)
    const image = getImage(page?.relationships.image.data?.id)

    useEffect(() => {
        dispatch(pagesActions.loadPage(pageSlug))
    }, [])

    return (
        <>
            <Container>
                <TopBanner
                    image={image}
                    date={page?.attributes.created_at}
                    category={page?.attributes.tag}
                    title={page?.attributes.title || ''}
                    showSocialBlock={false}
                />
            </Container>

            {isMobile && <Divider sx={{ mt: 2 }} />}

            <Container
                sx={{
                    maxWidth: {
                        xs: '100%',
                        sm: '100%',
                        md: '100%',
                        lg: '65%',
                        xl: '65%',
                    },
                }}
            >
                <Grid container mt={{ xs: 5, lg: 7.5 }}>
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                px: {
                                    lg: 6.875,
                                },
                            }}
                            className={
                                isMobile ? classes.rootMobile : classes.root
                            }
                        >
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: page?.attributes.content || '',
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Container>

            <Box sx={{ mt: { lg: 20, xs: 9 } }}>
                <MainArticleCardsList />
            </Box>
        </>
    )
}
