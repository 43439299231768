import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { ECountryCode } from 'types'

import { OfflineTradeAddCustomer } from '../components/OfflineTradeAddCustomer'
import { OfflineTradeAddOrder } from '../components/OfflineTradeAddOrder'
import { OfflineTradeCustomerAdded } from '../components/OfflineTradeCustomerAdded'
import { OfflineTradeDone } from '../components/OfflineTradeDone'
import { OfflineTradeGetLoyalty } from '../components/OfflineTradeGetLoyalty'
import { OfflineTradeLayout } from '../components/OfflineTradeLayout'
import { offlineTradeActions } from '../slice'
import { selectPage } from '../slice/selectors'
import { EOfflineTradePage } from '../slice/types'

export const OfflineTrade: React.FC = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    const { country } = useParams<{ country: ECountryCode }>()

    useEffect(() => {
        if (
            country !== ECountryCode.RU &&
            country !== ECountryCode.KZ &&
            country !== ECountryCode.UZ &&
            country !== ECountryCode.BY
        )
            history.push(`/offline_trade/${ECountryCode.RU}`)
        else {
            dispatch(offlineTradeActions.setCountryCode(country))
        }
    }, [country])

    const page = useSelector(selectPage)
    return (
        <OfflineTradeLayout>
            {page === EOfflineTradePage.START && <OfflineTradeGetLoyalty />}

            {page === EOfflineTradePage.CREATE_CUSTOMER && (
                <OfflineTradeAddCustomer />
            )}

            {page === EOfflineTradePage.CREATE_ORDER && (
                <OfflineTradeAddOrder />
            )}

            {page === EOfflineTradePage.DONE && <OfflineTradeDone />}

            {page === EOfflineTradePage.CUSTOMER_ADDED && (
                <OfflineTradeCustomerAdded />
            )}
        </OfflineTradeLayout>
    )
}
