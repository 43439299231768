import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'types'

import { accountAdapter } from '.'

const { selectAll } = accountAdapter.getSelectors()

const selectDomain = (state: RootState) => state.account

export const selectDisabled = createSelector(
    [selectDomain],
    (state) => state.disabled
)

export const selectAccountLoadStatus = createSelector(
    [selectDomain],
    (state) => state.status
)

export const selectAccount = createSelector([selectDomain], (state) =>
    selectAll(state).find(Boolean)
)

export const selectAccountLoyalty = createSelector(
    [selectDomain],
    (state) => state.loyalty.data
)

export const selectAccountBonuses = createSelector([selectDomain], (state) =>
    Number(state.loyalty.data.bonus)
)

export const selectAccountForm = createSelector(
    [selectDomain],
    (state) => state.form
)

export const selectAccountWholesale = createSelector(
    [selectDomain],
    (state) => selectAll(state).find(Boolean)?.attributes.wholesale || false
)
