import React from 'react'
import { Link } from 'react-router-dom'

import { ProductCardProps } from '.'

export const ProductLink: React.FC<ProductCardProps> = ({
    product,
    children,
}) => {
    if (!product) return <>{children}</>

    return <Link to={`/products/${product.attributes.slug}`}>{children}</Link>
}
