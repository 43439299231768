import {
    Container,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
} from '@mui/material'
import { citiesActions } from 'app/modules/Cities/slice'
import {
    selectCityById,
    selectCurrentShopCity,
} from 'app/modules/Cities/slice/selectors'
import { selectShops } from 'app/modules/Shops/slice/selectors'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ICity } from 'types/ICity'

export const ShopCitySelect: React.FC = () => {
    const dispatch = useDispatch()

    const getCityById = useSelector(selectCityById)

    const shops = useSelector(selectShops)
    const shopsCities = shops
        .map((shop) => shop.relationships.city.data?.id)
        .filter((value, index, self) => {
            return self.indexOf(value) === index
        })
        .filter(Boolean) as string[]

    const cities = shopsCities
        .map((shopCity) => getCityById(shopCity))
        .filter(Boolean) as ICity[]

    const currentCity = useSelector(selectCurrentShopCity)

    const handleCurrentCity = (city: ICity) => {
        dispatch(citiesActions.setActiveShopCityId(city.id))
    }

    const handleChange = (event: SelectChangeEvent) => {
        dispatch(citiesActions.setActiveShopCityId(event.target.value))
    }

    return (
        <Container sx={{ mt: 6.25 }}>
            <FormControl
                variant="standard"
                sx={{ width: 300, maxWidth: '100%' }}
            >
                <InputLabel>Ваш город</InputLabel>

                <Select
                    value={currentCity?.id}
                    onChange={handleChange}
                    label="Ваш город"
                >
                    {cities.map((city) => (
                        <MenuItem
                            key={city.id}
                            value={city.id}
                            onClick={() => handleCurrentCity(city)}
                        >
                            {city.attributes.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Container>
    )
}
