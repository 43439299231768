import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { ResponsiveStyleValue } from '@mui/system'
import React from 'react'

import { ReactComponent as DolyameBagIcon } from './assets/DolyameBagIcon.svg'
import { ReactComponent as DolyameFileIcon } from './assets/DolyameFileIcon.svg'
import { ReactComponent as DolyameZeroProcent } from './assets/DolyameZeroProcent.svg'

const DolyameAllowMoreItem: React.FC<{
    icon: React.ReactNode
    upperText: string
    loverText: string
    maxWidth?: ResponsiveStyleValue<string | number>
}> = ({ icon, upperText, loverText, maxWidth }) => (
    <Box
        width={{ xs: '100%' }}
        height={{ xs: '124px', md: '156px' }}
        pl={{ xs: '24px', md: '50px' }}
        display="flex"
        flexDirection="row"
        alignItems="center"
        border="1px solid #DEDEDE"
        borderRadius="28px"
    >
        {icon}
        <Typography
            component="span"
            ml="20px"
            sx={{
                fontSize: { xs: '14px', md: '18px' },
                fontWeight: 'medium',
            }}
            maxWidth={maxWidth}
        >
            <Typography
                sx={(theme) => ({
                    fontSize: 'inherit',
                    fontWeight: 'inherit',
                    display: maxWidth ? 'inline' : 'block',
                    color: theme.palette.primary.main,
                })}
            >
                {upperText}
            </Typography>
            <Typography
                sx={{
                    fontSize: 'inherit',
                    fontWeight: 'inherit',
                    display: 'inline',
                }}
            >
                {loverText}
            </Typography>
        </Typography>
    </Box>
)

export const DolyameAllowMoreItems: React.FC = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))

    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            <Typography
                variant="h1"
                fontWeight="medium"
                sx={{ fontSize: { xs: '24px', md: '42px' } }}
            >
                Позвольте себе больше
            </Typography>

            <Grid
                container
                direction={{ xs: 'column', md: 'row' }}
                spacing={{ xs: '12px', md: '30px' }}
                mt={{ xs: '32px', md: '50px' }}
            >
                <Grid item xs={12} md={4}>
                    <DolyameAllowMoreItem
                        icon={<DolyameBagIcon />}
                        upperText="Покупайте сейчас"
                        loverText=" — платите потом"
                        maxWidth={isMobile ? '150px' : '200px'}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <DolyameAllowMoreItem
                        icon={<DolyameFileIcon />}
                        upperText="Никаких документов"
                        loverText=" и кредитных договоров"
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <DolyameAllowMoreItem
                        icon={
                            <Box mr={{ xs: '-5px', md: '0px' }}>
                                <DolyameZeroProcent />
                            </Box>
                        }
                        upperText="Без процентов"
                        loverText=" и комиссий"
                    />
                </Grid>
            </Grid>
        </Box>
    )
}
