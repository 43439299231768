import {
    Checkbox,
    FormControlLabel,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import React from 'react'

import { ReactComponent as DesktopCheckedCheckboxIcon } from '../../assets/DesktopCheckedCheckboxIcon.svg'
import { ReactComponent as DesktopUncheckedCheckboxIcon } from '../../assets/DesktopUncheckedCheckboxIcon.svg'
import { ReactComponent as MobileCheckedCheckboxIcon } from '../../assets/MobileCheckedCheckboxIcon.svg'
import { ReactComponent as MobileUncheckedCheckboxIcon } from '../../assets/MobileUncheckedCheckboxIcon.svg'

export interface ProductsFilterFormControlLabelProps {
    labelText: string | React.ReactNode
    checked: boolean
    onChange: (
        event: React.ChangeEvent<HTMLInputElement>,
        checked: boolean
    ) => void
}

export const ProductsFilterFormControlLabel: React.FC<
    ProductsFilterFormControlLabelProps
> = ({ labelText, checked, onChange }) => {
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={checked}
                    onChange={onChange}
                    checkedIcon={
                        isDesktop ? (
                            <DesktopCheckedCheckboxIcon />
                        ) : (
                            <MobileCheckedCheckboxIcon />
                        )
                    }
                    icon={
                        isDesktop ? (
                            <DesktopUncheckedCheckboxIcon />
                        ) : (
                            <MobileUncheckedCheckboxIcon />
                        )
                    }
                    sx={{ p: '0px', mr: '12px' }}
                />
            }
            label={
                typeof labelText === 'string' ? (
                    <Typography
                        variant="body2"
                        sx={{
                            fontSize: { xs: '14px', lg: '14px' },
                            lineHeight: { lg: '23px' },
                            display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 1,
                        }}
                    >
                        {labelText}
                    </Typography>
                ) : (
                    labelText
                )
            }
            sx={{ mx: '0px' }}
        />
    )
}
