import { useMediaQuery, useTheme } from '@mui/material'
import React from 'react'

import { DesktopHeader } from './DesktopHeader'
import { MobileHeader } from './MobileHeader'

export const Header: React.FC = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))
    const isTablet = useMediaQuery(theme.breakpoints.between('md', 'lg'))
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

    return (
        <>
            {(isMobile || isTablet) && <MobileHeader />}

            {isDesktop && <DesktopHeader />}
        </>
    )
}
