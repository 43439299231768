import { Button, ButtonProps } from '@mui/material'
import React from 'react'

export const QuantityControlButton: React.FC<ButtonProps> = ({
    children,
    disabled,
    onClick,
}) => {
    return (
        <Button
            sx={{
                p: 0,
                width: 32,
                height: 32,
                minWidth: 32,
                fontWeight: 100,
            }}
            variant="contained"
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </Button>
    )
}
