import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material'
import SizeSelectionGuideImage from 'app/modules/Products/assets/SizeSelectionGuideImage.png'
import SizeSelectionGuideImageStatic from 'app/modules/Products/assets/SizeSelectionGuideImageStatic.png'
import React from 'react'

export const SizeSelectionGuide: React.FC<{
    handleCloseTooltip?: () => void
    staticPage?: boolean
}> = ({ handleCloseTooltip, staticPage }) => {
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

    return (
        <Box
            sx={{
                pt: '24px',
                pb: '12px',
                px: { xs: '16px', md: staticPage ? '0px' : '16px' },
                width: {
                    md: staticPage ? '100%' : '550px',
                },
                display: 'flex',
                alignItems: 'start',
                flexDirection: 'column',
            }}
        >
            <Typography
                mt={1.5}
                variant="body2"
                fontWeight="regular"
                width="100%"
                textAlign={'left'}
                sx={{
                    fontSize: {
                        xs: '14px',
                        md: staticPage ? '20px' : '14px',
                    },
                    width: {
                        xs: '100%',
                        md: staticPage ? '670px' : '100%',
                    },
                    lineHeight: 'initial',
                }}
            >
                {`Возникают сомнения в выборе размера кольца? Предлагаем вам два надежных и простых способа для его определения.`}
            </Typography>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: {
                        xs: 'column',
                        md: staticPage ? 'row' : 'column',
                    },
                    gap: { md: staticPage ? '80px' : '0px' },
                    mt: { xs: '40px', md: staticPage ? '65px' : '40px' },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: {
                            xs: 'column',
                            md: 'row',
                        },
                        justifyContent: {
                            md: 'space-between',
                        },

                        width: '100%',
                    }}
                >
                    <Box
                        sx={{
                            width: {
                                md: staticPage ? 'auto' : '255px',
                            },
                        }}
                    >
                        <Typography
                            variant="h2"
                            sx={{
                                fontSize: {
                                    xs: '16px',
                                    md: staticPage ? '23px' : '16px',
                                },
                            }}
                            fontWeight={500}
                            textTransform="uppercase"
                        >
                            1 способ:
                        </Typography>

                        <Typography
                            sx={{
                                fontSize: {
                                    xs: '14px',
                                    md: staticPage ? '20px' : '14px',
                                },
                            }}
                            mt="16px"
                        >
                            Вам понадобится только линейка и ваше кольцо с
                            пальчика, размер которого желаете узнать.
                            <br /> <br />
                            Измеряем диаметр внутренней части кольца —
                            расстояние от одной внутренней стенки окружности до
                            противоположной.
                        </Typography>
                    </Box>
                    {!(staticPage && isDesktop) && (
                        <Box
                            component={'img'}
                            src={SizeSelectionGuideImage}
                            sx={{
                                mt: {
                                    xs: '35px',
                                },
                                width: {
                                    xs: 'calc(100vw - 32px)',
                                    md: '216px',
                                },
                                maxWidth: '400px',
                            }}
                        />
                    )}
                </Box>

                {!(staticPage && isDesktop) && (
                    <Typography color={theme.palette.primary.main} mt="40px">
                        Величина диаметра кольца в миллиметрах = размеру
                        вашего&nbsp;пальчика.
                    </Typography>
                )}

                <Box
                    sx={{
                        mt: {
                            xs: '40px',
                            md: staticPage ? '0px' : '40px',
                        },
                    }}
                >
                    <Typography
                        variant="h2"
                        sx={{
                            fontSize: {
                                xs: '16px',
                                md: staticPage ? '23px' : '16px',
                            },
                        }}
                        fontWeight={500}
                        textTransform="uppercase"
                    >
                        2 способ:
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: {
                                xs: '14px',
                                md: staticPage ? '20px' : '14px',
                            },
                        }}
                        mt="16px"
                    >
                        Для точного определения размера мы всегда рекомендуем
                        обратиться в ближайший ювелирный бутик, где с помощью
                        кольцемера вам помогут
                        определить&nbsp;диаметр&nbsp;кольца и ширину пальчика.
                    </Typography>
                </Box>
            </Box>

            {staticPage && isDesktop && (
                <Box position="relative">
                    <Box
                        component={'img'}
                        src={SizeSelectionGuideImageStatic}
                        sx={{
                            mt: '50px',
                            width: {
                                md: '600px',
                            },
                        }}
                    />
                    <Typography
                        sx={{
                            position: 'absolute',
                            width: '400px',
                            fontSize: '23px',
                            color: 'primary.main',
                            top: '80px',
                            right: '0px',
                            fontWeight: 500,
                        }}
                    >
                        Величина диаметра кольца&nbsp;в&nbsp;миллиметрах =
                        размеру&nbsp;вашего&nbsp;пальчика.
                    </Typography>
                </Box>
            )}

            {handleCloseTooltip && (
                <Box sx={{ mt: '30px', pb: '16px', width: '100%' }}>
                    <Button
                        fullWidth
                        variant="contained"
                        size="large"
                        color="primary"
                        sx={{
                            px: 1.75,
                            width: '100%',
                        }}
                        onClick={handleCloseTooltip}
                    >
                        <Typography variant="body2" fontWeight={500}>
                            ПОНЯТНО
                        </Typography>
                    </Button>
                </Box>
            )}
        </Box>
    )
}
