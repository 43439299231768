import { Box } from '@mui/material'
import { selectImageById } from 'app/modules/Images/slice/selectors'
import { selectLineItemById } from 'app/modules/LineItems/slice/selectors'
import { selectVariantById } from 'app/modules/Variants/slice/selectors'
import React from 'react'
import { useSelector } from 'react-redux'
import { ILineItem } from 'types/ILineItem'

import { OrderDetailProduct, OrderDetailProps } from '.'

export const OrderDetailProducts: React.FC<OrderDetailProps> = ({ cart }) => {
    const getLineItem = useSelector(selectLineItemById)
    const getImage = useSelector(selectImageById)
    const getVariant = useSelector(selectVariantById)

    const listItems =
        cart?.relationships.line_items.data.reduce(
            (acc: ILineItem[], value) => {
                const lineItem = getLineItem(value.id)

                if (lineItem) {
                    return [...acc, lineItem]
                }

                return acc
            },
            []
        ) || []

    const items = listItems.map((item) => {
        const variant = getVariant(item.relationships.variant.data?.id)

        const image = getImage(
            variant?.relationships.images.data[0]?.id,
            '612',
            true
        )

        return {
            optionsText: variant?.attributes.options_text || '',
            item,
            image,
        }
    })

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            borderBottom="1px solid #DEDEDE"
            mt="30px"
        >
            {items.map(({ item, image, optionsText }) => (
                <Box key={item.id} pb="30px" width="100%">
                    <OrderDetailProduct
                        item={item}
                        image={image}
                        optionsText={optionsText}
                    />
                </Box>
            ))}
        </Box>
    )
}
