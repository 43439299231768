import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    IconButton,
    Stack,
    Typography,
} from '@mui/material'
import { CloseIcon } from 'app/components/Icons/CloseIcon'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { citiesActions } from '../../slice'

interface DesktopWelcomeCityDialogProps {
    onCityChange: () => void
}

export const DesktopWelcomeCityDialog: React.FC<
    DesktopWelcomeCityDialogProps
> = ({ onCityChange }) => {
    const dispatch = useDispatch()

    const [open, setOpen] = useState<boolean>(true)

    const handleMoscowSet = () => {
        dispatch(citiesActions.setActiveCityId('1'))
        setOpen(false)
    }

    const handleCityChange = () => {
        onCityChange()
        setOpen(false)
    }

    const handleClose = () => {
        setOpen(false)
    }
    return (
        <Dialog open={open} onClose={handleClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '30px',
                    right: '30px',
                }}
            >
                <IconButton onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </Box>

            <Box
                sx={{
                    py: 4.25,
                    px: 3.75,
                    width: {
                        md: '550px',
                    },
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <Typography
                    sx={{ pt: 2, fontWeight: 500, textTransform: 'uppercase' }}
                    variant="h5"
                    textAlign={'center'}
                >
                    ближайший к вам
                    <br />
                    город москва?
                </Typography>

                <DialogContent>
                    <DialogContentText sx={{ mb: 3.5 }}>
                        <Typography
                            variant="body2"
                            fontWeight={500}
                            color="grey.600"
                            textAlign={'center'}
                        >
                            {
                                'От выбранного города зависит наличие товаров и стоимость доставки'
                            }
                        </Typography>
                    </DialogContentText>
                </DialogContent>

                <DialogActions
                    sx={{
                        width: '100%',
                    }}
                >
                    <Stack
                        sx={{
                            width: '100%',
                        }}
                        spacing={3.75}
                        direction={'row'}
                    >
                        <Button
                            fullWidth
                            variant="contained"
                            size="large"
                            color="primary"
                            sx={{ px: 1.75 }}
                            onClick={handleMoscowSet}
                        >
                            <Typography variant="body2" fontWeight={500}>
                                Да, МОСКВА
                            </Typography>
                        </Button>

                        <Button
                            fullWidth
                            variant="outlined"
                            size="large"
                            sx={{ px: 1.75 }}
                            onClick={handleCityChange}
                        >
                            <Typography variant="body2" fontWeight={500}>
                                НЕТ, ДРУГОЙ ГОРОД
                            </Typography>
                        </Button>
                    </Stack>
                </DialogActions>
            </Box>
        </Dialog>
    )
}
