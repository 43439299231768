import { Badge, Box, Container, Grid } from '@mui/material'
import { accountActions } from 'app/modules/Account/slice'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useRouteMatch } from 'react-router-dom'

import { authActions } from '../../../../../Auth/slice'
import {
    selectAuthStatus,
    selectModalStatus,
} from '../../../../../Auth/slice/selectors'
import { cartActions } from '../../../../../Cart/slice'
import { selectCart, selectCartOpen } from '../../../../../Cart/slice/selectors'
import { ReactComponent as SearchSVG } from '../../../../assets/catalog_tab_bar.svg'
import { ReactComponent as FavoriteSVG } from '../../../../assets/heart_tab_bar.svg'
import { ReactComponent as HomeSVG } from '../../../../assets/home_tab_bar.svg'
import { ReactComponent as CartSVG } from '../../../../assets/shopping_bag_tab_bar.svg'
import { ReactComponent as UserSVG } from '../../../../assets/user_tab_bar.svg'
import { useHeaderContext } from '../../../Header/HeaderContextProvider'
import { Color } from '../../constants/colors'
import NavigationTab from '../NavigationTab'
import { MOBILE_NAVIGATION_HEIGHT } from '.'

export const PRODUCTS_PATH = 'products'
export const CATALOGUE_PARENT_PATH = 'osnovnoi-katalogh'

const MobileNavigation: React.FC = () => {
    const history = useHistory()
    const { url, path } = useRouteMatch()
    const dispatch = useDispatch()

    const cart = useSelector(selectCart)
    const isCartOpen = useSelector(selectCartOpen)
    const isPWA = window.matchMedia('(display-mode: standalone)').matches

    const { isAuthorized, notAuthorized } = useSelector(selectAuthStatus)

    const authModalStatus = useSelector(selectModalStatus)
    const { touch, close, isOpened: isCatalogueOpened } = useHeaderContext()

    useEffect(() => {
        if (!isCatalogueOpened) {
            dispatch(accountActions.activeAccountModal())
        } else {
            dispatch(accountActions.disabledAccountModal())
        }
    }, [isCatalogueOpened])

    const onMainPageClickHandler = useCallback(() => {
        if (isCatalogueOpened) {
            close()
        }
        dispatch(cartActions.hideCart())
        dispatch(authActions.setModalStatus('close'))

        history.push('/')
    }, [isCartOpen])

    const onCatalogClickHandler = useCallback(() => {
        dispatch(cartActions.hideCart())
        dispatch(authActions.setModalStatus('close'))
        touch()
    }, [touch])

    const onCartClickHandler = useCallback(() => {
        close()
        dispatch(authActions.setModalStatus('close'))
        dispatch(cartActions.showCart())
    }, [])

    const onFavoriteClickHandler = useCallback(() => {
        close()
        dispatch(cartActions.hideCart())
        dispatch(authActions.setModalStatus('close'))
        if (isAuthorized) {
            history.push('/profile/favorites')
        }
        if (notAuthorized) {
            dispatch(authActions.setReferrerUrl('/profile/favorites'))
            dispatch(authActions.resetPhone())
            dispatch(authActions.setModalStatus('phone'))
        }
    }, [])

    const onProfileClickHandler = useCallback(() => {
        close()
        dispatch(cartActions.hideCart())
        dispatch(accountActions.activeAccountModal())

        history.push('/profile')
    }, [])

    const shouldHighlightCatalogue = useMemo(() => {
        const urlSteps = url.split('/')

        if (urlSteps.includes(PRODUCTS_PATH)) {
            return true
        }

        if (urlSteps.includes(CATALOGUE_PARENT_PATH)) {
            return true
        }

        return isCatalogueOpened
    }, [url, isCatalogueOpened])

    const isAuthModalOpened = useMemo(
        () => authModalStatus === 'phone' || authModalStatus === 'code',
        [authModalStatus, path, url]
    )

    const isProfileOpened = useMemo(
        () =>
            path === '/profile' ||
            (path === '/profile/*' && url !== '/profile/favorites'),
        [url, path]
    )

    const isAnyModalOpened = useMemo(() => {
        if (isCatalogueOpened || isCartOpen || isAuthModalOpened) {
            return true
        }
        return false
    }, [isCatalogueOpened, isCartOpen, isAuthModalOpened])

    const matchLink = useCallback(
        (link: string) => {
            return url === link && !isAnyModalOpened
                ? Color.ACTIVE_COLOR
                : Color.DEFAULT_COLOR
        },
        [url, isAnyModalOpened]
    )

    return (
        <Box
            sx={{
                zIndex: 1300,
                position: 'sticky',
                width: '100%',
                minHeight: `${MOBILE_NAVIGATION_HEIGHT}px`,
                padding: isPWA ? '12px 0 26px 0' : '12px 0 8px 0',
                backgroundColor: '#fff',
                bottom: 0,
                left: 0,
                right: 0,
                boxShadow: '0px -2px 16px rgba(0, 0, 0, 0.06)',
            }}
        >
            <Container>
                <Grid container sx={{ alignItems: 'center' }}>
                    <NavigationTab
                        icon={<HomeSVG stroke={matchLink('/')} />}
                        isActive={path === '/' && !isAnyModalOpened}
                        onClick={onMainPageClickHandler}
                    >
                        Главная
                    </NavigationTab>

                    <NavigationTab
                        icon={
                            <SearchSVG
                                stroke={
                                    shouldHighlightCatalogue &&
                                    !isCartOpen &&
                                    !isAuthModalOpened
                                        ? Color.ACTIVE_COLOR
                                        : Color.DEFAULT_COLOR
                                }
                            />
                        }
                        isActive={
                            shouldHighlightCatalogue &&
                            !isCartOpen &&
                            !isAuthModalOpened
                        }
                        onClick={onCatalogClickHandler}
                    >
                        Каталог
                    </NavigationTab>

                    <NavigationTab
                        icon={
                            <Badge
                                sx={{ display: 'flex' }}
                                color="primary"
                                badgeContent={
                                    cart?.relationships.line_items.data.length
                                }
                            >
                                <CartSVG
                                    stroke={
                                        isCartOpen
                                            ? Color.ACTIVE_COLOR
                                            : Color.DEFAULT_COLOR
                                    }
                                />
                            </Badge>
                        }
                        isActive={isCartOpen}
                        onClick={onCartClickHandler}
                    >
                        Корзина
                    </NavigationTab>

                    <NavigationTab
                        icon={
                            <FavoriteSVG
                                stroke={matchLink('/profile/favorites')}
                            />
                        }
                        isActive={
                            url === '/profile/favorites' && !isAnyModalOpened
                        }
                        onClick={onFavoriteClickHandler}
                    >
                        Избранное
                    </NavigationTab>

                    <NavigationTab
                        icon={
                            <UserSVG
                                stroke={
                                    (isAuthModalOpened || isProfileOpened) &&
                                    !isCartOpen &&
                                    !isCatalogueOpened
                                        ? Color.ACTIVE_COLOR
                                        : Color.DEFAULT_COLOR
                                }
                            />
                        }
                        isActive={
                            (isAuthModalOpened || isProfileOpened) &&
                            !isCartOpen &&
                            !isCatalogueOpened
                        }
                        onClick={onProfileClickHandler}
                    >
                        {isAuthorized ? 'Профиль' : 'Войти'}
                    </NavigationTab>
                </Grid>
            </Container>
        </Box>
    )
}

export default MobileNavigation
