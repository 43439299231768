import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { selectImageById } from 'app/modules/Images/slice/selectors'
import { selectLineItemById } from 'app/modules/LineItems/slice/selectors'
import { selectVariantById } from 'app/modules/Variants/slice/selectors'
import React from 'react'
import { useSelector } from 'react-redux'
import { DolyameID } from 'utils/DolyameID'

interface LineItemProps {
    id: string
}

export const LineItem: React.FC<LineItemProps> = ({ id }) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))

    const getLineItem = useSelector(selectLineItemById)
    const getImage = useSelector(selectImageById)
    const getVariant = useSelector(selectVariantById)

    const lineItem = getLineItem(id)

    if (!lineItem) return null

    const variant = getVariant(lineItem?.relationships.variant.data?.id)
    const image = getImage(variant?.relationships.images.data[0]?.id)

    return (
        <Box display="flex">
            <DolyameID lineItem={lineItem} />
            <Box
                sx={{
                    width: { xs: '56px', md: '80px' },
                    height: { xs: '56px', md: '80px' },
                    flexShrink: 0,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundImage: `url(${image || ''})`,
                }}
            />
            {!isMobile && (
                <Box ml={2} flexGrow={1}>
                    <Box display="flex" justifyContent="space-between">
                        <Typography variant="body2">
                            {lineItem?.attributes.name}
                        </Typography>

                        <Box sx={{ display: 'flex' }}>
                            <Typography
                                noWrap
                                sx={{ fontWeight: 'medium' }}
                                variant="body2"
                            >
                                {lineItem?.attributes.display_total}
                            </Typography>
                        </Box>
                    </Box>

                    <Typography mt={1}>
                        {lineItem?.attributes.options_text}
                    </Typography>
                </Box>
            )}
        </Box>
    )
}
