import { PayloadAction } from '@reduxjs/toolkit'
import { accountActions } from 'app/modules/Account/slice'
import { call, put, takeLeading } from 'redux-saga/effects'
import { IActiveStorageBlobItemResponse } from 'types/IActiveStorageBlob'
import { request } from 'utils/request'

import { activeStorageBlobActions } from '.'

export function* uploadImage(action: PayloadAction<File>) {
    try {
        const requestURL = `/api/v2/storefront/file`

        const data = new FormData()
        data.append('file', action.payload)

        const response: IActiveStorageBlobItemResponse = yield call(
            request,
            requestURL,
            {
                method: 'POST',
                data: data,
            }
        )

        yield put(
            accountActions.updateAccount({
                avatar_blob_id: response.data.id,
            })
        )
        yield put(activeStorageBlobActions.statusFinished())
    } catch (error: any) {
        yield put(activeStorageBlobActions.statusError())
    }
}

export function* activeStorageBlobWatcher() {
    yield takeLeading(activeStorageBlobActions.uploadImage.type, uploadImage)
}
