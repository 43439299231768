import {
    createEntityAdapter,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit'
import { EStatus } from 'types'
import { IVariant } from 'types/IVariant'
import { ResourceObject, Response } from 'utils/json-api'
import { isIVariant } from 'utils/json-api/resourceCheckers'

import { VariantsState } from './types'

export const variantsAdapter = createEntityAdapter<IVariant>()

const slice = createSlice({
    name: 'variants',
    initialState: variantsAdapter.getInitialState<VariantsState>({
        ids: [],
        entities: {},
        status: EStatus.INITIAL,
    }),
    reducers: {
        variantsLoaded(
            state,
            action: PayloadAction<
                Response<
                    ResourceObject | ResourceObject[],
                    (IVariant | ResourceObject)[]
                >
            >
        ) {
            const variants = action.payload.included.filter(isIVariant)

            variantsAdapter.setMany(state, variants)
        },
        statusError(state) {
            state.status = EStatus.ERROR
        },
    },
})

export const { actions: variantsActions, reducer: variantsReducer } = slice
