import { Container, Grid } from '@mui/material'
import { selectCurrentShopCity } from 'app/modules/Cities/slice/selectors'
import { selectShopByCityId } from 'app/modules/Shops/slice/selectors'
import React from 'react'
import Carousel from 'react-material-ui-carousel'
import { useSelector } from 'react-redux'

import { ShopCard } from '../ShopCard'
import { DesktopShopCard } from '../ShopCard/DesktopShopCard'
import { ReactComponent as NextIcon } from './assets/NextIcon.svg'
import { ReactComponent as PrevIcon } from './assets/PrevIcon.svg'

export const DesktopShopCardList: React.FC = () => {
    const currentCity = useSelector(selectCurrentShopCity)
    const getShops = useSelector(selectShopByCityId)

    const mainBoutiqueShops = getShops(currentCity?.id).filter(
        (shop) => shop.attributes.kind === 'main_boutique'
    )

    const otherShops = getShops(currentCity?.id).filter(
        (shop) => shop.attributes.kind !== 'main_boutique'
    )

    return (
        <Container sx={{ mt: { lg: 6.25, xs: 6 } }}>
            {mainBoutiqueShops.length > 0 && (
                <Carousel
                    animation="slide"
                    indicators={false}
                    navButtonsAlwaysVisible
                    PrevIcon={<PrevIcon />}
                    NextIcon={<NextIcon />}
                    navButtonsProps={{
                        style: {
                            backgroundColor: 'transparent',
                        },
                    }}
                >
                    {mainBoutiqueShops.map((shop) => (
                        <DesktopShopCard key={shop.id} shop={shop} />
                    ))}
                </Carousel>
            )}
            <Grid container mt={3.75} spacing={3.75}>
                {otherShops.map((shop) => (
                    <Grid key={shop.id} item xs={12} lg={6}>
                        <ShopCard shop={shop} />
                    </Grid>
                ))}
            </Grid>
        </Container>
    )
}
