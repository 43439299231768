import { Grid } from '@mui/material'
import { OrderDetail } from 'app/modules/Cart/components/OrderDetail'
import { PartnerCreateOrderSteps } from 'app/modules/Cart/components/PartnerCreateOrderSteps'
import { selectCart } from 'app/modules/Cart/slice/selectors'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

export const DesktopPartnerCreateOrder: React.FC = () => {
    const cart = useSelector(selectCart)

    useEffect(() => {
        if (cart?.attributes.payment_url) {
            window.location.href = cart.attributes.payment_url
        }
    }, [cart?.attributes.payment_url])

    if (!cart) {
        return null
    }

    return (
        <Grid container sx={{ height: '100%' }}>
            <Grid item xs={12} lg={7}>
                <PartnerCreateOrderSteps />
            </Grid>

            <Grid
                item
                xs={12}
                lg={5}
                sx={{
                    p: 10,
                    backgroundColor: '#F5F3F3',
                }}
            >
                <OrderDetail cart={cart} />
            </Grid>
        </Grid>
    )
}
