import {
    createEntityAdapter,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit'
import { EStatus } from 'types'
import { ICity, ICityCollectionResponse } from 'types/ICity'

import { ICitiesState } from './types'

export const cityAdapter = createEntityAdapter<ICity>()

const slice = createSlice({
    name: 'cities',
    initialState: cityAdapter.getInitialState<ICitiesState>({
        ids: [],
        entities: {},
        status: EStatus.INITIAL,
        current_city_id: localStorage.getItem('current_city_id') || '',
        current_shop_city_id: '',
        is_modal_open: false,
        form: {
            open: false,
            current_city_id: '',
        },
    }),
    reducers: {
        loadCities(state) {
            state.status = EStatus.PENDING
        },
        changeCurrentCity(state, action: PayloadAction<string>) {
            state.form.current_city_id = action.payload
            state.form.open = true
        },
        hideCurrentCity(state) {
            state.form.open = false
        },
        citiesLoaded(state, action: PayloadAction<ICityCollectionResponse>) {
            cityAdapter.setAll(state, action.payload.data)
            state.status = EStatus.FINISHED
        },
        setActiveCityId(state, action: PayloadAction<string>) {
            state.form.open = false
            state.current_city_id = action.payload
            localStorage.setItem('current_city_id', action.payload)
        },
        setActiveShopCityId(state, action: PayloadAction<string>) {
            state.current_shop_city_id = action.payload
        },
        openModal(state) {
            state.is_modal_open = true
        },
        closeModal(state) {
            state.is_modal_open = false
        },
        statusError(state) {
            state.status = EStatus.ERROR
        },
    },
})

export const { actions: citiesActions, reducer: citiesReducer } = slice
