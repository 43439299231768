import React, { createContext, useContext, useEffect } from 'react'

import { IYandexTranslateContext } from '.'

export const YandexTranslateContext = createContext<IYandexTranslateContext>(
    {} as IYandexTranslateContext
)

export function useYandexTranslateContext() {
    const context = useContext(YandexTranslateContext)

    if (context === undefined) {
        throw new Error(
            'useYandexTranslateContext must be used within the YandexTranslateContextProvider'
        )
    }

    return context
}

export const YandexTranslateContextProvider: React.FC = ({ children }) => {
    const userLangRU = true
    // navigator.languages.includes('ru') ||
    // navigator.languages.includes('ru-RU')

    useEffect(() => {
        if (!userLangRU && !localStorage['yt-widget']) {
            localStorage.setItem(
                'yt-widget',
                JSON.stringify({
                    lang: 'en',
                    active: true,
                })
            )
        }
    }, [userLangRU])

    function yaTranslateSetLang(lang: string | null) {
        // Записываем выбранный язык в localStorage объект yt-widget
        // Writing the selected language to localStorage
        localStorage.setItem(
            'yt-widget',
            JSON.stringify({
                lang: lang,
                active: true,
            })
        )
        window.location.reload()
    }

    function yaTranslateGetCode(): string {
        // Возвращаем язык на который переводим
        // Returning the language to which we are translating
        return localStorage['yt-widget'] != undefined &&
            JSON.parse(localStorage['yt-widget']).lang != undefined
            ? JSON.parse(localStorage['yt-widget']).lang
            : ''
    }

    useEffect(function yaTranslateInit() {
        // Подключаем виджет yandex translate
        // Connecting the yandex translate widget
        const script = document.createElement('script')
        script.src = `https://translate.yandex.net/website-widget/v1/widget.js?widgetId=ytWidget&pageLang=ru&widgetTheme=light&autoMode=false`
        document.getElementsByTagName('head')[0]?.appendChild(script)
    }, [])

    return (
        <>
            <div id="ytWidget" style={{ display: 'none' }}></div>
            <YandexTranslateContext.Provider
                value={{ yaTranslateSetLang, yaTranslateGetCode }}
            >
                {children}
            </YandexTranslateContext.Provider>
        </>
    )
}
