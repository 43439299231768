import {
    createEntityAdapter,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit'
import { EStatus } from 'types'
import {
    IProduct,
    ProductFilterParams,
    ProductSortVariants,
} from 'types/IProduct'
import { ResourceObject, Response } from 'utils/json-api'
import { isIProduct } from 'utils/json-api/resourceCheckers'

import { IProductsRequest, IProductsState } from './types'

export const productsAdapter = createEntityAdapter<IProduct>()

const slice = createSlice({
    name: 'products',
    initialState: productsAdapter.getInitialState<IProductsState>({
        ids: [],
        entities: {},
        loadProductStatus: EStatus.INITIAL,
        loadProductsStatus: EStatus.INITIAL,

        filtersParams: {
            max_price: 0,
            min_price: 0,
            product_properties: [],
            option_types: [],
        },

        categoryPage: {
            sort: ProductSortVariants.CREATED_AT,
            filter: {},

            productsIDs: [],
            curentPage: 1,
            totalPages: 0,
            totalProductsCount: 0,

            loadProducts: false,

            loadProductsStatus: EStatus.INITIAL,
        },

        searchPage: {
            productsIDs: [],
            totalPages: 0,
            totalProductsCount: 0,
        },

        favoritesPage: {
            productsIDs: [],
            totalPages: 0,
            totalProductsCount: 0,
        },

        noveltyProductsIDs: [],

        popularProductsIDs: [],
        loadPopularProductsStatus: EStatus.INITIAL,

        saleProductsIDs: [],

        giftProductsIDs: [],
    }),
    reducers: {
        setFilterParams(state, { payload: response }: PayloadAction<Response>) {
            const max_price = response.meta.filters?.max_price || 0
            const min_price = response.meta.filters?.min_price || 0

            const product_properties =
                response.meta.filters?.product_properties || []
            const option_types = response.meta.filters?.option_types || []

            state.filtersParams = {
                max_price,
                min_price,
                product_properties,
                option_types,
            }
        },

        loadProduct(state, _action: PayloadAction<string>) {
            state.loadProductStatus = EStatus.PENDING
        },
        productLoaded(state, action: PayloadAction<IProduct>) {
            productsAdapter.setOne(state, action.payload)

            state.loadProductStatus = EStatus.FINISHED
        },

        productsAddedFromInclude(
            state,
            action: PayloadAction<
                Response<
                    ResourceObject | ResourceObject[],
                    (IProduct | ResourceObject)[]
                >
            >
        ) {
            const products = action.payload.included.filter(isIProduct)

            productsAdapter.setMany(state, products)
        },
        productToggleFavorite(_state, _action: PayloadAction<string>) {
            //
        },
        productToggleFavorite1C(_state, _action: PayloadAction<string>) {
            //
        },

        statusProductError(state) {
            state.loadProductStatus = EStatus.ERROR
        },
        statusProductsError(state) {
            state.loadProductsStatus = EStatus.ERROR
        },
        statusProductsPopularError(state) {
            state.loadPopularProductsStatus = EStatus.ERROR
        },
        // Блок кода товаров со страницы категории
        setCategoryPageSort(state, action: PayloadAction<ProductSortVariants>) {
            state.categoryPage.sort = action.payload
        },
        /**
         * Сбрасывает список товаров
         * Устанавливает флаг загрузки продуктов на true
         */
        setCategoryPageFilter(
            state,
            action: PayloadAction<ProductFilterParams>
        ) {
            state.categoryPage.filter = action.payload
            // state.categoryPage.filter = {...action.payload,
            //     price: [action.payload?.price?.length ?
            //       (!!action.payload.price[0] && action.payload.price[0] >= state.filtersParams.min_price ? action.payload.price[0] : state.filtersParams.min_price)
            //       : state.filtersParams.min_price,
            //         action.payload?.price?.length ?
            //         (!!action.payload.price[1] && action.payload.price[1] <= state.filtersParams.max_price ? action.payload.price[1] : state.filtersParams.max_price)
            //         : state.filtersParams.max_price,
            //     ]
            // }
            state.categoryPage.curentPage = 1
            state.categoryPage.productsIDs = []
            state.categoryPage.totalProductsCount = 0
            state.categoryPage.totalPages = 0
            state.categoryPage.loadProducts = true
            state.categoryPage.loadProductsStatus = EStatus.PENDING
        },
        loadCategoryPageProducts(
            state,
            _action: PayloadAction<IProductsRequest>
        ) {
            state.categoryPage.loadProductsStatus = EStatus.PENDING
        },
        loadNextCategoryPageProducts(
            state,
            _action: PayloadAction<IProductsRequest>
        ) {
            state.categoryPage.loadProductsStatus = EStatus.PENDING
            state.categoryPage.curentPage++
        },
        /**
         * Устанавливает флаг загрузки продуктов на false
         */
        categoryPageProductsLoaded(
            state,
            action: PayloadAction<{
                products: IProduct[]
                totalPages: number
                totalProductsCount: number
                addProducts: boolean
            }>
        ) {
            if (action.payload.addProducts) {
                state.categoryPage.productsIDs.push(
                    ...action.payload.products.map((product) => product.id)
                )
            } else {
                state.categoryPage.productsIDs = action.payload.products.map(
                    (product) => product.id
                )
            }

            state.categoryPage.totalPages = action.payload.totalPages
            state.categoryPage.totalProductsCount =
                action.payload.totalProductsCount

            productsAdapter.setMany(state, action.payload.products)

            state.categoryPage.loadProducts = false
            state.categoryPage.loadProductsStatus = EStatus.FINISHED
        },
        // Блок кода товаров со страницы поиска
        searchPageProductsLoaded(
            state,
            action: PayloadAction<{
                products: IProduct[]
                totalPages: number
                totalProductsCount: number
                addProducts: boolean
            }>
        ) {
            if (action.payload.addProducts) {
                state.searchPage.productsIDs.push(
                    ...action.payload.products.map((product) => product.id)
                )
            } else {
                state.searchPage.productsIDs = action.payload.products.map(
                    (product) => product.id
                )
            }
            state.searchPage.totalPages = action.payload.totalPages
            state.searchPage.totalProductsCount =
                action.payload.totalProductsCount

            productsAdapter.setMany(state, action.payload.products)
        },
        // Блок кода Избранного
        loadFavorites(state, _action: PayloadAction<number>) {
            state.loadProductsStatus = EStatus.PENDING
        },
        favoriteProductsLoaded(
            state,
            action: PayloadAction<{
                products: IProduct[]
                totalPages: number
                totalProductsCount: number
                addProducts: boolean
            }>
        ) {
            if (action.payload.addProducts) {
                state.favoritesPage.productsIDs.push(
                    ...action.payload.products.map((product) => product.id)
                )
            } else {
                state.favoritesPage.productsIDs = action.payload.products.map(
                    (product) => product.id
                )
            }

            state.favoritesPage.totalPages = action.payload.totalPages
            state.favoritesPage.totalProductsCount =
                action.payload.totalProductsCount

            productsAdapter.setMany(state, action.payload.products)

            state.loadProductsStatus = EStatus.FINISHED
        },
        // Блок кода popular
        loadPopularProducts(state, _action: PayloadAction<IProductsRequest>) {
            state.loadPopularProductsStatus = EStatus.PENDING
        },
        popularProductsLoaded(state, action: PayloadAction<IProduct[]>) {
            state.popularProductsIDs = action.payload.map(
                (product) => product.id
            )

            productsAdapter.setMany(state, action.payload)

            state.loadPopularProductsStatus = EStatus.FINISHED
        },
        // Блок кода новинок
        loadNoveltyProducts(state, _action: PayloadAction<IProductsRequest>) {
            state.loadProductsStatus = EStatus.PENDING
        },
        noveltyProductsLoaded(state, action: PayloadAction<IProduct[]>) {
            state.noveltyProductsIDs = action.payload.map(
                (product) => product.id
            )

            productsAdapter.setMany(state, action.payload)

            state.loadProductsStatus = EStatus.FINISHED
        },
        // Блок кода товаров распродажи
        loadSaleProducts(state, _action: PayloadAction<IProductsRequest>) {
            state.loadProductsStatus = EStatus.PENDING
        },
        saleProductsLoaded(state, action: PayloadAction<IProduct[]>) {
            state.saleProductsIDs = action.payload.map((product) => product.id)

            productsAdapter.setMany(state, action.payload)

            state.loadProductsStatus = EStatus.FINISHED
        },
        // Блок кода товаров подарков
        loadGiftProducts(
            state,
            _action: PayloadAction<{ taxons: string; perPage: number }>
        ) {
            state.loadProductsStatus = EStatus.PENDING
        },
        giftProductsLoaded(state, action: PayloadAction<IProduct[]>) {
            state.giftProductsIDs = action.payload.map((product) => product.id)

            productsAdapter.setMany(state, action.payload)
            state.loadProductsStatus = EStatus.FINISHED
        },
        // Блок кода мультиверс
        getMultiverseProducts(state, _action: PayloadAction<string[]>) {
            state.loadProductsStatus = EStatus.PENDING
        },
        multiverseProductsLoaded(state, action: PayloadAction<IProduct[]>) {
            productsAdapter.setMany(state, action.payload)
            state.loadProductsStatus = EStatus.FINISHED
        },
    },
})

export const { actions: productsActions, reducer: productsReducer } = slice
