import { LoadingButton } from '@mui/lab'
import { cartActions } from 'app/modules/Cart/slice'
import { selectAddItemStatus } from 'app/modules/Cart/slice/selectors'
import { selectVariantById } from 'app/modules/Variants/slice/selectors'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ym from 'react-yandex-metrika'
import { EStatus } from 'types'
import {
    googleAnalyticsEvent,
    googleAnalyticsEvents,
} from 'utils/googleAnalytics'
import { topMailEvent, TopMailEventGoals } from 'utils/topMailMetrica'
import { useYandexMetricaEcommerce } from 'utils/yandexMetrica'

import { ProductSelectSizeModal } from '../ProductDetail/components/ProductSelectSizeModal'
import { CartButtonProps } from '.'

declare global {
    interface Window {
        AddToCart?: any
        addToCart?: any
        addToFavorites?: any
    }
}

export const CartButton: React.FC<CartButtonProps> = ({
    product,
    variants,
    selectedVariant,
    setSelectedVariant,
    caption = 'В корзину',
    addToCartCallbackFunction = () => {
        // nope
    },
}) => {
    const dispatch = useDispatch()

    const [selectSizeModalOpen, setSelectSizeModalOpen] = useState(false)

    const addItemStatus = useSelector(selectAddItemStatus)
    const { yandexMetricaEcommerceAdd } = useYandexMetricaEcommerce()
    const getVariant = useSelector(selectVariantById)

    const defaultVariant = product.relationships.default_variant.data?.id

    function AddToCart(variant_id: string) {
        yandexMetricaEcommerceAdd(product, 1, getVariant(variant_id))

        topMailEvent(TopMailEventGoals.basket)
        ym('reachGoal', 'Basket')
        googleAnalyticsEvent(googleAnalyticsEvents.add_cart)

        dispatch(
            cartActions.addItem({
                variant_id,
                quantity: 1,
            })
        )

        addToCartCallbackFunction()
    }

    const handleAdd = () => {
        const variant_id =
            variants.length > 0 ? selectedVariant : defaultVariant

        if (variant_id) {
            AddToCart(variant_id)
        } else {
            setSelectSizeModalOpen(true)
        }
    }

    const handleCloseSelectSizeModal = () => {
        setSelectSizeModalOpen(false)
    }

    const handleApplySelectSizeModal = () => {
        if (selectedVariant) AddToCart(selectedVariant)
        setSelectSizeModalOpen(false)
    }

    const availableToBuy = (() => {
        if (variants.length > 0) {
            return variants.find(
                (variant) => variant.attributes.available_in_current_city
            )
        } else {
            return product.attributes.available_in_current_city
        }
    })()

    const btnCartDisabled = !availableToBuy

    const btnCartText = availableToBuy ? caption : 'Нет в наличии в этом городе'

    return (
        <>
            <LoadingButton
                fullWidth
                color="primary"
                variant="contained"
                onClick={handleAdd}
                disabled={btnCartDisabled}
                loading={addItemStatus === EStatus.PENDING}
                sx={{
                    height: '100%',
                }}
            >
                {btnCartText}
            </LoadingButton>
            <ProductSelectSizeModal
                {...{
                    product,
                    variants,
                    selectedVariant,
                    setSelectedVariant,
                    isOpen: selectSizeModalOpen,
                    handleClose: handleCloseSelectSizeModal,
                    handleApply: handleApplySelectSizeModal,
                    btnCartDisabled,
                    btnCartText,
                }}
            />
        </>
    )
}
