import { Box, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'

import { DESKTOP_HEADER_HEIGHT } from '../components/Header/DesktopHeader'
import { MOBILE_HEADER_HEIGHT } from '../components/Header/MobileHeader'
import { MOBILE_NAVIGATION_HEIGHT } from '../components/Navigation/partials/MobileNavigation'
import { IHeaderContextProps } from '../types/IHeaderContext'
import { MainLayout } from './MainLayout'

interface OrderLayoutProps {
    bgcolor?: string
    headerContextProps: IHeaderContextProps
}

export const OrderLayout: React.FC<OrderLayoutProps> = ({
    bgcolor,
    headerContextProps,
    children,
}) => {
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

    return (
        <MainLayout headerContextProps={headerContextProps}>
            <Box
                sx={{
                    position: 'relative',
                    top:
                        isDesktop && headerContextProps.mode === 'fixed'
                            ? `${DESKTOP_HEADER_HEIGHT}px`
                            : 0,
                    minHeight: {
                        xs: `calc(100vh - ${MOBILE_HEADER_HEIGHT}px - ${MOBILE_NAVIGATION_HEIGHT}px)`,
                        md: 'initial',
                        lg: `calc(100vh - ${DESKTOP_HEADER_HEIGHT}px)`,
                    },
                }}
            >
                <Box
                    sx={{
                        bgcolor,
                        display: 'flex',
                        flexGrow: 1,
                        flexDirection: 'column',
                        height: '100%',
                    }}
                >
                    {children}
                </Box>
            </Box>
        </MainLayout>
    )
}
