import {
    Box,
    Button,
    Grid,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { selectProductsOptionSize } from 'app/modules/Products/slice/selectors'
import { IProductOptionValue } from 'app/modules/Products/slice/types'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { useCategoryPageContext } from '../../hooks'
import { ProductsFilterKind } from '../../ProductsFilterKind'
import { ProductsFilterShowMoreFiltersButton } from './ProductsFilterShowMoreFiltersButton'

interface ProductsFilterSizeItemProps {
    option_value: IProductOptionValue
    checked: boolean
    setSelectedVariant: (option_value: IProductOptionValue) => void
}

const ProductsFilterSizeItem: React.FC<ProductsFilterSizeItemProps> = ({
    option_value,
    checked,
    setSelectedVariant,
}) => (
    <Button
        variant="outlined"
        value={option_value.id}
        sx={(theme) => ({
            borderRadius: '10px',
            color: theme.palette.primary.dark,
            borderColor: '#DEDEDE',
            boxSizing: 'border-box',
            height: { xs: '11.73333vw', sm: '40px' },
            minWidth: { xs: '13vw', sm: '47px' },
            width: { xs: '13vw', sm: '47px' },
            '&:hover': {
                backgroundColor: { md: `${theme.palette.primary.main}14` },
                color: { md: theme.palette.primary.main },
                borderColor: { xs: '#DEDEDE', md: theme.palette.primary.main },
            },
            '&.MuiButton-outlinedSuccess': {
                backgroundColor: `${theme.palette.primary.main}14`,
                color: theme.palette.primary.main,
                border: '2px solid',
            },
        })}
        color={checked ? 'success' : 'primary'}
        onClick={() => {
            setSelectedVariant(option_value)
        }}
    >
        <Typography fontWeight="medium" fontSize="14px">
            {option_value.name.replace('-', '- ')}
        </Typography>
    </Button>
)

export const ProductsFilterSize: React.FC = () => {
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

    const [collapsed, setCollapsed] = useState(true)

    const productsOptionSize = useSelector(selectProductsOptionSize)

    const { filterReadAdapter, handleChangeProductOption } =
        useCategoryPageContext()

    if (!productsOptionSize) return null

    const checked = (option_value: IProductOptionValue) =>
        Boolean(
            filterReadAdapter.options?.[productsOptionSize.presentation]?.find(
                (value) => value === option_value.name
            )
        )

    const setSelectedVariant = (option_value: IProductOptionValue) => {
        handleChangeProductOption(
            productsOptionSize,
            option_value,
            !checked(option_value)
        )
    }

    const sorterOptionValues = [...productsOptionSize.option_values].sort(
        (a, b) =>
            Number(a.name.split('-')[0]?.replace(',', '.')) -
            Number(b.name.split('-')[0]?.replace(',', '.'))
    )

    const option_values =
        isDesktop && collapsed
            ? sorterOptionValues.slice(0, 8)
            : sorterOptionValues

    return (
        <ProductsFilterKind name="Размеры">
            <Box sx={{ mt: { md: '-4px' } }}>
                <Grid
                    container
                    spacing={{ xs: '10px', md: '6px' }}
                    rowSpacing={{ xs: '16px', md: '6px' }}
                >
                    {option_values.map((option_value) => {
                        return (
                            <Grid item key={option_value.id}>
                                <ProductsFilterSizeItem
                                    option_value={option_value}
                                    checked={checked(option_value)}
                                    setSelectedVariant={setSelectedVariant}
                                />
                            </Grid>
                        )
                    })}
                </Grid>
            </Box>
            {isDesktop && (
                <ProductsFilterShowMoreFiltersButton
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}
                />
            )}
        </ProductsFilterKind>
    )
}
