import { Box, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'

import { Footer } from '../components/Footer'
import { DESKTOP_HEADER_HEIGHT } from '../components/Header/DesktopHeader'
import { MOBILE_HEADER_HEIGHT } from '../components/Header/MobileHeader'
import { MOBILE_NAVIGATION_HEIGHT } from '../components/Navigation/partials/MobileNavigation'
import { IHeaderContextProps } from '../types/IHeaderContext'
import { MainLayout } from './MainLayout'

interface LayoutProps {
    bgcolor?: string
    headerContextProps: IHeaderContextProps
}

export const Layout: React.FC<LayoutProps> = ({
    bgcolor,
    headerContextProps,
    children,
}) => {
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

    return (
        <MainLayout headerContextProps={headerContextProps}>
            <Box
                sx={{
                    position: 'relative',
                    top:
                        isDesktop && headerContextProps.mode === 'fixed'
                            ? `${DESKTOP_HEADER_HEIGHT}px`
                            : 0,
                    minHeight: {
                        xs: `calc(100vh - ${MOBILE_HEADER_HEIGHT}px - ${MOBILE_NAVIGATION_HEIGHT}px)`,
                        md: 'initial',
                        lg: `calc(100vh - ${DESKTOP_HEADER_HEIGHT}px)`,
                    },
                }}
            >
                <Box
                    pb={{ xs: 5, md: 20 }}
                    display="flex"
                    flexGrow={1}
                    flexDirection="column"
                    bgcolor={bgcolor}
                >
                    {children}
                </Box>

                <Footer />
            </Box>
        </MainLayout>
    )
}
