import { Box, IconButton, Paper, Typography } from '@mui/material'
import React, { useState } from 'react'

import { AccountDataForm } from '../../components/AccountDataForm'
import { AvatarForm } from '../../components/AvatarForm'
import { ReactComponent as IconEdit } from './assets/icon_edit.svg'

export const AccountSettings: React.FC = () => {
    const [hasAccountDataEdit, setHasAccountDataEdit] = useState<boolean>(false)

    const handleClickAccountDataEdit = () => {
        setHasAccountDataEdit(true)
    }

    const handleFinishAccountDataEdit = () => {
        setHasAccountDataEdit(false)
    }

    return (
        <Paper
            sx={{
                width: '100%',
                px: 6.25,
                pb: 7.5,
                pt: 5.625,
            }}
            elevation={0}
        >
            <Typography
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: 40,
                    fontWeight: 500,
                    textTransform: 'uppercase',
                }}
                variant="h5"
            >
                Личные данные
                {!hasAccountDataEdit && (
                    <IconButton
                        sx={{ ml: 1 }}
                        onClick={handleClickAccountDataEdit}
                    >
                        <IconEdit />
                    </IconButton>
                )}
            </Typography>

            <Box sx={{ mt: 3.75 }}>
                <AvatarForm />
            </Box>

            <Box sx={{ mt: 5 }}>
                <AccountDataForm
                    hasEdit={hasAccountDataEdit}
                    onEditFinish={handleFinishAccountDataEdit}
                />
            </Box>
        </Paper>
    )
}
