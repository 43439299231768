import { Box } from '@mui/material'
import React from 'react'
import { ILineItem } from 'types/ILineItem'
import { IProduct } from 'types/IProduct'

interface DolyameIDProps {
    lineItem?: ILineItem
    product?: IProduct
    selectedVariant?: string | null
}

function getVariantId({
    product,
    lineItem,
    selectedVariant,
}: DolyameIDProps): string | undefined | null {
    if (selectedVariant) return selectedVariant

    if (lineItem) return lineItem.relationships.variant.data?.id

    if (product) {
        if (product.relationships.variants.data.length) {
            return (
                product.relationships.default_variant.data?.id ||
                product.relationships.variants.data.find(Boolean)?.id
            )
        } else {
            return product.id
        }
    }

    return
}

export const DolyameID: React.FC<DolyameIDProps> = (props) => (
    <Box sx={{ display: 'none' }} component="aside" id="DolyameID">
        {getVariantId(props)}
    </Box>
)
