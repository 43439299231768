import { Button, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

import { useYandexTranslateContext } from './yandexTranslateContext'

const LanguageButton: React.FC<{
    text: string
    shortName: string
}> = ({ text, shortName }) => {
    const { yaTranslateSetLang, yaTranslateGetCode } =
        useYandexTranslateContext()

    return (
        <Button
            variant="text"
            sx={{ p: '0px', mr: '20px', minWidth: '0px' }}
            onClick={() => yaTranslateSetLang(shortName)}
        >
            <Typography
                fontSize="14px"
                fontWeight="regular"
                textTransform="none"
                color={
                    shortName === yaTranslateGetCode()
                        ? 'text.primary'
                        : '#858585'
                }
            >
                {text}
            </Typography>
        </Button>
    )
}

export const LanguageSwitch: React.FC = () => {
    return (
        <Box display="flex">
            <LanguageButton text="Русский" shortName="ru" />

            <LanguageButton text="English" shortName="en" />
        </Box>
    )
}
