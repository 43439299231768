import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CategoryCard } from '../components/CategoryCard'
import { categoriesActions } from '../slice'
import { selectHeaderCategories } from '../slice/selectors'

export const CategoryCardsList: React.FC = () => {
    const dispatch = useDispatch()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))

    const [categoriesCount, setCategoriesCount] = useState(6)

    const categories = useSelector(selectHeaderCategories)

    useEffect(() => {
        dispatch(categoriesActions.loadCategories())
    }, [])

    const handleAllCategories = () => {
        setCategoriesCount(categories.length)
    }

    return (
        <>
            <Typography
                sx={{
                    textAlign: 'center',
                    fontWeight: 500,
                    textTransform: 'uppercase',
                }}
                variant="h5"
            >
                категории
            </Typography>
            <Box
                sx={{
                    mt: 2,
                    px: 0,
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: { xs: '2vw', md: 'unset' },
                    rowGap: { xs: '2vw', md: 'unset' },
                    justifyContent: 'space-between',
                }}
            >
                {categories.slice(0, categoriesCount).map((category) => (
                    <CategoryCard key={category.id} category={category} />
                ))}
            </Box>
            {isMobile && categoriesCount === 6 && (
                <Box display="flex" justifyContent="center" mt={4}>
                    <Button
                        variant="banner"
                        color="dark"
                        sx={{ width: '100%' }}
                        onClick={handleAllCategories}
                    >
                        Показать все
                    </Button>
                </Box>
            )}
        </>
    )
}
