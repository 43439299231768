import { ICategory } from 'types/ICategory'

import { mindBoxOperation, productInfo, ProductInfoParams } from '.'

const VIEW_PRODUCT_OPERATION_NAME = 'Website.ViewProduct'
const VIEW_CATEGORY_OPERATION_NAME = 'Website.ViewCategory'

export const mindboxViewProduct = (
    params: ProductInfoParams,
    wholesale: boolean
) => {
    mindBoxOperation({
        operation: VIEW_PRODUCT_OPERATION_NAME,
        data: {
            viewProduct: productInfo(params),
        },
        wholesale,
    })
}

export const mindboxViewCategory = (
    category: ICategory,
    wholesale: boolean
) => {
    mindBoxOperation({
        operation: VIEW_CATEGORY_OPERATION_NAME,
        data: {
            viewProductCategory: {
                productCategory: {
                    ids: {
                        products1C: category.id,
                    },
                },
            },
        },
        wholesale,
    })
}
