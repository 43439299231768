import { Box } from '@mui/material'
import { HeaderColorVariant } from 'app/modules/Layout/types/HeaderColorVariant'
import React from 'react'
import { Link } from 'react-router-dom'

import { ReactComponent as DesktopLogoDark } from './assets/desktop_logo_dark.svg'
import { ReactComponent as DesktopLogoLight } from './assets/desktop_logo_light.svg'
import { ReactComponent as MobileLogoDark } from './assets/mobile_logo_dark.svg'

interface LogoProps {
    align: 'left' | 'center'
    size: 'small' | 'large'
    variant: HeaderColorVariant
}

export const Logo: React.FC<LogoProps> = ({ align, size, variant }) => {
    const logoByVariant = (() => {
        switch (variant) {
            case 'dark':
                return (
                    (size === 'large' && <DesktopLogoDark />) ||
                    (size === 'small' && <MobileLogoDark />)
                )
            case 'light':
                return <DesktopLogoLight />
            default:
                return ''
        }
    })()

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: align,
            }}
            to="/"
            component={Link}
        >
            {logoByVariant}
        </Box>
    )
}
