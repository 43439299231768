import { createSelector } from '@reduxjs/toolkit'
import placeholderImage from 'assets/images/product_image_placeholder.jpg'
import { RootState } from 'types'

import { imagesAdapter } from '.'
import { ImageSizeVariants } from './types'

const { selectById } = imagesAdapter.getSelectors()

const selectDomain = (state: RootState) => state.images

export const selectImageById = createSelector(
    [selectDomain],
    (state) =>
        (
            id?: string,
            size?: keyof typeof ImageSizeVariants,
            useDefault = false
        ) => {
            if (!id) return useDefault ? placeholderImage : ''

            const image = selectById(state, id)

            if (!image) return useDefault ? placeholderImage : ''

            if (size) {
                const { width, height } = ImageSizeVariants[size]
                const sizedImage = image.attributes.styles.find(
                    (value) => value.width === width && value.height === height
                )?.url

                if (sizedImage) return sizedImage
            }

            return image.attributes.original_url
        }
)
