import { selectAccountWholesale } from 'app/modules/Account/slice/selectors'
import React from 'react'
import { useSelector } from 'react-redux'

import { ProductProps } from '../../templates/Product/Product'
import { ProductCustomerDetail } from './ProductCustomerDetail'
import { ProductPartnerDetail } from './ProductPartnerDetail'

export const ProductDetail: React.FC<ProductProps> = (props) => {
    const wholesale = useSelector(selectAccountWholesale)

    if (wholesale) {
        return <ProductPartnerDetail {...props} />
    } else {
        return <ProductCustomerDetail {...props} />
    }
}
