import { Typography } from '@mui/material'
import React from 'react'

import { ProductDetailComponentProps } from '.'

export const ProductName: React.FC<ProductDetailComponentProps> = ({
    product,
}) => (
    <Typography
        component="h1"
        sx={(theme) => ({
            typography: {
                xs: {
                    ...theme.typography.h5,
                    fontWeight: '700',
                    fontSize: '20px',
                    lineHeight: '28px',
                },
                md: {
                    ...theme.typography.h4,
                    fontSize: '2rem',
                    fontWeight: '500',
                },
            },
            color: theme.palette.text.primary,
            textTransform: 'none',
        })}
    >
        {product.attributes.name}
    </Typography>
)
