import { TabContext, TabPanel } from '@mui/lab'
import { Box, Tab, TabProps, Tabs, Typography } from '@mui/material'
import moment from 'moment'
import React, { useState } from 'react'
import { convertMonth } from 'utils/convertMonth'

interface DolyameTabProps {
    value: string
    text: string
}

export const DolyameTab: React.FC<DolyameTabProps & TabProps> = ({
    value,
    text,

    ...props
}) => {
    return (
        <Tab
            value={value}
            sx={{
                width: '50%',
                border: '1.5px solid transparent',
                px: { xs: '16px', md: '30px' },
                '&.Mui-selected': {
                    backgroundColor: 'rgba(84, 166, 127, 0.08)',
                    border: '1.5px solid #54A67F',
                    borderRadius: '10px',
                },
            }}
            label={
                <Box display="flex" width="100%" alignItems="center">
                    <Typography
                        variant="h6"
                        sx={(theme) => ({
                            fontSize: { xs: '14px', md: '16px' },
                            fontWeight: 500,
                            textAlign: 'left',
                            color: props.disabled
                                ? theme.palette.text.disabled
                                : theme.palette.text.primary,
                            maxWidth: '178px',
                            ml: { xs: '0px', md: '20px' },
                        })}
                    >
                        {text}
                    </Typography>
                </Box>
            }
            {...props}
        />
    )
}

const BottomBlockClass = 'digi-dolyame-scheme digi-dolyame-scheme-2357'
const ContentClass = 'digi-dolyame-scheme__content'
const detailsClass = 'digi-dolyame-scheme__description-details'

const detailsPayment = 'digi-dolyame-scheme__details-of-payment'
const detailsPaymentItem = 'digi-dolyame-scheme__details-of-payment_item'
const detailsPaymentDate = 'digi-dolyame-scheme__details-of-payment_date'
const detailsPaymentPrice = 'digi-dolyame-scheme__details-of-payment_price'

export const DolyamePaymentBottomBlock: React.FC<{ total: number }> = ({
    total,
}) => {
    const [currentTab, setCurrentTab] = useState('1')

    const paymentDate = new Date(new Date().setDate(new Date().getDate() + 45))
    const paymentDateString = `${paymentDate.getDate()} ${convertMonth(
        moment(paymentDate).format('MM')
    )}`

    const handleShippingMethodIdChange = (newValue: any) => {
        setCurrentTab(newValue)
    }

    return (
        <Box className={BottomBlockClass}>
            <Box
                className={ContentClass}
                sx={{
                    borderBottom: '1px solid #DEDEDE',
                }}
            >
                <div className={detailsClass}>Выберите срок оплаты</div>
                <TabContext value={currentTab}>
                    <Tabs
                        value={currentTab}
                        centered
                        sx={{
                            mt: '16px',

                            border: '1px solid #DEDEDE',
                            borderRadius: '10px',

                            '& .MuiTabs-flexContainer': {
                                height: '100%',
                            },
                            '& .MuiTabs-indicator': {
                                display: 'none',
                            },
                        }}
                        onChange={(event, value) => {
                            handleShippingMethodIdChange(value)
                        }}
                    >
                        <DolyameTab text="3 месяца" value="1" />
                        <DolyameTab text="6 месяцев" value="2" />
                    </Tabs>
                    <TabPanel value="1" sx={{ px: 0 }}>
                        <Box
                            component={'ul'}
                            className={detailsPayment}
                            sx={{
                                alignItems: 'flex-end',
                                justifyContent: 'center',
                            }}
                        >
                            <Box component="li" className={detailsPaymentItem}>
                                <div className={detailsPaymentDate}>
                                    Сегодня
                                </div>
                                <div className={detailsPaymentPrice}>0 ₽</div>
                            </Box>
                            <Box component="li" className={detailsPaymentItem}>
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        transform: 'translateY(-51px)',
                                    }}
                                >
                                    <div className={detailsPaymentDate}>
                                        {'Далее 3 платежа с '}
                                        {paymentDateString}
                                    </div>
                                    <div className={detailsPaymentPrice}>
                                        {Math.floor(total / 3)} ₽ в месяц
                                    </div>
                                </Box>
                            </Box>

                            <Box
                                component="li"
                                className={detailsPaymentItem}
                            />
                            <Box
                                component="li"
                                className={detailsPaymentItem}
                            />
                        </Box>
                    </TabPanel>
                    <TabPanel value="2" sx={{ px: 0 }}>
                        <Box
                            component={'ul'}
                            className={detailsPayment}
                            sx={{
                                alignItems: 'flex-end',
                                justifyContent: 'center',
                            }}
                        >
                            <li className={detailsPaymentItem}>
                                <div className={detailsPaymentDate}>
                                    Сегодня
                                </div>
                                <div className={detailsPaymentPrice}>0 ₽</div>
                            </li>

                            <Box
                                component={'li'}
                                className={detailsPaymentItem}
                            >
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        transform: 'translate(35px, -51px)',
                                    }}
                                >
                                    <div className={detailsPaymentDate}>
                                        {`Далее 6 платежей с ${paymentDateString}`}
                                    </div>
                                    <div className={detailsPaymentPrice}>
                                        {Math.floor(total / 6)} ₽ в месяц
                                    </div>
                                </Box>
                            </Box>
                            <li className={detailsPaymentItem}></li>
                            <li className={detailsPaymentItem}></li>
                            <li className={detailsPaymentItem}></li>
                            <li className={detailsPaymentItem}></li>
                            <li className={detailsPaymentItem}></li>
                        </Box>
                    </TabPanel>
                </TabContext>

                <p className="digi-dolyame-scheme__details">
                    После оформления заявки Вы можете скачать договор и график
                    платежей
                    <br />
                    Досрочное полное и частичное погашение возможно в любое
                    время
                </p>

                <p className="digi-dolyame-scheme__details">
                    {'Подробности на '}

                    <a
                        href="https://dolyame.ru"
                        target="_blank"
                        rel="nofollow noreferrer"
                    >
                        dolyame.ru
                    </a>
                </p>
            </Box>
        </Box>
    )
}
