import queryString from 'query-string'
import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import ym from 'react-yandex-metrika'

export const useHandleSearch = () => {
    const history = useHistory()

    const handleSeach = (query: string) => {
        history.push(`/search?query=${query}`)
    }

    return { handleSeach }
}

export const useSearchQuery = () => {
    const location = useLocation()
    const parsedQueryString = queryString.parse(location.search)
    const query = parsedQueryString.query

    useEffect(() => {
        if (typeof query === 'string') {
            ym('hit', window.location.href)
        }
    }, [query])

    return { query: typeof query === 'string' ? query : '' }
}
