import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'types'

import { addressesAdapter } from '.'

const { selectById } = addressesAdapter.getSelectors()

const selectDomain = (state: RootState) => state.addresses

export const selectAddressById = createSelector(
    [selectDomain],
    (state) => (id?: string) => id ? selectById(state, id) : undefined
)
