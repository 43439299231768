export const enum TopMailEventGoals {
    'basket' = 'basket',
    'payment_choice' = 'payment_choice',
}

export interface ITopMailEvent {
    id: '3265026'
    type: 'reachGoal'
    goal: TopMailEventGoals
}

export const topMailEvent = (goal: TopMailEventGoals) => {
    console.log(goal)

    const _tmr = window._tmr || (window._tmr = [])
    _tmr.push({ id: '3265026', type: 'reachGoal', goal })
}
