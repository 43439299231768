import { bannersActions } from 'app/modules/Banners/slice'
import { selectTaxonBanners } from 'app/modules/Banners/slice/selectors'
import {
    PODVESKI_SMYSLY_ID,
    POPULAR_CATEGORY_ID,
    SALE_CATEGORY_ID,
} from 'app/modules/Categories'
import {
    selectCategories,
    selectFirstDepthCategories,
} from 'app/modules/Categories/slice/selectors'
import { useRouterContext } from 'app/modules/Navigation/RouterContext'
import { isUndefined, mapValues } from 'lodash'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { ProductFilterParams, ProductSortVariants } from 'types/IProduct'

import { productsActions } from '../../../slice'
import {
    selectCategoryPageData,
    selectFiltersParamsPrices,
    selectProductsAllOptionTypes,
    selectProductsProperties,
} from '../../../slice/selectors'
import {
    ICategoryPageContext,
    ICategoryPageContextProps,
    IFilterReadAdapter,
} from './types'

export const CategoryPageContext = createContext<ICategoryPageContext>(
    {} as ICategoryPageContext
)

export function useCategoryPageContext(): ICategoryPageContext {
    const context = useContext(CategoryPageContext)

    if (context === undefined) {
        throw new Error(
            'useProductFilterContext must be used within the ProductFilterContextProvider'
        )
    }

    return context
}

export const CategoryPageContextProvider: React.FC<
    ICategoryPageContextProps
> = ({ category, children }) => {
    const dispatch = useDispatch()
    const RouterContext = useRouterContext()

    const [filterModalOpen, setFilterModalOpen] = useState(false)

    const categories = useSelector(selectCategories)

    const headerCategories = useSelector(selectFirstDepthCategories)
    const filterParamsPrices = useSelector(selectFiltersParamsPrices)

    const router = useLocation()
    const navigate = useHistory()
    const openFilterModal = () => setFilterModalOpen(true)
    const closeFilterModal = () => setFilterModalOpen(false)

    const isSaleCategory = category.id === SALE_CATEGORY_ID
    const isPopularCategory = category.id === POPULAR_CATEGORY_ID
    const isSmyslyCategory = category.id === PODVESKI_SMYSLY_ID

    const banners = useSelector(selectTaxonBanners)(category)

    const {
        sort,
        filter,
        products,
        curentPage,
        totalPages,
        totalProductsCount,
        loadProducts,
    } = useSelector(selectCategoryPageData)

    const productsOptions = useSelector(selectProductsAllOptionTypes)
    const productsProperties = useSelector(selectProductsProperties)

    const filterReadAdapter: IFilterReadAdapter = (() => {
        const filterCopy = JSON.parse(JSON.stringify(filter))

        if (filterCopy.taxons) {
            filterCopy.taxons = filterCopy.taxons.split(',')
        }
        if (filter.properties) {
            Object.keys(filterCopy.properties).map((key) => {
                filterCopy.properties[key] =
                    filterCopy.properties[key].split(',')
            })
        }
        return filterCopy
    })()

    const viewFilters = (() => {
        const result: ICategoryPageContext['viewFilters'] = []
        const category = headerCategories.find(
            (i) => i.attributes.permalink === router.pathname.slice(1)
        )

        if (filterReadAdapter.price) {
            if (
                filterReadAdapter.price[0] !== 0 &&
                filterReadAdapter.price[0] !== null &&
                filterReadAdapter.price[0] !== filterParamsPrices.min_price &&
                !category?.attributes.filters.segments.length
            ) {
                result.push({
                    displayName: `от ${filterReadAdapter.price[0]} Р`,
                    handleDeleteFilter: () => {
                        handleChangePrice({
                            priceFrom: filterParamsPrices.min_price || 0,
                        })
                    },
                })
            } else if (
                filterReadAdapter.price[0] !== 0 &&
                filterReadAdapter.price[0] !== null &&
                filterReadAdapter.price[0] !== filterParamsPrices.min_price &&
                filterReadAdapter.price[0] !==
                    category?.attributes.filters.price.min
            ) {
                result.push({
                    displayName: `от ${filterReadAdapter.price[0]} Р`,
                    handleDeleteFilter: () => {
                        handleChangePrice({
                            priceFrom: filterParamsPrices.min_price || 0,
                        })
                    },
                })
            }
            if (
                filterReadAdapter.price[1] !== 0 &&
                filterReadAdapter.price[1] !== null &&
                filterReadAdapter.price[1] !== filterParamsPrices.max_price &&
                filterReadAdapter.price[1] < filterParamsPrices.max_price &&
                !category?.attributes.filters.segments.length
            ) {
                result.push({
                    displayName: `до ${filterReadAdapter.price[1]} Р`,
                    handleDeleteFilter: () => {
                        handleChangePrice({
                            priceTo: filterParamsPrices.max_price || 0,
                        })
                    },
                })
            } else if (
                filterReadAdapter.price[1] !== 0 &&
                filterReadAdapter.price[1] !== null &&
                filterReadAdapter.price[1] !== filterParamsPrices.max_price &&
                filterReadAdapter.price[1] < filterParamsPrices.max_price &&
                filterReadAdapter.price[1] !==
                    category?.attributes.filters.price.max
            ) {
                result.push({
                    displayName: `до ${filterReadAdapter.price[1]} Р`,
                    handleDeleteFilter: () => {
                        handleChangePrice({
                            priceTo: filterParamsPrices.max_price || 0,
                        })
                    },
                })
            }
        }

        if (filterReadAdapter.available_in_current_city) {
            result.push({
                displayName: `В наличии`,
                handleDeleteFilter: () => {
                    handleAvailability()
                },
            })
        }

        if (filterReadAdapter.sale) {
            result.push({
                displayName: `Скидки`,
                handleDeleteFilter: () => {
                    handleSaleChange()
                },
            })
        }

        filterReadAdapter.taxons?.forEach((taxon) => {
            if (taxon === '1') {
                return
            }
            if (category?.attributes.filters.segments.length) {
                !category.attributes.filters.taxons.find(
                    (i) => i.toString() === taxon
                ) &&
                    result.push({
                        displayName:
                            headerCategories
                                .find((i) =>
                                    i.included.find(
                                        (item) => item?.id === taxon
                                    )
                                )
                                ?.included.find((i) => i?.id === taxon)
                                ?.attributes?.name ||
                            categories.find((i) => i.id === taxon)?.attributes
                                .name ||
                            taxon,
                        handleDeleteFilter: () => {
                            handleTaxonsChange(taxon, false)
                        },
                    })
            } else {
                result.push({
                    displayName:
                        headerCategories
                            .find((i) =>
                                i.included.find((item) => item?.id === taxon)
                            )
                            ?.included.find((i) => i?.id === taxon)?.attributes
                            ?.name ||
                        categories.find((i) => i.id === taxon)?.attributes
                            .name ||
                        taxon,
                    handleDeleteFilter: () => {
                        handleTaxonsChange(taxon, false)
                    },
                })
            }
        })

        for (const iterator in filterReadAdapter.options) {
            const optionType = productsOptions.find(
                (option) => option.presentation === iterator
            )
            filterReadAdapter.options[iterator]?.forEach((optionValueName) => {
                const option_value = optionType?.option_values.find(
                    (option_value) => option_value.name === optionValueName
                )

                if (optionType && option_value)
                    result.push({
                        displayName: `${iterator}: ${option_value.name}`,
                        handleDeleteFilter: () => {
                            handleChangeProductOption(
                                optionType,
                                option_value,
                                false
                            )
                        },
                    })
            })
        }

        for (const iterator in filterReadAdapter.properties) {
            const productProperty = productsProperties.find(
                (property) => property.presentation === iterator
            )

            filterReadAdapter.properties[iterator]?.forEach((filter_param) => {
                const propertyValue = productProperty?.values.find(
                    (propertyValue) =>
                        propertyValue.filter_param === filter_param
                )

                let hideProperty = false

                if (
                    category?.attributes.filters.segments.length &&
                    category.attributes.filters.properties.length
                ) {
                    hideProperty =
                        !!category.attributes.filters.product_properties[
                            iterator
                        ]?.find((i) => i === filter_param)
                }

                if (productProperty && propertyValue && !hideProperty)
                    result.push({
                        displayName: `${productProperty.name}: ${propertyValue.value}`,
                        handleDeleteFilter: () => {
                            handleChangeProductProperty(
                                productProperty,
                                propertyValue,
                                false
                            )
                        },
                    })
            })
        }

        // purchasable?: boolean
        // status?: string | undefined

        return result
    })()

    let segments = null
    let blacklist = null
    const currentCategory = headerCategories.find(
        (i) => i.attributes.permalink === router.pathname.slice(1)
    )

    if (currentCategory?.attributes.filters.segments.length) {
        segments = currentCategory.attributes.filters.segments
    }

    if (currentCategory?.attributes.filters.blacklist.length) {
        blacklist = currentCategory.attributes.filters.blacklist
    }

    const perPage = 32

    const mainCategory = categories.find((i) => i.id === '1')
    const newTaxons = filter?.taxons
        ?.split(',')
        .map((i) => {
            const selectedCategory = mainCategory?.categories?.find(
                (item) => item.id === i
            )

            if (!selectedCategory) return i

            const find = !!selectedCategory.relationships.children?.data.find(
                (item) => filter?.taxons?.split(',').find((i) => i === item.id)
            )

            if (find) return ' '

            return i
        })
        .filter((i) => i !== ' ')
        .join(',')

    const productsRequest: ICategoryPageContext['productsRequest'] = {
        page: curentPage,
        sort,
        segments,
        blacklist,
        filter: {
            ...filter,
            taxons: newTaxons ? newTaxons : '1',
            sale: isSaleCategory ? true : filter.sale,
            popular: isPopularCategory ? true : filter.sale,
        },
        perPage,
    }

    const handleChangeFilter = (
        name: keyof ProductFilterParams,
        value: ProductFilterParams[keyof ProductFilterParams]
    ) => {
        dispatch(
            productsActions.setCategoryPageFilter({
                ...filter,
                [name]: value,
            })
        )
    }

    const handleChangeFilters = (filters: {
        [key: string]: ProductFilterParams[keyof ProductFilterParams]
    }) => {
        dispatch(
            productsActions.setCategoryPageFilter({
                ...filter,
                ...filters,
            })
        )
    }

    const handleClearFiltersWithoutTaxons = () => {
        dispatch(
            productsActions.setCategoryPageFilter({
                taxons: filter.taxons,
            })
        )
    }

    const handleChangeProductProperty: ICategoryPageContext['handleChangeProductProperty'] =
        (property, property_value, checked) => {
            const currentProperty = filterReadAdapter.properties?.[
                property.presentation
            ]?.filter(
                (filter_param) => filter_param !== property_value.filter_param
            )

            if (!currentProperty) {
                if (checked) {
                    handleChangeFilter('properties', {
                        ...filter.properties,
                        [property.presentation]: [
                            property_value.filter_param,
                        ].join(','),
                    })
                }
                return
            }

            if (checked) {
                currentProperty.push(property_value.filter_param)
            }

            handleChangeFilter('properties', {
                ...filter.properties,
                [property.presentation]: currentProperty.length
                    ? currentProperty.join(',')
                    : undefined,
            })
        }

    const handleChangeProductOption: ICategoryPageContext['handleChangeProductOption'] =
        (optionType, option_value, checked) => {
            const currentOption = filter.options?.[
                optionType.presentation
            ]?.filter((name) => name !== option_value.name)
            if (!currentOption) {
                if (checked) {
                    handleChangeFilter('options', {
                        ...filter.options,
                        [optionType.presentation]: [option_value.name],
                    })
                }
                return
            }

            if (checked) {
                currentOption.push(option_value.name)
            }

            handleChangeFilter('options', {
                ...filter.options,
                [optionType.presentation]: currentOption,
            })
        }

    const handleChangePrice: ICategoryPageContext['handleChangePrice'] = ({
        priceFrom,
        priceTo,
    }) => {
        const finalPriceFrom = isUndefined(priceFrom)
            ? filterReadAdapter.price?.[0] || 0
            : priceFrom
        const finalPriceTo = isUndefined(priceTo)
            ? filterReadAdapter.price?.[1] || 0
            : priceTo

        if (finalPriceFrom + finalPriceTo === 0) {
            handleDeleteFilter('price')
        } else {
            handleChangeFilter('price', [finalPriceFrom, finalPriceTo])
        }
    }

    const handleChangeSort = (value: ProductSortVariants) => {
        dispatch(productsActions.setCategoryPageSort(value))
    }

    const handleAvailability = () => {
        handleChangeFilter(
            'available_in_current_city',
            !filter.available_in_current_city
        )
    }

    const handleSaleChange = () => {
        handleChangeFilter('sale', !filter.sale)
    }

    const handleTaxonsChange = (id: string, checked: boolean) => {
        const currentTaxons = filterReadAdapter.taxons?.filter(
            (taxon) => taxon !== id
        )

        if (!currentTaxons) {
            if (checked) {
                handleChangeFilter('taxons', [id].join(','))
            }
            return
        }

        if (checked) {
            currentTaxons.push(id)
        }

        if (currentTaxons.length)
            handleChangeFilter('taxons', currentTaxons.join(','))
        else handleDeleteFilter('taxons')
    }

    const handleDeleteFilter = (name: keyof ProductFilterParams) => {
        const changedFilter = {
            ...filter,
            [name]: undefined,
        }

        dispatch(productsActions.setCategoryPageFilter(changedFilter))
    }

    const handleClearFilter = () => {
        const category = headerCategories.find(
            (i) => i.attributes.permalink === router.pathname.slice(1)
        )
        if (category?.attributes.filters.segments.length) {
            dispatch(
                productsActions.setCategoryPageFilter({
                    taxons: category.attributes.filters.taxons.join(','),
                    price: category.attributes.filters.price && [
                        category.attributes.filters.price.min,
                        category.attributes.filters.price.max,
                    ],
                    properties: mapValues(
                        category.attributes.filters.product_properties,
                        (value) => value.join(',')
                    ),
                })
            )
        } else {
            if (category) {
                dispatch(
                    productsActions.setCategoryPageFilter({
                        taxons: category.attributes.filters.taxons.length
                            ? category.attributes.filters.taxons.join(',')
                            : '1',
                        ...(category.attributes.filters.price && {
                            price: [
                                category.attributes.filters.price.min || 0,
                                category.attributes.filters.price.max ||
                                    Infinity,
                            ],
                        }),
                        properties: mapValues(
                            category.attributes.filters.product_properties,
                            (value) => value.join(',')
                        ),
                    })
                )
                return
            }

            dispatch(productsActions.setCategoryPageFilter({}))
        }
    }

    useEffect(() => {
        dispatch(bannersActions.loadTaxonBanner({ taxonID: category.id }))
    }, [category.id])

    useEffect(
        function loadProductsAfterFilterChange() {
            if (loadProducts) {
                dispatch(
                    productsActions.loadCategoryPageProducts(productsRequest)
                )
            }
        },
        [JSON.stringify(filter), loadProducts]
    )

    useEffect(() => {
        const category = headerCategories.find(
            (i) => i.attributes.permalink === router.pathname.slice(1)
        )
        if (!category) {
            if (router.pathname !== '/catalog') {
                navigate.push('/')
                return
            }
            dispatch(
                productsActions.setCategoryPageFilter({
                    taxons: '1',
                })
            )
            return
        }

        const isSaleCategory = category.id === SALE_CATEGORY_ID
        const isPopularCategory = category.id === POPULAR_CATEGORY_ID

        if (isPopularCategory) {
            dispatch(
                productsActions.setCategoryPageFilter({
                    popular: true,
                })
            )
            return
        }
        if (isSaleCategory) {
            dispatch(
                productsActions.setCategoryPageFilter({
                    sale: true,
                })
            )
            return
        }

        if (category.attributes.permalink.split('/')[0] === 'catalog') {
            /**
             * При переходе на страницу каталога, если переход осуществляется назад
             * со страницы товара то список товаров, состояние скролла и фильтры остаются прежними.
             * При всех остальных переходах очищается фильтр и сбрасывается список товаров
             */
            const keepProductsAndFilter =
                RouterContext.to.startsWith('/products/') &&
                RouterContext.from.includes(category.attributes.permalink)

            if (keepProductsAndFilter) return

            dispatch(
                productsActions.setCategoryPageFilter({
                    taxons: category.attributes.filters.taxons.length
                        ? category.attributes.filters.taxons.join(',')
                        : '1',
                    ...(category.attributes.filters.price && {
                        price: [
                            category.attributes.filters.price.min || 0,
                            category.attributes.filters.price.max || Infinity,
                        ],
                    }),
                    properties: mapValues(
                        category.attributes.filters.product_properties,
                        (value) => value.join(',')
                    ),
                })
            )
        }
    }, [router, headerCategories])

    return (
        <CategoryPageContext.Provider
            value={{
                category,

                isSaleCategory,
                isPopularCategory,
                isSmyslyCategory,

                filterModalOpen,

                banners,
                products,
                curentPage,
                totalPages,
                totalProductsCount,

                perPage,

                filterReadAdapter,
                viewFilters,

                productsRequest,

                handleChangeFilter,

                openFilterModal,
                closeFilterModal,

                handleChangeProductOption,
                handleChangeProductProperty,
                handleChangePrice,
                handleClearFiltersWithoutTaxons,

                handleClearFilter,
                handleChangeFilters,
                handleChangeSort,
                handleAvailability,
                handleSaleChange,
                handleTaxonsChange,
                handleDeleteFilter,
            }}
        >
            {children}
        </CategoryPageContext.Provider>
    )
}
