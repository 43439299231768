import {
    Box,
    Button,
    Container,
    Skeleton,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { selectImageById } from 'app/modules/Images/slice/selectors'
import placeholderImage from 'assets/images/product_image_placeholder.jpg'
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import type { ProductCardProps } from '../ProductCard'
import { SaleBadget } from '../ProductCard/SaleBadget'
import { ProductLikeIcon } from '../ProductFavorite'

export const SliderProductCard: React.FC<
    ProductCardProps & { redirectUrl?: string }
> = ({ product, redirectUrl }) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'lg'))
    const imageIndex = 0
    const [isHover, setHover] = useState<boolean>(false)

    const getImage = useSelector(selectImageById)

    const images = useMemo(() => {
        const result =
            product?.relationships.images.data.reduce(
                (acc: string[], value) => {
                    const image = getImage(value.id, '1024')

                    if (!image) {
                        return acc
                    }

                    return [...acc, image]
                },
                []
            ) || []

        if (result.length === 0) {
            result.push(placeholderImage)
        }
        return result
    }, [product])

    return (
        <Link
            to={redirectUrl || `/products/${product?.attributes.slug}`}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            {product ? (
                <Box
                    sx={{
                        height: {
                            xs: 173,
                            md: '26.9vw',
                        },
                        overflow: 'hidden',
                        position: 'relative',
                    }}
                >
                    <Box
                        sx={(theme) => ({
                            height: 'inherit',
                            width: 'inherit',
                            bgcolor: '#F7F7F7',
                            transition: theme.transitions.create(
                                ['transform'],
                                {
                                    duration:
                                        theme.transitions.duration.standard,
                                }
                            ),
                            '&:hover': { transform: 'scale(1.05)' },
                        })}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                overflow: 'hidden',
                                zIndex: -1,
                            }}
                            src={images[imageIndex]}
                            component="img"
                        />
                        {!images[imageIndex] && (
                            <Skeleton
                                width="100%"
                                height="100%"
                                variant="rectangular"
                            />
                        )}
                        {redirectUrl && (
                            <Box
                                width="100%"
                                height="100%"
                                sx={{
                                    position: 'absolute',
                                    backgroundColor:
                                        'rgba(255, 255, 255, 0.85)',
                                    top: '0',
                                }}
                            >
                                {isMobile ? (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                width: '82px',
                                                textAlign: 'center',
                                                fontWeight: 500,
                                                pt: '50%',
                                            }}
                                        >
                                            Показать всё
                                        </Typography>
                                    </Box>
                                ) : (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexDirection: 'column',
                                            gap: '24px',
                                            height: '100%',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                width: '240px',
                                                textAlign: 'center',
                                                fontWeight: 500,
                                            }}
                                        >
                                            Ознакомьтесь с остальными товарами
                                        </Typography>
                                        <Button
                                            variant={'outlined'}
                                            sx={{
                                                borderRadius: '0px',
                                                width: '280px',
                                                height: '60px',
                                                color: 'black',
                                                border: '1px solid black',
                                                '&:hover': {
                                                    color: 'black',
                                                    border: '1px solid black',
                                                },
                                            }}
                                        >
                                            Показать все
                                        </Button>
                                    </Box>
                                )}
                            </Box>
                        )}
                    </Box>
                    {!redirectUrl && (
                        <>
                            <Container
                                sx={{
                                    position: 'absolute',
                                    top: {
                                        xs: '8px',
                                        md: 20,
                                    },
                                    left: {
                                        xs: '8px',
                                        md: 20,
                                    },
                                    px: { xs: 0 },
                                }}
                            >
                                <SaleBadget
                                    salePercent={
                                        product.attributes.sale_percent
                                    }
                                />
                            </Container>

                            <Box
                                sx={{
                                    position: 'absolute',
                                    bottom: {
                                        xs: 8,
                                        md: 16,
                                        lg: 20,
                                    },
                                    left: {
                                        xs: 8,
                                        md: 16,
                                        lg: 20,
                                    },
                                }}
                            >
                                <ProductLikeIcon
                                    product={product}
                                    isHover={isHover}
                                />
                            </Box>
                        </>
                    )}
                </Box>
            ) : (
                <Skeleton
                    sx={{
                        height: {
                            xs: 149,
                            md: 310,
                            lg: 420,
                            xl: 530,
                        },
                    }}
                    variant="rectangular"
                />
            )}

            {!redirectUrl && (
                <Box
                    sx={{
                        pt: 1.5,
                        pb: 0,
                        pl: 1,
                        pr: 0,
                    }}
                >
                    <Box sx={{ height: { xs: '2em', lg: '3rem' } }}>
                        <Typography
                            gutterBottom
                            sx={{
                                display: '-webkit-box',
                                overflow: 'hidden',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 2,
                            }}
                            variant={isMobile ? 'body3' : 'body2'}
                            fontWeight="medium"
                        >
                            {product ? product.attributes.name : <Skeleton />}
                        </Typography>
                    </Box>
                    <Box display={'flex'}>
                        <Typography variant={isMobile ? 'body3' : 'body2'}>
                            {product ? (
                                product.attributes.display_price
                            ) : (
                                <Skeleton />
                            )}
                        </Typography>

                        {product?.attributes.sale_percent && (
                            <Typography
                                variant={isMobile ? 'body3' : 'body2'}
                                color="grey.400"
                                sx={{
                                    textDecoration: 'line-through',
                                    ml: 1.25,
                                }}
                            >
                                {product.attributes.display_compare_at_price}
                            </Typography>
                        )}
                    </Box>
                </Box>
            )}
        </Link>
    )
}
