import {
    Box,
    Button,
    Container,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import React from 'react'

import { ReactComponent as HeartIcon } from '../assets/heart.svg'
import image_11 from '../assets/image_11.jpg'
import image_11_desktop from '../assets/image_11_desktop.jpg'

export interface FranchiseItemsOnPhotoProps {
    onButtonClick: () => void
}

export const FranchiseItemsOnPhoto: React.FC<FranchiseItemsOnPhotoProps> = ({
    onButtonClick,
}) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'lg'))

    return (
        <Box
            sx={{
                px: 0,
                height: isMobile ? '330px' : '160px',
            }}
        >
            <Box
                component={'img'}
                width={'100%'}
                src={isMobile ? image_11 : image_11_desktop}
                sx={{
                    objectFit: 'cover',
                    position: 'absolute',
                    zIndex: -1,
                    height: isMobile ? '330px' : '160px',
                }}
            />
            <Container
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    flexDirection: isMobile ? 'column' : 'row',
                    justifyContent: 'space-between',
                    gap: 3,
                    pt: { xs: 3, md: 3, lg: 6 },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: isMobile ? 'start' : 'center',
                        flexDirection: isMobile ? 'column' : 'row',
                        // justifyContent: 'space-between',
                        gap: 3,
                        // pt: { xs: 3, lg: 6 },
                    }}
                >
                    <HeartIcon />
                    <Typography
                        variant={'h5'}
                        fontWeight={400}
                        sx={{
                            textTransform: 'uppercase',
                            textAlign: 'left',
                            color: 'white',
                            maxWidth: isMobile ? '400px' : 'auto',
                        }}
                    >
                        оставьте заявку и мы ответим на все интересующие вас
                        вопросы
                    </Typography>
                </Box>
                <Button
                    variant="contained"
                    sx={{
                        borderRadius: '15px',
                        height: '60px',
                        maxWidth: isMobile ? '400px' : '280px',
                        width: '100%',
                        backgroundColor: 'white',
                        color: 'black',
                    }}
                    onClick={onButtonClick}
                >
                    Оставить заявку
                </Button>
            </Container>
        </Box>
    )
}
