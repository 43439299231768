import { useCallback, useEffect, useState } from 'react'

interface IOffsetHeaderProps {
    headerHeight: number
    reservedHeight: number
}

export const useOffsetHeader = ({
    headerHeight,
    reservedHeight,
}: IOffsetHeaderProps): number => {
    const [offsetHeader, setOffsetHeader] = useState<number>(0)
    const [lastScrollYPosition, setLastScrollYPosition] = useState<number>(
        window.scrollY
    )

    const scrollEventListener = useCallback(() => {
        const newScrollYPosition = window.scrollY

        // if scrolling from bottom to top
        if (
            newScrollYPosition < lastScrollYPosition &&
            newScrollYPosition > reservedHeight
        ) {
            setOffsetHeader(0)
        } else {
            // if scrolling down
            if (newScrollYPosition > reservedHeight) {
                setOffsetHeader(headerHeight)
                // if scrolled to the roof
            } else if (newScrollYPosition < headerHeight) {
                setOffsetHeader(0)
            }
        }

        setLastScrollYPosition(newScrollYPosition)
    }, [lastScrollYPosition])

    useEffect(() => {
        addEventListener('scroll', scrollEventListener)

        return () => {
            removeEventListener('scroll', scrollEventListener)
        }
    }, [lastScrollYPosition])

    return offsetHeader
}

export const useDefaultYState = () => {
    const [DefaultYState, setDefaultYState] = useState(window.scrollY === 0)

    const scrollEventListener = useCallback(() => {
        setDefaultYState(window.scrollY === 0)
    }, [window.scrollY === 0])

    useEffect(() => {
        addEventListener('scroll', scrollEventListener)

        return () => {
            removeEventListener('scroll', scrollEventListener)
        }
    }, [])

    return DefaultYState
}
