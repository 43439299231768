import { Box, Container } from '@mui/material'
import { MindboxTarget } from 'app/components/MinboxContactForm'
import { selectAccountWholesale } from 'app/modules/Account/slice/selectors'
import React from 'react'
import { useSelector } from 'react-redux'

import {
    ProductCardGrid,
    ProductCardListShowMoreButton,
} from '../components/ProductCardGrid'
import { ProductsFilter } from '../components/ProductsFilter'
import { ProductsFilterTopBar } from '../components/ProductsFilter/ProductsFilterTopBar'

export const ProductCardsList: React.FC = () => {
    const wholesale = useSelector(selectAccountWholesale)

    return (
        <Container sx={{ px: { xs: '0px' } }}>
            {wholesale ? (
                <ProductCardGrid />
            ) : (
                <>
                    <ProductsFilterTopBar />
                    <Box display="flex" flexDirection="row">
                        <ProductsFilter />

                        <ProductCardGrid />
                    </Box>
                </>
            )}

            <ProductCardListShowMoreButton />

            <MindboxTarget
                sx={{
                    mt: { xs: '40px', md: '50px' },
                    mb: { xs: -5, md: -20 },
                }}
                popmechanicId="57913"
            />
        </Container>
    )
}
