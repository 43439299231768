import { Box, Container } from '@mui/material'
import {
    DolyameAllowMoreItems,
    DolyameFAQ,
    DolyameHowToPay,
    DolyameMainBanner,
} from 'app/modules/DolyamePage'
import { DESKTOP_HEADER_HEIGHT } from 'app/modules/Layout/components/Header/DesktopHeader'
import React from 'react'

export const DolyamePage: React.FC = () => {
    return (
        <Box>
            <Box
                minHeight={{
                    // Этот блок нужен для отступа сверху, так как хедер находится в состоянии roll-up
                    // А не в  fixed, но замена этого состояния изменит все другие статичные страницы
                    lg: `${DESKTOP_HEADER_HEIGHT}px`,
                }}
            />
            <Container
                sx={{
                    mt: { xs: '32px', md: '80px' },
                    px: { xs: '16px', md: '45px' },
                }}
            >
                <DolyameMainBanner />
            </Container>
            <Container
                sx={{
                    my: { xs: '50px', md: '120px' },
                    px: { xs: '16px', md: '45px' },
                }}
            >
                <DolyameAllowMoreItems />
            </Container>
            <Container
                sx={(theme) => ({
                    py: { xs: '50px', md: '80px' },
                    px: { xs: '16px', md: '45px' },
                    backgroundColor: `${theme.palette.primary.main}14`,
                })}
            >
                <DolyameHowToPay />
            </Container>
            <Container
                sx={{
                    my: { xs: '50px', md: '120px' },
                    px: { xs: '16px', md: 'auto' },
                }}
            >
                <DolyameFAQ />
            </Container>
        </Box>
    )
}
