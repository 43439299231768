import { Grid } from '@mui/material'
import { selectPagesSort } from 'app/modules/Articles/slice/selectors'
import React from 'react'
import { useSelector } from 'react-redux'

import { ArticleAdCard } from '../../ArticleAdCard'
import { ArticleBigCard } from '../../ArticleBigCard'
import { ArticleCard } from '../../ArticleCard'

export const DesktopArticleCatList: React.FC = () => {
    const pages = useSelector(selectPagesSort)

    return (
        <Grid container mt={2} spacing={4}>
            <Grid item xs={12} lg={6} sx={{ position: 'relative' }}>
                {pages && pages[0] && <ArticleBigCard page={pages[0]} />}
            </Grid>
            <Grid item xs={12} lg={6}>
                <Grid container spacing={4}>
                    <Grid item xs={12} lg={6}>
                        {pages
                            .filter((item, index) => index > 0)
                            .filter((item, index) => index % 2 == 0)
                            .map((page, index) => (
                                <ArticleCard
                                    key={index}
                                    page={page}
                                    photoLayout={'vertical'}
                                />
                            ))}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <ArticleAdCard />
                        {pages
                            .filter((item, index) => index > 0)
                            .filter((item, index) => index % 2 == 1)
                            .map((page, index) => (
                                <ArticleCard
                                    key={index}
                                    page={page}
                                    photoLayout={'vertical'}
                                />
                            ))}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
