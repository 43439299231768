import { countriesActions } from 'app/modules/Countries/slice'
import { imagesActions } from 'app/modules/Images/slice'
import { shopsActions } from 'app/modules/Shops/slice'
import { call, put, takeLatest } from 'redux-saga/effects'
import { ICityCollectionResponse } from 'types/ICity'
import { ICountry } from 'types/ICountry'
import { IShop } from 'types/IShop'
import { request } from 'utils/request'

import { citiesActions } from '.'

export function* loadCities() {
    try {
        const response: ICityCollectionResponse = yield call(
            request,
            `/api/v2/storefront/cities?include=shops,shops.image,country&per_page=500 `
        )

        const countries = response.included.filter(
            (value): value is ICountry => value.type === 'country'
        )

        const shops = response.included.filter(
            (value): value is IShop => value.type === 'shop'
        )

        yield put(imagesActions.imagesAdded(response))
        yield put(countriesActions.countriesAdded(countries))
        yield put(shopsActions.shopsAdded(shops))
        yield put(citiesActions.citiesLoaded(response))
    } catch (error: any) {
        yield put(citiesActions.statusError())
    }
}

export function* citiesWatcher() {
    yield takeLatest(citiesActions.loadCities.type, loadCities)
}
