import {
    createEntityAdapter,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit'
import { IMultiverse, MultiverseTypes } from 'types/IMultiverse'
import { ResourceObject, Response } from 'utils/json-api'
import { readonlyArrayIncludes } from 'utils/readonlyArrayincIudes'

import { MultiverseState } from './types'

export const multiverseAdapter = createEntityAdapter<IMultiverse>()

const slice = createSlice({
    name: 'multiverse',
    initialState: multiverseAdapter.getInitialState<MultiverseState>({
        ids: [],
        entities: {},
    }),
    reducers: {
        multiverseOjectsAdded(
            state,
            action: PayloadAction<
                Response<
                    ResourceObject | ResourceObject[],
                    (IMultiverse | ResourceObject)[]
                >
            >
        ) {
            const multiverseOjects = action.payload.included.filter(
                (item): item is IMultiverse =>
                    readonlyArrayIncludes(MultiverseTypes, item.type)
            )

            multiverseAdapter.setMany(state, multiverseOjects)
        },
    },
})

export const { actions: multiverseActions, reducer: multiverseReducer } = slice
