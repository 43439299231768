import { Box, Typography, useTheme } from '@mui/material'
import { selectAccountWholesale } from 'app/modules/Account/slice/selectors'
import { selectCurrentCity } from 'app/modules/Cities/slice/selectors'
import { HeaderColorVariant } from 'app/modules/Layout/types/HeaderColorVariant'
import React from 'react'
import { useSelector } from 'react-redux'

import { ReactComponent as LocationIconBlack } from '../../../assets/location_icon_black.svg'
import { ReactComponent as LocationIcon } from '../../../assets/location_icon_white.svg'
import { useHeaderContext } from '../HeaderContextProvider'

interface HeaderLocationSwitchProps {
    size?: 'small' | 'large'
    variant: HeaderColorVariant
}

export const HeaderLocationSwitch: React.FC<HeaderLocationSwitchProps> = ({
    size,
    variant,
}) => {
    const { isCityOpened, cityOpen } = useHeaderContext()
    const wholesale = useSelector(selectAccountWholesale)
    const theme = useTheme()

    const colorByVariant = (() => {
        switch (variant) {
            case 'dark':
                return theme.palette.text.primary
            case 'light':
                return theme.palette.common.white
            default:
                return ''
        }
    })()

    const city = useSelector(selectCurrentCity)

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: wholesale ? 'auto' : 'pointer',
                }}
                onClick={() => {
                    if (!wholesale) cityOpen?.()
                }}
            >
                {variant === 'dark' ? <LocationIconBlack /> : <LocationIcon />}

                <Typography
                    sx={(theme) => ({
                        ml: '5px',
                        color: isCityOpened
                            ? theme.palette.primary.main
                            : colorByVariant,
                    })}
                    fontWeight={500}
                    variant={
                        (size === 'large' && 'body2') ||
                        (size === 'small' && 'body3') ||
                        'body3'
                    }
                >
                    {city?.attributes.name}
                </Typography>
            </Box>
        </>
    )
}
