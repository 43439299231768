import { LoadingButton } from '@mui/lab'
import { InputAdornment, TextField, Typography, useTheme } from '@mui/material'
import { selectAccountBonuses } from 'app/modules/Account/slice/selectors'
import { checkoutActions } from 'app/modules/Checkout/slice'
import { selectLoyaltyData } from 'app/modules/Checkout/slice/selectors'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'

import { ICartProps, IControlActive } from '../types'

interface IBonusesControl {
    controlActive: IControlActive
    maxBonusesAmount: number
}

export const BonusesControl: React.FC<IBonusesControl & ICartProps> = ({
    controlActive,
    maxBonusesAmount,
}) => {
    const { isActive, reason } = controlActive
    const theme = useTheme()
    const dispatch = useDispatch()

    const { data, loading } = useSelector(selectLoyaltyData)
    const accountBonuses = useSelector(selectAccountBonuses)

    const [textForLabel, setTextForLabel] = useState<string>(
        'Бонусных рублей к списанию'
    )
    const [isShowButton, setIsShowButton] = useState<boolean>(false)
    enum captions {
        apply = 'Списать',
        cancel = 'Отменить',
    }
    const [buttonCaption, setButtonCaption] = useState(captions.apply)

    const validationSchema = yup.object({
        bonuses: yup
            .number()
            .min(0)
            .max(
                maxBonusesAmount,
                `Вы можете списать не более ${maxBonusesAmount} б.р.`
            ),
    })

    const formik = useFormik({
        validationSchema,
        initialValues: { bonuses: data.bonuses },
        validateOnBlur: true,
        validateOnChange: true,
        enableReinitialize: true,
        onSubmit: () => {
            //
        },
    })

    const handleBonuses = () => {
        if (buttonCaption === captions.cancel) {
            dispatch(checkoutActions.applyBonuses(0))
        }
        if (buttonCaption === captions.apply && !formik.errors.bonuses) {
            dispatch(checkoutActions.applyBonuses(formik.values.bonuses))
        }
    }

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const value = parseInt(e.target.value.replace(/[^0-9]/g, ''))

        if (isNaN(value) || value < 1) {
            setIsShowButton(false)
            formik.setFieldValue('bonuses', 0)
            return
        }

        formik.setFieldValue('bonuses', value)
        if (data.bonuses === value) {
            setButtonCaption(captions.cancel)
        } else {
            setButtonCaption(captions.apply)
        }

        setIsShowButton(value <= maxBonusesAmount)
    }

    useEffect(() => {
        if (data.bonuses > 0) {
            formik.setErrors({})
            setTextForLabel('Успешно списаны')
            setIsShowButton(true)
            setButtonCaption(captions.cancel)
        } else {
            if (accountBonuses === 0) {
                setTextForLabel('У вас нет бонусных рублей')
            } else {
                setTextForLabel('Бонусных рублей к списанию')
            }
            setIsShowButton(false)
        }
    }, [data, accountBonuses])

    return (
        <>
            <Typography
                variant="body2"
                color={
                    isActive
                        ? theme.palette.text.primary
                        : theme.palette.grey[400]
                }
                fontWeight={500}
            >
                Списать бонусные рубли
            </Typography>

            <TextField
                disabled={!isActive}
                fullWidth
                variant="standard"
                label={isActive ? formik.errors.bonuses || textForLabel : ' '}
                name="bonuses"
                value={
                    isActive
                        ? formik.values.bonuses === 0
                            ? ''
                            : formik.values.bonuses
                        : reason
                }
                error={!!formik.errors.bonuses}
                onChange={handleChange}
                sx={{
                    '& label.Mui-focused': {
                        color: formik.errors.bonuses
                            ? 'red'
                            : theme.palette.text.secondary,
                    },
                }}
                InputLabelProps={{
                    sx: {
                        color: isActive
                            ? theme.palette.text.secondary
                            : theme.palette.grey[400],
                    },
                }}
                InputProps={{
                    endAdornment: (
                        <>
                            {isActive && isShowButton && (
                                <InputAdornment position="start">
                                    <LoadingButton
                                        loading={loading}
                                        onClick={handleBonuses}
                                        sx={{
                                            textTransform: 'none',
                                            color:
                                                buttonCaption === captions.apply
                                                    ? theme.palette.primary.main
                                                    : theme.palette.text
                                                          .disabled,
                                            fontWeight: 400,
                                        }}
                                    >
                                        {buttonCaption}
                                    </LoadingButton>
                                </InputAdornment>
                            )}
                        </>
                    ),
                }}
            />
        </>
    )
}
