import { Box, IconButton, Stack, Typography } from '@mui/material'
import { DeleteIcon } from 'app/components/Icons/DeleteIcon'
import React from 'react'
import { ILineItem } from 'types/ILineItem'

import { QuantityControl } from '../../QuantityControl'

interface MobileOrderItemProps {
    item: ILineItem
    image?: string
    handleDelete: () => void
}

export const MobileOrderItem: React.FC<MobileOrderItemProps> = ({
    item,
    image,
    handleDelete,
}) => {
    return (
        <Stack spacing={1.5} direction="row">
            <Box
                sx={{
                    width: 50,
                    height: 50,
                    flexShrink: 0,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundImage: `url(${image || ''})`,
                }}
            />
            <Box
                sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                    }}
                >
                    <Stack spacing={0.5}>
                        <Typography variant="body3">
                            {item.attributes.name}
                        </Typography>

                        <Typography variant="body3">
                            {item.attributes.options_text}
                        </Typography>
                    </Stack>

                    <IconButton
                        sx={{ mt: -1, mr: -1, ml: 1 }}
                        onClick={handleDelete}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Box>

                <Box
                    sx={{
                        mt: 0.5,
                        display: 'flex',
                        flexGrow: 1,
                        alignItems: 'flex-end',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography sx={{ fontWeight: 'medium' }} variant="body2">
                        {item.attributes.display_total}
                    </Typography>

                    <QuantityControl item={item} />
                </Box>
            </Box>
        </Stack>
    )
}
