import { Box, Typography } from '@mui/material'
import {
    selectActivePaymentMethodId,
    selectLoyaltyData,
} from 'app/modules/Checkout/slice/selectors'
import { LoyaltyPromoType } from 'app/modules/Checkout/slice/types'
import React from 'react'
import { useSelector } from 'react-redux'
import { ShippingMethodType } from 'types/ICustomerInformation'
import { PaymentMethodId } from 'types/IPaymentsShipmentsAttributes'

import { OrderDetailDeliveryInformationProps, OrderDetailProps } from '.'
import { OrderDetailItemCount } from './OrderDetailItemCount'

export const OrderDetailSubSummary: React.FC<
    OrderDetailProps & OrderDetailDeliveryInformationProps
> = ({ cart, showDeliveryInformation }) => {
    const {
        data: { whichPromoUsed, bonuses },
    } = useSelector(selectLoyaltyData)
    const paymentMethodId = useSelector(selectActivePaymentMethodId)
    const { loyalty_data, mindbox_data } = cart.attributes

    const hideEarnedBonuses =
        cart.attributes.delivery_state === ShippingMethodType.pickup &&
        paymentMethodId === PaymentMethodId.onPickup

    return (
        <Box py="30px" borderBottom="1px solid #DEDEDE">
            <Box mt={2.5} display={'flex'} justifyContent={'space-between'}>
                <OrderDetailItemCount
                    itemCount={Number(cart.attributes.item_count)}
                />

                <Typography
                    variant="body1"
                    fontWeight="medium"
                    sx={{ fontSize: { xs: '18px', md: '14px' } }}
                >
                    {cart.attributes.display_item_total}
                </Typography>
            </Box>

            {showDeliveryInformation && (
                <Box mt={2.5} display={'flex'} justifyContent={'space-between'}>
                    <Typography
                        variant="body1"
                        sx={{ fontSize: { xs: '18px', md: '14px' } }}
                    >
                        Стоимость доставки
                    </Typography>

                    <Typography
                        variant="body1"
                        color={
                            Number(cart.attributes.ship_total)
                                ? 'text.primary'
                                : 'primary'
                        }
                        fontWeight="medium"
                        sx={{ fontSize: { xs: '18px', md: '14px' } }}
                    >
                        {Number(cart.attributes.ship_total)
                            ? cart.attributes.display_ship_total
                            : 'Бесплатно'}
                    </Typography>
                </Box>
            )}

            {!!mindbox_data?.items_discount && (
                <Box mt={2.5} display={'flex'} justifyContent={'space-between'}>
                    <Typography
                        variant="body1"
                        sx={{ fontSize: { xs: '18px', md: '14px' } }}
                    >
                        Полная скидка
                    </Typography>

                    <Typography
                        variant="body1"
                        color="error"
                        fontWeight="medium"
                        overflow="visible"
                        whiteSpace="nowrap"
                        sx={{ fontSize: { xs: '18px', md: '14px' } }}
                    >
                        -{mindbox_data.items_discount}
                    </Typography>
                </Box>
            )}

            {!!bonuses && (
                <Box mt={2.5} display={'flex'} justifyContent={'space-between'}>
                    <Typography
                        variant="body1"
                        sx={{ fontSize: { xs: '18px', md: '14px' } }}
                    >
                        {whichPromoUsed === LoyaltyPromoType.bonuses
                            ? 'Оплата бонусными рублями'
                            : 'Промокод'}
                    </Typography>

                    <Typography
                        variant="body1"
                        color="error"
                        fontWeight="medium"
                        overflow="visible"
                        whiteSpace="nowrap"
                        sx={{ fontSize: { xs: '18px', md: '14px' } }}
                    >
                        -{bonuses}
                    </Typography>
                </Box>
            )}

            {!hideEarnedBonuses && !!loyalty_data?.earned_bonuses && (
                <Box mt={2.5} display={'flex'} justifyContent={'space-between'}>
                    <Typography
                        variant="body1"
                        sx={{ fontSize: { xs: '18px', md: '14px' } }}
                    >
                        Начисляемые бонусы
                    </Typography>

                    <Typography
                        variant="body1"
                        color="primary"
                        fontWeight="medium"
                        overflow="visible"
                        whiteSpace="nowrap"
                        sx={{ fontSize: { xs: '18px', md: '14px' } }}
                    >
                        {loyalty_data.earned_bonuses}
                    </Typography>
                </Box>
            )}
        </Box>
    )
}
