import {
    COUNTRY_CODE_PRICE,
    DISCOUNT_LEVEL,
    ORDER_STATE,
    SHOP_KIND_NAMES,
} from 'app/constants'
import { ECountryCode, ShopKind, TOrderState } from 'types'

export const convertCountryCodePrice = (countryCode: ECountryCode) => {
    return COUNTRY_CODE_PRICE[countryCode as ECountryCode]
}

export const convertShopKind = (kind: ShopKind) => {
    return SHOP_KIND_NAMES[kind as ShopKind]
}

export const convertOrderState = (state: keyof typeof TOrderState) => {
    return ORDER_STATE[state]
}

export const convertDiscountLevel = (level: number) => {
    return DISCOUNT_LEVEL[level] || ''
}
