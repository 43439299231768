import { useCategoryPageContext } from 'app/modules/Products/components/ProductsFilter/hooks'
import React from 'react'
import { Helmet } from 'react-helmet-async'

export function getCategoryMeta(name: string) {
    let title = ''
    let description = ''

    switch (name) {
        case 'Новинки':
            title =
                'Трендовые ювелирные изделия купить в официальном интернет-магазине Island Soul'
            description =
                'Новинки ювелирных изделий по цене от 1600 рублей c доставкой по всей России'
            break
        case 'Кольца':
            title =
                'Кольца из серебра купить в официальном интернет-магазине Island Soul'
            description =
                'Купить украшения из серебра с острова Бали. Кольца по цене от 1000 рублей c доставкой по всей России'
            break
        case 'Серьги':
            title =
                'Серебряные серьги купить в официальном интернет-магазине Island Soul'
            description =
                'Купить украшения из серебра с острова Бали. Серьги по цене от 1600 рублей c доставкой по всей России'
            break
        case 'Бодичейны':
            title =
                'Бодичейны купить в официальном интернет-магазине Island Soul'
            description =
                'Купить бодичейн из серебра по цене от 17100 рублей c доставкой по всей России'
            break
        case 'Галстуки':
            title =
                'Серебряный галстук купить в официальном интернет-магазине Island Soul'
            description =
                'Купить украшения из серебра с острова Бали. Колье галстук из серебра по цене от 7300 рублей c доставкой по всей России'
            break
        case 'Браслеты':
            title =
                'Серебряные браслеты купить в официальном интернет-магазине Island Soul'
            description =
                'Купить украшения из серебра. Браслеты женские по цене от 4100 рублей c доставкой по всей России '
            break
        case 'Колье':
            title = 'Колье купить в официальном интернет-магазине Island Soul'
            description =
                'Купить колье из серебра. Чокеры по цене от 4130 рублей c доставкой по всей России'
            break
        case 'Подвески':
            title =
                'Серебряные подвески купить в официальном интернет-магазине Island Soul'
            description =
                'Купить украшения из серебра. Кулоны по цене от 1330 рублей c доставкой по всей России '
            break
        case 'Home':
            title =
                'Украшения для дома купить в официальном интернет-магазине Island Soul'
            description =
                'Купить украшения для дома. Свечи, кристаллы, декор по цене от 220 руб. с доставкой по всей России'
            break
        case 'Одежда':
            title =
                'Дизайнерская одежда российских брендов в официальном интернет-магазине Island Soul'
            description =
                'Купить дизайнерские платья, футболки, рубашки по цене от 2700 руб. с доставкой по всей России'
            break
        case 'Последний шанс':
            title =
                'Ювелирные украшения со скидкой купить в официальном интернет-магазине Island Soul'
            description =
                'Серебряные украшения купить недорого. Ювелирные изделия по цене от 700 руб.с доставкой по всей России'
            break
        default:
            break
    }

    return { title, description }
}

export const CategoryMeta: React.FC = () => {
    const { category } = useCategoryPageContext()

    const { title, description } = getCategoryMeta(category.attributes.name)

    if (!title) return null

    return (
        <Helmet title={title}>
            <meta name="description" content={description} />
        </Helmet>
    )
}
