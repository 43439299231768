import { Box, Container } from '@mui/material'
import GridStaticRow, { GridStaticRowProps } from 'app/components/GridStaticRow'
import React from 'react'

export const HistoryTable: React.FC = () => {
    const grids: GridStaticRowProps[] = [
        {
            image: '/assets/images/Rectangle_1132_his1.jpg',
            title: 'Первое производство на острове Бали и заказ партии первого украшения',
            description:
                'Самое первое украшение основательница бренда Дарья Пашкевич хотела сделать для себя. То, о чём она долго мечтала и записала в свой виш-лист никак не могла найти в магазинах, поэтому заказала на производстве, но там объявили минимальную партию. Тогда Дарья приобрела себе первое украшение с острова Бали, а остальное решила продать через инстаграм. Это и стало отправной точкой в её предпринимательской деятельности.',
        },
        {
            image: '/assets/images/Rectangle_1132_his2.jpg',
            title: 'Украшение, положившее начало истории island soul',
            description:
                '12 января 2016 года - первый пост в Инстаграм с украшением, положившим начало истории Island Soul - кулон в виде острова Бали',
        },
        {
            image: '/assets/images/Rectangle_1132_his3.jpg',
            title: 'Дарья Пашкевич основала компанию Island Soul в Индонезии на острове Бали',
            description:
                'Дарья Пашкевич о названии бренда: «В моем Instagram я писала пост о том, что изначально у меня был ник “Is possible”, что в переводе с английского значит “все возможно”. Так и есть.... Я уехала на Бали, встретила отца своих детей, стала мамой двух замечательных девочек, увлеклась йогой и даже начала её преподавать. Когда я стала yoga-teacher, то изменила название аккаунта на yogainbali, а потом уже на Daria_Soul, ведь в первую очередь не у тела есть душа, а у души есть тело.<br /> Я в первую очередь душа, поэтому в бренд я вложила свои внутренние ощущения. <br />Так и получилось название “Island Soul” - душа острова.» <br /><br />В этом же году была запущена доставка по острову.',
        },
        {
            image: '/assets/images/Rectangle_1132_his4.jpg',
            title: 'Открытие первого шоурума в Москве на Белорусской',
            description: '',
        },
        {
            image: '/assets/images/Rectangle_1132_his5.jpg',
            title: 'Открытие бутика на острове Бали в Чангу',
            description: '',
        },
        {
            image: '/assets/images/Rectangle_1132_his6.jpg',
            title: 'Открытие флагманского бутика Dom Island Soul в Москве на Садовом кольце',
            description: `23 августа 2019 года, в день Рождения основательницы бренда - Дарьи, 
            мы открыли большой магазин в центре Москвы на Садовом кольце и назвали его D.O.M. 
            (Первый среди Богов) Island Soul. И это действительно дом для большой семьи - всех, кто причастен к Island Soul. 
            Он стал оазисом спокойствия, тепла и умиротворения посреди мегаполиса. Здесь мы проводим встречи, 
            мастер-классы и праздники для клиентов. Это место, куда хочется возвращаться всем: и нашей команде, и нашим покупателям. 
            Появление D.O.M. - новый виток в истории бренда. Мы поняли, что ценность бренда заключена не только в украшениях и той энергии, 
            которую они таят, а она гораздо больше и глубже – в том, что мы можем менять жизни людей!<br /><br />
            За неделю до открытия D.O.M в 2019 году мы решились продать первую франшизу,
            чтобы ещё одна единомышленница смогла изменить свою жизнь, реализоваться и сделать мир прекраснее.`,
        },
        {
            image: '/assets/images/Rectangle_1132_his7.jpg',
            title: 'Открытие флагманского бутика Island Soul в городе Сочи на Парковой',
            description:
                'Открытие флагманского бутика Island Soul в городе Сочи на Парковой, а также 30 бутиков и островов в ТЦ по франшизе',
        },
        {
            image: '/assets/images/Rectangle_1132_his8.jpg',
            title: 'Открыто более 70 бутиков и островов в ТЦ по франшизе',
            description:
                'Открыто более 70 бутиков и островов в ТЦ по франшизе и 5 собственных точек, в том числе 3 флагманских бутика',
        },
        {
            image: '/assets/images/Rectangle_1132_his9.jpg',
            title: 'А дальше – больше',
            description: `Развитие ISLAND SOUL – это пример того, как синергия команды и идеи может заставлять мечты сбываться. 
            Присоединяйтесь к нам - как покупатель, сотрудник, партнер или даже йога-тичер – и вы получите много больше, 
            чем ожидаете от простого украшения. <br /><br />Island Soul в душе, навсегда.`,
        },
    ]

    return (
        <Container disableGutters>
            <Box mt={11}>
                {grids.map((item, index) => (
                    <GridStaticRow
                        key={index}
                        image={item.image}
                        title={item.title}
                        description={item.description}
                        rightSide={index % 2 === 1}
                    />
                ))}
            </Box>
        </Container>
    )
}
