import { PayloadAction } from '@reduxjs/toolkit'
import { call, put, takeLatest } from 'redux-saga/effects'
import { request } from 'utils/request'

import { giftFormActions } from '.'
import { GiftFormAttributes } from './types'

export function* sendGift(action: PayloadAction<GiftFormAttributes>) {
    try {
        yield call(request, `/api/v2/storefront/gift`, {
            method: 'POST',
            data: action.payload,
        })

        yield put(giftFormActions.giftFormSended())
    } catch (error: any) {
        //
    }
}

export function* giftFormWatcher() {
    yield takeLatest(giftFormActions.sendGiftForm.type, sendGift)
}
