import { Typography } from '@mui/material'
import React from 'react'

import { ProductDetailComponentProps } from '.'

export const ProductSKU: React.FC<ProductDetailComponentProps> = ({
    product,
}) => {
    return (
        <Typography color="text.disabled" variant={'body2'}>
            Артикул {product.attributes.sku}
        </Typography>
    )
}
