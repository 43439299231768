import { selectImageById } from 'app/modules/Images/slice/selectors'
import { selectVideoById } from 'app/modules/Videos/slice/selectors'
import placeholderImage from 'assets/images/product_image_placeholder.jpg'
import { useSelector } from 'react-redux'
import { IProduct } from 'types/IProduct'

import { IProductVisualContent } from '../slice/types'

export const useProductVisualContent = (product?: IProduct) => {
    const getImage = useSelector(selectImageById)
    const getVideo = useSelector(selectVideoById)

    if (!product)
        return {
            productVisualContent: [],
            productVideoContent: [],
            productImageContent: [],
        }

    const productVisualContent: IProductVisualContent[] = []

    const productVideoContent = product.relationships.videos.data.reduce(
        (acc: IProductVisualContent[], value) => {
            const video = getVideo(value.id)

            if (!video) {
                return acc
            }

            const mini = getImage(video.relationships.preview.data?.id, '612')
            const placeholder = getImage(
                video.relationships.preview.data?.id,
                '2048'
            )

            return [
                ...acc,
                {
                    videoSrc: video.attributes.attachment_url,
                    mini,
                    placeholder,
                },
            ]
        },
        []
    )

    const productImageContent = product.relationships.images.data.reduce(
        (acc: IProductVisualContent[], value) => {
            const image: IProductVisualContent = {
                mini: getImage(value.id, '612'),
                middle: getImage(value.id, '1024'),
                large: getImage(value.id, '2048'),
            }

            if (!image) {
                return acc
            }

            return [...acc, image]
        },
        []
    )

    productVisualContent.push(...productVideoContent)
    productVisualContent.push(...productImageContent)

    if (productVisualContent.length === 0) {
        productVisualContent.push({
            mini: placeholderImage,
            middle: placeholderImage,
            large: placeholderImage,
        })
    }
    return { productVisualContent, productVideoContent, productImageContent }
}
