import { giftFormWatcher } from 'app/features/GiftForm/slice/saga'
import { accountWatcher } from 'app/modules/Account/slice/saga'
import { activeStorageBlobWatcher } from 'app/modules/ActiveStorageBlob/slice/saga'
import { pagesWatcher } from 'app/modules/Articles/slice/saga'
import { authWatcher } from 'app/modules/Auth/slice/saga'
import { bannersWatcher } from 'app/modules/Banners/slice/saga'
import { cartWatcher } from 'app/modules/Cart/slice/saga'
import { categoriesWatcher } from 'app/modules/Categories/slice/saga'
import { checkoutWatcher } from 'app/modules/Checkout/slice/saga'
import { citiesWatcher } from 'app/modules/Cities/slice/saga'
import { offlineTradeWatcher } from 'app/modules/OfflineTrade/slice/saga'
import { ordersWatcher } from 'app/modules/Order/slice/saga'
import { productsWatcher } from 'app/modules/Products/slice/saga'
import { searchWatcher } from 'app/modules/Search/slice/saga'
import { all } from 'redux-saga/effects'

export default function* rootSaga() {
    yield all([
        accountWatcher(),
        activeStorageBlobWatcher(),
        authWatcher(),
        bannersWatcher(),
        cartWatcher(),
        categoriesWatcher(),
        checkoutWatcher(),
        citiesWatcher(),
        giftFormWatcher(),
        offlineTradeWatcher(),
        ordersWatcher(),
        pagesWatcher(),
        productsWatcher(),
        searchWatcher(),
    ])
}
