import { PayloadAction } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { ECountryCode } from 'types'
import { ICustomer } from 'types/ICustomer'
import { request } from 'utils/request'

import { offlineTradeActions } from '.'
import { selectCountryCode } from './selectors'
import { ICustomerData, IOrderData } from './types'

export function* getLoyalty(action: PayloadAction<string>) {
    try {
        const countryCode: ECountryCode = yield select(selectCountryCode)

        const response: ICustomer = yield call(
            request,
            `/api/v2/storefront/offline_trade/get_loyalty`,
            {
                params: {
                    phone: action.payload,
                },
                headers: {
                    'X-Spree-Country-Code': countryCode,
                },
            }
        )

        yield put(offlineTradeActions.loyaltyLoaded(response))
    } catch (error: any) {
        toast.error(
            error.data.error_description ||
                error.data.error ||
                'Что-то пошло не так',
            {
                type: 'error',
                theme: 'colored',
                position: 'top-center',
                hideProgressBar: true,
            }
        )
        yield put(offlineTradeActions.loyaltyGetError(action.payload))
    }
}

export function* sendSms(action: PayloadAction<string>) {
    try {
        const countryCode: ECountryCode = yield select(selectCountryCode)

        const response: ICustomer = yield call(
            request,
            `/api/v2/storefront/offline_trade/get_loyalty`,
            {
                params: {
                    phone: action.payload,
                },
                headers: {
                    'X-Spree-Country-Code': countryCode,
                },
            }
        )

        yield put(offlineTradeActions.loyaltyLoaded(response))
    } catch (error: any) {
        yield put(offlineTradeActions.loyaltyGetError(action.payload))
    }
}

export function* createCustomer(action: PayloadAction<ICustomerData>) {
    try {
        const countryCode: ECountryCode = yield select(selectCountryCode)

        const response: ICustomer = yield call(
            request,
            `/api/v2/storefront/offline_trade/create_customer`,
            {
                method: 'POST',
                data: action.payload,
                headers: {
                    'X-Spree-Country-Code': countryCode,
                },
            }
        )

        yield put(offlineTradeActions.customerCreated(response))
    } catch (error: any) {
        toast.error(
            error.data.error_description ||
                error.data.error ||
                'Что-то пошло не так',
            {
                type: 'error',
                theme: 'colored',
                position: 'top-center',
                hideProgressBar: true,
            }
        )
        yield put(offlineTradeActions.createCustomerError())
    }
}

export function* createOrder(action: PayloadAction<IOrderData>) {
    try {
        const countryCode: ECountryCode = yield select(selectCountryCode)

        yield call(request, `/api/v2/storefront/offline_trade/create_order`, {
            method: 'POST',
            data: action.payload,
            headers: {
                'X-Spree-Country-Code': countryCode,
            },
        })

        yield put(offlineTradeActions.orderCreated())
    } catch (error: any) {
        toast.error(
            error.data.error_description ||
                error.data.error ||
                'Что-то пошло не так',
            {
                type: 'error',
                theme: 'colored',
                position: 'top-center',
                hideProgressBar: true,
            }
        )
        yield put(offlineTradeActions.createOrderError())
    }
}

export function* offlineTradeWatcher() {
    yield takeLatest(offlineTradeActions.getLoyalty.type, getLoyalty)
    yield takeLatest(offlineTradeActions.sendSms.type, sendSms)
    yield takeLatest(offlineTradeActions.createCustomer.type, createCustomer)
    yield takeLatest(offlineTradeActions.createOrder.type, createOrder)
}
