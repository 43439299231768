import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'types'

import { PODVESKI_SMYSLY_ID, POPULAR_CATEGORY_ID } from '..'
import { categoriesAdapter } from '.'

const { selectAll, selectById } = categoriesAdapter.getSelectors()

const selectDomain = (state: RootState) => state.categories

export const selectStatus = createSelector(
    [selectDomain],
    (state) => state.status
)

export const selectCategories = createSelector([selectDomain], (state) =>
    selectAll(state)
)

export const selectCategoryById = createSelector(
    [selectDomain],
    (state) => (id?: string) => id ? selectById(state, id) : undefined
)

export const selectFirstDepthCategories = createSelector(
    [selectDomain],
    (state) => {
        return [
            ...selectAll(state).filter((value) => value.attributes.depth === 1),
        ].sort((a, b) => {
            return a.attributes.position - b.attributes.position
        })
    }
)

export const selectHeaderCategories = createSelector(
    [selectDomain],
    (state) => {
        return [
            ...selectAll(state).filter(
                (value) =>
                    value.attributes.depth === 1 &&
                    value.id !== POPULAR_CATEGORY_ID &&
                    value.id !== PODVESKI_SMYSLY_ID
            ),
        ].sort((a, b) => {
            return a.attributes.position - b.attributes.position
        })
    }
)

export const selectMainCategories = createSelector([selectDomain], (state) => {
    return [...selectAll(state).filter((value) => value.attributes.for_block)]
})

export const selectCategoryByPermalink = createSelector(
    [selectDomain],
    (state) => (permalink: string) =>
        selectAll(state).find(
            (value) => value.attributes.permalink === permalink
        )
)

export const selectChildrenCategories = createSelector(
    [selectDomain],
    (state) => (parentId?: string) =>
        selectAll(state).filter(
            (category) =>
                parentId && category.relationships.parent?.data?.id === parentId
        )
)
