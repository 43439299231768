import { AccountModal } from 'app/modules/Account/templates/AccountModal'
import { authActions } from 'app/modules/Auth/slice'
import {
    selectAuthStatus,
    selectAuthTokenData,
    selectModalStatus,
    selectSigninForm,
} from 'app/modules/Auth/slice/selectors'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { AuthModal } from './AuthModal'

export const Auth: React.FC = ({ children }) => {
    const dispatch = useDispatch()
    const history = useHistory()

    const authTokenData = useSelector(selectAuthTokenData)

    const { referrer_url } = useSelector(selectSigninForm)
    const modalStatus = useSelector(selectModalStatus)

    const { authInitial, isAuthorized } = useSelector(selectAuthStatus)

    useEffect(() => {
        if (!authInitial) return

        if (authTokenData.exsist) {
            if (authTokenData.millisecondsToExpire < 0) {
                dispatch(
                    authActions.refreshToken({
                        refresh_token: authTokenData.refresh_token,
                        loadAccount: true,
                    })
                )
            } else {
                dispatch(authActions.loadAccount())
            }
        } else {
            dispatch(authActions.setNotAuthorized())
        }
    }, [])

    useEffect(() => {
        if (!authTokenData.exsist) return

        if (authTokenData.millisecondsToExpire > 0) {
            const refreshTimeout = setTimeout(() => {
                dispatch(
                    authActions.refreshToken({
                        refresh_token: authTokenData.refresh_token,
                        loadAccount: false,
                    })
                )
            }, authTokenData.millisecondsToExpire)

            return () => {
                clearTimeout(refreshTimeout)
            }
        }
    }, [authTokenData.refresh_token])

    useEffect(() => {
        if (isAuthorized && modalStatus !== 'close') {
            dispatch(authActions.setModalStatus('close'))
            if (referrer_url) {
                history.push(referrer_url)
            }
        }
    }, [isAuthorized])

    return (
        <React.Fragment key={authTokenData.created_at}>
            <AccountModal />
            <AuthModal />
            {children}
        </React.Fragment>
    )
}
