import { Box } from '@mui/material'
import React from 'react'

interface MobileContentWrapperProps {
    backgroundImage: string
}

export const MobileContentWrapper: React.FC<MobileContentWrapperProps> = ({
    backgroundImage,
    children,
}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                height: '100%',
                position: 'relative',
            }}
        >
            <Box
                sx={{
                    width: { xs: '75vw', sm: '50vw', md: '35vw' },
                    height: { xs: '75vw', sm: '50vw', md: '35vw' },
                    backgroundSize: 'cover',
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundPosition: 'center',
                }}
            >
                <Box
                    sx={{
                        pb: 2,
                        px: 2,
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        height: '100%',
                        width: '100%',
                        left: 0,
                        top: 0,
                        position: 'absolute',
                        boxSizing: 'border-box',
                        justifyContent: 'flex-end',

                        color: (theme) => theme.palette.common.white,
                    }}
                >
                    {children}
                </Box>
            </Box>
        </Box>
    )
}
