import { PayloadAction } from '@reduxjs/toolkit'
import { imagesActions } from 'app/modules/Images/slice'
import { call, put, takeLatest } from 'redux-saga/effects'
import {
    ICategory,
    ICategoryCollectionResponse,
    ICategoryItemResponse,
} from 'types/ICategory'
import { request } from 'utils/request'

import { categoriesActions } from '.'

function* getCategory(action: PayloadAction<string>) {
    try {
        const requestURL = `/api/v2/storefront/taxons/${action.payload}?include=image,mini_image`

        const response: ICategoryItemResponse = yield call(request, requestURL)

        yield put(imagesActions.imagesAdded(response))
        yield put(categoriesActions.categoryLoaded(response.data))
    } catch (error: any) {
        yield put(categoriesActions.statusError())
    }
}

function* getCategories() {
    try {
        const requestURL = `/api/v2/storefront/taxons?include=image,mini_image,filtering_taxons`

        const response: ICategoryCollectionResponse = yield call(
            request,
            requestURL
        )

        const newData = response.data.map((i) => {
            if (i.id === '1') {
                return {
                    ...i,
                    included: [],
                    categories: response.included.filter(
                        (i) => i.type === 'taxon'
                    ),
                }
            }

            const included = i.relationships.filtering_taxons.data.map((item) =>
                response.included.find(
                    (includedItem) => includedItem.id === item.id
                )
            )

            return { ...i, included }
        })
        yield put(imagesActions.imagesAdded(response))
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        yield put(categoriesActions.categoriesLoaded(newData))
    } catch (error: any) {
        yield put(categoriesActions.statusError())
    }
}

export function* categoriesWatcher() {
    yield takeLatest(categoriesActions.loadCategory.type, getCategory)
    yield takeLatest(categoriesActions.loadCategories.type, getCategories)
}
