import { Box, Typography } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import React from 'react'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(() =>
    createStyles({
        icon: {
            width: '48px',
            height: '48px',
            flexShrink: 0,
        },
    })
)

export interface GreyIconInfoBlockItem {
    icon: string
    title: string
    href?: string
    description: string
}

interface GreyIconInfoBlockProps {
    item: GreyIconInfoBlockItem
}

export const GreyIconInfoBlock: React.FC<GreyIconInfoBlockProps> = ({
    item,
}) => {
    const classes = useStyles()

    return (
        <>
            {!!item.href && (
                <Box
                    p={4}
                    mt={5}
                    sx={{
                        backgroundColor: '#F5F3F3',
                    }}
                    display={'flex'}
                    component={Link}
                    to={item.href}
                >
                    <img src={item.icon} className={classes.icon} />
                    <Box ml={3.5} my={1}>
                        <Typography
                            variant="h5"
                            fontWeight={500}
                            sx={{ textTransform: 'uppercase' }}
                        >
                            {item.title}
                        </Typography>
                        <Typography variant="body2" mt={2.5}>
                            {item.description}
                        </Typography>
                    </Box>
                </Box>
            )}

            {!item.href && (
                <Box
                    p={4}
                    mt={5}
                    sx={{
                        backgroundColor: '#F5F3F3',
                    }}
                    display={'flex'}
                >
                    <img src={item.icon} className={classes.icon} />
                    <Box ml={3.5} my={1}>
                        <Typography
                            variant="h5"
                            fontWeight={500}
                            sx={{ textTransform: 'uppercase' }}
                        >
                            {item.title}
                        </Typography>
                        <Typography variant="body2" mt={2.5}>
                            {item.description}
                        </Typography>
                    </Box>
                </Box>
            )}
        </>
    )
}
