import {
    Box,
    Divider,
    Grid,
    Paper,
    Skeleton,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import React from 'react'
import { ICart } from 'types/ICart'

import { LineItem } from './LineItem'
import { OrderDetails } from './OrderDetails'
import { OrderInformation } from './OrderInformation'

interface OrderItemProps {
    order?: ICart
}

export const OrderItem: React.FC<OrderItemProps> = ({ order }) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))

    return (
        <Paper elevation={0}>
            <Grid container>
                <Grid item xs={12} lg={6} xl={7}>
                    <Box
                        pt={{
                            xs: 2,
                            md: 5.625,
                        }}
                        pb={{
                            xs: 4,
                            md: 4.625,
                        }}
                        px={{
                            xs: 2,
                            md: 6.25,
                        }}
                        display="flex"
                        flexDirection="column"
                    >
                        <Typography
                            sx={(theme) => ({
                                textTransform: 'uppercase',
                                typography: {
                                    xs: {
                                        ...theme.typography.body1,
                                        fontWeight: 500,
                                    },
                                    md: {
                                        ...theme.typography.h5,
                                    },
                                },
                            })}
                            variant="h5"
                            fontWeight="medium"
                        >
                            {order ? (
                                `Заказ № ${order?.attributes.number}`
                            ) : (
                                <Skeleton />
                            )}
                        </Typography>

                        {order ? (
                            <OrderInformation order={order} />
                        ) : (
                            <Skeleton>
                                <OrderInformation order={order} />
                            </Skeleton>
                        )}
                    </Box>
                </Grid>

                <Grid
                    item
                    sx={{
                        borderTop: (theme) => ({
                            xs: `1px solid ${theme.palette.divider}`,
                            lg: 'none',
                        }),
                        borderLeft: (theme) => ({
                            lg: `1px solid ${theme.palette.divider}`,
                        }),
                    }}
                    xs={12}
                    lg={6}
                    xl={5}
                >
                    <Box display="flex" flexDirection="column">
                        <Box
                            px={{
                                xs: 2,
                                md: 6.25,
                            }}
                            py={{ xs: 4, md: 5 }}
                        >
                            {order ? (
                                <Stack
                                    direction={isMobile ? 'row' : 'column'}
                                    spacing={2.5}
                                >
                                    {order?.relationships.line_items.data.map(
                                        (lineItem, index) => (
                                            <LineItem
                                                key={index}
                                                id={lineItem.id}
                                            />
                                        )
                                    )}
                                </Stack>
                            ) : (
                                <Skeleton />
                            )}
                        </Box>

                        <Divider flexItem />

                        <OrderDetails order={order} />
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    )
}
