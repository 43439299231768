import { LoadingButton } from '@mui/lab'
import { Box, Button, useMediaQuery, useTheme } from '@mui/material'
import { testPhoneNumber } from 'app/components/PhoneField/utils/validatePhoneNumber'
import { checkoutActions } from 'app/modules/Checkout/slice'
import { selectPartnerInformation } from 'app/modules/Checkout/slice/selectors'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EStatus } from 'types'
import * as yup from 'yup'

import { DesktopPartnerInformation } from './DesktopPartnerInformation'
import { MobilePartnerInformation } from './MobilePartnerInformation'

interface PartnerInformationFormProps {
    onPrevious: () => void
}

export const PartnerInformationForm: React.FC<PartnerInformationFormProps> = ({
    onPrevious,
}) => {
    const dispatch = useDispatch()
    const theme = useTheme()

    const [isLoading, setLoading] = useState<boolean>(false)

    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

    const { data, status } = useSelector(selectPartnerInformation)

    const validationSchema = yup.object({
        order: yup.object({
            email: yup.string().email('Не корректный Email').required(),
            legal_name: yup.string().required(),
            ship_address_attributes: yup.object({
                firstname: yup.string().required(),
                lastname: yup.string().required(),
                phone: yup.string().required().test(testPhoneNumber),
            }),
        }),
    })

    const formik = useFormik({
        validationSchema,
        initialValues: data,
        validateOnBlur: false,
        validateOnChange: false,
        enableReinitialize: true,
        onSubmit: (values) => {
            setLoading(true)
            dispatch(checkoutActions.partnerOrderComplete(values))
        },
    })

    useEffect(() => {
        if (status === EStatus.ERROR) {
            setLoading(false)
            dispatch(checkoutActions.cleanPartnerInformationStatus())
        }
    }, [status])

    useEffect(() => {
        dispatch(checkoutActions.cleanPartnerInformationStatus())
    }, [])

    return (
        <Box
            sx={{ mt: 5 }}
            noValidate
            component="form"
            autoComplete="off"
            onSubmit={(e: React.FormEvent) => {
                e.preventDefault()

                formik.handleSubmit()
            }}
        >
            {isMobile && <MobilePartnerInformation formik={formik} />}

            {isDesktop && <DesktopPartnerInformation formik={formik} />}

            <Box sx={{ mt: 5, mb: 2 }}>
                <div>
                    <LoadingButton
                        loading={isLoading}
                        variant="contained"
                        onClick={() => formik.handleSubmit()}
                        sx={{ mt: 1, mr: 2, width: '240px' }}
                    >
                        ОФОРМИТЬ ЗАКАЗ
                    </LoadingButton>
                    <Button onClick={() => onPrevious()} sx={{ mt: 1, mr: 1 }}>
                        Назад
                    </Button>
                </div>
            </Box>
            {isMobile && <Box sx={{ width: '100%', height: 72 }} />}
        </Box>
    )
}
