import { LoadingButton } from '@mui/lab'
import { Box, Stack, Typography } from '@mui/material'
import { selectCart } from 'app/modules/Cart/slice/selectors'
import { selectImageById } from 'app/modules/Images/slice/selectors'
import { selectLineItemById } from 'app/modules/LineItems/slice/selectors'
import { selectVariantById } from 'app/modules/Variants/slice/selectors'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ILineItem } from 'types/ILineItem'

import { OrderItem } from './OrderItem'

interface OrderItemsListProps {
    onNextStep: () => void
}

export const PartnerOrderItemsList: React.FC<OrderItemsListProps> = ({
    onNextStep,
}) => {
    const getLineItem = useSelector(selectLineItemById)
    const getImage = useSelector(selectImageById)
    const getVariant = useSelector(selectVariantById)

    const cart = useSelector(selectCart)

    const listItems =
        cart?.relationships.line_items.data.reduce(
            (acc: ILineItem[], value) => {
                const lineItem = getLineItem(value.id)

                if (lineItem) {
                    return [...acc, lineItem]
                }

                return acc
            },
            []
        ) || []

    const items = useMemo(() => {
        return listItems.map((item) => {
            const variant = getVariant(item.relationships.variant.data?.id)
            const image = getImage(
                variant?.relationships.images.data[0]?.id,
                '612',
                true
            )

            return {
                item,
                image,
            }
        })
    }, [listItems])

    return (
        <>
            <Box
                sx={{
                    border: '1px solid #DEDEDE',
                    mt: 2.75,
                    p: {
                        lg: 4,
                        xs: 2.25,
                    },
                }}
            >
                {!items.length && (
                    <Typography mt={5} variant="subtitle2">
                        Корзина пустая
                    </Typography>
                )}
                <Stack spacing={3.75}>
                    {items.map(({ item, image }) => (
                        <OrderItem key={item.id} item={item} image={image} />
                    ))}
                </Stack>
            </Box>
            <Box sx={{ mt: 3, mb: 2 }}>
                <LoadingButton
                    variant="contained"
                    onClick={onNextStep}
                    sx={{ mt: 1, mr: 2, width: '240px' }}
                >
                    ПРОДОЛЖИТЬ
                </LoadingButton>
            </Box>
        </>
    )
}
