import {
    createEntityAdapter,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit'
import { EStatus } from 'types'
import { ICategory } from 'types/ICategory'

import { CategoriesState } from './types'

export const categoriesAdapter = createEntityAdapter<ICategory>({
    sortComparer: (a, b) =>
        a.attributes.updated_at.localeCompare(b.attributes.updated_at),
})

const slice = createSlice({
    name: 'categories',
    initialState: categoriesAdapter.getInitialState<CategoriesState>({
        ids: [],
        entities: {},
        status: EStatus.INITIAL,
    }),
    reducers: {
        loadCategory(state, _action: PayloadAction<string>) {
            state.status = EStatus.PENDING
        },
        loadCategories(state) {
            state.status = EStatus.PENDING
        },
        categoryLoaded(state, action: PayloadAction<ICategory>) {
            categoriesAdapter.setOne(state, action.payload)

            state.status = EStatus.FINISHED
        },
        categoriesLoaded(state, action: PayloadAction<ICategory[]>) {
            categoriesAdapter.setAll(state, action.payload)

            state.status = EStatus.FINISHED
        },
        statusError(state) {
            state.status = EStatus.ERROR
        },
    },
})

export const { actions: categoriesActions, reducer: categoriesReducer } = slice
