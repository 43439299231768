import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'types'

import { listItemsAdapter } from '.'

const { selectAll, selectById } = listItemsAdapter.getSelectors()

const selectDomain = (state: RootState) => state.listItems

export const selectStatus = createSelector(
    [selectDomain],
    (state) => state.status
)

export const selectLineItemById = createSelector(
    [selectDomain],
    (state) => (id: string) => selectById(state, id)
)

export const selectLineItemByVariantId = createSelector(
    [selectDomain],
    (state) => (id?: string) =>
        id
            ? selectAll(state).find(
                  (lineItem) => lineItem.relationships.variant.data?.id === id
              )
            : undefined
)
