import { LoadingButton } from '@mui/lab'
import { Box } from '@mui/material'
import { checkoutActions } from 'app/modules/Checkout/slice'
import { selectPaymentsShipmentsAttributes } from 'app/modules/Checkout/slice/selectors'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    googleAnalyticsEvent,
    googleAnalyticsEvents,
} from 'utils/googleAnalytics'

import { useCartItemsOutOfStock } from '../../hooks/useCartItemsInStock'
import { CartBackButton } from '../CartBackButton'
import { CartNavigationsButtons } from '../CartNavigationsButtons'
import { ShippingInfo } from './ShippingInfo'

export const CustomerShipmentsAttributes: React.FC = () => {
    const dispatch = useDispatch()

    const { outOfStock: checkoutDisabled } = useCartItemsOutOfStock()

    const [isNextLoading, setNextLoading] = useState<boolean>(false)
    const [isPreviousLoading, setPreviousLoading] = useState<boolean>(false)
    const { data: paymentsShipmentsAttributes } = useSelector(
        selectPaymentsShipmentsAttributes
    )
    const shipmentsAttributes =
        paymentsShipmentsAttributes.order.shipments_attributes.find(Boolean)

    const handleSaveShipmentsAttributes = () => {
        googleAnalyticsEvent(googleAnalyticsEvents.confirmation_receipt)
        setNextLoading(true)
        dispatch(checkoutActions.confirmDeliveryMethod())
    }

    const handlePrevious = () => {
        setPreviousLoading(true)
        dispatch(checkoutActions.loadCheckoutPrevious())
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }, [])

    return (
        <>
            {shipmentsAttributes && (
                <Box sx={{ mt: { xs: '26px', md: '37px' } }}>
                    <ShippingInfo shipmentsAttributes={shipmentsAttributes} />
                </Box>
            )}

            <Box
                sx={{
                    mt: { xs: '42px', md: '48px' },
                    mb: { xs: '50px', md: '36px' },
                }}
            >
                <CartNavigationsButtons
                    backButton={
                        <CartBackButton
                            handlePrevious={handlePrevious}
                            loading={isPreviousLoading}
                        />
                    }
                    nextButton={
                        <LoadingButton
                            loading={isNextLoading}
                            variant="contained"
                            onClick={handleSaveShipmentsAttributes}
                            sx={{
                                width: '100%',
                                height: '100%',
                                maxWidth: { md: '222px' },
                            }}
                            disabled={checkoutDisabled}
                        >
                            ПРОДОЛЖИТЬ
                        </LoadingButton>
                    }
                />
            </Box>
        </>
    )
}
