import { Box, Typography } from '@mui/material'
import React from 'react'

export const CustomerPaymentUserAgreement: React.FC = () => {
    return (
        <Typography
            variant="subtitle2"
            fontWeight="regular"
            color="#757575"
            component="span"
            sx={{ fontSize: { xs: '12px', md: '14px' } }}
        >
            {`Нажимая «Оформить заказ», вы соглашаетесь с условиями `}
            <Box
                component="a"
                sx={(theme) => ({ color: theme.palette.primary.main })}
                display={'inline'}
                fontWeight="medium"
                rel="noreferrer"
                href="/agreements/1_Договор_оферта_ИП_Судорженко_Д_В.pdf"
                target="_blank"
            >
                {`“оферты”`}
            </Box>
            {', '}
            <Box
                component="a"
                sx={(theme) => ({ color: theme.palette.primary.main })}
                display={'inline'}
                fontWeight="medium"
                href="/static/delivery"
            >
                {`“доставки и оплаты”`}
            </Box>
            {` и `}
            <Box
                component="a"
                sx={(theme) => ({ color: theme.palette.primary.main })}
                display={'inline'}
                fontWeight="medium"
                href="/static/return_exchange"
            >
                {`“возврата и обмена товара”`}
            </Box>
        </Typography>
    )
}
