import { Typography, useMediaQuery, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

import LoyaltyMainBannerImageDesktop from './assets/LoyaltyMainBannerImageDesktop.jpg'
import LoyaltyMainBannerImageMobile from './assets/LoyaltyMainBannerImageMobile.jpg'

export const LoyaltyMainBanner: React.FC = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))

    return (
        <Box
            display="flex"
            flexWrap="wrap"
            alignItems="center"
            justifyContent="space-between"
        >
            <Box
                sx={{
                    maxWidth: { lg: '590px' },
                    width: { xs: '100%', md: '44vw' },
                    position: 'absolute',
                    top: { xs: '0px' },
                    display: { xs: 'flex', md: 'none' },
                    flexDirection: { xs: 'column' },
                    alignItems: { xs: 'center' },
                }}
            >
                <Box sx={{ maxWidth: { xs: '310px', md: '100%' } }}>
                    <Typography
                        component="h1"
                        sx={{
                            fontSize: { xs: '24px', md: '42px' },
                            lineHeight: { xs: '24px' },
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                            color: 'white',
                            textAlign: 'center',
                            pt: { xs: '40px' },
                            pb: { xs: '16px' },
                        }}
                    >
                        ПРОГРАММА ЛОЯЛЬНОСТИ: ПЕРЕЗАГРУЗКА
                    </Typography>
                </Box>

                <Box>
                    <Typography
                        component="h2"
                        sx={{
                            fontSize: { xs: '20px', md: '42px' },
                            color: 'white',
                            textAlign: 'center',
                        }}
                    >
                        Присоединяйтесь к клубу привилегий Island Soul Way
                    </Typography>
                </Box>
            </Box>
            <Box
                component="a"
                sx={{
                    width: { xs: '100vw', md: '100%' },
                }}
                href="https://t.me/ISJcard_bot"
                target="blank"
            >
                <Box
                    component="img"
                    src={
                        isMobile
                            ? LoyaltyMainBannerImageMobile
                            : LoyaltyMainBannerImageDesktop
                    }
                    sx={{ objectFit: 'cover', width: 'inherit' }}
                />
            </Box>
        </Box>
    )
}
