import { LoadingButton } from '@mui/lab'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { selectCart } from 'app/modules/Cart/slice/selectors'
import { checkoutActions } from 'app/modules/Checkout/slice'
import {
    selectActivePaymentMethodId,
    selectPaymentMethods,
} from 'app/modules/Checkout/slice/selectors'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import ym from 'react-yandex-metrika'
import { getCookieValue } from 'utils/getCookieValue'
import {
    googleAnalyticsEvent,
    googleAnalyticsEvents,
} from 'utils/googleAnalytics'
import { topMailEvent, TopMailEventGoals } from 'utils/topMailMetrica'
import { useYandexMetricaEcommerce } from 'utils/yandexMetrica'

import { useCartItemsOutOfStock } from '../../hooks/useCartItemsInStock'
// import { ReactComponent as RibbonIcon } from '../../assets/Ribbon.svg'
import { CartBackButton } from '../CartBackButton'
import { CartNavigationsButtons } from '../CartNavigationsButtons'
import { LoyaltyDetailContainer } from '../LoyaltyDetail'
import { OrderDetail } from '../OrderDetail'
import { PaymentItems } from '../PaymentItems'
import { CustomerPaymentUserAgreement } from './CustomerPaymentUserAgreement'

export const CustomerPaymentMethod: React.FC = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))

    const dispatch = useDispatch()

    const cart = useSelector(selectCart)
    const paymentMethods = useSelector(selectPaymentMethods)
    const { yandexMetricaEcommercePurchase } = useYandexMetricaEcommerce()
    const { outOfStock: checkoutDisabled } = useCartItemsOutOfStock()

    const [isNextLoading, setNextLoading] = useState<boolean>(false)
    const [isPreviousLoading, setPreviousLoading] = useState<boolean>(false)
    const paymentMethodId = useSelector(selectActivePaymentMethodId)

    const handleOrderComplete = useCallback(() => {
        if (!cart) {
            toast.error('Корзина пустая', {
                type: 'error',
            })
            return
        }

        const activePaymentMethod = paymentMethods.find(
            (method) => method.payment_method_id === paymentMethodId
        )

        if (activePaymentMethod) {
            yandexMetricaEcommercePurchase(cart)
            googleAnalyticsEvent(googleAnalyticsEvents.payment_choice)
            ym('reachGoal', 'checkout')
            setNextLoading(true)

            dispatch(
                checkoutActions.setRoistatVisit(getCookieValue('roistat_visit'))
            )

            dispatch(
                checkoutActions.updatePaymentsAttributes({
                    order: {
                        payments_attributes: [activePaymentMethod],
                    },
                })
            )

            localStorage.setItem(
                'cartOrderCompleted',
                `${cart.attributes.number}`
            )
        }
    }, [paymentMethodId, cart, dispatch])

    useEffect(function topMailOnLoadPayments() {
        topMailEvent(TopMailEventGoals.payment_choice)
    }, [])

    const handlePrevious = () => {
        setPreviousLoading(true)
        dispatch(checkoutActions.loadCheckoutPrevious())
    }

    if (!cart) {
        return null
    }

    return (
        <Box
            sx={{
                mt: { xs: '16px' },
                mb: { xs: '36px', md: '36px' },
                maxWidth: '600px',
            }}
        >
            <PaymentItems
                selectedPaymentMethodId={paymentMethodId}
                setPaymentMethodId={(paymentMethodId) =>
                    dispatch(
                        checkoutActions.setPaymentMethodId(paymentMethodId)
                    )
                }
            />

            <Box sx={{ mt: { xs: '40px', md: '60px' } }}>
                <LoyaltyDetailContainer />
            </Box>

            <Box
                sx={{
                    position: { xs: 'fixed', md: 'relative' },
                    bottom: { xs: '58px', md: 'unset' },
                    height: { xs: '64px' },
                    py: { xs: '8px' },
                    px: { xs: '16px', md: '0px' },
                    mx: { xs: '-16px', md: '0px' },
                    mt: { xs: '0', md: '60px' },
                    width: { xs: '100%', md: '280px' },
                    backgroundColor: { xs: '#FFFFFF', md: 'unset' },
                    boxShadow: {
                        xs: '0px 4px 20px rgba(0, 0, 0, 0.2)',
                        md: 'unset',
                    },
                }}
            >
                <CartNavigationsButtons
                    backButton={
                        <CartBackButton
                            handlePrevious={handlePrevious}
                            loading={isPreviousLoading}
                        />
                    }
                    nextButton={
                        <LoadingButton
                            fullWidth
                            loading={isNextLoading}
                            size="large"
                            color="primary"
                            variant="contained"
                            onClick={handleOrderComplete}
                            sx={{ height: '48px', width: '100%', px: '0px' }}
                            disabled={checkoutDisabled}
                        >
                            <Typography
                                sx={{ fontWeight: 500 }}
                                variant="body2"
                            >
                                Оформить заказ
                            </Typography>
                            {/* <Box
                                sx={{
                                    position: 'absolute',
                                    right: '-16px',
                                    top: '-28px',
                                }}
                            >
                                <RibbonIcon />
                            </Box> */}
                        </LoadingButton>
                    }
                />
            </Box>

            <Box
                sx={{
                    backgroundColor: {
                        xs: theme.palette.grey[100],
                        md: 'unset',
                    },
                    mx: { xs: '-16px', md: '0px' },
                }}
            >
                {isMobile && <OrderDetail cart={cart} />}

                <Box
                    mt="20px"
                    sx={{
                        px: { xs: '16px', md: '0px' },
                        pb: { xs: '72px', md: '0px' },
                    }}
                >
                    <CustomerPaymentUserAgreement />
                </Box>
            </Box>
        </Box>
    )
}
