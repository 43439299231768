import { Box, Container, Typography } from '@mui/material'
import React from 'react'

import {
    ExchangeBanner,
    PackagingBanner,
    PersonalConsultantBanner,
    ServiceDepartmentClientsBanner,
} from '../ExperienceBannerList/ExperienceBannerList'

export const DesktopExperienceBanner: React.FC = () => {
    return (
        <Container sx={{ px: { md: '2.34375vw' } }}>
            <Typography
                sx={{
                    width: '100%',
                    textAlign: 'center',
                    textTransform: 'uppercase',
                    mb: '46px',
                    fontWeight: 'medium',
                }}
                variant="h4"
            >
                Island soul experience
            </Typography>

            <Box
                sx={{
                    display: 'flex',
                    height: '28vw',
                }}
            >
                <Box sx={{ width: '25%' }}>
                    <ServiceDepartmentClientsBanner />
                </Box>
                <Box sx={{ width: '25%' }}>
                    <PersonalConsultantBanner />
                </Box>
                <Box sx={{ width: '25%' }}>
                    <PackagingBanner />
                </Box>
                <Box sx={{ width: '25%' }}>
                    <ExchangeBanner />
                </Box>
            </Box>
        </Container>
    )
}
