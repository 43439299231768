import { getMatchedMask } from './get-matched-mask'

export const testPhoneNumber = (phoneNumber?: string | null) => {
    if (!phoneNumber) return false
    const mask = getMatchedMask(phoneNumber)
    return (
        !!mask &&
        mask.replace(/[^0-9]+/g, '').length ===
            phoneNumber.replace(/[^0-9]+/g, '').length
    )
}
