import { Container, Grid } from '@mui/material'
import { AccountGift } from 'app/modules/Account/templates/AccountGift'
import { AccountMenu } from 'app/modules/Account/templates/AccountMenu'
import { AccountSettings } from 'app/modules/Account/templates/AccountSettings'
import { OrdersList } from 'app/modules/Order/templates/OrdersList'
import { FavoritesList } from 'app/modules/Products/templates/FavoritesList'
import React from 'react'
import { Route, Switch } from 'react-router-dom'

export const ProfilePages: React.FC = () => {
    return (
        <Container>
            <Grid
                container
                mt={{ sx: 3, sm: 6, md: 25, lg: 23, xl: 23 }}
                rowSpacing={3}
                columnSpacing={3.75}
            >
                <Grid item xs={12} lg={3}>
                    <AccountMenu />
                </Grid>

                <Grid item xs={12} lg={9}>
                    <Switch>
                        <Route
                            exact
                            path={['/profile/orders']}
                            component={OrdersList}
                        />
                        <Route
                            exact
                            path="/profile/favorites"
                            component={FavoritesList}
                        />
                        <Route
                            exact
                            path="/profile/gift"
                            component={AccountGift}
                        />
                        <Route
                            exact
                            path={['/profile', '/profile/personal']}
                            component={AccountSettings}
                        />
                    </Switch>
                </Grid>
            </Grid>
        </Container>
    )
}
