import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Link,
    TextField,
    Typography,
} from '@mui/material'
import { Modal } from 'app/components/Modal'
import { PhoneField } from 'app/components/PhoneField'
import { testPhoneNumber } from 'app/components/PhoneField/utils/validatePhoneNumber'
import axios from 'axios'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import * as yup from 'yup'

interface FranchiseRequestModalProps {
    open: boolean
    handleClose: () => void
}

const initialValues = { name: '', phone: '', email: '', personal_data: false }

export const FranchiseRequestModal: React.FC<FranchiseRequestModalProps> = ({
    open,
    handleClose,
}) => {
    const [success, setSuccses] = useState(false)

    const validationSchema = yup.object({
        name: yup.string().required(),
        phone: yup.string().test(testPhoneNumber).required(),
        email: yup.string().email('Не корректный Email').required(),
        personal_data: yup.bool().isTrue(),
    })

    const formik = useFormik({
        validationSchema,
        initialValues,
        validateOnMount: true,
        onSubmit: async (values) => {
            try {
                const data = new FormData()
                data.append('entry.670956342', values.name)
                data.append('entry.1438911587', values.phone)
                data.append('entry.1319349771', values.email)

                await axios
                    .post(
                        'https://docs.google.com/forms/u/2/d/e/1FAIpQLSclRb8AgsY6b5cq-s1rVwGa3Ob27eBlaTwNQOZGL49mGvOjRA/formResponse',
                        data
                    )
                    .finally(() => {
                        setSuccses(true)
                    })
            } catch (error) {
                //
            }
        },
    })

    useEffect(() => {
        if (success) {
            formik.setValues(initialValues)
            handleClose()
            setSuccses(false)
        }
    }, [success])

    return (
        <Modal open={open} title={'заявка'} handleClose={handleClose}>
            <Box
                sx={{
                    width: 'auto',
                    mt: { xs: 3 },
                    mr: { xs: 2, sm: 4, md: 4, lg: 4, xl: 4 },
                    ml: { xs: 2, sm: 4, md: 4, lg: 4, xl: 4 },
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                }}
            >
                <Typography variant="body2">
                    Заполните данные, чтобы мы связались с вами
                </Typography>

                <TextField
                    fullWidth
                    label="Ваше имя"
                    variant="standard"
                    name="name"
                    value={formik.values.name}
                    error={!!formik.errors.name}
                    onChange={formik.handleChange}
                />

                <PhoneField
                    fullWidth
                    name="phone"
                    label="Номер телефона"
                    value={formik.values.phone || ''}
                    error={!!formik.errors.phone}
                    onChange={formik.handleChange}
                />

                <TextField
                    fullWidth
                    variant="standard"
                    label="Email"
                    name="email"
                    value={formik.values.email || ''}
                    error={!!formik.errors.email}
                    onChange={formik.handleChange}
                />
                <FormControlLabel
                    sx={{
                        alignItems: 'flex-start',
                    }}
                    control={
                        <Checkbox
                            sx={{ pt: 0 }}
                            checked={formik.values.personal_data || false}
                            onChange={() =>
                                formik.setFieldValue(
                                    'personal_data',
                                    !formik.values.personal_data
                                )
                            }
                        />
                    }
                    label={
                        <Typography variant="body2">
                            Я даю своё согласие на обработку персональных данных
                            в соответствии с{' '}
                            <Link
                                rel="noreferrer"
                                href="/agreements/3_Согласие_на_обработку_персональных_данных_ИП_Судорженко_Д_В.pdf"
                                target="_blank"
                            >
                                политикой конфиденциальности
                            </Link>
                        </Typography>
                    }
                />
                <Button
                    variant="contained"
                    sx={{
                        borderRadius: '15px',
                        height: '60px',
                        width: 'auto',
                    }}
                    disabled={!formik.isValid}
                    onClick={() => {
                        formik.handleSubmit()
                    }}
                >
                    Оставить заявку
                </Button>

                {success && (
                    <Typography variant="body2">
                        Ваши данные успешно отправлены
                    </Typography>
                )}
            </Box>
        </Modal>
    )
}
