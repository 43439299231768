import { Box, Container, useTheme } from '@mui/material'
import { useDefaultYState } from 'hooks/useOffsetHeader'
import React from 'react'

import { Logo } from '../../Logo'
import { HeaderCategories } from '../HeaderCategories'
import { useHeaderContext } from '../HeaderContextProvider'
import { HeaderLocation } from '../HeaderLocation'
import { HeaderPrimaryActions } from '../HeaderPrimaryActions'
import { HeaderPrimaryLinks } from '../HeaderPrimaryLinks'
import { HeaderSearch } from '../HeaderSearch'
import { DESKTOP_HEADER_HEIGHT } from '.'

export const DesktopHeader: React.FC = () => {
    const theme = useTheme()
    const {
        variant: contextVariant,
        mode,
        isCityOpened,
        isDesktopSearchOpened,
    } = useHeaderContext()

    const isRollUpMode = mode === 'roll-up'

    const isDefaultYState = useDefaultYState()

    const variant =
        isCityOpened ||
        isDesktopSearchOpened ||
        (isRollUpMode && !isDefaultYState)
            ? 'dark'
            : contextVariant

    return (
        <Box
            component="header"
            sx={{
                minHeight: `${DESKTOP_HEADER_HEIGHT}px`,
                maxHeight: `${DESKTOP_HEADER_HEIGHT}px`,
                position: 'fixed',
                top: 0,
                width: '100%',
                zIndex: 1004,
                overflow: 'hidden',
            }}
        >
            <Box
                sx={(theme) => ({
                    backgroundColor: theme.palette.primary.main,

                    height: '3px',
                })}
            />
            <Box
                sx={{
                    backgroundColor:
                        isRollUpMode && !isCityOpened && !isDesktopSearchOpened
                            ? isDefaultYState
                                ? 'transparent'
                                : theme.palette.background.paper
                            : theme.palette.background.paper,
                    transition: 'all 0.5s ease-in-out',
                }}
            >
                <Container
                    sx={{
                        minHeight: '50px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'start',
                        px: { md: '46px' },
                        pt: { md: '11px' },
                    }}
                >
                    <Box sx={{ flexBasis: '50%', flexShrink: 1, pt: '17px' }}>
                        <HeaderPrimaryLinks variant={variant} />
                    </Box>
                    <Box sx={{ pt: '8px' }}>
                        <Logo align="center" size="large" variant={variant} />
                    </Box>
                    <Box sx={{ flexBasis: '50%', flexShrink: 1 }}>
                        <HeaderPrimaryActions variant={variant} />
                    </Box>
                </Container>

                <HeaderLocation />

                <Container
                    sx={{
                        px: { md: '46px' },
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            flexBasis: isDesktopSearchOpened ? '100%' : '10%',
                            mt: '6px',
                            minWidth: '100px',
                        }}
                    >
                        <HeaderSearch variant={variant} />
                    </Box>
                    {isDesktopSearchOpened || (
                        <>
                            <HeaderCategories variant={variant} />
                            <Box sx={{ flexBasis: '10%' }}></Box>
                        </>
                    )}
                </Container>
            </Box>
        </Box>
    )
}
