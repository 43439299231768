import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { GiftFormAttributes, GiftFormState } from './types'

const initialState: GiftFormState = {
    open: false,
    attributes: {
        addressee_email: '',
        addressee_name: '',
        sender_email: '',
        sender_name: '',
        product: '',
    },
}

const slice = createSlice({
    initialState,
    name: 'giftForm',
    reducers: {
        showGiftForm(state) {
            state.open = true
        },
        hideGiftForm(state) {
            state.open = false
        },
        sendGiftForm(_state, _action: PayloadAction<GiftFormAttributes>) {
            //
        },
        giftFormSended(state) {
            state.open = false
        },
    },
})

export const { actions: giftFormActions, reducer: giftFormReducer } = slice
