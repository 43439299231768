import { Collapse, Container, Typography } from '@mui/material'
import { MobileMenuItem } from 'app/components/MobileMenuItem'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { ReactComponent as MinusIcon } from '../../assets/minus_icon.svg'
import { ReactComponent as PlusIcon } from '../../assets/plus_icon.svg'

export const MobileUniverseISBlock: React.FC = () => {
    const [isOpen, setOpen] = useState<boolean>(false)

    return (
        <Container
            onClick={() => setOpen(!isOpen)}
            sx={{
                py: 0.625,
            }}
        >
            <MobileMenuItem
                text={'Вселенная Island Soul'}
                endContent={isOpen ? <MinusIcon /> : <PlusIcon />}
            />

            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <Link to={'/static/history'}>
                    <Typography variant="body2" sx={{ py: 1.25 }}>
                        История бренда
                    </Typography>
                </Link>

                <Link to={'/static/daria_soul'}>
                    <Typography variant="body2" sx={{ py: 1.25 }}>
                        Daria Soul
                    </Typography>
                </Link>

                <Link to={'/journal'}>
                    <Typography variant="body2" sx={{ py: 1.25 }}>
                        Журнал SoulLife
                    </Typography>
                </Link>

                <Link to={'/static/branded_packaging'}>
                    <Typography variant="body2" sx={{ py: 1.25 }}>
                        Фирменная упаковка
                    </Typography>
                </Link>
            </Collapse>
        </Container>
    )
}
