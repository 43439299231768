import { useMediaQuery, useTheme } from '@mui/material'
import {
    selectCitiesByCountry,
    selectCurrentCity,
    selectStatus,
} from 'app/modules/Cities/slice/selectors'
import { ChangeCityModal } from 'app/modules/Cities/templates/ChangeCityModal'
import { WelcomeCityDialog } from 'app/modules/Cities/templates/WelcomeCityDialog'
import { selectCountries } from 'app/modules/Countries/slice/selectors'
import React from 'react'
import { useSelector } from 'react-redux'
import { EStatus } from 'types'
import { ICountryData } from 'types/ICountry'

import { useHeaderContext } from '../HeaderContextProvider'
import { DesktopHeaderLocation } from './DesktopHeaderLocation'
import { MobileHeaderLocation } from './MobileHeaderLocation'

export const HeaderLocation: React.FC = React.memo(() => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
    const { cityOpen } = useHeaderContext()

    const currentCityId = useSelector(selectCurrentCity)?.id
    const cityStatus = useSelector(selectStatus)

    const countries = useSelector(selectCountries)
    const getCities = useSelector(selectCitiesByCountry)

    const countriesData: ICountryData[] = [
        ...countries.map((country) => ({
            name: country.attributes.name,
            isWideCountry: country.attributes.iso_name === 'RU',
            cities: getCities(country.id),
        })),
        {
            name: 'Остальные',
            isWideCountry: false,
            cities: getCities(),
        },
    ]

    const handleCityChange = () => {
        localStorage.removeItem('current_city_id')
        cityOpen?.()
    }

    return (
        <>
            {isMobile && <MobileHeaderLocation countriesData={countriesData} />}

            {isDesktop && (
                <DesktopHeaderLocation countriesData={countriesData} />
            )}

            <ChangeCityModal />

            {!currentCityId && cityStatus === EStatus.FINISHED && (
                <WelcomeCityDialog onCityChange={handleCityChange} />
            )}
        </>
    )
})
