import { LoadingButton } from '@mui/lab'
import React from 'react'

import { ReactComponent as ArrowLeft } from '../assets/ArrowLeft.svg'

interface CartBackButtonProps {
    handlePrevious: () => void
    loading: boolean
}
export const CartBackButton: React.FC<CartBackButtonProps> = ({
    handlePrevious,
    loading,
}) => {
    return (
        <LoadingButton
            sx={(theme) => ({
                backgroundColor: theme.palette.grey[100],
                minWidth: '48px',
                width: '48px',
                minHeight: '48px',
                height: '48px',
                borderRadius: '8px',
            })}
            onClick={handlePrevious}
            loading={loading}
        >
            {!loading && <ArrowLeft />}
        </LoadingButton>
    )
}
