import { TabContext, TabPanel } from '@mui/lab'
import {
    Box,
    Button,
    Tabs,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import React, { useState } from 'react'

import boutique_desktop from '../assets/boutique_desktop.png'
import boutique_mobile from '../assets/boutique_mobile.png'
import island_desktop from '../assets/island_desktop.png'
import island_mobile from '../assets/island_mobile.png'
import popup_desktop from '../assets/popup_desktop.png'
import popup_mobile from '../assets/popup_mobile.png'

export interface FranchiseTabProps {
    label: string
    value: string
    active: boolean
    onClick: (value: string) => void
}

export const FranchiseTab: React.FC<FranchiseTabProps> = ({
    label,
    value,
    active,
    onClick,
}) => {
    return (
        <Button
            variant="contained"
            sx={{
                height: '60px',
                borderRadius: '60px',
                backgroundColor: (theme) =>
                    active ? theme.palette.primary.main : 'white',
                color: (theme) =>
                    active
                        ? theme.palette.primary.contrastText
                        : theme.palette.text.primary,
                border: active ? 'none' : 'solid',
                borderWidth: active ? '0px' : '1.5px',
                borderColor: (theme) => theme.palette.divider,
                mx: 0.5,
                width: { xs: '90px' },
            }}
            onClick={() => {
                onClick(value)
            }}
        >
            <Typography variant="body2" sx={{ px: 2, textTransform: 'none' }}>
                {label}
            </Typography>
        </Button>
    )
}

export const FranchiseTabs: React.FC = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))

    const [currentTab, setCurrentTab] = useState('1')

    return (
        <TabContext value={currentTab}>
            <Tabs centered sx={{ border: 'none' }}>
                <FranchiseTab
                    label="Бутик"
                    value="1"
                    onClick={setCurrentTab}
                    active={currentTab === '1'}
                />
                <FranchiseTab
                    label="Поп-Ап"
                    value="2"
                    onClick={setCurrentTab}
                    active={currentTab === '2'}
                />
                <FranchiseTab
                    label="Остров"
                    value="3"
                    onClick={setCurrentTab}
                    active={currentTab === '3'}
                />
            </Tabs>
            <TabPanel value="1" sx={{ px: 0 }}>
                <Box
                    component="img"
                    src={isMobile ? boutique_mobile : boutique_desktop}
                    sx={{
                        borderRadius: '15px',
                        width: '100%',
                    }}
                ></Box>
            </TabPanel>
            <TabPanel value="2" sx={{ px: 0 }}>
                <Box
                    component="img"
                    src={isMobile ? popup_mobile : popup_desktop}
                    sx={{
                        borderRadius: '15px',
                        width: '100%',
                    }}
                ></Box>
            </TabPanel>
            <TabPanel value="3" sx={{ px: 0 }}>
                <Box
                    component="img"
                    src={isMobile ? island_mobile : island_desktop}
                    sx={{
                        borderRadius: '15px',
                        width: '100%',
                    }}
                ></Box>
            </TabPanel>
        </TabContext>
    )
}
