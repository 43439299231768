import { Close as ClearIcon } from '@mui/icons-material'
import { Box, IconButton, TextField, useTheme } from '@mui/material'
import { SearchIcon } from 'app/components/Icons/SearchIcon'
import { HeaderColorVariant } from 'app/modules/Layout/types/HeaderColorVariant'
import { useHandleSearch } from 'app/modules/Search/hooks/useSearch'
import { Formik } from 'formik'
import React, { useEffect, useRef } from 'react'
import { isSearchPageOpen } from 'utils/isSearchPageOpen'
import * as yup from 'yup'

import { useHeaderContext } from '../HeaderContextProvider'

interface HeaderSearchProps {
    variant: HeaderColorVariant
}

export const HeaderSearch: React.FC<HeaderSearchProps> = ({ variant }) => {
    const { handleSeach } = useHandleSearch()
    const theme = useTheme()
    const inputRef = useRef<HTMLInputElement>(null)

    const {
        isDesktopSearchOpened,
        setOpenDesktopSearchState,
        setCloseDesktopSearchState,
    } = useHeaderContext()

    const data = {
        query: '',
    }

    const validationSchema = yup.object({
        query: yup.string().required(),
    })

    const colorByVariant = (() => {
        switch (variant) {
            case 'dark':
                return theme.palette.getContrastText('#FFFFFF')
            case 'light':
                return theme.palette.getContrastText('#000000')
            default:
                return ''
        }
    })()

    const searchIconClick = () => {
        const element = inputRef.current
        if (element) {
            element.focus()
        }
    }

    return (
        <Formik
            {...{
                validationSchema,
                initialValues: data,
                validateOnBlur: true,
                validateOnChange: true,
                validateOnMount: true,
                onSubmit: (values) => {
                    handleSeach(values.query)
                },
            }}
        >
            {({ values, handleChange, handleSubmit, setFieldValue }) => {
                const onTextFieldBlur = () => {
                    if (!values.query) {
                        setCloseDesktopSearchState()
                    }
                }

                const handleClearSearch = () => {
                    setFieldValue('query', '')
                    setCloseDesktopSearchState()
                }

                useEffect(() => {
                    if (isSearchPageOpen() && values.query) {
                        handleClearSearch()
                    }
                })

                return (
                    <Box
                        sx={{
                            minWidth: '100px',
                            display: isSearchPageOpen() ? 'none' : 'unset',
                        }}
                        noValidate
                        component="form"
                        onSubmit={handleSubmit}
                    >
                        <TextField
                            fullWidth
                            inputRef={inputRef}
                            variant="standard"
                            placeholder="Поиск"
                            name="query"
                            value={values.query}
                            onChange={handleChange}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleSubmit()
                                }
                            }}
                            onFocus={setOpenDesktopSearchState}
                            onBlur={onTextFieldBlur}
                            sx={{
                                input: {
                                    '&::placeholder': {
                                        color: colorByVariant,
                                        opacity: 1,
                                    },
                                },
                                '.MuiInput-input': {
                                    pt: '4px',
                                },
                                '.MuiInput-underline:before': {
                                    display: 'none',
                                },
                            }}
                            InputProps={{
                                startAdornment: (
                                    <>
                                        <Box
                                            mr={1.25}
                                            onClick={searchIconClick}
                                            sx={{
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <SearchIcon
                                                color={colorByVariant}
                                            />
                                        </Box>
                                        <IconButton
                                            sx={{
                                                display: isDesktopSearchOpened
                                                    ? 'unset'
                                                    : 'none',
                                                position: 'absolute',
                                                top: '6px',
                                                right: 0,
                                                p: 0,
                                                color: theme.palette.grey[400],
                                                '&:hover': {
                                                    color: theme.palette.primary
                                                        .main,
                                                },
                                            }}
                                            onClick={handleClearSearch}
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </>
                                ),
                            }}
                        />
                    </Box>
                )
            }}
        </Formik>
    )
}
