import { ChevronRight as ChevronRightIcon } from '@mui/icons-material'
import { Box, Container, Divider, Drawer, Typography } from '@mui/material'
import { SocialNetsBlock } from 'app/components/SocialNetsBlock/SocialNetsBlock'
import { LanguageSwitch } from 'app/features/YandexTranslate/LanguageSwitch'
import { selectHeaderCategories } from 'app/modules/Categories/slice/selectors'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { MOBILE_NAVIGATION_HEIGHT } from '../../Navigation/partials/MobileNavigation'
import { HeaderLocation } from '../HeaderLocation'
import { HeaderLocationSwitch } from '../HeaderLocationSwitch'
import { MOBILE_HEADER_HEIGHT } from '.'

interface MobileHeaderMenuProps {
    isOpened: boolean
}

export const MobileHeaderMenu = React.memo<MobileHeaderMenuProps>(
    ({ isOpened }) => {
        const categories = useSelector(selectHeaderCategories)

        return (
            <Drawer
                open={isOpened}
                anchor="top"
                sx={{
                    top: MOBILE_HEADER_HEIGHT,
                    height: `calc(100% - ${MOBILE_NAVIGATION_HEIGHT}px - ${MOBILE_HEADER_HEIGHT}px)`,
                    zIndex: 1001,
                }}
                PaperProps={{
                    sx: {
                        top: MOBILE_HEADER_HEIGHT,
                        height: `calc(100% - ${MOBILE_NAVIGATION_HEIGHT}px - ${MOBILE_HEADER_HEIGHT}px)`,
                    },
                }}
                BackdropProps={{ sx: { display: 'none' } }}
            >
                <Divider />

                <Container>
                    <Box
                        sx={{
                            py: 2.5,
                        }}
                    >
                        <HeaderLocationSwitch size="large" variant="dark" />

                        <HeaderLocation />
                    </Box>
                </Container>

                <Divider />

                <Container>
                    <Box
                        sx={{
                            py: 2.5,
                        }}
                    >
                        <LanguageSwitch />
                    </Box>
                </Container>

                <Divider />

                <Container>
                    <Box
                        sx={{
                            py: 1.5,
                        }}
                    >
                        {categories.map((category, index) => (
                            <Typography
                                sx={{
                                    py: 1.75,
                                    fontWeight: 'medium',
                                    textTransform: 'uppercase',
                                }}
                                key={index}
                                variant="body2"
                            >
                                <Link to={`/${category.attributes.permalink}`}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                        }}
                                        component="span"
                                    >
                                        {category.attributes.name}

                                        <ChevronRightIcon />
                                    </Box>
                                </Link>
                            </Typography>
                        ))}
                    </Box>
                </Container>

                <Divider />

                <Container>
                    <Typography
                        sx={{
                            pt: 3.75,
                            pb: 3.25,
                            fontWeight: 'medium',
                            textTransform: 'uppercase',
                        }}
                        variant="body2"
                    >
                        <Link to={`/store`}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                                component="span"
                            >
                                Магазины
                                <ChevronRightIcon />
                            </Box>
                        </Link>
                    </Typography>
                </Container>

                <Divider />

                <Container sx={{ pb: '10px' }}>
                    <Box sx={{ py: 3.75 }}>
                        <Typography
                            color="primary"
                            variant="body1"
                            fontWeight={500}
                        >
                            <a href="tel:+78002012323">8 (800) 201-23-23</a>
                        </Typography>
                    </Box>
                    <Box>
                        <SocialNetsBlock />
                    </Box>

                    <Typography
                        color="grey.800"
                        variant="body2"
                        fontWeight={500}
                        component={Link}
                        to={'/static/user_agreement'}
                        pt="16px"
                    >
                        Информация о продавце
                    </Typography>
                </Container>
            </Drawer>
        )
    }
)
