import { ILineItem } from 'types/ILineItem'
import { IProduct, ProductTypes } from 'types/IProduct'
import { IPromotion } from 'types/IPromotion'
import { IVariant } from 'types/IVariant'
import { readonlyArrayIncludes } from 'utils/readonlyArrayincIudes'

import { ResourceObject } from '.'

export const isILineItem = (item: ResourceObject): item is ILineItem =>
    item.type === 'line_item'

export const isIProduct = (item: ResourceObject): item is IProduct =>
    readonlyArrayIncludes(ProductTypes, item.type)

export const isIVariant = (item: ResourceObject): item is IVariant =>
    item.type === 'variant'

export const isIPromotion = (item: ResourceObject): item is IPromotion =>
    item.type === 'promotion'
