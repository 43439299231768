import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material'
import { DESKTOP_HEADER_HEIGHT } from 'app/modules/Layout/components/Header/DesktopHeader'
import React from 'react'

import FranchiseBanner_desktop from '../assets/FranchiseBanner_desktop.jpg'
import FranchiseBanner_mobile from '../assets/FranchiseBanner_mobile.jpg'

export interface FranchiseBannerProps {
    onButtonClick: () => void
}

export const FranchiseBanner: React.FC<FranchiseBannerProps> = ({
    onButtonClick,
}) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))

    return (
        <Box
            display={'flex'}
            alignItems={'center'}
            flexDirection={'column'}
            position="relative"
            sx={{
                mt: { lg: `${DESKTOP_HEADER_HEIGHT}px` },
            }}
        >
            <Box
                sx={{
                    objectFit: 'cover',
                    width: { xs: '100vw', md: '100%' },
                }}
                component={'img'}
                src={
                    isMobile ? FranchiseBanner_mobile : FranchiseBanner_desktop
                }
            />

            <Button
                sx={{
                    position: 'absolute',
                    height: { xs: '33.57px', md: '65.4px' },
                    maxWidth: '400px',
                    width: { xs: '200px', md: '389px' },
                    top: { xs: '73.6vw', md: '23vw' },
                    background: '#FFFFFF',
                    border: {
                        xs: '1.02667px solid #1C3D20',
                        md: '2px solid #54A67F',
                    },
                    borderRadius: '49px',
                }}
                onClick={onButtonClick}
            >
                <Typography
                    fontWeight="medium"
                    sx={{ fontSize: { xs: '14px', md: '28px' } }}
                >
                    Оставить заявку
                </Typography>
            </Button>
        </Box>
    )
}
