import { Typography, useMediaQuery, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

import { ReactComponent as DolyameLogoDesktop } from './assets/DolyameLogoDesktop.svg'
import { ReactComponent as DolyameLogoMobile } from './assets/DolyameLogoMobile.svg'
import DolyameMainBannerImage from './assets/DolyameMainBannerImage.png'

export const DolyameMainBanner: React.FC = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))

    return (
        <Box
            display="flex"
            flexWrap="wrap"
            alignItems="center"
            justifyContent="space-between"
        >
            <Box
                sx={{
                    maxWidth: { md: '36vw', lg: '590px' },
                    width: { md: '44vw' },
                }}
            >
                <Box sx={{ maxWidth: { xs: '310px', md: '100%' } }}>
                    <Typography
                        component="h1"
                        sx={{
                            fontSize: { xs: '24px', md: '42px' },
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                            display: 'inline',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 'inherit',
                                fontWeight: 'inherit',
                                display: 'inherit',
                                color: theme.palette.primary.main,
                            }}
                        >
                            покупайте сейчас
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 'inherit',
                                fontWeight: 'inherit',
                                display: { xs: 'inherit', md: 'inline-block' },
                                pr: { xs: '14px' },
                            }}
                        >
                            {' — платите'}
                        </Typography>
                    </Typography>

                    <Box
                        display="inline-block"
                        sx={{
                            transform: {
                                xs: 'translate(0px,4px)',
                                md: 'translate(0px,6px)',
                            },
                        }}
                    >
                        {isMobile ? (
                            <DolyameLogoMobile />
                        ) : (
                            <DolyameLogoDesktop />
                        )}
                    </Box>
                </Box>
                <Box mt={{ xs: '10px', md: '20px' }}>
                    <Typography component="span">
                        Платежи можно делить на четыре части с помощью сервиса «
                        <Typography
                            fontWeight="medium"
                            sx={{
                                display: 'inline',
                                color: theme.palette.primary.main,
                            }}
                        >
                            Долями
                        </Typography>
                        »: вы платите 25 % и сразу забираете покупки, а
                        остальные три части спишутся потом.
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    width: { xs: '91.466666vw', md: '46.875vw' },
                    mt: { xs: '32px', md: '0px' },
                }}
            >
                <Box
                    component="img"
                    src={DolyameMainBannerImage}
                    sx={{ objectFit: 'cover', width: 'inherit' }}
                />
            </Box>
        </Box>
    )
}
