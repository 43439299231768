import { useMediaQuery, useTheme } from '@mui/material'
import React from 'react'

import { DesktopExperienceBanner } from './DesktopExperienceBanner'
import { MobileExperienceBanner } from './MobileExperienceBanner'

export const ExperienceBanner: React.FC = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'lg'))
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

    return (
        <>
            {isMobile && <MobileExperienceBanner />}

            {isDesktop && <DesktopExperienceBanner />}
        </>
    )
}
