import { Box, Radio, Typography } from '@mui/material'
import React from 'react'
import { PaymentMethodId } from 'types/IPaymentsShipmentsAttributes'

interface PaymentItemProps {
    active: boolean
    item: IPaymentItem
}

export interface IPaymentItem {
    text: string
    value: PaymentMethodId
    handleChange: (paymentMethodId: PaymentMethodId) => void
    IconComponent: React.ReactNode
    ButtomComponent?: React.ReactNode
}

export const PaymentItem: React.FC<PaymentItemProps> = ({ item, active }) => {
    const { value, text, IconComponent, handleChange, ButtomComponent } = item
    return (
        <>
            <Box
                px="30px"
                height="92px"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={(theme) => ({
                    border: active
                        ? `1.5px solid ${theme.palette.primary.main}`
                        : `1.5px solid transparent`,
                    borderRadius: '10px',
                    backgroundColor: active
                        ? `${theme.palette.primary.main}14`
                        : 'unset',
                    cursor: 'pointer',
                })}
                onClick={() => handleChange(value)}
            >
                <Box display="flex" alignItems="center">
                    <Box mr="16px" display="flex" alignItems="center">
                        {IconComponent}
                    </Box>
                    <Typography
                        variant="body1"
                        color="black"
                        fontWeight="medium"
                    >
                        {text}
                    </Typography>
                </Box>
                <Radio
                    checked={active}
                    value={value}
                    name="radio-buttons"
                    inputProps={{
                        'aria-label': value,
                    }}
                    sx={{
                        color: '#DEDEDE',
                        px: 0,
                    }}
                />
            </Box>
            {active && ButtomComponent}
        </>
    )
}
