import {
    Box,
    Tab,
    TabProps,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { ReactComponent as DeliveryStateTabRadioChecked } from 'app/modules/Cart/assets/DeliveryStateTabRadioChecked.svg'
import { ReactComponent as DeliveryStateTabRadioUnchecked } from 'app/modules/Cart/assets/DeliveryStateTabRadioUnchecked.svg'
import React from 'react'

interface DeliveryStateTabProps {
    value: string
    text: string
    subtext?: string
    checked: boolean
}

export const DeliveryStateTab: React.FC<DeliveryStateTabProps & TabProps> = ({
    value,
    text,
    subtext,
    checked,
    ...props
}) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

    return (
        <Tab
            value={value}
            sx={{
                width: '50%',
                border: '1.5px solid transparent',
                px: { xs: '16px', md: '30px' },
                '&.Mui-selected': {
                    backgroundColor: 'rgba(84, 166, 127, 0.08)',
                    border: '1.5px solid #54A67F',
                    borderRadius: '10px',
                },
            }}
            label={
                <Box display="flex" width="100%" alignItems="center">
                    {isDesktop &&
                        (checked ? (
                            <DeliveryStateTabRadioChecked />
                        ) : (
                            <DeliveryStateTabRadioUnchecked />
                        ))}
                    <Typography
                        variant="h6"
                        sx={(theme) => ({
                            fontSize: { xs: '14px', md: '16px' },
                            fontWeight: 500,
                            textAlign: 'left',
                            color: props.disabled
                                ? theme.palette.text.disabled
                                : theme.palette.text.primary,
                            maxWidth: '178px',
                            ml: { xs: '0px', md: '20px' },
                        })}
                    >
                        {text}
                        <Typography
                            fontSize="14px"
                            fontWeight="regular"
                            color="#858585"
                            mt="2px"
                        >
                            {subtext}
                        </Typography>
                    </Typography>
                    {isMobile &&
                        (checked ? (
                            <DeliveryStateTabRadioChecked />
                        ) : (
                            <DeliveryStateTabRadioUnchecked />
                        ))}
                </Box>
            }
            {...props}
        />
    )
}
