import { Typography, useTheme } from '@mui/material'
import React from 'react'

interface ILoyaltyInfo {
    max_online_percent?: number
    maxBonuses: number
    maxAmount: number
}

export const LoyaltyInfo: React.FC<ILoyaltyInfo> = ({
    max_online_percent,
    maxBonuses,
    maxAmount,
}) => {
    const theme = useTheme()

    if (!max_online_percent) {
        return (
            <Typography variant="body2">
                Бонусы и промокод нельзя использовать вместе, а также применять
                к товарам со скидкой.
            </Typography>
        )
    }

    return (
        <Typography variant="body2" component="span">
            У вас
            <Typography
                variant="body2"
                style={{ color: theme.palette.primary.main }}
                display="inline"
                fontWeight="medium"
            >
                {` ${maxBonuses} бонусных рублей. `}
            </Typography>
            Вы можете использовать
            <Typography
                variant="body2"
                style={{ color: theme.palette.primary.main }}
                display="inline"
                fontWeight="medium"
            >
                {` до ${maxAmount} б.р. `}
            </Typography>
            ({max_online_percent}% от стоимости товаров без скидки).
            <br />
            Бонусы и промокод нельзя использовать вместе, а также применять к
            товарам со скидкой.
        </Typography>
    )
}
