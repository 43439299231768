import { Typography, useTheme } from '@mui/material'
import { HeaderColorVariant } from 'app/modules/Layout/types/HeaderColorVariant'
import React from 'react'

interface HeaderPhoneProps {
    variant: HeaderColorVariant
}

export const HeaderPhone: React.FC<HeaderPhoneProps> = ({ variant }) => {
    const theme = useTheme()

    const colorByVariant = (() => {
        switch (variant) {
            case 'dark':
                return theme.palette.text.primary
            case 'light':
                return theme.palette.common.white
            default:
                return ''
        }
    })()

    return (
        <Typography
            sx={{ color: colorByVariant }}
            variant="body3"
            fontWeight={500}
        >
            <a href="tel:+78002012323">8 800 201 23 23</a>
        </Typography>
    )
}
