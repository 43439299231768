import { Box, Container } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

export const JournalTitle: React.FC = () => {
    return (
        <Container>
            <Link to={'/journal/'}>
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        mt: {
                            xs: 3,
                            lg: 7.25,
                        },
                        objectFit: 'cover',
                        backgroundSize: '99%',
                        backgroundImage: 'url(/assets/images/bg_soul_life.jpg)',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                    }}
                    component="img"
                    src="/assets/images/text_soul_life_bold.svg"
                />
            </Link>
        </Container>
    )
}
