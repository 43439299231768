import {
    createEntityAdapter,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit'
import { IAddress } from 'types/IAddress'

import { AddressesState } from './types'

export const addressesAdapter = createEntityAdapter<IAddress>()

const slice = createSlice({
    name: 'addresses',
    initialState: addressesAdapter.getInitialState<AddressesState>({
        ids: [],
        entities: {},
    }),
    reducers: {
        addressesAdded(state, action: PayloadAction<IAddress[]>) {
            addressesAdapter.setMany(state, action.payload)
        },
    },
})

export const { actions: addressesActions, reducer: addressesReducer } = slice
