export const productCountText = (itemCount: number) => {
    const itemCountLastDigit = itemCount % 10
    const itemCountDecimalDigit = Math.floor(itemCount / 10)

    if (itemCountDecimalDigit === 1) return 'товаров'

    switch (itemCountLastDigit) {
        case 1:
            return 'товар'
        case 2:
        case 3:
        case 4:
            return 'товара'
        default:
            return 'товаров'
    }
}
