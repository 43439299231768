import { Box, Typography } from '@mui/material'
import React from 'react'

interface MobileMenuItemProps {
    icon?: JSX.Element | string
    color?: string
    text?: JSX.Element | string
    endContent?: JSX.Element
    onClick?: () => void
}

const MenuText: React.FC<{ text: string; color?: string }> = ({
    text,
    color,
}) => (
    <Typography
        sx={{
            fontWeight: 'medium',
            textTransform: 'uppercase',
        }}
        color={color}
        variant="body2"
    >
        {text}
    </Typography>
)

export const MobileMenuItem: React.FC<MobileMenuItemProps> = ({
    endContent,
    color,
    icon,
    text,
    onClick,
}) => (
    <Box
        sx={{
            py: 1.75,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        }}
        component="span"
        onClick={onClick}
    >
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            {!!icon && typeof icon === 'string' && (
                <Box
                    display={'flex'}
                    alignItems={'center'}
                    src={icon}
                    component={'img'}
                    alt={'icon'}
                    sx={{
                        mr: 2,
                    }}
                />
            )}
            {!!icon && React.isValidElement(icon) && (
                <Box mr={2} display={'flex'} alignItems={'center'}>
                    {icon}
                </Box>
            )}

            {typeof text === 'string' ? (
                <MenuText text={text} color={color} />
            ) : (
                text
            )}
        </Box>

        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            {endContent}
        </Box>
    </Box>
)
