import { Box, FormGroup } from '@mui/material'
import { SALE_CATEGORY_ID } from 'app/modules/Categories'
import {
    selectCategories,
    selectChildrenCategories,
    selectHeaderCategories,
} from 'app/modules/Categories/slice/selectors'
import { ProductsFilterShowMoreFiltersButton } from 'app/modules/Products/components/ProductsFilter/ProductsFiltersList/components/ProductsFilterShowMoreFiltersButton'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { useCategoryPageContext } from '../../hooks'
import { ProductsFilterKind } from '../../ProductsFilterKind'
import { ProductsFilterFormControlLabel } from './ProductsFilterFormControlLabel'

interface I {
    category: string
    opened?: boolean
}
export const ProductsFilterSubCategory: React.FC<I> = ({
    category,
    opened,
}) => {
    const [collapsed, setCollapsed] = useState(true)
    const {
        filterReadAdapter,
        handleClearFilter,
        handleChangeFilter,
        isSaleCategory,
        handleTaxonsChange,
    } = useCategoryPageContext()
    const getSubCategories = useSelector(selectChildrenCategories)

    const categories = useSelector(selectCategories)
    const headerCategories = useSelector(selectHeaderCategories)

    const filterCategories = isSaleCategory
        ? headerCategories
        : getSubCategories(
              categories
                  .find((i) => i.id === '1')
                  ?.categories?.find((i) => i.id === category)?.id
          ) || null

    if (filterCategories.length === 0) return null

    const option_values = collapsed
        ? filterCategories.slice(0, 5)
        : filterCategories

    return (
        <ProductsFilterKind
            name={
                headerCategories.find((i) => i.id === category)?.attributes
                    .name || 'Подкатегория'
            }
        >
            <FormGroup>
                {option_values
                    .filter((category) => category.id !== SALE_CATEGORY_ID)
                    .map((category) => {
                        return (
                            <Box
                                key={category.id}
                                sx={{
                                    mb: { xs: '12px', lg: '2px' },
                                    height: { xs: '24px', md: '28px' },
                                }}
                            >
                                <ProductsFilterFormControlLabel
                                    labelText={category.attributes.name}
                                    checked={
                                        filterReadAdapter?.taxons?.includes(
                                            category.id
                                        ) || false
                                    }
                                    onChange={(event) => {
                                        const checked = event.target.checked
                                        handleTaxonsChange(category.id, checked)
                                    }}
                                />
                            </Box>
                        )
                    })}
            </FormGroup>
            {filterCategories?.length > 5 && (
                <ProductsFilterShowMoreFiltersButton
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}
                />
            )}
        </ProductsFilterKind>
    )
}
