import {
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
    useTheme,
} from '@mui/material'
import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'

interface AccountMenuItemProps {
    icon: JSX.Element
    text: string
    link: string
}

export const AccountMenuItem: React.FC<AccountMenuItemProps> = ({
    icon,
    text,
    link,
}) => {
    const { url } = useRouteMatch()
    const isActive = () =>
        url === link || (link === '/profile/personal' && url === '/profile')
    const theme = useTheme()
    return (
        <Link to={link}>
            <ListItemButton>
                <ListItemIcon>
                    {React.cloneElement(icon, {
                        stroke: isActive()
                            ? theme.palette.primary.main
                            : theme.palette.grey[400],
                    })}
                </ListItemIcon>

                <ListItemText>
                    <Typography
                        sx={{ textTransform: 'uppercase' }}
                        color={isActive() ? 'primary' : 'text'}
                        variant="body2"
                        fontWeight="medium"
                    >
                        {text}
                    </Typography>
                </ListItemText>
            </ListItemButton>
        </Link>
    )
}
