import 'react-multi-carousel/lib/styles.css'

import { Box, useMediaQuery, useTheme } from '@mui/material'
import { bannersActions } from 'app/modules/Banners/slice'
import {
    selectCaruselDesktopBanners,
    selectCaruselMobileBanners,
    selectMainBanners,
} from 'app/modules/Banners/slice/selectors'
import React, { useEffect } from 'react'
import Carousel from 'react-multi-carousel'
import { useDispatch, useSelector } from 'react-redux'

import { BannerContent } from '../BannerContent'

export const MainBanner: React.FC = () => {
    const responsive = {
        desktop: {
            breakpoint: { max: 4000, min: 464 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    }
    const dispatch = useDispatch()

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))

    const banners = useSelector(selectCaruselDesktopBanners)
    const mobileBanners = useSelector(selectCaruselMobileBanners)
    const mainBanners = useSelector(selectMainBanners)

    useEffect(
        function LoadMainBanners() {
            if (mainBanners.length == 0)
                dispatch(bannersActions.loadMainBanner())
        },
        [mainBanners.length]
    )

    return (
        <Box
            sx={{
                position: 'relative',
                width: '100%',
                minHeight: { xs: '100vw', md: 'unset' },
                // maxHeight: { xs: '120vw', md: '39.5vw' },
            }}
        >
            {!isMobile && banners && banners.length > 0 && (
                <Carousel
                    showDots={banners.length > 1}
                    autoPlay={banners.length > 1}
                    infinite={banners.length > 1}
                    responsive={responsive}
                    autoPlaySpeed={10000}
                >
                    {banners.map((banner) => (
                        <BannerContent key={banner.id} content={banner} />
                    ))}
                </Carousel>
            )}
            {isMobile && mobileBanners && mobileBanners.length > 0 && (
                <Carousel
                    showDots={banners.length > 1}
                    autoPlay={banners.length > 1}
                    infinite={banners.length > 1}
                    responsive={responsive}
                    autoPlaySpeed={10000}
                >
                    {mobileBanners.map((banner) => (
                        <BannerContent key={banner.id} content={banner} />
                    ))}
                </Carousel>
            )}
            {/* {mainBanner && <BannerContent content={mainBanner} />} */}
        </Box>
    )
}
