import 'swiper/swiper.min.css'
import 'swiper/modules/navigation/navigation.min.css' // Navigation module
import 'swiper/modules/pagination/pagination.min.css' // Pagination module
import './styles.css'

import { Box, CardMedia, Skeleton } from '@mui/material'
import { AutoplayVideo } from 'app/components/AutoplayVideo'
import React, { useRef } from 'react'
import SwiperCore, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { useProductVisualContent } from '../../hooks/useProductVisualContent'
import { IProductVisualContent } from '../../slice/types'
import { ProductLikeIcon } from '../ProductFavorite'
import { ProductCardProps } from '.'
import { ImageSizeProps } from './ProductCard'
import { ProductCardImagePicker } from './ProductCardImagePicker'
import { SaleBadget } from './SaleBadget'
import { TruckBadget } from './TruckBadget'

SwiperCore.use([Pagination])

export const ProductCardImageCarousel: React.FC<{
    productVisualContent: IProductVisualContent[]
}> = ({ productVisualContent }) => {
    const swiperRef = useRef<SwiperCore>()

    const renderBullet = function (index: number, className: string) {
        return `<span class="${className}" id="swiper-pagination-bullet-ProductCardImage"></span>`
    }

    return (
        <Box
            className="ProductCardImageCarousel"
            sx={{
                width: ImageSizeProps,
                height: ImageSizeProps,
            }}
        >
            <Swiper
                modules={[Pagination]}
                pagination={{
                    clickable: true,
                    dynamicBullets: true,
                    dynamicMainBullets: 3,
                    renderBullet,
                }}
                onInit={(swiper) => {
                    swiperRef.current = swiper
                }}
            >
                {productVisualContent.map((visualContent, index) => (
                    <SwiperSlide key={index}>
                        <Box
                            sx={{
                                overflow: 'hidden',
                                borderRadius: { xs: '16px', lg: '28px' },
                                WebkitMaskImage:
                                    '-webkit-radial-gradient(white, black)',
                            }}
                        >
                            {'videoSrc' in visualContent ? (
                                <AutoplayVideo
                                    src={visualContent.videoSrc}
                                    poster={visualContent.placeholder}
                                    sx={{
                                        objectFit: 'cover',
                                        width: ImageSizeProps,
                                        height: ImageSizeProps,
                                    }}
                                />
                            ) : (
                                <CardMedia
                                    sx={(theme) => ({
                                        objectFit: 'cover',
                                        width: ImageSizeProps,
                                        height: ImageSizeProps,
                                        transition: theme.transitions.create(
                                            ['transform'],
                                            {
                                                duration:
                                                    theme.transitions.duration
                                                        .standard,
                                            }
                                        ),
                                        '&:hover': { transform: 'scale(1.05)' },
                                    })}
                                    src={visualContent.mini}
                                    component="img"
                                />
                            )}
                        </Box>
                    </SwiperSlide>
                ))}
            </Swiper>

            <Box
                sx={{
                    position: 'absolute',
                    bottom: { xs: '8px', md: '10px', xl: '12px' },
                    width: '100%',
                    zIndex: 1,
                }}
            >
                <ProductCardImagePicker
                    productVisualContent={productVisualContent}
                    setImageIndex={(newValue) => {
                        swiperRef.current?.slideTo(newValue)
                    }}
                />
            </Box>
        </Box>
    )
}

export const ProductCardImages: React.FC<
    ProductCardProps & { isHover: boolean }
> = ({ product, isHover }) => {
    const { productImageContent, productVideoContent } =
        useProductVisualContent(product)

    const productVisualContent = [...productImageContent]

    productVisualContent.splice(1, 0, ...productVideoContent)

    if (!product)
        return (
            <Skeleton
                sx={{
                    width: ImageSizeProps,
                    height: ImageSizeProps,
                    borderRadius: { xs: '16px', lg: '28px' },
                }}
                variant="rectangular"
            />
        )

    return (
        <Box
            sx={{
                width: ImageSizeProps,
                height: ImageSizeProps,
                position: 'relative',
            }}
        >
            <ProductCardImageCarousel
                productVisualContent={productVisualContent.slice(0, 6)}
            />

            <Box
                sx={{
                    position: 'absolute',
                    top: {
                        xs: 8,
                        md: 20,
                    },
                    left: {
                        xs: 8,
                        md: 20,
                    },

                    height: {
                        xs: 23,
                        md: 35,
                    },
                    zIndex: 1,
                }}
            >
                <SaleBadget salePercent={product.attributes.sale_percent} />
            </Box>

            <Box
                sx={{
                    position: 'absolute',
                    top: {
                        md: '16px',
                    },
                    right: {
                        md: '16px',
                    },
                    left: { xs: '3px', md: 'initial' },
                    bottom: { xs: '3px', md: 'initial' },
                    zIndex: 1,
                }}
            >
                <ProductLikeIcon product={product} isHover={isHover} />
            </Box>

            {/* <Box
                sx={{
                    position: 'absolute',
                    bottom: {
                        md: '16px',
                    },
                    left: {
                        md: '16px',
                    },
                    right: { xs: '3px', md: 'initial' },
                    top: { xs: '3px', md: 'initial' },
                    zIndex: 1,
                }}
            >
                <TruckBadget />
            </Box> */}
        </Box>
    )
}
