import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EStatus } from 'types'
import {
    ICustomerInformation,
    ShippingMethodType,
} from 'types/ICustomerInformation'
import { IMindBoxData } from 'types/IMindBoxData'
import { IPartnerInformation } from 'types/IPartnerInformation'
import {
    IPaymentsAttributes,
    IPaymentsResponseAttributes,
    IShipmentsAttributes,
    PaymentMethodId,
} from 'types/IPaymentsShipmentsAttributes'
import { IUserItemResponse } from 'types/IUser'

import { CheckoutState, LoyaltyPromoType } from './types'

const initialState: CheckoutState = {
    status: EStatus.INITIAL,
    forms: {
        partnerInformation: {
            status: EStatus.INITIAL,
            data: {
                order: {
                    email: '',
                    legal_name: '',
                    ship_address_attributes: {
                        lastname: '',
                        firstname: '',
                        phone: '',
                    },
                },
            },
        },
        customerInformation: {
            status: EStatus.INITIAL,
            data: {
                order: {
                    email: '',
                    roistat: '',
                    delivery_state: ShippingMethodType.courier,
                    special_instructions: '',
                    ship_address_attributes: {
                        firstname: '',
                        lastname: '',
                        address1: '',
                        city: '',
                        phone: '',
                        zipcode: '',
                        country_iso: '',
                        lat: 0,
                        lng: 0,
                    },
                },
            },
        },
        paymentsShipmentsAttributes: {
            loading: false,
            data: {
                order: {
                    shipments_attributes: [],
                    payments_attributes: [],
                },
            },
        },
        loyalty: {
            loading: false,
            data: {
                available_bonuses: 0,
                bonuses: 0,
                promocode_total: 0,
                promocode_error: '',
                whichPromoUsed: LoyaltyPromoType.none,
            },
        },
        mindBoxData: {
            coupon_discount: null,
            coupon_code: null,
            items_discount: 0,
        },
        paymentMethodId: null,
    },
}

const slice = createSlice({
    name: 'checkout',
    initialState,
    reducers: {
        loadCheckoutNext() {
            //
        },
        loadCheckoutPrevious() {
            //
        },
        loadPaymentMethods() {
            //
        },
        applyBonuses(state, _action: PayloadAction<number>) {
            state.forms.loyalty.loading = true
        },
        setBonuses(state, action: PayloadAction<string>) {
            state.forms.loyalty.loading = false
            state.forms.loyalty.data.bonuses = Math.abs(
                parseFloat(action.payload)
            )
        },
        setAvailableBonuses(state, action: PayloadAction<number>) {
            state.forms.loyalty.loading = false
            state.forms.loyalty.data.available_bonuses = action.payload
        },
        applyPromocode(state, _action: PayloadAction<string>) {
            state.forms.loyalty.loading = true
        },
        deletePromocode(state) {
            state.forms.loyalty.loading = true
        },
        setPromocode(state, action: PayloadAction<string>) {
            state.forms.loyalty.loading = false
            state.forms.loyalty.data.promocode_total = Math.abs(
                parseFloat(action.payload || '0')
            )
        },
        setPromocodeError(state, action: PayloadAction<string>) {
            state.forms.loyalty.loading = false
            state.forms.loyalty.data.promocode_error = action.payload
        },
        setWhichPromoUsed(state, action: PayloadAction<LoyaltyPromoType>) {
            state.forms.loyalty.data.whichPromoUsed = action.payload
        },
        setMindBoxData(state, action: PayloadAction<IMindBoxData>) {
            state.forms.mindBoxData = action.payload
        },
        cleanCustomerInformationStatus(state) {
            state.forms.customerInformation.status = EStatus.INITIAL
        },
        updateCustomerInformation(
            state,
            _action: PayloadAction<ICustomerInformation>
        ) {
            state.forms.customerInformation.status = EStatus.PENDING
        },
        customerInformationUpdated(state) {
            state.forms.customerInformation.status = EStatus.FINISHED
        },
        setCustomerInformationDeliveryState(
            state,
            action: PayloadAction<ShippingMethodType>
        ) {
            // state.forms.customerInformation.data.order.delivery_state = action.payload
        },
        setCustomerInformation(
            state,
            action: PayloadAction<ICustomerInformation | undefined>
        ) {
            if (action.payload) {
                state.forms.customerInformation.data.order = {
                    ...state.forms.customerInformation.data.order,
                    ...action.payload.order,
                    delivery_state: ShippingMethodType.courier,
                    ship_address_attributes: {
                        ...state.forms.customerInformation.data.order
                            .ship_address_attributes,
                        ...action.payload.order.ship_address_attributes,
                    },
                }
            }
        },
        setDefaultCustomerInformation(
            state,
            action: PayloadAction<IUserItemResponse>
        ) {
            if (!state.forms.customerInformation.data.order.email) {
                state.forms.customerInformation.data.order.email =
                    action.payload.data.attributes.email
            }
            if (
                !state.forms.customerInformation.data.order
                    .ship_address_attributes.firstname
            ) {
                state.forms.customerInformation.data.order.ship_address_attributes.firstname =
                    action.payload.data.attributes.firstname
            }
            if (
                !state.forms.customerInformation.data.order
                    .ship_address_attributes.lastname
            ) {
                state.forms.customerInformation.data.order.ship_address_attributes.lastname =
                    action.payload.data.attributes.lastname
            }
            if (
                !state.forms.customerInformation.data.order
                    .ship_address_attributes.phone
            ) {
                state.forms.customerInformation.data.order.ship_address_attributes.phone =
                    action.payload.data.attributes.phone
            }

            // PARTNER

            if (!state.forms.partnerInformation.data.order.email) {
                state.forms.partnerInformation.data.order.email =
                    action.payload.data.attributes.email
            }
            if (
                !state.forms.partnerInformation.data.order
                    .ship_address_attributes.firstname
            ) {
                state.forms.partnerInformation.data.order.ship_address_attributes.firstname =
                    action.payload.data.attributes.firstname
            }
            if (
                !state.forms.partnerInformation.data.order
                    .ship_address_attributes.lastname
            ) {
                state.forms.partnerInformation.data.order.ship_address_attributes.lastname =
                    action.payload.data.attributes.lastname
            }
            if (
                !state.forms.partnerInformation.data.order
                    .ship_address_attributes.phone
            ) {
                state.forms.partnerInformation.data.order.ship_address_attributes.phone =
                    action.payload.data.attributes.phone
            }
        },
        confirmDeliveryMethod() {
            //
        },
        updatePaymentsAttributes(
            _state,
            _action: PayloadAction<IPaymentsResponseAttributes>
        ) {
            //
        },
        setShipmentsAttributes(
            state,
            action: PayloadAction<IShipmentsAttributes>
        ) {
            if (action.payload) {
                state.forms.paymentsShipmentsAttributes.data.order.shipments_attributes =
                    [action.payload]
            }
        },
        setPaymentsAttributes(
            state,
            action: PayloadAction<IPaymentsAttributes[]>
        ) {
            if (action.payload) {
                state.forms.paymentsShipmentsAttributes.data.order.payments_attributes =
                    [...action.payload]
            }
        },
        orderComplete() {
            //
        },
        cleanPartnerInformationStatus(state) {
            state.forms.partnerInformation.status = EStatus.INITIAL
        },
        partnerOrderComplete(
            state,
            _action: PayloadAction<IPartnerInformation>
        ) {
            state.forms.partnerInformation.status = EStatus.PENDING
        },
        statusError(state) {
            state.forms.customerInformation.status = EStatus.ERROR
        },
        setRoistatVisit(state, action: PayloadAction<string>) {
            state.forms.customerInformation.data.order.roistat = action.payload
        },
        setPaymentMethodId(state, action: PayloadAction<PaymentMethodId>) {
            state.forms.paymentMethodId = action.payload
        },
    },
})

export const { actions: checkoutActions, reducer: checkoutReducer } = slice
