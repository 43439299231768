import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

export type IRouterContext = {
    to: string
    from: string
}

const RouterContext = React.createContext<IRouterContext>({ to: '', from: '' })

export function useRouterContext(): IRouterContext {
    const context = useContext(RouterContext)

    if (context === undefined) {
        throw new Error(
            'useRouterContext must be used within the RouterContextProvider'
        )
    }

    return context
}

export const RouterContextProvider: React.FC = ({ children }) => {
    const location = useLocation()
    const [route, setRoute] = useState({
        to: location.pathname,
        from: location.pathname,
    })

    useEffect(() => {
        setRoute((prev) => ({ to: location.pathname, from: prev.to }))
    }, [location])

    return (
        <RouterContext.Provider value={route}>
            {children}
        </RouterContext.Provider>
    )
}
