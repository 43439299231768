import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { selectActiveStorageBlobImageById } from 'app/modules/ActiveStorageBlob/slice/selectors'
import React from 'react'
import { useSelector } from 'react-redux'

import { selectAccount } from '../slice/selectors'
import { AvatarImage } from './AvatarImage'

export const AccountInfo: React.FC = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))

    const account = useSelector(selectAccount)
    const getImage = useSelector(selectActiveStorageBlobImageById)

    const userName =
        account?.attributes.firstname || account?.attributes.lastname
            ? [account.attributes.firstname, account.attributes.lastname].join(
                  ' '
              )
            : account?.attributes.email
            ? account?.attributes.email
            : account?.attributes.phone || ''

    const imageSrc = getImage(account?.relationships?.avatar_blob?.data?.id)

    return (
        <Box display={'flex'} alignItems={'center'}>
            <AvatarImage
                name={userName}
                image={imageSrc}
                size={isMobile ? '64px' : '54px'}
            />
            <Typography
                variant="body2"
                fontWeight={500}
                sx={{ textTransform: 'uppercase', ml: 2 }}
            >
                {userName}
            </Typography>
        </Box>
    )
}
