import { ArrowBack as ArrowBackIcon } from '@mui/icons-material'
import { Box, Container, Grid, IconButton, Paper } from '@mui/material'
import { Auth } from 'app/modules/Auth/templates/Auth'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { offlineTradeActions } from '../slice'
import { selectPage } from '../slice/selectors'
import { EOfflineTradePage } from '../slice/types'
import { OfflineTradeLogo } from './OfflineTradeLogo'

export const OfflineTradeLayout: React.FC = ({ children }) => {
    const dispatch = useDispatch()

    const page = useSelector(selectPage)

    const handleHistoryBack = () => {
        switch (page) {
            case EOfflineTradePage.DONE:
                dispatch(offlineTradeActions.createOrderPage())
                break
            case EOfflineTradePage.CREATE_ORDER:
            case EOfflineTradePage.CREATE_CUSTOMER:
                dispatch(offlineTradeActions.startPage())
                break
        }
    }

    return (
        <Auth>
            <Box bgcolor="grey.100" height="100vh">
                <Container>
                    <Grid container sx={{ py: 2, alignItems: 'center' }}>
                        <Grid item xs>
                            {page !== EOfflineTradePage.START &&
                                page !== EOfflineTradePage.CUSTOMER_ADDED && (
                                    <IconButton
                                        sx={{ bgcolor: 'white' }}
                                        onClick={handleHistoryBack}
                                    >
                                        <ArrowBackIcon />
                                    </IconButton>
                                )}
                        </Grid>
                        <Grid
                            display={'flex'}
                            justifyContent={'center'}
                            item
                            xs
                        >
                            <OfflineTradeLogo />
                        </Grid>
                        <Grid item xs />
                    </Grid>
                </Container>

                <Container sx={{ mt: 2 }}>
                    <Paper elevation={0} sx={{ p: 2.5 }}>
                        {children}
                    </Paper>
                </Container>
            </Box>
        </Auth>
    )
}
