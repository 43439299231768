import { useMediaQuery, useTheme } from '@mui/material'
import React from 'react'

import { MobileUniverseISBlock } from './MobileUniverseISBlock'

export const UniverseISBlock: React.FC = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'lg'))

    return <>{isMobile && <MobileUniverseISBlock />}</>
}
