import { useRouteMatch } from 'react-router-dom'

export const isSearchPageOpen = () => {
    return window.location.href.includes('/search')
}

export const whichPageIsOpen = () => {
    const { url } = useRouteMatch()
    const result = []

    if (url.includes('/search')) result.push('search')
    if (url.includes('/store')) result.push('store')
    if (url.includes('/journal')) result.push('journal')
    if (url.includes('/favorites')) result.push('favorites')
    if (url.includes('/profile')) result.push('profile')

    return result
}
