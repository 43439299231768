import {
    createEntityAdapter,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit'
import { IImage } from 'types/IImage'

import { ImagesState } from './types'

export const imagesAdapter = createEntityAdapter<IImage>()

const slice = createSlice({
    name: 'imagesForArticles',
    initialState: imagesAdapter.getInitialState<ImagesState>({
        ids: [],
        entities: {},
    }),
    reducers: {
        imagesForArticlesAdded(state, action: PayloadAction<IImage[]>) {
            imagesAdapter.setMany(state, action.payload)
        },
    },
})

export const {
    actions: imagesForArticlesActions,
    reducer: imagesForArticlesReducer,
} = slice
