import { useMediaQuery, useTheme } from '@mui/material'
import React from 'react'

import { DesktopShopCardList } from './DesktopShopCardList'
import { MobileShopCardList } from './MobileShopCardList'

export const ShopCardList: React.FC = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'lg'))
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

    return (
        <>
            {isDesktop && <DesktopShopCardList />}

            {isMobile && <MobileShopCardList />}
        </>
    )
}
