import 'react-dadata/dist/react-dadata.css'

import {
    DesktopDatePicker,
    LoadingButton,
    LocalizationProvider,
} from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import {
    Box,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material'
import { testPhoneNumber } from 'app/components/PhoneField/utils/validatePhoneNumber'
import { useFormik } from 'formik'
import moment from 'moment'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EStatus } from 'types'
import * as yup from 'yup'

import { accountActions } from '../slice'
import {
    selectAccount,
    selectAccountForm,
    selectAccountLoadStatus,
} from '../slice/selectors'

interface AccountDataFormProps {
    hasEdit: boolean
    onEditFinish: () => void
}

export const AccountDataForm: React.FC<AccountDataFormProps> = ({
    hasEdit,
    onEditFinish,
}) => {
    const dispatch = useDispatch()

    const { data } = useSelector(selectAccountForm)
    const status = useSelector(selectAccountLoadStatus)
    const accaunt = useSelector(selectAccount)

    const validationSchema = yup.object({
        attributes: yup.object({
            email: yup.string().email('Не корректный Email').required(),
            phone: yup.string().test(testPhoneNumber),
        }),
    })

    const formik = useFormik({
        validationSchema,
        initialValues: data,
        validateOnBlur: true,
        validateOnChange: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            const birthday = moment
                .utc(
                    `
                    ${moment(values.attributes.birthday_date).format(
                        'DD.MM.yyyy'
                    )} 12:00
                    `,
                    'DD/MM/YYYY hh:mm'
                )
                .unix()

            onEditFinish()

            dispatch(
                accountActions.updateAccount({
                    email: values.attributes.email,
                    firstname: values.attributes.firstname,
                    lastname: values.attributes.lastname,
                    gender: values.attributes.gender,
                    phone: values.attributes.phone,
                    city: values.attributes.city,
                    birthday: data.attributes.birthday ? undefined : birthday,
                })
            )
        },
    })

    if (!accaunt) return null

    return (
        <Box
            noValidate
            component="form"
            onSubmit={(e: React.FormEvent) => {
                e.preventDefault()

                formik.handleSubmit()
            }}
        >
            <Grid container rowSpacing={3.75} columnSpacing={10}>
                <Grid item xs={12} md={4}>
                    <TextField
                        fullWidth
                        variant="standard"
                        label="Имя"
                        name="attributes.firstname"
                        value={formik.values.attributes.firstname || ''}
                        error={!!formik.errors.attributes?.firstname}
                        disabled={!hasEdit}
                        InputProps={{ disableUnderline: !hasEdit }}
                        onChange={formik.handleChange}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <TextField
                        fullWidth
                        variant="standard"
                        label="Фамилия"
                        name="attributes.lastname"
                        value={formik.values.attributes.lastname || ''}
                        error={!!formik.errors.attributes?.lastname}
                        disabled={!hasEdit}
                        InputProps={{ disableUnderline: !hasEdit }}
                        onChange={formik.handleChange}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <FormControl
                        fullWidth
                        variant="standard"
                        error={!!formik.errors.attributes?.gender}
                    >
                        <InputLabel>Пол</InputLabel>
                        <Select
                            value={formik.values.attributes.gender || ''}
                            label="Пол"
                            disabled={!hasEdit}
                            disableUnderline={!hasEdit}
                            IconComponent={!hasEdit ? 'span' : undefined}
                            onChange={(e) => {
                                const { value } = e.target

                                formik.setFieldValue('attributes.gender', value)
                            }}
                        >
                            {[
                                {
                                    label: 'Прекрасная девушка',
                                    value: 'female',
                                },
                                {
                                    label: 'Прекрасный мужчина',
                                    value: 'male',
                                },
                            ].map((gender, index) => (
                                <MenuItem key={index} value={gender.value}>
                                    {gender.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={4}>
                    <TextField
                        disabled
                        fullWidth
                        variant="standard"
                        label="Номер телефона"
                        value={formik.values.attributes.phone || ''}
                        error={!!formik.errors.attributes?.phone}
                        InputProps={{ disableUnderline: true }}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <TextField
                        fullWidth
                        variant="standard"
                        label="Email"
                        name="attributes.email"
                        value={formik.values.attributes.email || ''}
                        error={!!formik.errors.attributes?.email}
                        disabled={!hasEdit}
                        InputProps={{ disableUnderline: !hasEdit }}
                        onChange={formik.handleChange}
                    />
                </Grid>

                <Grid item xs={12} md={4} />
            </Grid>

            <Box mt={6.25}>
                {/* <Typography variant="body2" fontWeight={500}>
                    Данные для косморасчета украшений и натальной карты
                </Typography> */}

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Grid container mt={0} rowSpacing={3.75} columnSpacing={10}>
                        <Grid item xs={12} md={4}>
                            <DesktopDatePicker
                                label="Дата рождения"
                                inputFormat="dd.MM.yyyy"
                                mask="__.__.____"
                                value={formik.values.attributes.birthday_date}
                                disabled={
                                    !hasEdit || !!accaunt.attributes.birthday
                                }
                                InputProps={{
                                    disableUnderline: !hasEdit,
                                }}
                                onChange={(val) => {
                                    formik.setFieldValue(
                                        'attributes.birthday_date',
                                        val
                                    )
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        {...params}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </LocalizationProvider>
            </Box>

            {hasEdit && (
                <Box mt={6.25} display={'flex'} justifyContent={'flex-start'}>
                    <LoadingButton
                        loading={status === EStatus.PENDING}
                        color="primary"
                        variant="contained"
                        onClick={() => formik.handleSubmit()}
                    >
                        Сохранить
                    </LoadingButton>
                </Box>
            )}
        </Box>
    )
}
