import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import Cookies from 'js-cookie'
import { stringify } from 'qs'

function parseJSON(response: AxiosResponse) {
    if (response.status === 204 || response.status === 205) {
        return null
    }

    return response.data
}

async function checkStatus(response: AxiosResponse) {
    if (response.status >= 200 && response.status < 300) {
        return response
    }

    throw response
}

export async function request(url: string, config?: AxiosRequestConfig) {
    return axios(url, {
        ...config,
        headers: {
            ...config?.headers,
            Authorization: `${localStorage.getItem(
                'is_token_type'
            )} ${localStorage.getItem('is_token')}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Spree-City-Id': localStorage.getItem('current_city_id') || '',
            ...config?.headers,
            'X-Spree-Device-Uuid': Cookies.get('mindboxDeviceUUID') || '',
        },
        paramsSerializer: (params) => {
            return stringify(params, { arrayFormat: 'brackets' })
        },
    })
        .then(async (data) => {
            const response: any = await checkStatus(data)

            return parseJSON(response)
        })
        .catch(async (data) => {
            const response: any = await checkStatus(data.response)

            return parseJSON(response)
        })
}
