import { selectAccountWholesale } from 'app/modules/Account/slice/selectors'
import { CustomerCreateOrder } from 'app/modules/Cart/templates/CustomerCreateOrder'
import { PartnerCreateOrder } from 'app/modules/Cart/templates/PartnerCreateOrder'
import React from 'react'
import { useSelector } from 'react-redux'

export const OrderCreatePage: React.FC = () => {
    const wholesale = useSelector(selectAccountWholesale)

    if (wholesale) {
        return <PartnerCreateOrder />
    } else {
        return <CustomerCreateOrder />
    }
}
