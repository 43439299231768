import { Box, Button, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

import { HistoryTable } from './../../components/HistoryTable'

export const HistoryPage: React.FC = () => {
    return (
        <Container
            sx={{
                mt: { md: 25, lg: 17, xl: 17 },
            }}
        >
            <Grid
                sx={{
                    mt: {
                        xs: 0,
                        lg: 14.25,
                    },
                }}
                container
                columnSpacing={2.5}
            >
                <Grid item xs={12} lg={9}>
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            mt: {
                                xs: 2.5,
                                lg: 7.25,
                            },
                            mb: {
                                xs: 2.5,
                                lg: 8.125,
                            },
                            objectFit: 'cover',
                            backgroundSize: 'cover',
                            backgroundImage:
                                'url(/assets/images/main_banner_pic_1.jpg)',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                        }}
                        component="img"
                        src="/assets/images/text_Island_soul_jewelry.svg"
                    />
                </Grid>

                <Grid item xs={12} lg={3}>
                    <Box
                        sx={{
                            mt: {
                                xs: 2.5,
                                lg: 14.25,
                            },
                        }}
                        maxWidth="360px"
                    >
                        <Typography
                            sx={{ textTransform: 'uppercase' }}
                            mb={3.75}
                            variant="h5"
                            fontWeight="medium"
                        >
                            Добро пожаловать во Вселенную Island soul
                        </Typography>

                        <Typography variant="body2">
                            Начавшись в 2016 году как небольшая история-мечта, в
                            настоящее время IS стал международным брендом с
                            представительствами во многих странах.
                            <br />
                            <br />
                            Наш бренд о свободном лайфстайле, о смелости быть
                            женщиной, проживать себя, доверять своим чувствам и
                            всегда идти в новое.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

            <Grid
                container
                sx={{
                    mt: 11.875,
                    flexDirection: {
                        xs: 'column-reverse',
                        lg: 'row',
                    },
                }}
                bgcolor="grey.100"
            >
                <Grid
                    item
                    sx={{
                        px: {
                            xs: 3,
                            lg: 6.25,
                        },
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    xs={12}
                    lg={6}
                >
                    <Box maxWidth="md">
                        <Typography
                            sx={{ textTransform: 'uppercase' }}
                            mb={3.75}
                            variant="h5"
                            textAlign="center"
                            fontWeight="medium"
                        >
                            Миссия Island Soul —<br />
                            объединять творческих, интересных, свободно мыслящих
                            людей, развивать их и учить быть счастливыми
                        </Typography>

                        <Grid
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Box
                                mb={3.75}
                                component={'img'}
                                src={'/assets/images/first_decoration.png'}
                            />
                        </Grid>

                        <Typography variant="body2" textAlign="center">
                            Наш бренд о свободном лайфстайле, о смелости быть
                            женщиной, проживать себя, доверять своим чувствам и
                            всегда идти в новое. Поэтому смело подмигивайте друг
                            другу, даже если не знакомы, но есть опознавательный
                            символ IS, возможно эти встречи не случайны и вы
                            окажетесь друг другу полезны, а для знакомства не
                            хватало всего лишь повода 💍
                        </Typography>

                        <Grid container mt={12} spacing={2}>
                            <Grid item xs={6}>
                                <Button
                                    to="/static/history"
                                    type="text"
                                    component={Link}
                                >
                                    История бренда
                                </Button>
                            </Grid>

                            <Grid item xs={6}>
                                <Button
                                    to="/journal"
                                    type="text"
                                    component={Link}
                                >
                                    Журнал SoulLife
                                </Button>
                            </Grid>

                            <Grid item xs={6}>
                                <Button
                                    to="/static/daria_soul"
                                    type="text"
                                    component={Link}
                                >
                                    Daria Soul
                                </Button>
                            </Grid>

                            {/* <Grid item xs={6}>
                                <Button to="/" type="text" component={Link}>
                                    Клуб IslandSoul
                                </Button>
                            </Grid> */}

                            {/* <Grid item xs={6}>
                                <Button to="/" type="text" component={Link}>
                                    Карьера
                                </Button>
                            </Grid>

                            <Grid item xs={6}>
                                <Button to="/" type="text" component={Link}>
                                    IslandSoul для прессы
                                </Button>
                            </Grid>

                            <Grid item xs={6}>
                                <Button to="/" type="text" component={Link}>
                                    Занятия йогой
                                </Button>
                            </Grid> */}
                        </Grid>
                    </Box>
                </Grid>

                <Grid
                    item
                    xs={12}
                    lg={6}
                    sx={{
                        mb: {
                            xs: 5,
                            sm: 5,
                            md: 15,
                        },
                    }}
                >
                    <Box width="100%" position="relative">
                        <Box
                            sx={{
                                display: 'flex',
                                width: '100%',
                                height: 'auto',
                            }}
                            src="/assets/images/ceo.jpg"
                            srcSet="/assets/images/ceo.jpg"
                            alt="ceo_photo"
                            component="img"
                        />

                        <Box
                            width="100%"
                            height="50%"
                            bottom={0}
                            position="absolute"
                            sx={{
                                background:
                                    'linear-gradient(179.45deg, rgba(0, 0, 0, 0.46) 0.47%, rgba(0, 0, 0, 0) 99.53%)',
                                transform: 'rotate(-180deg)',
                            }}
                        />

                        <Grid container p={5} bottom={0} position="absolute">
                            <Grid item xs={10} lg={7}>
                                <Box maxWidth="sm">
                                    <Typography
                                        mb={1.875}
                                        color="common.white"
                                        variant="body2"
                                    >
                                        Сегодня ISLAND SOUL - это возможность
                                        для меня выражать свою любовь к этому
                                        миру, делиться ценностями и идеями,
                                        создавая украшения, которые подчеркивают
                                        уникальность каждой женщины.
                                    </Typography>

                                    <Typography
                                        color="common.white"
                                        variant="body2"
                                        fontWeight="medium"
                                    >
                                        Дарья Пашкевич, CEO
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={2} lg={1}>
                                <Box
                                    href={'/static/daria_soul'}
                                    component={'a'}
                                    bottom={0}
                                    right={0}
                                    position="absolute"
                                    sx={{
                                        mb: {
                                            xs: 1,
                                            sm: 2,
                                            md: 6,
                                            lg: 6,
                                            xl: 6,
                                        },
                                        mr: {
                                            xs: 1,
                                            sm: 2,
                                            md: 6,
                                            lg: 6,
                                            xl: 6,
                                        },
                                    }}
                                >
                                    <Button
                                        fullWidth
                                        variant="outlined"
                                        sx={{
                                            color: 'white',
                                            borderColor: 'white',
                                            '&:hover': {
                                                borderColor: 'white',
                                            },
                                        }}
                                    >
                                        ОБ ОСНОВАТЕЛЕ
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mt: {
                        xs: 11.875,
                        lg: 25,
                    },
                }}
            >
                <Typography
                    sx={{ textTransform: 'uppercase' }}
                    mb={10}
                    variant="h4"
                    textAlign="center"
                    fontWeight="medium"
                >
                    Страницы истории
                </Typography>

                <HistoryTable />
            </Box>
        </Container>
    )
}
