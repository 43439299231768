import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'

export interface GridStaticRowTextProps {
    title: string
    description: string
    bigSize?: boolean
}

export const GridStaticRowText: React.FC<GridStaticRowTextProps> = ({
    title,
    description,
    bigSize,
}) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))

    return (
        <Box>
            <Grid container>
                {!isMobile && <Grid item xs={bigSize ? 1 : 2} />}
                <Grid item xs={isMobile ? 12 : bigSize ? 10 : 8}>
                    <Box>
                        <Typography
                            variant="h5"
                            fontWeight={500}
                            sx={{ textTransform: 'uppercase' }}
                        >
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: title,
                                }}
                            />
                        </Typography>
                        <Typography variant="body2" mt={3}>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: description,
                                }}
                            />
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}
