import ScrollToTop from 'app/features/ScrollToTop'
import { Layout } from 'app/modules/Layout/templates/Layout'
import { OrderLayout } from 'app/modules/Layout/templates/OrderLayout'
import { MultiversePage } from 'app/modules/Multiverse'
import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { CategoryPage } from './CategoryPage'
import { JournalPage } from './JournalPage'
import { MainPage } from './MainPage'
import { OfflineTradePage } from './OfflineTradePage'
import { OrderCreatePage } from './OrderCreatePage'
import { ProductPage } from './ProductPage'
import { ProfilePages } from './ProfilePages'
import { SearchPage } from './SearchPage'
import { StaticPages } from './StaticPages'
import { StorePage } from './StorePage'

export const Pages: React.FC = () => (
    <>
        <ScrollToTop />
        <Switch>
            <Route
                exact
                path={['/offline_trade', '/offline_trade/:country']}
                component={OfflineTradePage}
            />

            <Route exact path={['/profile', '/profile/*']}>
                <Layout
                    bgcolor="grey.100"
                    headerContextProps={{
                        variant: 'dark',
                        position: 'relative',
                        mode: 'roll-up',
                    }}
                >
                    <Switch>
                        <Route
                            exact
                            path={['/profile', '/profile/*']}
                            component={ProfilePages}
                        />
                    </Switch>
                </Layout>
            </Route>

            <Route exact path={['/journal', '/store', '/journal/*']}>
                <Layout
                    headerContextProps={{
                        variant: 'dark',
                        position: 'relative',
                        mode: 'roll-up',
                    }}
                >
                    <Switch>
                        <Route path="/journal" component={JournalPage} />

                        <Route path="/store" component={StorePage} />
                    </Switch>
                </Layout>
            </Route>

            <Route exact path={['/products/*']}>
                <Layout
                    headerContextProps={{
                        variant: 'dark',
                        position: 'relative',
                        mode: 'fixed',
                    }}
                >
                    <Switch>
                        <Route
                            exact
                            path="/products/:productSlug"
                            component={ProductPage}
                        />
                    </Switch>
                </Layout>
            </Route>

            <Route exact path={['/static/*', '/search', '/search/*']}>
                <Layout
                    headerContextProps={{
                        variant: 'dark',
                        position: 'relative',
                        mode: 'roll-up',
                    }}
                >
                    <Switch>
                        <Route exact path="/static/*" component={StaticPages} />

                        <Route
                            exact
                            path={['/search', '/search/:query']}
                            component={SearchPage}
                        />
                    </Switch>
                </Layout>
            </Route>

            <Route exact path={'/orders/*'}>
                <OrderLayout
                    headerContextProps={{
                        variant: 'dark',
                        position: 'relative',
                        mode: 'fixed',
                    }}
                >
                    <Switch>
                        <Route
                            exact
                            path="/orders/create"
                            component={OrderCreatePage}
                        />
                    </Switch>
                </OrderLayout>
            </Route>

            <Route exact path={['/']}>
                <Layout
                    headerContextProps={{
                        variant: 'dark',
                        position: 'relative',
                        mode: 'fixed',
                    }}
                >
                    <Switch>
                        <Route exact path="/" component={MainPage} />
                    </Switch>
                </Layout>
            </Route>

            {/* <Route exact path="/multiverse">
                <OrderLayout
                    headerContextProps={{
                        variant: 'light',
                        position: 'absolute',
                        mode: 'roll-up',
                    }}
                >
                    <MultiversePage />
                </OrderLayout>
            </Route> */}

            <Route exact path={['/*']}>
                <Layout
                    headerContextProps={{
                        variant: 'light',
                        position: 'absolute',
                        mode: 'roll-up',
                    }}
                >
                    <Switch>
                        <Route exact path="/*" component={CategoryPage} />
                    </Switch>
                </Layout>
            </Route>
        </Switch>
    </>
)
