import { LoadingButton } from '@mui/lab'
import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EStatus } from 'types'

import DoneIcon from '../assets/done_icon.svg'
import { offlineTradeActions } from '../slice'
import { selectLoyaltyForm } from '../slice/selectors'

export const OfflineTradeDone: React.FC = () => {
    const dispatch = useDispatch()

    const { data, status } = useSelector(selectLoyaltyForm)

    const handleAddOrder = () => {
        dispatch(offlineTradeActions.getLoyalty(data.phone))
    }

    const handleMainPage = () => {
        dispatch(offlineTradeActions.resetPage())
    }

    return (
        <>
            <Typography variant="body1" fontWeight={500} textAlign={'center'}>
                Покупка добавлена
            </Typography>

            <Box display={'flex'} justifyContent={'center'} mt={5.5}>
                <Box alt={'Done'} src={DoneIcon} component={'img'} />
            </Box>

            <LoadingButton
                fullWidth
                loading={status === EStatus.PENDING}
                variant="contained"
                sx={{ mt: 6.5 }}
                onClick={handleAddOrder}
            >
                ДОБАВИТЬ ПОКУПКУ
            </LoadingButton>

            <Button
                fullWidth
                variant="outlined"
                sx={{ mt: 1 }}
                onClick={handleMainPage}
            >
                ВЕРНУТЬСЯ НА ГЛАВНУЮ
            </Button>
        </>
    )
}
