import { Logout as LogoutIcon } from '@mui/icons-material'
import {
    Box,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { authActions } from 'app/modules/Auth/slice'
import { cartActions } from 'app/modules/Cart/slice'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { ReactComponent as GiftIcon } from '../assets/gift_icon.svg'
import { ReactComponent as HeartIcon } from '../assets/heart_icon.svg'
import { ReactComponent as ShoppingBagIcon } from '../assets/shopping_bag_icon.svg'
import { ReactComponent as UserIcon } from '../assets/user_icon.svg'
import { AccountInfo } from '../components/AccountInfo'
import { AccountMenuItem } from '../components/AccountMenuItem'
import { accountActions } from '../slice'
import { selectAccountWholesale } from '../slice/selectors'

export const AccountMenu: React.FC = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))

    const wholesale = useSelector(selectAccountWholesale)

    const handleLogout = () => {
        history.push('/')
        dispatch(authActions.logout())
        dispatch(accountActions.logout())
        dispatch(cartActions.deleteCartState())
        dispatch(cartActions.deleteCartToken())
        dispatch(cartActions.createCart())
    }

    return (
        <>
            {!isMobile && (
                <Box>
                    <AccountInfo />
                    <List sx={{ mt: 5.875 }}>
                        <AccountMenuItem
                            link={'/profile/orders'}
                            icon={<ShoppingBagIcon />}
                            text={'Заказы'}
                        />

                        <AccountMenuItem
                            link={'/profile/favorites'}
                            icon={<HeartIcon />}
                            text={'Избранное'}
                        />

                        {!wholesale && (
                            <AccountMenuItem
                                link={'/profile/gift'}
                                icon={<GiftIcon />}
                                text={'бонусная программа'}
                            />
                        )}

                        <AccountMenuItem
                            link={'/profile/personal'}
                            icon={<UserIcon />}
                            text={'личные данные'}
                        />

                        <ListItemButton onClick={handleLogout}>
                            <ListItemIcon>
                                <LogoutIcon color="error" />
                            </ListItemIcon>

                            <ListItemText>
                                <Typography
                                    sx={{ textTransform: 'uppercase' }}
                                    color="error"
                                    variant="body2"
                                    fontWeight="medium"
                                >
                                    Выйти
                                </Typography>
                            </ListItemText>
                        </ListItemButton>
                    </List>
                </Box>
            )}
        </>
    )
}
