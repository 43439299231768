import {
    Box,
    Step,
    StepContent,
    StepIconProps,
    StepLabel,
    StepLabelProps,
    StepProps,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import React from 'react'

const CustomStepIcon: React.FC<StepIconProps> = (props) => {
    const theme = useTheme()
    const { active, icon } = props

    return (
        <Box
            height="28px"
            width="28px"
            sx={{
                backgroundColor: active
                    ? theme.palette.primary.main
                    : theme.palette.grey[400],
                borderRadius: '6px',
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <Typography
                variant="body2"
                fontWeight="medium"
                textAlign="center"
                color={theme.palette.primary.contrastText}
            >
                {icon}
            </Typography>
        </Box>
    )
}

const CreateOrderStepLabel: React.FC<StepLabelProps> = (props) => (
    <StepLabel
        sx={{
            '& .MuiStepLabel-iconContainer': {
                alignSelf: 'flex-start',
                pr: { xs: '12px', md: '16px' },
            },
            '& .MuiStepLabel-label': {
                fontSize: { xs: '18px', md: '24px' },
                '&.Mui-completed': {
                    fontSize: { xs: '14px' },
                    fontWeight: 'regular',
                    color: '#858585',
                },
                '&.Mui-disabled': {
                    fontSize: { xs: '14px' },
                    fontWeight: 'regular',
                    color: '#858585',
                },
            },
        }}
        StepIconComponent={CustomStepIcon}
        {...props}
    />
)

const CreateOrderStepContent: React.FC<{ active: number } & StepLabelProps> = (
    props
) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))

    return (
        <StepContent
            sx={
                isMobile && props.active
                    ? {
                          border: { xs: '0px' },
                          p: { xs: '0px' },
                          m: { xs: '0px' },
                      }
                    : {
                          minHeight: { xs: '16px', md: '0px' },
                      }
            }
            {...props}
        />
    )
}

export const CreateOrderStep: React.FC<
    { text: string; currentactivestep: number } & StepProps
> = (props) => (
    <Step {...props}>
        <CreateOrderStepLabel>{props.text}</CreateOrderStepLabel>
        <CreateOrderStepContent
            active={+(props.index === props.currentactivestep)}
        >
            {props.children}
        </CreateOrderStepContent>
    </Step>
)
