import { EntityState } from '@reduxjs/toolkit'
import { ECountryCode, EStatus } from 'types'
import { ICustomer } from 'types/ICustomer'

export enum EOfflineTradePage {
    START = 'start',
    CREATE_ORDER = 'create_order',
    CREATE_CUSTOMER = 'create_customer',
    DONE = 'done',
    CUSTOMER_ADDED = 'customer_added',
}

export interface IOrderData {
    phone?: string
    customer_id: number
    amount: number
    promotional_amount: number
    customer_firstname: string
    customer_lastname: string
    shop_id: string
    bonuses?: number
    code?: string
}

export interface ICustomerData {
    email: string
    firstname: string
    lastname: string
    birthday: string
    phone: string
    city: string
    shop_id: string
    code: string
}

export interface IOfflineTradeState extends EntityState<ICustomer> {
    page: EOfflineTradePage
    countryCode: ECountryCode
    forms: {
        getLoyalty: {
            status: EStatus
            data: {
                phone: string
            }
        }
        createCustomer: {
            status: EStatus
            data: ICustomerData
        }
        createOrder: {
            status: EStatus
            data: IOrderData
        }
    }
}
