import { LoadingButton } from '@mui/lab'
import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EStatus } from 'types'

import { ProductCard } from '../components/ProductCard'
import { productsActions } from '../slice'
import {
    selectFavoritesProductsData,
    selectProductsStatus,
} from '../slice/selectors'

export const FavoritesList: React.FC = () => {
    const dispatch = useDispatch()

    const { products, totalPages, totalProductsCount } = useSelector(
        selectFavoritesProductsData
    )

    const ProductsCount = products.length
    const status = useSelector(selectProductsStatus)

    const [page, setPage] = useState(1)

    const handleClickShowMore = () => {
        if (page >= totalPages) {
            return
        }

        setPage(page + 1)
    }

    useEffect(() => {
        dispatch(productsActions.loadFavorites(page))
    }, [page])

    return (
        <>
            <Grid container rowSpacing={{ xs: 2, md: 8.75 }} columnSpacing={3}>
                {(!products.length && status !== EStatus.FINISHED
                    ? Array.from(new Array(2))
                    : products
                ).map((product, index) => (
                    <Grid item key={index} xs={12} md={4}>
                        <ProductCard product={product} />
                    </Grid>
                ))}
            </Grid>

            {!!products.length && totalPages > 1 && (
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    mt={10}
                >
                    <Typography variant="body2">{`Показано ${ProductsCount} из ${totalProductsCount}`}</Typography>

                    <LoadingButton
                        sx={{ mt: 2 }}
                        variant="banner"
                        color="dark"
                        loading={status !== EStatus.FINISHED}
                        onClick={handleClickShowMore}
                    >
                        Показать ещё
                    </LoadingButton>
                </Box>
            )}
        </>
    )
}
