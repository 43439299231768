import { ResourceObject } from 'utils/json-api'

export const MultiverseTypes = ['multiverse'] as const

export interface IMultiverse extends ResourceObject {
    id: string
    type: typeof MultiverseTypes[number]
    attributes: {
        /** Изображение для конструктора */
        constructor_url: string
        /** Изображение для итоговой сборки */
        assembly_url: string
    }
}
