import { LoadingButton, LocalizationProvider, MobileDatePicker } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import {
    Box,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material'
import { citiesActions } from 'app/modules/Cities/slice'
import { selectCities } from 'app/modules/Cities/slice/selectors'
import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EStatus } from 'types'
import * as yup from 'yup'

import { ReactComponent as EditIcon } from '../assets/edit_icon.svg'
import { offlineTradeActions } from '../slice'
import { selectCreateCustomerForm, selectLoyaltyForm } from '../slice/selectors'

export const OfflineTradeAddCustomer: React.FC = () => {
    const dispatch = useDispatch()

    const { data, status } = useSelector(selectCreateCustomerForm)
    const { status: smsStatus } = useSelector(selectLoyaltyForm)
    const cities = useSelector(selectCities)

    const validationSchema = yup.object({
        email: yup.string().email('Не корректный Email').required(),
        firstname: yup.string().required(),
        lastname: yup.string().required(),
        birthday: yup.date().required(),
        phone: yup.string().required(),
        city: yup.string().required(),
        shop_id: yup.string().required(),
        code: yup.string().required().min(4).max(4),
    })

    const formik = useFormik({
        validationSchema,
        initialValues: data,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: (values) => {
            dispatch(offlineTradeActions.createCustomer(values))
        },
    })

    const handleStartPage = () => {
        dispatch(offlineTradeActions.startPage())
    }

    const handleReSendSmsCode = () => {
        dispatch(offlineTradeActions.sendSms(formik.values.phone))
    }

    useEffect(() => {
        dispatch(citiesActions.loadCities())
    }, [])

    return (
        <Box
            noValidate
            component="form"
            autoComplete="off"
            onSubmit={(e: React.FormEvent) => {
                e.preventDefault()

                formik.handleSubmit()
            }}
        >
            <Typography variant="body1" fontWeight={500}>
                Регистрация
            </Typography>

            <Box sx={{ mt: 0.5, display: 'flex', alignItems: 'center' }}>
                <Typography
                    sx={{ fontWeight: 500 }}
                    color="grey.600"
                    variant="body2"
                >
                    {formik.values.phone}
                </Typography>

                <IconButton
                    size="small"
                    sx={{ width: '30px', height: '30px', mt: -0.75, ml: 0.5 }}
                    onClick={handleStartPage}
                >
                    <EditIcon />
                </IconButton>
            </Box>

            <Box mt={3.5}>
                <TextField
                    fullWidth
                    sx={{ mb: 2 }}
                    variant="standard"
                    label="Имя"
                    name="firstname"
                    value={formik.values.firstname}
                    error={!!formik.errors.firstname}
                    onChange={formik.handleChange}
                />

                <TextField
                    fullWidth
                    sx={{ mb: 2 }}
                    variant="standard"
                    label="Фамилия"
                    name="lastname"
                    value={formik.values.lastname}
                    error={!!formik.errors.lastname}
                    onChange={formik.handleChange}
                />

                <TextField
                    fullWidth
                    sx={{ mb: 2 }}
                    variant="standard"
                    label="Email"
                    name="email"
                    value={formik.values.email}
                    error={!!formik.errors.email}
                    onChange={formik.handleChange}
                />

                <Box mb={2}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileDatePicker
                            label="Дата рождения"
                            inputFormat="dd.MM.yyyy"
                            value={formik.values.birthday}
                            onChange={(val) => {
                                formik.setFieldValue('birthday', val)
                            }}
                            renderInput={(params) => (
                                <TextField
                                    fullWidth
                                    variant="standard"
                                    error={!!formik.errors.birthday}
                                    {...params}
                                />
                            )}
                        />
                    </LocalizationProvider>
                </Box>

                <FormControl
                    variant="standard"
                    fullWidth
                    error={!!formik.errors.city}
                    sx={{ mb: 2 }}
                >
                    <InputLabel>Город</InputLabel>
                    <Select
                        value={formik.values.city}
                        label="Город"
                        onChange={(e) => {
                            const { value } = e.target

                            formik.setFieldValue('city', value)
                        }}
                    >
                        {cities.map((city, index) => (
                            <MenuItem key={index} value={city.attributes.name}>
                                {city.attributes.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <TextField
                    fullWidth
                    sx={{ mb: 2 }}
                    variant="standard"
                    label="Код магазина"
                    name="shop_id"
                    value={formik.values.shop_id}
                    error={!!formik.errors.shop_id}
                    onChange={(e) => {
                        const { value } = e.target
                        dispatch(offlineTradeActions.changeShopId(value))
                        formik.setFieldValue('shop_id', value)
                    }}
                />

                <TextField
                    fullWidth
                    sx={{ mb: 2 }}
                    variant="standard"
                    label="СМС-код"
                    name="code"
                    value={formik.values.code}
                    error={!!formik.errors.code}
                    onChange={formik.handleChange}
                />

                <LoadingButton
                    loading={smsStatus === EStatus.PENDING}
                    variant="text"
                    sx={{
                        mt: 2,
                        textTransform: 'unset',
                    }}
                    onClick={() => handleReSendSmsCode()}
                >
                    Отправить код повторно
                </LoadingButton>
            </Box>

            <LoadingButton
                loading={status === EStatus.PENDING}
                fullWidth
                variant="contained"
                sx={{ mt: 6 }}
                onClick={() => formik.handleSubmit()}
            >
                ПРОДОЛЖИТЬ
            </LoadingButton>
        </Box>
    )
}
