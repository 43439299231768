import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import ym from 'react-yandex-metrika'
import { EAuthStatus, EStatus, TAuthModalStatus } from 'types'
import { IUser } from 'types/IUser'

import { AuthState, IAuthAnswer, ICreateAccount, ISignin } from './types'

const initialState: AuthState = {
    status: EStatus.INITIAL,
    auth_status: EAuthStatus.INITIAL,
    token: {
        access_token: localStorage.getItem('is_token') || '',
        token_type: localStorage.getItem('is_token_type') || '',
        expires_in: Number(localStorage.getItem('is_expires_in')) || 0,
        refresh_token: localStorage.getItem('is_refresh_token') || '',
        created_at: Number(localStorage.getItem('is_created_at')) || 0,
    },
    forms: {
        modal_status: 'close',
        signin: {
            referrer_url: '',
            status: EStatus.INITIAL,
            data: {
                personal_data: false,
                offer_agreement: false,
                grant_type: 'password',
                phone: '',
                code: '',
                newsletter_agreement: false,
            },
        },
        create_account: {
            status: EStatus.INITIAL,
            data: {
                user: {
                    phone: '',
                    newsletter_agreement: false,
                },
            },
            requestTime: 0,
        },
    },
}

const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetStatus(state) {
            state.status = EStatus.INITIAL
        },
        setReferrerUrl(state, action: PayloadAction<string>) {
            state.forms.signin.referrer_url = action.payload
        },
        setModalStatus(state, action: PayloadAction<TAuthModalStatus>) {
            state.forms.modal_status = action.payload
        },
        resetPhoneStatus(state) {
            state.forms.create_account.status = EStatus.INITIAL
        },
        resetPhone(state) {
            state.forms.create_account.data.user.phone = ''
        },
        getCode(
            state,
            _action: PayloadAction<{
                requestData: ICreateAccount
                resend?: true
            }>
        ) {
            state.status = EStatus.PENDING
            state.forms.create_account.status = EStatus.PENDING
        },
        getCodeCompleted(state, action: PayloadAction<IUser>) {
            state.status = EStatus.FINISHED
            state.forms.create_account.status = EStatus.FINISHED
            state.forms.modal_status = 'code'
            state.forms.signin.data.phone = action.payload.attributes.phone
            state.forms.create_account.data.user.phone =
                action.payload.attributes.phone
            state.forms.signin.data.hide_personal_data =
                action.payload.attributes.personal_data
            // state.forms.signin.data.newsletter_agreement =
            //     action.payload.attributes.newsletter_agreement
            state.forms.create_account.data.user.newsletter_agreement =
                action.payload.attributes.newsletter_agreement
            state.forms.create_account.requestTime =
                new Date().getTime() / 1000 + 60
        },
        getOfflineTradeCode(state, _action: PayloadAction<ICreateAccount>) {
            state.status = EStatus.PENDING
            state.forms.create_account.status = EStatus.PENDING
        },
        getOfflineTradeCodeCompleted(
            state,
            _action: PayloadAction<ICreateAccount>
        ) {
            state.status = EStatus.FINISHED
            state.forms.create_account.status = EStatus.FINISHED
        },
        signIn(state, _action: PayloadAction<ISignin>) {
            state.status = EStatus.PENDING
            state.forms.signin.status = EStatus.PENDING
        },
        signInCompleted(state, action: PayloadAction<IAuthAnswer>) {
            state.auth_status = EAuthStatus.AUTHORIZED
            state.status = EStatus.FINISHED
            state.forms.signin.status = EStatus.FINISHED
            state.token = {
                access_token: action.payload.access_token,
                token_type: action.payload.token_type,
                expires_in: action.payload.expires_in,
                refresh_token: action.payload.refresh_token,
                created_at: action.payload.created_at,
            }
            localStorage.setItem('is_token', action.payload.access_token)
            localStorage.setItem(
                'is_refresh_token',
                action.payload.refresh_token
            )
            localStorage.setItem('is_token_type', action.payload.token_type)
            localStorage.setItem(
                'is_expires_in',
                action.payload.expires_in.toString()
            )
            localStorage.setItem(
                'is_created_at',
                action.payload.created_at.toString()
            )
            ym('reachGoal', 'login')
        },
        logout(state) {
            state.auth_status = EAuthStatus.NOT_AUTHORIZED

            state.token = {
                access_token: '',
                token_type: '',
                expires_in: 0,
                refresh_token: '',
                created_at: 0,
            }

            localStorage.removeItem('is_token')
            localStorage.removeItem('is_refresh_token')
            localStorage.removeItem('is_token_type')
            localStorage.removeItem('is_expires_in')
            localStorage.removeItem('is_created_at')
        },
        refreshToken(
            _state,
            _action: PayloadAction<{
                refresh_token: string
                loadAccount: boolean
            }>
        ) {
            //
        },
        loadAccount() {
            //
        },
        statusError(state) {
            state.status = EStatus.ERROR
            state.forms.signin.status = EStatus.ERROR
        },
        setAuthorized(state) {
            state.auth_status = EAuthStatus.AUTHORIZED
        },
        setNotAuthorized(state) {
            state.auth_status = EAuthStatus.NOT_AUTHORIZED
        },
    },
})

export const { actions: authActions, reducer: authReducer } = slice
