import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'types'

import { videosAdapter } from '.'

const { selectById } = videosAdapter.getSelectors()

const selectDomain = (state: RootState) => state.videos

export const selectVideoById = createSelector(
    [selectDomain],
    (state) => (id: string) => selectById(state, id)
)
