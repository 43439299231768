import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { ResponsiveStyleValue } from '@mui/system'
import React from 'react'

import LoyaltyBenefitImage_1_desktop from './assets/LoyaltyBenefitImage_1_desktop.jpg'
import LoyaltyBenefitImage_1_mobile from './assets/LoyaltyBenefitImage_1_mobile.jpg'
import LoyaltyBenefitImage_2_desktop from './assets/LoyaltyBenefitImage_2_desktop.jpg'
import LoyaltyBenefitImage_2_mobile from './assets/LoyaltyBenefitImage_2_mobile.jpg'
import LoyaltyBenefitImage_3_desktop from './assets/LoyaltyBenefitImage_3_desktop.jpg'
import LoyaltyBenefitImage_3_mobile from './assets/LoyaltyBenefitImage_3_mobile.jpg'

const LoyaltyBenefit: React.FC<{
    icon: React.ReactNode
    upperText: string
    loverText: string
    maxWidth?: ResponsiveStyleValue<string | number>
}> = ({ icon, upperText, loverText }) => (
    <Box
        width={{ xs: '100%', lg: '30vw' }}
        maxWidth={{ lg: '29vw' }}
        height={{ xs: '374px', md: 'auto' }}
        px={{ xs: '40px', md: '70px' }}
        display="flex"
        flexDirection="column"
        alignItems="center"
        borderRadius="20px"
        sx={{
            backgroundColor: 'white',
            pt: { xs: '34px', md: '60px' },
            pb: { xs: '40px', md: '50px' },
        }}
    >
        <Typography
            component="h3"
            sx={{
                fontSize: { xs: '16px', md: '1.25vw' },
                lineHeight: { xs: '16px', md: '1.25vw' },
                textAlign: { xs: 'center' },
                fontWeight: 500,
                textTransform: 'uppercase',
            }}
        >
            {upperText}
        </Typography>
        <Typography
            component="h4"
            sx={{
                fontSize: { xs: '14px', md: '1vw' },
                lineHeight: { xs: '14px', md: '1vw' },
                textAlign: { xs: 'center' },
                mt: { xs: '15px' },
                mb: { xs: '25px' },
                height: { md: '66px' },
            }}
        >
            {loverText}
        </Typography>

        {icon}
    </Box>
)

export const LoyaltyBenefits: React.FC = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))

    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            <Typography
                variant="h1"
                fontWeight="medium"
                sx={{ fontSize: { xs: '24px', md: '42px' } }}
            >
                ПРЕИМУЩЕСТВА КЛУБА
            </Typography>
            <Typography
                variant="h2"
                fontWeight="medium"
                sx={{
                    fontSize: { xs: '14px', md: '20px' },
                    textAlign: { xs: 'center' },
                    mt: { xs: '20px' },
                }}
            >
                Совершайте покупки, переходите на новую ступень в Клубе
                Привилегий и открывайте доступ к особым условиям.
            </Typography>

            <Grid
                container
                direction={{ xs: 'column', md: 'row' }}
                spacing={{ xs: '25px', md: '30px' }}
                mt={{ xs: '32px', md: '50px' }}
            >
                <Grid item xs={12} md={4}>
                    <LoyaltyBenefit
                        upperText="Кешбэк с покупок"
                        loverText="Получайте до 9% бонусных рублей в виде кешбэка на ваш бонусный счет после каждой покупки"
                        icon={
                            <Box
                                component="img"
                                src={
                                    isMobile
                                        ? LoyaltyBenefitImage_1_mobile
                                        : LoyaltyBenefitImage_1_desktop
                                }
                                sx={{
                                    width: { xs: '257px', md: '22.9vw' },
                                    borderRadius: { xs: '20px' },
                                }}
                            />
                        }
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <LoyaltyBenefit
                        upperText={`Бонусы\xa0в\xa0день\xa0рождения`}
                        loverText="Получайте 2000 бонусных рублей в день рождения"
                        icon={
                            <Box
                                component="img"
                                src={
                                    isMobile
                                        ? LoyaltyBenefitImage_2_mobile
                                        : LoyaltyBenefitImage_2_desktop
                                }
                                sx={{
                                    width: { xs: '257px', md: '22.9vw' },
                                    borderRadius: { xs: '20px' },
                                }}
                            />
                        }
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <LoyaltyBenefit
                        upperText="Бонусные баллы"
                        loverText="Оплачивайте до 15% от стоимости покупки бонусными баллами"
                        icon={
                            <Box
                                component="img"
                                src={
                                    isMobile
                                        ? LoyaltyBenefitImage_3_mobile
                                        : LoyaltyBenefitImage_3_desktop
                                }
                                sx={{
                                    width: { xs: '257px', md: '22.9vw' },
                                    borderRadius: { xs: '20px' },
                                }}
                            />
                        }
                    />
                </Grid>
            </Grid>
        </Box>
    )
}
