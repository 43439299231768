import Cookies from 'js-cookie'
import { IProduct } from 'types/IProduct'
import { IVariant } from 'types/IVariant'

export { mindboxSetCart } from './MindBoxCart'
export { mindboxViewCategory, mindboxViewProduct } from './MindBoxView'

export enum AddProductLists {
    addToFavorites = 'AddProductToIzbrannoe1ItemList',
    addToWathed = 'AddProductToProsmotrennyeTovaryItemList',
    addToShared = 'AddProductToTovaryKotorymiPodelilisItemList',
}

export enum RemoveProductLists {
    removeFromFavorites = 'RemoveProductFromIzbrannoe1ItemList',
}

export interface ProductInfoParams {
    product: IProduct
    variant?: IVariant
}

export const mindBoxOperation = ({
    operation,
    data,
    wholesale,
}: {
    operation: string
    data: any
    wholesale: boolean
}) => {
    if (!wholesale) {
        const interval = setInterval(() => {
            if (!!Cookies.get('mindboxDeviceUUID')) {
                window.mindbox('async', {
                    operation,
                    data,
                    onSuccess: function () {
                        console.log('succses', operation)
                    },
                    onError: function (error) {
                        console.log({ error })
                    },
                })
                clearInterval(interval)
            }
        }, 2000)
    }
}

export const productInfo = ({ product, variant }: ProductInfoParams) => {
    if (product.relationships.variants.data.length && variant)
        return {
            product: {
                ids: {
                    products1C: variant.attributes.id_1c,
                },
            },
        }

    return {
        productGroup: {
            ids: {
                products1C: product.attributes.id_1c,
            },
        },
    }
}

export const mindboxAddToList = (
    product: IProduct,
    listName: AddProductLists,
    wholesale: boolean
) => {
    mindBoxOperation({
        operation: listName,
        data: {
            addProductToList: {
                ...productInfo({ product }),
                pricePerItem: product.attributes.price,
            },
        },
        wholesale,
    })
}

export const mindboxRemoveFromList = (
    product: IProduct,
    listName: RemoveProductLists,
    wholesale: boolean
) => {
    mindBoxOperation({
        operation: listName,
        data: {
            removeProductFromList: {
                ...productInfo({ product }),
                pricePerItem: product.attributes.price,
            },
        },
        wholesale,
    })
}
