import { authActions } from 'app/modules/Auth/slice'
import { selectAuthStatus } from 'app/modules/Auth/slice/selectors'
import { productsActions } from 'app/modules/Products/slice'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ILineItem } from 'types/ILineItem'
import { IProduct } from 'types/IProduct'

import { selectProductBySlug } from '../../slice/selectors'

export function useHandleToggleFavorite(inputProduct: IProduct | ILineItem) {
    const dispatch = useDispatch()

    const { isAuthorized, notAuthorized } = useSelector(selectAuthStatus)

    const product = useSelector(selectProductBySlug)(
        inputProduct.attributes.slug
    )

    const handleToggleFavorite = (e: React.FormEvent) => {
        e.preventDefault()

        if (notAuthorized) {
            dispatch(authActions.setModalStatus('phone'))
        }
        if (isAuthorized) {
            dispatch(
                productsActions.productToggleFavorite(
                    inputProduct.attributes.slug
                )
            )
        }
    }

    const isFavorite =
        inputProduct.attributes.favorite_for_user ||
        product?.attributes.favorite_for_user ||
        false

    return { isFavorite, handleToggleFavorite }
}
