import { useMediaQuery, useTheme } from '@mui/material'
import React from 'react'

import { DesktopPartnerCreateOrder } from './DesktopPartnerCreateOrder'
import { MobilePartnerCreateOrder } from './MobilePartnerCreateOrder'

export const PartnerCreateOrder: React.FC = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'lg'))
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

    return (
        <>
            {isMobile && <MobilePartnerCreateOrder />}

            {isDesktop && <DesktopPartnerCreateOrder />}
        </>
    )
}
