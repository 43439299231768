import { Box, Typography } from '@mui/material'
import React from 'react'

export type HeaderActionIconProps = {
    text: string
}

export const HeaderActionIcon: React.FC<HeaderActionIconProps> = ({
    text,
    children,
}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '1px',
            }}
        >
            {children}
            <Typography
                variant="body3"
                sx={{
                    fontWeight: '500',
                    fontSize: '10px',
                    lineHeight: '16px',
                    verticalAlign: 'top',
                    overflow: 'visible',
                    textAlign: 'center',
                    position: 'absolute',
                    bottom: '-10px',
                }}
            >
                {text}
            </Typography>
        </Box>
    )
}
