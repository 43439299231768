import { useMediaQuery, useTheme } from '@mui/material'
import { selectCurrentShopCity } from 'app/modules/Cities/slice/selectors'
import { selectShopByCityId } from 'app/modules/Shops/slice/selectors'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
    Clusterer,
    Map,
    Placemark,
    YMaps,
    ZoomControl,
} from 'react-yandex-maps'
import { IShop } from 'types/IShop'

export const YandexMap: React.FC = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))

    const [isCtrl, setCtrl] = useState<boolean>(false)

    const currentCity = useSelector(selectCurrentShopCity)
    const getShops = useSelector(selectShopByCityId)

    const currentCityCenter = () => {
        return [
            parseFloat(currentCity?.attributes.lat || '54'),
            parseFloat(currentCity?.attributes.lng || '78'),
        ]
    }

    const currentCityZoom = () => {
        return currentCity ? 10 : 4
    }

    const getShopCoordinates = (shop: IShop) => {
        return [
            parseFloat(shop.attributes.lat),
            parseFloat(shop.attributes.lng),
        ]
    }

    useEffect(() => {
        const body = document.getElementsByTagName('body')[0]

        if (body) {
            body.onkeydown = callbackDown
            body.onkeyup = callbackUp
        }

        return () => {
            if (body) {
                body.onkeydown = null
                body.onkeyup = null
            }
        }
    }, [])

    const callbackDown = (e: any) => {
        if (e.keyCode === 17) {
            setCtrl(true)
        }
    }
    const callbackUp = (e: any) => {
        if (e.keyCode === 17) {
            setCtrl(false)
        }
    }

    return (
        <YMaps>
            <Map
                width="100%"
                height={isMobile ? '100vw' : '50vw'}
                state={{
                    center: currentCityCenter(),
                    zoom: currentCityZoom(),
                }}
                instanceRef={(ref: any) => {
                    if (ref && ref.behaviors && isCtrl) {
                        ref.behaviors.enable(['scrollZoom'])
                    } else if (ref && ref.behaviors && !isCtrl) {
                        ref.behaviors.disable(['scrollZoom'])
                    }
                }}
            >
                <ZoomControl options={{ float: 'right' }} />

                <Clusterer
                    options={{
                        preset: 'islands#blueClusterIcons',
                        groupByCoordinates: false,
                        minClusterSize: 2,
                        gridSize: 70,
                    }}
                >
                    {getShops(currentCity?.id).map((shop) => (
                        <Placemark
                            key={shop.id}
                            geometry={getShopCoordinates(shop)}
                            options={{
                                preset: 'islands#blueShoppingCircleIcon',
                            }}
                            defaultProperties={{
                                hintContent: `<b>Island Soul</b><br />${shop.attributes.working_hours}`,
                                balloonContentHeader: 'Island Soul',
                                balloonContentBody: `${shop.attributes.name}<br />${shop.attributes.working_hours}`,
                            }}
                            modules={[
                                'geoObject.addon.balloon',
                                'geoObject.addon.hint',
                            ]}
                        />
                    ))}
                </Clusterer>
            </Map>
        </YMaps>
    )
}
