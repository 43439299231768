import {
    Box,
    Container,
    Divider,
    Grid,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import GridStaticRow, { GridStaticRowProps } from 'app/components/GridStaticRow'
import { TopBanner } from 'app/components/TopBanner'
import React from 'react'

export const DariaSoul: React.FC = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))

    const grids: GridStaticRowProps[] = [
        {
            image: '/assets/images/image62.jpg',
            title: 'начало',
            description:
                'Позже, благодаря интересу к моей подвеске, я поняла, что этот остров такой знаковый не только для меня, что стало первым толчком для зарождения бренда.<br /><br />Сегодня ISLAND SOUL - это возможность для меня выражать свою любовь к этому миру, делиться ценностями и идеями, создавая украшения, которые подчеркивают уникальность каждой женщины.<br /><br />В 2017 мы открыли доставку по острову, затем маленький шоурум в Москве, сегодня у нас 4 собственных магазина: в Москве, Санкт-Петербурге, Сочи и Бали, 16 успешных франшиз по всей России, партнёры в Казахстане и Украине, и это все ещё самое начало нашего большого Пути.',
        },
        {
            image: '/assets/images/image61.jpg',
            title: 'первый магазин',
            description:
                'Сегодня ISLAND SOUL - это возможность для меня выражать свою любовь к этому миру, делиться ценностями и идеями, создавая украшения, которые подчеркивают уникальность каждой женщины.<br /><br />В 2017 мы открыли доставку по острову, затем маленький шоурум в Москве, сегодня у нас 4 собственных магазина: в Москве, Санкт-Петербурге, Сочи и Бали, 16 успешных франшиз по всей России, партнёры в Казахстане и Украине, и это все ещё самое начало нашего большого Пути.',
        },
        {
            image: '/assets/images/image63.jpg',
            title: 'трансформация души',
            description:
                'Пока другие раздают советы как мне лучше жить, какую обувь и где носить, какой цвет волос оставить, как красить глаза, как одеваться и вести себя, я просто продолжаю свой Личный путь, мои остаются, не мои отваливаются, мое дело не сворачивать с пути, не предавать себя, как бы больно иногда не было, и да я не просто иногда плачу, я кажется умираю от боли, но с Божьей помощью нахожу силы на Возрождение, в рамках одной жизни я проживаю столько судеб, в поисках Истины. Найду ли я ее покажет время, но я всё ещё полна Веры, она и есть моя движущая сила.',
        },
    ]

    return (
        <Container
            sx={{
                mt: { md: 25, lg: 17, xl: 17 },
            }}
        >
            <TopBanner
                image={'/assets/images/Rectangle1132.jpg'}
                description={'Дарья Пашкевич, основательница компании'}
                title={
                    'ISLAND SOUL для меня - это больше чем бренд, больше чем бизнес, это история моей жизни'
                }
            />
            <Divider sx={{ borderColor: theme.palette.divider }} />
            <Container disableGutters>
                <Grid container py={14}>
                    <Grid item xs={isMobile ? 12 : 1} />
                    <Grid item xs={isMobile ? 12 : 6}>
                        <Typography
                            variant="h5"
                            fontWeight={500}
                            sx={{ textTransform: 'uppercase' }}
                        >
                            что для меня island soul
                        </Typography>
                        <Typography mt={3}>
                            Компания ISLAND SOUL для меня - это больше чем
                            бренд, больше чем бизнес, это история моей жизни, а
                            если точнее, ее нового прекрасного начала. Начало на
                            острове Бали.
                        </Typography>
                        <Typography mt={3}>
                            Я знаю тут каждую тропинку, говорю на его языке и
                            чувствую себя частью этого, окружённого океаном,
                            магического места, которое подарило мне счастье быть
                            мамой. Именно поэтому мое первое украшение я сделала
                            в форме контура Бали. Это было очень лично.
                        </Typography>
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        width: '100%',
                        position: 'relative',
                        paddingBottom: '56.25%',
                        height: '0',
                        overflow: 'hidden',
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            borderWidth: 0,
                            outlineWidth: 0,
                        }}
                        allowFullScreen
                        title="YouTube video player"
                        src="https://www.youtube.com/embed/s9HCBQy5ps4"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        component="iframe"
                    />
                </Box>
            </Container>
            <Box mt={11}>
                {grids.map((item, index) => (
                    <GridStaticRow
                        key={index}
                        image={item.image}
                        title={item.title}
                        description={item.description}
                        rightSide={index % 2 === 1}
                    />
                ))}
            </Box>
            {/* <Box mt={25} sx={{ textAlign: 'center' }}>
                <Typography variant="h5" sx={{ textTransform: 'uppercase' }}>
                    <a
                        rel="noreferrer"
                        href="https://www.instagram.com/daria_soul"
                        target="_blank"
                    >
                        @daria_soul
                    </a>
                </Typography>
            </Box> */}
        </Container>
    )
}
