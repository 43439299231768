import { Box, Stack } from '@mui/material'
import React from 'react'

import { ReactComponent as TelegramGreenIcon } from './assets/telegram_green_icon.svg'
import { ReactComponent as VKGreenIcon } from './assets/vk_green_icon.svg'
import { ReactComponent as WhatsAppGreenIcon } from './assets/whatsapp_green_icon.svg'
import YoutubeGreenIcon from './assets/Youtube.png'

export const SocialNetsBlock: React.FC = () => {
    return (
        <Stack spacing={3} direction="row" alignItems="center">
            <Box
                sx={{ display: 'flex' }}
                rel="noreferrer"
                href="https://m.vk.com/public211205674"
                target="_blank"
                component="a"
            >
                <VKGreenIcon />
            </Box>

            <Box
                sx={{ display: 'flex' }}
                rel="noreferrer"
                href="https://t.me/+6n-0K5F5BblhNjVi"
                target="_blank"
                component="a"
            >
                <TelegramGreenIcon />
            </Box>

            <Box
                sx={{ display: 'flex' }}
                rel="noreferrer"
                href="https://wa.me/79999718174"
                target="_blank"
                component="a"
            >
                <WhatsAppGreenIcon />
            </Box>

            <Box
                sx={{ display: 'flex' }}
                rel="noreferrer"
                href="https://www.youtube.com/channel/UCtHo8K1aLV2nd-Xdi3J4eTQ"
                target="_blank"
                component="a"
            >
                <Box
                    width={'24px'}
                    height={'24px'}
                    component={'img'}
                    src={YoutubeGreenIcon}
                />
            </Box>
        </Stack>
    )
}
