import { Grid, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'

import { BoxLoyalty, LoyaltyItem } from './BoxLoyalty'

const loyaltyItems: LoyaltyItem[] = [
    {
        title: `\xa0`,
        subtitle: `\xa0`,
        details: [
            { value: 'Описание уровня', style: { height: '150px' } },
            { value: 'Welcome бонус' },
            { value: 'Бонусы в день рождения' },
            { value: 'Начисление бонусов' },
            { value: 'Условия получения', style: { height: '100px' } },
            { value: 'Скидка бонусами' },
        ],
    },
    {
        title: '1 ступень',
        subtitle: 'Пробуждение',
        details: [
            {
                value: 'Добро пожаловать! Ты только знакомишься со смыслами в украшениях и своими желаниями, но уже делаешь огромный вклад в себя.',
                style: { height: '150px' },
                bold: true,
            },
            { value: '500', bold: true },
            { value: '2000', bold: true },
            { value: '3%', bold: true, textStyle: { fontSize: '24px' } },
            {
                value: 'Регистрация на сайте, обязательное заполнение личного кабинета / заполнение анкеты',
                bold: true,
                style: { height: '100px' },
            },
            { value: 'До 15% оплаты бонусами', bold: true },
        ],
    },
    {
        title: '2 ступень',
        subtitle: 'осознание',
        details: [
            {
                value: 'Мы рады быть твоим проводником в мир осознанности. Наслаждайся своим путем!',
                style: { height: '150px' },
                bold: true,
            },
            { value: '', bold: true },
            { value: '2000', bold: true },
            { value: '3%', bold: true, textStyle: { fontSize: '24px' } },
            {
                value: 'Сумма покупок до 100 000 ₽',
                bold: true,
                style: { height: '100px' },
            },
            { value: 'До 15% оплаты бонусами', bold: true },
        ],
    },
    {
        title: '3 ступень',
        subtitle: 'преображение',
        details: [
            {
                value: 'Мы создаем ювелирные талисманы, способные изменить мировоззрение. Истинное преображение наступает через внутреннюю трансформацию.',
                style: { height: '150px' },
                bold: true,
            },
            { value: '' },
            { value: '2000', bold: true },
            { value: '5%', bold: true, textStyle: { fontSize: '24px' } },
            {
                value: 'Сумма покупок от 100 000 ₽ до 200 000 ₽',
                bold: true,
                style: { height: '100px' },
            },
            { value: 'До 15% оплаты бонусами', bold: true },
        ],
    },
    {
        title: '4 ступень',
        subtitle: 'познание',
        details: [
            {
                value: 'Принимая свет и тень в себе, ты познаешь все грани своей женственности. Истинная красота - в твоей целостности.',
                style: { height: '150px' },
                bold: true,
            },
            { value: '' },
            { value: '2000', bold: true },
            { value: '7%', bold: true, textStyle: { fontSize: '24px' } },
            {
                value: 'Сумма покупок от 200 000 ₽ до 300 000 ₽',
                bold: true,
                style: { height: '100px' },
            },
            { value: 'До 15% оплаты бонусами', bold: true },
        ],
    },
    {
        title: '5 ступень',
        subtitle: 'самадхи',
        details: [
            {
                value: 'Ты достигла высшего уровня гармонии и спокойствия, когда сознание освобождается от противоречий между внутренним и внешним мирами. Мы счастливы видеть тебя здесь.',
                style: { height: '150px' },
                bold: true,
            },
            { value: '' },
            { value: '2000', bold: true },
            { value: '9%', bold: true, textStyle: { fontSize: '24px' } },
            {
                value: 'Сумма покупок от 300 000 ₽ и выше',
                bold: true,
                style: { height: '100px' },
            },
            { value: 'До 15% оплаты бонусами', bold: true },
        ],
    },
]

export const LoyaltyTable: React.FC<{ indexes?: number[] }> = ({ indexes }) => {
    const theme = useTheme()
    const isTablet = useMediaQuery(theme.breakpoints.between('md', 'lg'))

    const remappedItems = indexes?.length
        ? loyaltyItems.map((item) => ({
              ...item,
              details: item.details.filter((_, index) =>
                  indexes.includes(index)
              ),
          }))
        : loyaltyItems

    let changedLoyaltyItems: LoyaltyItem[] = []

    if (isTablet) {
        changedLoyaltyItems = remappedItems.slice(1)
    } else {
        changedLoyaltyItems = remappedItems
    }

    return (
        <Grid
            container
            spacing={{ xs: 2, md: 0 }}
            sx={{
                overflow: 'hidden',
                pr: {
                    md: '32px',
                },
            }}
        >
            {changedLoyaltyItems.map((loyaltyItem, index) => (
                <Grid item xs={12} md={2} key={index} p={0}>
                    <BoxLoyalty
                        left={index === 0 && !isTablet}
                        item={loyaltyItem}
                        labelItem={changedLoyaltyItems[0]}
                    />
                </Grid>
            ))}
        </Grid>
    )
}
