import { Box, Typography } from '@mui/material'
import React from 'react'
import { ILineItem } from 'types/ILineItem'

interface OrderDetailProductProps {
    item: ILineItem
    image: string
    optionsText: string
}

export const OrderDetailProduct: React.FC<OrderDetailProductProps> = ({
    item,
    image,
    optionsText,
}) => (
    <Box display="flex" justifyContent="space-between" width="100%">
        <Box display="flex">
            <Box
                sx={{
                    width: '64px',
                    height: '64px',
                    borderRadius: '16px',
                }}
                component="img"
                src={image}
            />

            <Box pl="16px" pr="16px">
                <Typography
                    variant="body2"
                    sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 1,
                    }}
                >
                    {item.attributes.name}
                </Typography>
                <Typography variant="body2" pt="8px" textTransform="lowercase">
                    {item.attributes.quantity} шт
                    {optionsText ? `, ${optionsText}` : ''}
                </Typography>
            </Box>
        </Box>
        <Box>
            <Typography
                variant="body2"
                fontWeight="medium"
                overflow="visible"
                whiteSpace="nowrap"
            >
                {item.attributes.display_price}
            </Typography>
            {Boolean(item.attributes.sale_percent) && (
                <Typography
                    variant="body2"
                    color="grey.400"
                    fontWeight="light"
                    overflow="visible"
                    whiteSpace="nowrap"
                    sx={{
                        textDecoration: 'line-through',
                    }}
                >
                    {item.attributes.display_compare_at_price}
                </Typography>
            )}
        </Box>
    </Box>
)
