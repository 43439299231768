import { PayloadAction } from '@reduxjs/toolkit'
import { imagesActions } from 'app/modules/Images/slice'
import { productsActions } from 'app/modules/Products/slice'
import { call, put, takeLatest } from 'redux-saga/effects'
import { IProductCollectionResponse } from 'types/IProduct'
import { request } from 'utils/request'

import { searchActions } from '.'
import { IProductsSearch } from './types'

export function* search(action: PayloadAction<IProductsSearch>) {
    try {
        const { page, perPage: per_page, searchQuery } = action.payload

        const response: IProductCollectionResponse = yield call(
            request,
            '/api/v2/storefront/search',
            {
                params: {
                    page,
                    per_page,
                    include: ['images'].join(','),
                    query: searchQuery,
                },
            }
        )

        const total = response.meta.total_count
        const totalPages = response.meta.total_pages

        yield put(imagesActions.imagesAdded(response))

        yield put(
            productsActions.searchPageProductsLoaded({
                products: response.data,
                totalPages: totalPages,
                totalProductsCount: total,
                addProducts: page > 1,
            })
        )

        yield put(searchActions.searchLoaded())
    } catch (error: any) {
        yield put(searchActions.statusError())
    }
}

export function* searchWatcher() {
    yield takeLatest(searchActions.search.type, search)
}
