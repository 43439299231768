import { Box } from '@mui/material'
import { checkoutActions } from 'app/modules/Checkout/slice'
import { selectPaymentMethods } from 'app/modules/Checkout/slice/selectors'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PaymentMethodId } from 'types/IPaymentsShipmentsAttributes'

import { ReactComponent as CardIcon } from '../assets/Card.svg'
import { ReactComponent as CashIcon } from '../assets/Cash.svg'
import { ReactComponent as DolyameIcon } from '../assets/Dolyame.svg'
import { ReactComponent as SbpIcon } from '../assets/Sbp.svg'
import { selectCart } from '../slice/selectors'
import { DolyamePaymentBottomBlock } from './DolyamePaymentBottomBlock'
import { IPaymentItem, PaymentItem } from './PaymentItem'

const dolyameText = 'Долями'
/**
 * В тексте буква о английская, что бы не работал штатный блок долями
 */
const dolyamePlusText = 'Дoлями'

interface PaymentItemsProps {
    selectedPaymentMethodId: PaymentMethodId | null
    setPaymentMethodId: (paymentMethodId: PaymentMethodId) => void
}

export const PaymentItems: React.FC<PaymentItemsProps> = ({
    selectedPaymentMethodId,
    setPaymentMethodId,
}) => {
    const dispatch = useDispatch()

    const cart = useSelector(selectCart)
    const total = Number(cart?.attributes.total ?? 0)
    const isDolyamePlus = total > 30000 && total <= 200000

    const paymentMethods = useSelector(selectPaymentMethods)

    useEffect(() => {
        dispatch(checkoutActions.loadPaymentMethods())
    }, [])

    useEffect(() => {
        const firstActiveMethod = paymentMethods.find(
            (method) => method.accessible
        )
        if (firstActiveMethod) {
            setPaymentMethodId(firstActiveMethod.payment_method_id)
        }
    }, [paymentMethods])

    const payments: IPaymentItem[] = [
        {
            value: PaymentMethodId.cardOnlile,
            text: 'Картой онлайн',
            handleChange: (paymentMethodId) =>
                setPaymentMethodId(paymentMethodId),
            IconComponent: <CardIcon />,
        },
        {
            value: PaymentMethodId.onPickup,
            text: 'При получении',
            handleChange: (paymentMethodId) =>
                setPaymentMethodId(paymentMethodId),
            IconComponent: <CashIcon />,
        },
        {
            value: PaymentMethodId.SBP,
            text: 'СБП',
            handleChange: (paymentMethodId) =>
                setPaymentMethodId(paymentMethodId),
            IconComponent: <SbpIcon />,
        },
        {
            value: PaymentMethodId.dolyame,
            text: isDolyamePlus ? dolyamePlusText : dolyameText,
            handleChange: (paymentMethodId) =>
                setPaymentMethodId(paymentMethodId),
            IconComponent: <DolyameIcon />,
            ButtomComponent: isDolyamePlus && (
                <DolyamePaymentBottomBlock total={total} />
            ),
        },
    ]

    const selectedPaymentMethodIndex = payments.findIndex(
        (payment) => selectedPaymentMethodId === payment.value
    )

    return (
        <Box
            sx={{
                borderTop:
                    selectedPaymentMethodIndex === 0
                        ? '1px solid transparent'
                        : '1px solid #DEDEDE',
                borderBottom:
                    selectedPaymentMethodIndex === payments.length - 1
                        ? '1px solid transparent'
                        : '1px solid #DEDEDE',
                borderLeft: '1px solid #DEDEDE',
                borderRight: '1px solid #DEDEDE',
                borderRadius: '10px',
            }}
        >
            {payments
                .filter(
                    (payment) =>
                        paymentMethods.find(
                            (method) =>
                                method.payment_method_id === payment.value
                        )?.accessible || false
                )
                .map((payment, index) => (
                    <Box
                        key={payment.value}
                        sx={{
                            borderBottom:
                                index < selectedPaymentMethodIndex - 1
                                    ? '1px solid #DEDEDE'
                                    : '1px solid transparent',
                            borderTop:
                                index > selectedPaymentMethodIndex + 1
                                    ? '1px solid #DEDEDE'
                                    : '1px solid transparent',
                            m: '-1px',
                        }}
                    >
                        <PaymentItem
                            item={payment}
                            active={selectedPaymentMethodId === payment.value}
                        />
                    </Box>
                ))}
        </Box>
    )
}
