import { Box, Typography } from '@mui/material'
import { ReactComponent as Delivery32 } from 'app/modules/Cart/assets/Delivery32.svg'
import { ReactComponent as Payment_security32 } from 'app/modules/Cart/assets/Payment_security32.svg'
import { ReactComponent as Return32 } from 'app/modules/Cart/assets/Return32.svg'
import React from 'react'

const OrderDetailInfoItem: React.FC<{
    icon: React.ReactNode
    text: string
    maxWidth?: string
}> = ({ icon, text, maxWidth = '115px' }) => (
    <Box
        width="30%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        maxWidth={maxWidth}
    >
        {icon}
        <Typography variant="body3" textAlign="center" pt="16px">
            {text}
        </Typography>
    </Box>
)

export const OrderDetailInfoItems: React.FC = () => {
    return (
        <Box display="flex" justifyContent="space-between" py="40px">
            <OrderDetailInfoItem
                icon={<Delivery32 />}
                text={'Бесплатная доставка от 15 000 ₽'}
            />
            <OrderDetailInfoItem
                icon={<Return32 />}
                text={'Возврат в течение первых 7 дней при покупке онлайн'}
                maxWidth="130px"
            />
            <OrderDetailInfoItem
                icon={<Payment_security32 />}
                text={'Безопасная оплата'}
            />
        </Box>
    )
}
