import { Container } from '@mui/material'
import { PartnerCreateOrderSteps } from 'app/modules/Cart/components/PartnerCreateOrderSteps'
import { selectCart } from 'app/modules/Cart/slice/selectors'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

export const MobilePartnerCreateOrder: React.FC = () => {
    const cart = useSelector(selectCart)

    useEffect(() => {
        if (cart?.attributes.payment_url) {
            window.location.href = cart.attributes.payment_url
        }
    }, [cart?.attributes.payment_url])

    return (
        <Container
            sx={{
                mt: { md: 7 },
            }}
        >
            <PartnerCreateOrderSteps />
        </Container>
    )
}
