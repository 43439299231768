import { Typography } from '@mui/material'
import React from 'react'
import { productCountText } from 'utils/productCountText'

interface OrderDetailItemCountProps {
    itemCount: number
}

export const OrderDetailItemCount: React.FC<OrderDetailItemCountProps> = ({
    itemCount,
}) => {
    const productsText = productCountText(itemCount)

    return (
        <Typography
            variant="body1"
            fontWeight="regular"
            sx={{ fontSize: { xs: '18px', md: '14px' } }}
        >
            {itemCount} {productsText} на сумму
        </Typography>
    )
}
