import { Box, Grid, Typography } from '@mui/material'
import { citiesActions } from 'app/modules/Cities/slice'
import { selectCurrentCity } from 'app/modules/Cities/slice/selectors'
import { chunk } from 'lodash'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ICountryData } from 'types/ICountry'

import { HeaderLocationChildProps } from '..'

const DesktopHeaderLocationCountryGrid: React.FC<{
    countryData: ICountryData
}> = ({ countryData }) => {
    const dispatch = useDispatch()

    const currentCity = useSelector(selectCurrentCity)

    const handleChangeCurrentCity = (id: string) => {
        if (currentCity?.id !== id)
            dispatch(citiesActions.changeCurrentCity(id))
    }

    const chunkSize = Math.ceil(
        countryData.isWideCountry
            ? countryData.cities.length / 4
            : countryData.cities.length / 2
    )

    const citiesChunks = chunk(countryData.cities, chunkSize)

    return (
        <Grid container mt={2.5}>
            {citiesChunks.map((citiesChunk, index) => (
                <Grid item key={index} xs={countryData.isWideCountry ? 3 : 6}>
                    <Grid container>
                        {citiesChunk.map((city, cityIndex) => (
                            <Grid item key={cityIndex} mt={1.25} xs={12}>
                                <Box
                                    sx={(theme) => ({
                                        cursor: 'pointer',
                                        color:
                                            city.id === currentCity?.id
                                                ? theme.palette.primary.main
                                                : 'black',
                                        '&:hover': {
                                            color: theme.palette.primary.main,
                                        },
                                    })}
                                    display={'flex'}
                                    alignItems={'center'}
                                    onClick={() =>
                                        handleChangeCurrentCity(city.id)
                                    }
                                >
                                    {city.id === currentCity?.id && (
                                        <Box
                                            sx={(theme) => ({
                                                bgcolor:
                                                    theme.palette.primary.main,
                                                width: '4px',
                                                height: '4px',
                                                borderRadius: '50%',
                                                mr: 0.875,
                                                ml: -1.375,
                                            })}
                                        />
                                    )}

                                    <Typography variant="body2">
                                        {city.attributes.name}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            ))}
        </Grid>
    )
}

export const DesktopHeaderLocationCountriesGrid: React.FC<
    HeaderLocationChildProps
> = ({ countriesData }) => {
    return (
        <Grid container mt={1.75}>
            {countriesData
                .filter((countryData) => countryData.cities.length > 0)
                .map((countryData, index) => (
                    <Grid
                        item
                        key={index}
                        xs={countryData.isWideCountry ? 12 : 6}
                        mt={4.5}
                    >
                        <Typography
                            variant="body2"
                            fontWeight={500}
                            sx={{ textTransform: 'uppercase' }}
                        >
                            {countryData.name}
                        </Typography>

                        <DesktopHeaderLocationCountryGrid
                            countryData={countryData}
                        />
                    </Grid>
                ))}
        </Grid>
    )
}
