import { Box, Container, Typography } from '@mui/material'
import GridStaticRow, { GridStaticRowProps } from 'app/components/GridStaticRow'
import React from 'react'

export const IsisPlatform: React.FC = () => {
    const grids: GridStaticRowProps[] = [
        {
            image: '/assets/images/isit_69.jpg',
            title: 'IT – сердце нашего бизнеса',
            description:
                '«ISIT» – это облачная ERP-система, которая охватывает все процессы всех клиентов нашего бизнеса: от партнеров до покупателей. Мы автоматизируем всё!<br /><br />Цифровые инструменты обеспечивают нам максимальную эффективность и безопасность бизнеса, позволяют более точно понимать клиентов и отвечать запросам нового времени.<br /><br />Мы инвестируем в цифрровые решения сегодня, чтобы завтра стать недосягаемым лидером рынка. ',
        },
        {
            image: '/assets/images/isit_70.jpg',
            title: 'монобрендовый маркетплейс для партнеров-франчайзи',
            description:
                '<b>1 Продажа товаров по всей стране.</b> Все магазины партнёров представлены на сайте и могут продавать товары по всей стране с центрального сайта.<br /><br /><b>2 Сплитирование платежей при покупке:</b> каждый партнёр получает деньги за покупки с центрального сайта сразу, при совершении оплаты. При этом партнеру не нужно заводить эквайринг.<br /><br /><b>3 Сборные заказы:</b> это значит что любой клиент с любой точки страны может купить любые товары сети. Если нет у партнёра, автоматически создастся внутренний автозаказ на перемещение от другого партнёра, где есть в наличии. При этом деньги каждый получит автоматом.<br /><br /><b>4 Автозаказ курьера:</b> партнеру не нужно беспокоиться о доставке по стране, система сама закажет курьера, который заберет из магазина и доставит товар до клиента.<br /><br /><i>и множество других уникальных IT решений</i>',
        },
        {
            image: '/assets/images/isit_71.jpg',
            title: 'Единая бонусная система',
            description:
                'Это значит, что клиент может копить баллы у любого партнёра на единый кошелек и тратить у любого партнёра по стране.<br /><br />Мы разработали бизнес-модель и систему при которой клиенту удобно, а партнёр никогда не теряет при продаже за баллы (компенсация деньгами из единого банка).<br /><br />У продавца есть удобный интерфейс программы лояльности для быстрой фиксации оффлайн покупок.',
        },
        {
            image: '/assets/images/isit_72.jpg',
            title: 'Кабинет франчайзи-партнёра',
            description:
                '<b>1 База знаний</b> по всем аспектам работы ювелирного магазина: рекламе, найму, общению с клиентом, управлению. В базе знаний хранятся все рабочие документы, макеты рекламных материалов, стандарты, фотографии и пр.<br /><br /><b>2 Работа с заказами</b> в том числе сборными (из разных магазинов)<br /><br /><b>3 Аналитика:</b> товарная и финансовая аналитика для франчайзи<br /><br /><b>4 Обучение</b> (скоро): онлайн-курсы с тестированием позволят всем консультантам легко овладеть необходимым объемом знаний. А ведь мы – не просто про украшения, мы - про что-то большее. Поэтому и знаний больше.<br /><br /><b>5 Роадмап открытия</b> (скоро)<br /><br /><b>6 Корпоративный портал и система рейтингов</b> (скоро)<br /><br /><i>и множество других уникальных IT решений</i>',
        },
        {
            image: '/assets/images/isit_73.jpg',
            title: 'Омниканальный кабинет клиента',
            description:
                'Омниканальный кабинет клиента: клиент видит не только онлайн покупки, но и офлайн покупки у любого партнёра, и более того - состав офлайн заказа.<br /><br />Такая система позволяет быть максималь клиентоцентричным и создавать необходимую атмосферу прозрачности и доверия с любимыми клиентами.',
        },
        {
            image: '/assets/images/isit_74.png',
            title: 'Цифровая КУЛЬТУРА',
            description:
                'Как компания, мы находимся в активной стадии цифровой трансформации. Мы построили цифровую бизнес-модель с акцентом на клиентоцентричность, разобрались с CJM и динамично формируем цифровой актив компании.<br /><br />Ежемесячно мы проводим стратегические сессии с экспертами в IT. Мы изучаем лучшие практики и стараемся их внедрять в ювелирный бизнес. Искусственный интеллект, data science, блокчейн – не пустые слова для нас. Roadmap разработки на ближайшие 2 года включает в себя проекты с использованием самых современных технологий.<br /><br />Присоединяйтесь к нам, все только начинается.',
        },
    ]

    return (
        <Container
            sx={{
                mt: { sm: 6, md: 23, lg: 23, xl: 23 },
            }}
        >
            <Box
                bgcolor={'grey.50'}
                display={'flex'}
                alignItems={'center'}
                flexDirection={'column'}
                pt={14.75}
                pb={7.5}
            >
                <Box
                    width={'75%'}
                    component={'img'}
                    src={'/assets/images/ISIT.png'}
                />

                <Typography
                    mt={12}
                    variant="h4"
                    fontWeight={500}
                    sx={{
                        textTransform: 'uppercase',
                    }}
                >
                    Island soul iT PLATFORM
                </Typography>
            </Box>

            <Box mt={8.75}>
                {grids.map((item, index) => (
                    <GridStaticRow
                        key={index}
                        image={item.image}
                        title={item.title}
                        description={item.description}
                        rightSide={index % 2 === 1}
                        bigSize
                    />
                ))}
            </Box>
        </Container>
    )
}
