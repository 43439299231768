import { NOVELTY_PRODUCTS_COUNTS } from 'app/constants'
import { SALE_CATEGORY_ID } from 'app/modules/Categories'
import { selectCategoryById } from 'app/modules/Categories/slice/selectors'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ProductSortVariants } from 'types/IProduct'

import { productsActions } from '../slice'
import { ProductSaleListSlider } from './ProductSaleListSlider'

export const ProductSaleListSliderContainer: React.FC = () => {
    const dispatch = useDispatch()

    const saleCategory = useSelector(selectCategoryById)(SALE_CATEGORY_ID)

    useEffect(() => {
        dispatch(
            productsActions.loadSaleProducts({
                page: 1,
                sort: ProductSortVariants.CREATED_AT,
                filter: {
                    taxons: SALE_CATEGORY_ID,
                },
                perPage: NOVELTY_PRODUCTS_COUNTS * 2,
            })
        )
    }, [])

    return (
        <ProductSaleListSlider
            subTitle="Ограниченное предложение"
            title={saleCategory?.attributes.name || ''}
            redirectUrl={saleCategory?.attributes.permalink}
        />
    )
}
