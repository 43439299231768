import { LoadingButton } from '@mui/lab'
import { Box, Container, Grid, Typography } from '@mui/material'
import { MindboxTarget } from 'app/components/MinboxContactForm'
import { CategoryCardsList } from 'app/modules/Categories/templates/CategoryCardsList'
import { ProductCard } from 'app/modules/Products/components/ProductCard'
import { selectSearchPageData } from 'app/modules/Products/slice/selectors'
import useOnScreen from 'hooks/useOnScreen'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EStatus } from 'types'

import { useSearchQuery } from '../../hooks/useSearch'
import { searchActions } from '../../slice'
import { selectStatus } from '../../slice/selectors'

export const MobileSearchList: React.FC = () => {
    const dispatch = useDispatch()
    const ref = useRef()
    const isVisible = useOnScreen(ref)

    const { query } = useSearchQuery()

    const {
        totalProductsCount: total,
        totalPages,
        products,
    } = useSelector(selectSearchPageData)
    const status = useSelector(selectStatus)

    const [page, setPage] = useState<number>(1)
    const count = products.length

    const handleClickShowMore = () => {
        if (page >= totalPages) {
            return
        }

        dispatch(
            searchActions.search({
                page: page + 1,
                perPage: 32,
                searchQuery: query,
            })
        )
        setPage(page + 1)
    }

    useEffect(() => {
        setPage(1)
        dispatch(
            searchActions.search({
                page: 1,
                perPage: 32,
                searchQuery: query,
            })
        )
    }, [query])

    // useEffect(() => {
    //     if (isVisible && status === EStatus.FINISHED && page < totalPages) {
    //         handleClickShowMore()
    //     }
    // }, [isVisible])

    return (
        <>
            <Container>
                <Box mt={3} mb={3} display={'flex'} flexDirection={'column'}>
                    <Typography
                        variant="body1"
                        fontWeight={500}
                        sx={{ textTransform: 'uppercase' }}
                    >
                        {status === EStatus.PENDING && page === 1
                            ? `ИЩЕМ ПО ЗАПРОСУ «${query}»`
                            : total !== 0
                            ? `НАЙДЕНО ПО ЗАПРОСУ «${query}»`
                            : `ПО ЗАПРОСУ «${query}» ничего не найдено`}
                    </Typography>

                    <Typography mt={0.5} variant="body2" color="grey.600">
                        {status === EStatus.PENDING && page === 1
                            ? ''
                            : total !== 0
                            ? `${total} товаров`
                            : 'Попробуйте найти нужные товары в рубриках'}
                    </Typography>
                </Box>

                <Grid
                    container
                    rowSpacing={{ xs: 3, md: 8.75 }}
                    columnSpacing={{ xs: 1.5, md: 3 }}
                >
                    {(!products.length && status !== EStatus.FINISHED
                        ? Array.from(new Array(3))
                        : products
                    ).map((product, index) => (
                        <Grid item key={index} xs={6} lg={4}>
                            <ProductCard product={product} />
                        </Grid>
                    ))}
                </Grid>

                <Box ref={ref}>
                    {!!products.length &&
                        totalPages > 1 &&
                        (page < totalPages || status !== EStatus.FINISHED) && (
                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                mt={10}
                            >
                                <Typography variant="body2">{`Показано ${count} из ${total}`}</Typography>

                                <LoadingButton
                                    sx={{ mt: 2 }}
                                    variant="banner"
                                    color="dark"
                                    loading={status === EStatus.PENDING}
                                    onClick={handleClickShowMore}
                                >
                                    Показать ещё
                                </LoadingButton>
                            </Box>
                        )}
                </Box>

                {total === 0 && status === EStatus.FINISHED && (
                    <>
                        <Box sx={{ mt: { xs: 7 } }}>
                            <CategoryCardsList />
                        </Box>
                    </>
                )}
            </Container>
            <MindboxTarget
                sx={{
                    mt: { xs: '40px', md: '50px' },
                }}
                popmechanicId="57914"
            />
        </>
    )
}
