import { Box, Typography } from '@mui/material'
import { ReactComponent as FavoriteBorderIcon } from 'app/modules/Cart/assets/CartItemFavoriteBorderIcon.svg'
import { ReactComponent as FavoriteIcon } from 'app/modules/Cart/assets/CartItemFavoriteIcon.svg'
import { useHandleToggleFavorite } from 'app/modules/Products/components/ProductFavorite/ProductHandleToggleFavorite'
import React from 'react'

import { CartItemProps } from './CartItem'

export const CartItemLikeButton: React.FC<CartItemProps> = ({ item }) => {
    const { isFavorite, handleToggleFavorite } = useHandleToggleFavorite(item)

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                py: '2px',
            }}
            onClick={handleToggleFavorite}
        >
            {isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}

            <Typography
                variant="body1"
                sx={{ display: { xs: 'none', md: 'inline' }, ml: '6px' }}
            >
                {isFavorite ? 'В избранном' : 'В избранное'}
            </Typography>
        </Box>
    )
}
