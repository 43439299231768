/* eslint-disable react/no-unescaped-entities */
import {
    Box,
    CardMedia,
    Container,
    Link as MuiLink,
    List,
    ListItem,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import React from 'react'

export const ReturnExchange: React.FC = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))

    return (
        <>
            <Container
                sx={{
                    width: '95%',
                    height: '44vw',
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'flex-end',
                    pl: { xs: 0, md: 0, lg: 0 },
                    pr: { xs: 0, md: 0, lg: 0 },
                    mt: { xs: 0, md: 30, lg: 25 },
                    background:
                        'linear-gradient(0deg, rgba(0, 0, 0, 0.46) 0%, rgba(0, 0, 0, 0) 100%)',
                }}
            >
                <CardMedia
                    sx={{
                        position: 'absolute',
                        objectFit: 'cover',
                        width: 'inherit',
                        zIndex: -1,
                    }}
                    src="/assets/images/ReturnExchange.jpg"
                    alt="Возврат и обмен ювелирных изделий"
                    component="img"
                />
                <Box
                    sx={{
                        pb: { xs: 2, md: 6, lg: 6 },
                        pl: { xs: 2, md: 6, lg: 6 },
                    }}
                >
                    <Typography
                        sx={{
                            color: (theme) => theme.palette.common.white,
                            fontWeight: 500,
                            textTransform: 'uppercase',
                        }}
                        variant={isMobile ? 'h5' : 'h3'}
                    >
                        ВОЗВРАТ И ОБМЕН
                    </Typography>
                    <Typography
                        sx={{
                            color: (theme) => theme.palette.common.white,
                            fontWeight: 500,
                            textTransform: 'uppercase',
                        }}
                        variant={isMobile ? 'h5' : 'h3'}
                    >
                        ЮВЕЛИРНЫХ ИЗДЕЛИЙ
                    </Typography>
                </Box>
            </Container>

            <Container
                sx={{
                    mt: { md: 14, lg: 14 },
                    maxWidth: '900px',
                }}
            >
                <h1>
                    ВОЗВРАТ И ОБМЕН ЮВЕЛИРНЫХ ИЗДЕЛИЙ, ПРИОБРЕТЕННЫХ В МАГАЗИНАХ
                    ОФФЛАЙН
                </h1>

                <h2>Возврат и обмен</h2>

                <p>
                    Ювелирные изделия надлежащего качества, согласно
                    законодательству РФ, возврату или обмену не подлежат (ч. 1.
                    ст. 25 Закона РФ от 07.02.1992 N 2300-1 "О защите прав
                    потребителей", «Перечень непродовольственных товаров
                    надлежащего качества, не подлежащих обмену», утвержденный
                    постановлением Правительства Российской Федерации от 31
                    декабря 2020 года № 2463).
                </p>
                <p>
                    Требование о замене изделий из драгоценных металлов и
                    драгоценных камней можно предъявить в течение гарантийного
                    срока
                    <sup>1</sup> в случае выявления в них дефектов.
                </p>
                <p>
                    При обнаружении у изделия дефектов Покупателю необходимо
                    обратиться к Продавцу с соответствующей претензией по
                    качеству товара. Выбор предъявляемого требования (замена,
                    возврат денежных средств и т.д.) в соответствии со ст. 18
                    Закона РФ от 07.02.1992 N 2300-1 "О защите прав
                    потребителей" остается за потребителем. Продавец обязан
                    принять у Покупателя изделие ненадлежащего качества и в
                    случае необходимости провести проверку качества изделия, в
                    которой Покупатель вправе участвовать.
                </p>
                <p>
                    Для осуществления обмена или возврата ювелирного изделия
                    ненадлежащего качества в течение гарантийного срока
                    покупатель обязан предоставить:
                </p>
                <span>
                    <ul>
                        <li>Кассовый чек (при наличии);</li>
                        <li>Ювелирное изделие;</li>
                        <li>Неповрежденные бирки изделия;</li>
                        <li>
                            <MuiLink
                                href="https://disk.yandex.ru/i/0zRjg0tQ_tGR6w"
                                color="primary"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Письменное заявление
                            </MuiLink>
                            <sup>2</sup>, паспорт для подтверждения паспортных
                            данных, согласие на обработку персональных данных;
                            согласие возместить расходы на проведение
                            экспертизы, а также связанные с ее проведением
                            расходы на хранение и транспортировку товара,
                            доставку изделия до склада продавца, в случае, если
                            в результате экспертизы товара установлено, что его
                            недостатки возникли вследствие обстоятельств, за
                            которые не отвечает продавец (изготовитель);
                        </li>
                        <li>
                            <MuiLink href="/Заявление-Акт ПП.docx" download>
                                акт на сдачу изделия на осмотр
                            </MuiLink>
                            .
                        </li>
                    </ul>
                </span>

                <h2>Сроки обращения</h2>
                <span>
                    <ul>
                        <li>
                            В течение установленного гарантийного срока (6
                            месяцев).
                        </li>
                    </ul>
                </span>

                <h2>
                    Сроки возврата денежных средств, замены товара ненадлежащего
                    качества
                </h2>

                <p>
                    Продавец обязан осуществить возврат денежных средств за
                    товар ненадлежащего качества в течение 10 календарных дней
                    со дня предъявления указанного требования Покупателем (ст.
                    22 Закона РФ от 07.02.1992 N 2300-1 "О защите прав
                    потребителей").
                </p>
                <p>
                    В случае обнаружения потребителем недостатков товара и
                    предъявления требования о его замене продавец (изготовитель)
                    обязан заменить такой товар в течение семи дней со дня
                    предъявления указанного требования потребителем, а при
                    необходимости дополнительной проверки качества такого товара
                    продавцом (изготовителем) - в течение двадцати дней со дня
                    предъявления указанного требования.
                </p>
                <p>
                    Если у продавца (изготовителя) в момент предъявления
                    требования отсутствует необходимый для замены товар, замена
                    должна быть проведена в течение месяца со дня предъявления
                    такого требования (ст. 21 Закона РФ от 07.02.1992 N 2300-1
                    "О защите прав потребителей").
                </p>

                <span>
                    <Typography
                        component={'p'}
                        color={theme.palette.text.disabled}
                    >
                        <sup>1</sup> Гарантийный срок на украшения Island Soul
                        составляет 6 месяцев со дня покупки.
                    </Typography>
                    <Typography
                        component={'p'}
                        color={theme.palette.text.disabled}
                    >
                        <sup>2</sup> В заявлении необходимо указать полные
                        реквизиты карты, с которой Покупатель производили оплату
                        ювелирного изделия, в случае оплаты по банковской карте;
                    </Typography>
                </span>
                <strong>
                    Расчеты с потребителем в случае приобретения им изделия
                    ненадлежащего качества:
                </strong>
                <span>
                    <ul>
                        <li>
                            при замене изделия ненадлежащего качества на товар
                            этой же марки (этой же модели и (или) артикула)
                            перерасчет цены товара не производится;
                        </li>
                        <li>
                            при замене изделия ненадлежащего качества на такой
                            же товар другой марки (модели, артикула) в случае,
                            если цена товара, подлежащего замене, ниже цены
                            товара, предоставленного взамен, потребитель должен
                            доплатить разницу в ценах; в случае, если цена
                            товара, подлежащего замене, выше цены товара,
                            предоставленного взамен, разница в ценах
                            выплачивается потребителю. Цена изделия, подлежащего
                            замене, определяется на момент его замены.
                        </li>
                    </ul>
                </span>

                <h1>
                    ВОЗВРАТ И ОБМЕН ТОВАРА, ПРИОБРЕТЕННОГО ЧЕРЕЗ
                    ИНТЕРНЕТ-МАГАЗИН
                </h1>

                <h2>Возврат и обмен товара надлежащего качества</h2>

                <p>
                    Согласно ст. 26.1 Закона РФ «О защите прав Потребителей» при
                    дистанционной продаже (интернет-магазин) Покупатель вправе
                    отказаться от товара в любой момент до передачи, в течение 7
                    календарных дней после передачи товара. Возврат товара
                    надлежащего качества возможен в случае, если сохранены его
                    товарный вид, потребительские свойства, целостность
                    опломбированных ярлыков, а также документ, подтверждающий
                    факт и условия покупки указанного товара (чек или
                    электронный чек). Отсутствие у потребителя документа,
                    подтверждающего факт и условия покупки товара, не лишает его
                    возможности ссылаться на другие доказательства приобретения
                    товара у данного продавца.
                </p>

                <h2>
                    {' '}
                    Возврат и обмен ювелирных изделий ненадлежащего качества,
                    приобретенных через интернет-магазин{' '}
                </h2>
                <p>
                    Для осуществления обмена или возврата товаров ненадлежащего
                    качества в течение гарантийного срока покупатель обязан
                    предоставить следующие документы:
                </p>
                <span>
                    <ul>
                        <li>
                            Фото товара ненадлежащего качества с указанием
                            дефектов (на фото должен просматриваться дефект);
                        </li>
                        <li>Кассовый чек или электронный чек (при наличии);</li>
                        <li>Неповрежденные бирки изделия;</li>
                        <li>
                            <MuiLink
                                href="https://disk.yandex.ru/i/0zRjg0tQ_tGR6w"
                                color="primary"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Заполненное заявление на возврат
                            </MuiLink>
                            <sup>3</sup> (в электронном виде или скан/фото
                            распечатанного документа), содержащее согласие на
                            обработку персональных данных, а также согласие
                            расходы на проведение экспертизы, а также связанные
                            с ее проведением расходы на хранение и
                            транспортировку товара, доставку изделия до склада
                            продавца, в случае, если в результате экспертизы
                            товара установлено, что его недостатки возникли
                            вследствие обстоятельств, за которые не отвечает
                            продавец (изготовитель);
                        </li>
                        <li>
                            <MuiLink href="/Заявление-Акт ПП.docx" download>
                                Акт на сдачу изделия на осмотр
                            </MuiLink>
                            .
                        </li>
                    </ul>
                </span>

                <h2>Сроки обращения</h2>
                <Typography component={'p'}>
                    В течение установленного гарантийного срока (6 месяцев).
                </Typography>
                <span>
                    <Typography
                        component={'em'}
                        color={theme.palette.text.disabled}
                    >
                        В заявлении требуется указать данные покупателя, который
                        совершал оплату товара и которому будет производиться
                        возврат денежных средств. Владелец банковского счёта, на
                        который будут перечислены денежные средства, должен
                        совпадать с заявителем. Денежные средства возвращаются
                        на расчетный счет лица, оформившего заказ.
                    </Typography>
                </span>

                <h2>К гарантийным случаям не относятся:</h2>
                <List sx={{ listStyleType: 'disc', padding: 'revert' }}>
                    <ListItem sx={{ display: 'list-item', pl: 0 }}>
                        <Typography>
                            Почернение изделий, покрытие патиной.
                        </Typography>
                        <Typography>
                            Это естественный химический процесс серебра. Серебро
                            925 пробы и Лигатура (Сплав для улучшения свойств
                            металла), покрываются патиной из-за естественных
                            причин - влаги, потожировых остатков, щелочных
                            соединений (мыла\шампуней), спиртосодержащих веществ
                            (духи\туалетная вода).
                        </Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item', pl: 0 }}>
                        <Typography>
                            Наличие грязи под камнем изделия.
                        </Typography>
                        <Typography>
                            Глухие касты не являются герметичными, рекомендуется
                            снимать изделия при принятии ванн, при нанесении
                            кремов, при занятии спортом, при мытье посуды и др.
                            При попадании влаги под камень, внутри образуется
                            патина, что негативно скажется на восприятии
                            изделия.
                        </Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item', pl: 0 }}>
                        <Typography>Помутнение камней изделия.</Typography>
                        <Typography>
                            Наши камни являются натуральными, при неаккуратном
                            ношении изделий камни могут изменить свои свойства.
                            При высоких температурах - почернеть\побелеть, при
                            долгом солнечном воздействии камень выцветает, при
                            сильных механических повреждениях камни трескаются.
                        </Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item', pl: 0 }}>
                        <Typography>Чернота внутри бус изделия.</Typography>
                        <Typography>
                            Наши бусы насаживаются на серебряную проволоку, при
                            прокручивании бусина истирает проволоку, оттого
                            появляется микростружка.
                        </Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item', pl: 0 }}>
                        <Typography>
                            Несовершенство натуральных камней изделия.
                        </Typography>
                        <Typography>
                            Совершенных натуральных камней не бывает, каждый
                            камень по-своему красив и любой натуральный дефект
                            камня - его преимущество. Изгибы барочного жемчуга и
                            наплывы, искривление дисперсии перламутра, поры на
                            лабрадорите, все это не является браком или
                            гарантийным случаем.
                        </Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item', pl: 0 }}>
                        <Typography>
                            Механические повреждения изделия.
                        </Typography>
                        <Typography>
                            царапины, следы удара, заломы, нарушение геометрии.
                        </Typography>
                    </ListItem>
                </List>

                <Typography component={'p'} pt={2}>
                    Носите изделия аккуратно, раз в неделю протирайте насухо
                    салфетками с раствором, тогда изделия будут радовать глаз.
                </Typography>
                <Typography component={'p'} pt={2}>
                    Претензии рассматриваются Продавцом при условии
                    предоставления документов и приобретённых ювелирных изделий
                    в течение 10 (десяти) календарных дней.
                </Typography>
            </Container>
        </>
    )
}
