import {
    Box,
    Container,
    Grid,
    Link,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { LegalInfoBlock } from 'app/components/LegalInfoBlock'
import React from 'react'

interface RowProps {
    text?: React.ReactNode
}

const TableRow: React.FC<RowProps> = ({ text }) => (
    <Grid item xs py={1}>
        <Typography variant="body2">{text}</Typography>
    </Grid>
)

export const UserAgreementPage: React.FC = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))

    const documents = [
        [
            <Box minWidth="300px" key="label">
                Договор-оферта
            </Box>,
            <Link
                key="download"
                rel="noreferrer"
                href="/agreements/1_Договор_оферта_ИП_Судорженко_Д_В.pdf"
                target="_blank"
            >
                скачать
            </Link>,
        ],
        [
            <Box minWidth="300px" key="label">
                Политика обработки персональных данных
            </Box>,
            <Link
                key="download"
                rel="noreferrer"
                href="/agreements/2_Политика_обработки_персональных_данных_ИП_Судорженко_Д_В.pdf"
                target="_blank"
            >
                скачать
            </Link>,
        ],
        [
            <Box minWidth="300px" key="label">
                Согласие на обработку персональных данных
            </Box>,
            <Link
                key="download"
                rel="noreferrer"
                href="/agreements/3_Согласие_на_обработку_персональных_данных_ИП_Судорженко_Д_В.pdf"
                target="_blank"
            >
                скачать
            </Link>,
        ],
        [
            <Box minWidth="300px" key="label">
                Согласие на рассылку электронных сообщений
            </Box>,
            <Link
                key="download"
                rel="noreferrer"
                href="/agreements/4_Согласие_рассылка_ИП_Судорженко_Д_В.pdf"
                target="_blank"
            >
                скачать
            </Link>,
        ],
    ]

    return (
        <Container
            sx={{
                mt: { md: 25, lg: 17, xl: 17 },
            }}
        >
            <Container disableGutters>
                <Typography variant="h4" mt={3}>
                    Оферта и Политика обработки персональных данных:
                </Typography>

                <Box sx={{ overflow: 'auto', mt: 4 }}>
                    <Grid container>
                        <Grid item xs={isMobile ? 12 : 6}>
                            {documents.map((row, index) => (
                                <Box key={index}>
                                    <Grid container>
                                        {row.map((size, key) => (
                                            <TableRow key={key} text={size} />
                                        ))}
                                    </Grid>
                                </Box>
                            ))}
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 4} />
                    </Grid>
                </Box>

                <LegalInfoBlock />
            </Container>
        </Container>
    )
}
