import { selectCart } from 'app/modules/Cart/slice/selectors'
import { selectLineItemById } from 'app/modules/LineItems/slice/selectors'
import { useSelector } from 'react-redux'

export const useOnlySales = () => {
    const getLineItem = useSelector(selectLineItemById)

    const cart = useSelector(selectCart)

    const onlySales = cart?.relationships.line_items.data.every(
        (value) => (getLineItem(value.id)?.attributes.sale_percent || 0) > 0
    )

    return onlySales || false
}
