import { Grid, Typography } from '@mui/material'
import React, { FC, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router'

import { INavigationTab } from './NavigationTab.types'

const NavigationTab: FC<INavigationTab> = ({
    icon,
    isActive,
    url,
    children,
    onClick,
}) => {
    const history = useHistory()

    const textColor = useMemo<string>(
        () => (isActive ? '#54A67F' : '#C1C1C1'),
        [isActive]
    )

    const onClickHandler = useCallback(() => {
        if (url && url.length) {
            history.push(url)
        }

        if (onClick) {
            onClick()
        }
    }, [])

    return (
        <Grid
            onClick={onClickHandler}
            item
            xs
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            {icon}
            <Typography
                sx={{
                    mt: 0.25,
                    fontSize: '10px',
                    lineHeight: '12px',
                    color: textColor,
                }}
            >
                {children}
            </Typography>
        </Grid>
    )
}

export default NavigationTab
