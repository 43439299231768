import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'types'

import { paymentsAdapter } from '.'

const { selectById } = paymentsAdapter.getSelectors()

const selectDomain = (state: RootState) => state.payments

export const selectPaymentById = createSelector(
    [selectDomain],
    (state) => (id?: string) => id ? selectById(state, id) : undefined
)
