import { PayloadAction } from '@reduxjs/toolkit'
import { checkoutActions } from 'app/modules/Checkout/slice'
import {
    setCustomerInformation,
    setShipmentsAttributes,
} from 'app/modules/Checkout/slice/dataSetters'
import { LoyaltyPromoType } from 'app/modules/Checkout/slice/types'
import { imagesActions } from 'app/modules/Images/slice'
import { listItemsActions } from 'app/modules/LineItems/slice'
import { productsActions } from 'app/modules/Products/slice'
import { variantsActions } from 'app/modules/Variants/slice'
import { toast } from 'react-toastify'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { ICartItemResponse } from 'types/ICart'
import {
    ILineItemAdd1CProps,
    ILineItemAddProps,
    ILineItemUpdateProps,
} from 'types/ILineItem'
import { IShop } from 'types/IShop'
import { request } from 'utils/request'

import { cartActions } from '.'
import { selectCartToken } from './selectors'

function* loadLoyaltyData(response: ICartItemResponse) {
    if (response.data.relationships?.promotions?.data[0]?.id === '13') {
        yield put(
            checkoutActions.setBonuses(response.data.attributes.promo_total)
        )
        yield put(checkoutActions.setWhichPromoUsed(LoyaltyPromoType.bonuses))
    } else if (response.data.relationships?.promotions?.data[0]?.id) {
        yield put(
            checkoutActions.setPromocode(response.data.attributes.promo_total)
        )
        yield put(checkoutActions.setWhichPromoUsed(LoyaltyPromoType.promocode))
    } else {
        yield put(checkoutActions.setWhichPromoUsed(LoyaltyPromoType.none))
    }
}
const CART_INCLUDE = [
    'line_items',
    'variants.product',
    'variants.images',
    'billing_address',
    'shipping_address',
    'user',
    'payments',
    'shipments.shipping_rates',
    'promotions',
] as const

export function* emptyCart() {
    try {
        const token: string = yield select(selectCartToken)
        const requestURL = `/api/v2/storefront/cart/empty`

        const response: ICartItemResponse = yield call(request, requestURL, {
            method: 'PATCH',
            params: {
                include: CART_INCLUDE.join(','),
            },
            headers: {
                'X-Spree-Order-Token': token,
            },
        })

        yield setCustomerInformation(response)
        yield put(
            checkoutActions.setAvailableBonuses(
                response.data.attributes.available_bonuses
            )
        )
        yield put(
            checkoutActions.setMindBoxData(
                response.data.attributes.mindbox_data
            )
        )
        yield put(productsActions.productsAddedFromInclude(response))
        yield put(variantsActions.variantsLoaded(response))
        yield put(imagesActions.imagesAdded(response))
        yield put(listItemsActions.listItemsLoaded(response))
        yield put(cartActions.cartLoaded(response))

        yield loadLoyaltyData(response)
    } catch (error: any) {
        yield put(cartActions.statusError())
    }
}

export function* createCart() {
    try {
        const requestURL = `/api/v2/storefront/cart`

        const response: ICartItemResponse = yield call(request, requestURL, {
            method: 'POST',
        })

        yield put(cartActions.cartCreated(response))
    } catch (error: any) {
        yield put(cartActions.statusError())
    }
}

export function* getLastCart() {
    try {
        const token: string = yield select(selectCartToken)
        const requestURL = `/api/v2/storefront/cart/last`

        const response: ICartItemResponse = yield call(request, requestURL, {
            params: {
                include: CART_INCLUDE.join(','),
            },
            headers: {
                'X-Spree-Order-Token': token,
            },
        })

        yield setCustomerInformation(response)
        yield put(productsActions.productsAddedFromInclude(response))
        yield put(variantsActions.variantsLoaded(response))
        yield put(imagesActions.imagesAdded(response))
        yield put(listItemsActions.listItemsLoaded(response))
        yield put(cartActions.cartLoaded(response))
        yield put(
            checkoutActions.setBonuses(response.data.attributes.promo_total)
        )
        yield put(cartActions.tokenUpdate(response.data.attributes.token))
    } catch (error: any) {
        if (error.status === 404) {
            yield put(cartActions.createCart())
        }
        yield put(cartActions.statusError())
    }
}

export function* getCart() {
    try {
        const token: string = yield select(selectCartToken)
        const requestURL = `/api/v2/storefront/cart`

        const response: ICartItemResponse = yield call(request, requestURL, {
            params: {
                include: CART_INCLUDE.join(','),
            },
            headers: {
                'X-Spree-Order-Token': token,
            },
        })

        yield put(productsActions.productsAddedFromInclude(response))
        yield setShipmentsAttributes(response)
        yield setCustomerInformation(response)
        yield put(
            checkoutActions.setAvailableBonuses(
                response.data.attributes.available_bonuses
            )
        )
        yield put(variantsActions.variantsLoaded(response))
        yield put(imagesActions.imagesAdded(response))
        yield put(listItemsActions.listItemsLoaded(response))
        yield put(cartActions.cartLoaded(response))

        yield loadLoyaltyData(response)
    } catch (error: any) {
        if (error.status === 404) {
            yield put(cartActions.createCart())
        }
        yield put(cartActions.statusError())
    }
}

export function* getCartShop() {
    try {
        const token: string = yield select(selectCartToken)
        const requestURL = `/api/v2/storefront/cart`

        const response: ICartItemResponse = yield call(request, requestURL, {
            params: {
                include: ['main_shop.image'].join(','),
            },
            headers: {
                'X-Spree-Order-Token': token,
            },
        })

        const shops = response.included
            .filter((item) => item.type === 'shop')
            .map((item) => item as IShop)

        yield put(imagesActions.imagesAdded(response))
        yield put(cartActions.setCartShop(shops[0] || null))

        yield put(
            cartActions.setEarnedBonuses({
                earned_bonuses:
                    response.data.attributes.loyalty_data.earned_bonuses,
            })
        )
    } catch (error: any) {
        if (error.status === 404) {
            yield put(cartActions.createCart())
        }
        yield put(cartActions.statusError())
    }
}

export function* cartAssociate() {
    try {
        const token: string = yield select(selectCartToken)

        const response: ICartItemResponse = yield call(
            request,
            '/api/v2/storefront/cart/associate',
            {
                method: 'PATCH',
                params: {
                    guest_order_token: token,
                    include: [
                        'billing_address',
                        'shipping_address',
                        'user',
                    ].join(','),
                },
                headers: {
                    'X-Spree-Order-Token': token,
                    'Content-Type': 'application/vnd.api+json',
                },
            }
        )

        yield setCustomerInformation(response)
        yield put(cartActions.cartLoaded(response))
    } catch (error: any) {
        yield put(cartActions.statusError())
    }
}

export function* addItem(action: PayloadAction<ILineItemAddProps>) {
    try {
        const token: string = yield select(selectCartToken)
        const requestURL = `/api/v2/storefront/cart/add_item?include=line_items,variants,variants.images`

        const response: ICartItemResponse = yield call(request, requestURL, {
            method: 'POST',
            params: {
                include: CART_INCLUDE.join(','),
            },
            data: action.payload,
            headers: {
                'X-Spree-Order-Token': token,
                'Content-Type': 'application/vnd.api+json',
            },
        })

        yield put(
            checkoutActions.setAvailableBonuses(
                response.data.attributes.available_bonuses
            )
        )
        yield put(
            checkoutActions.setMindBoxData(
                response.data.attributes.mindbox_data
            )
        )
        yield put(variantsActions.variantsLoaded(response))
        yield put(imagesActions.imagesAdded(response))
        yield put(listItemsActions.listItemsLoaded(response))
        yield put(cartActions.cartLoaded(response))
        yield put(cartActions.itemAdded())
    } catch (error: any) {
        yield put(cartActions.addItemStatusError())
        toast.error(
            error.data.error_description ||
                error.data.error ||
                'Что-то пошло не так',
            {
                type: 'error',
            }
        )
    }
}

export function* addItem1c(action: PayloadAction<ILineItemAdd1CProps>) {
    try {
        const token: string = yield select(selectCartToken)
        const requestURL = `/api/v2/storefront/cart/add_item?include=line_items,variants,variants.images`

        const response: ICartItemResponse = yield call(request, requestURL, {
            method: 'POST',
            params: {
                include: CART_INCLUDE.join(','),
            },
            data: action.payload,
            headers: {
                'X-Spree-Order-Token': token,
                'Content-Type': 'application/vnd.api+json',
            },
        })

        yield put(
            checkoutActions.setAvailableBonuses(
                response.data.attributes.available_bonuses
            )
        )
        yield put(
            checkoutActions.setMindBoxData(
                response.data.attributes.mindbox_data
            )
        )
        yield put(variantsActions.variantsLoaded(response))
        yield put(imagesActions.imagesAdded(response))
        yield put(listItemsActions.listItemsLoaded(response))
        yield put(cartActions.cartLoaded(response))
        yield put(cartActions.itemAdded())
    } catch (error: any) {
        yield put(cartActions.addItemStatusError())
        toast.error(
            error.data.error_description ||
                error.data.error ||
                'Что-то пошло не так',
            {
                type: 'error',
            }
        )
    }
}

export function* updateItem(action: PayloadAction<ILineItemUpdateProps>) {
    try {
        const token: string = yield select(selectCartToken)
        const requestURL = `/api/v2/storefront/cart/set_quantity?include=line_items,variants,variants.images`

        const response: ICartItemResponse = yield call(request, requestURL, {
            method: 'PATCH',
            params: {
                include: CART_INCLUDE.join(','),
            },
            data: action.payload,
            headers: {
                'X-Spree-Order-Token': token,
                'Content-Type': 'application/vnd.api+json',
            },
        })

        yield put(
            checkoutActions.setAvailableBonuses(
                response.data.attributes.available_bonuses
            )
        )
        yield put(
            checkoutActions.setMindBoxData(
                response.data.attributes.mindbox_data
            )
        )
        yield put(variantsActions.variantsLoaded(response))
        yield put(imagesActions.imagesAdded(response))
        yield put(listItemsActions.listItemsLoaded(response))
        yield put(cartActions.cartLoaded(response))
    } catch (error: any) {
        yield put(cartActions.statusError())

        const errorText: string =
            error.data.error_description ||
            error.data.error ||
            'Что-то пошло не так'

        const prettyErrorText = errorText.replace(`\\"`, `"`).replace('\\', '')

        toast.error(prettyErrorText, {
            type: 'error',
        })
    }
}

export function* deleteItem(action: PayloadAction<string>) {
    try {
        const token: string = yield select(selectCartToken)
        const requestURL = `/api/v2/storefront/cart/remove_line_item/${action.payload}?include=line_items,variants,variants.images`

        const response: ICartItemResponse = yield call(request, requestURL, {
            method: 'DELETE',
            params: {
                include: CART_INCLUDE.join(','),
            },
            headers: {
                'X-Spree-Order-Token': token,
                'Content-Type': 'application/vnd.api+json',
            },
        })

        yield put(
            checkoutActions.setAvailableBonuses(
                response.data.attributes.available_bonuses
            )
        )
        yield put(
            checkoutActions.setMindBoxData(
                response.data.attributes.mindbox_data
            )
        )
        yield put(variantsActions.variantsLoaded(response))
        yield put(imagesActions.imagesAdded(response))
        yield put(listItemsActions.listItemsLoaded(response))
        yield put(cartActions.cartLoaded(response))
    } catch (error: any) {
        yield put(cartActions.statusError())
    }
}

export function* cartWatcher() {
    yield takeLatest(cartActions.emptyCart.type, emptyCart)
    yield takeLatest(cartActions.loadCart.type, getCart)
    yield takeLatest(cartActions.getLastCart.type, getLastCart)
    yield takeLatest(cartActions.getCartShop.type, getCartShop)
    yield takeLatest(cartActions.createCart.type, createCart)
    yield takeLatest(cartActions.addItem.type, addItem)
    yield takeLatest(cartActions.addItem1C.type, addItem1c)
    yield takeLatest(cartActions.updateItem.type, updateItem)
    yield takeLatest(cartActions.deleteItem.type, deleteItem)
    yield takeLatest(cartActions.cartAssociate.type, cartAssociate)
}
