import {
    Box,
    Button,
    CardMedia,
    Container,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import React, { useState } from 'react'

import franchiseVideo from './assets/franchiseVideo.mp4'
import image_11 from './assets/image_11.jpg'
import ISGrow_desktop from './assets/ISGrow_desktop.png'
import ISGrow_mobile from './assets/ISGrow_mobile.png'
import ISinNumbers_desktop from './assets/ISinNumbers_desktop.png'
import ISinNumbers_mobile from './assets/ISinNumbers_mobile.png'
import ISleftPalm1_desktop from './assets/ISleftPalm1_desktop.png'
import ISleftPalm1_mobile from './assets/ISleftPalm1_mobile.png'
import ISleftPalm2_desktop from './assets/ISleftPalm2_desktop.png'
import ISmoreThan_desktop from './assets/ISmoreThan_desktop.png'
import ISmoreThan_mobile from './assets/ISmoreThan_mobile.png'
import ISnewModel_desktop from './assets/ISnewModel_desktop.png'
import ISnewModel_mobile from './assets/ISnewModel_mobile.png'
import ISnewPopup_desktop from './assets/ISnewPopup_desktop.png'
import ISnewPopup_mobile from './assets/ISnewPopup_mobile.png'
import ISourPlans_desktop from './assets/ISourPlans_desktop.png'
import ISourPlans_mobile from './assets/ISourPlans_mobile.png'
import ISpriority_desktop from './assets/ISpriority_desktop.png'
import ISpriority_mobile from './assets/ISpriority_mobile.png'
import ISrightPalm1_desktop from './assets/ISrightPalm1_desktop.png'
import ISrightPalm1_mobile from './assets/ISrightPalm1_mobile.png'
import ISrightPalm2_desktop from './assets/ISrightPalm2_desktop.png'
import placeholder from './assets/placeholder.jpg'
import { FranchiseBanner } from './components/FranchiseBanner'
import { FranchiseItemsOnPhoto } from './components/FranchiseItemsOnPhoto'
import { FranchiseTabs } from './components/FranchiseTabs'
import { FranchiseRequestModal } from './FranchiseRequestModal'

export const FranchisePage: React.FC = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

    const [videoIsPlaying, setvideoIsPlaying] = useState(false)
    const videoCardMedia = React.useRef<HTMLVideoElement>(null)

    const [showFranchiseRequestModal, setShowFranchiseRequestModal] =
        useState(false)
    const handleCloseFranchiseRequestModal = () => {
        setShowFranchiseRequestModal(false)
    }
    const handleOpenFranchiseRequestModal = () => {
        setShowFranchiseRequestModal(true)
    }

    const handlePlaceholderClick = () => {
        if (videoCardMedia.current) {
            videoCardMedia.current.play()
            setvideoIsPlaying(true)
        }
    }

    return (
        <>
            <FranchiseBanner onButtonClick={handleOpenFranchiseRequestModal} />
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    px: { xs: '0px' },
                }}
            >
                <Box
                    position="relative"
                    sx={{ mt: { xs: '40px', md: '70px' } }}
                >
                    <Typography
                        variant={isMobile ? 'h5' : 'h4'}
                        fontWeight={500}
                        sx={(theme) => ({
                            textTransform: 'uppercase',
                            textAlign: 'center',
                            color: theme.palette.primary.main,
                        })}
                    >
                        ISLAND SOUL В ЦИФРАХ
                    </Typography>
                    <Box
                        component={'img'}
                        sx={{
                            mt: { xs: '40px', md: '70px' },
                            width: { xs: '100vw', md: '100%' },
                            px: { md: '45px' },
                        }}
                        src={
                            isMobile ? ISinNumbers_mobile : ISinNumbers_desktop
                        }
                    />
                    {isDesktop && (
                        <Box position="absolute" sx={{ top: 0, width: '100%' }}>
                            <Box position="relative">
                                <Box
                                    component={'img'}
                                    sx={{
                                        position: 'absolute',
                                        left: 0,
                                        top: '-13.3vw',
                                        width: '43.4375vw',
                                        zIndex: -1,
                                    }}
                                    src={ISleftPalm1_desktop}
                                />
                                <Box
                                    component={'img'}
                                    sx={{
                                        position: 'absolute',
                                        right: 0,
                                        top: '-14.6vw',
                                        width: '40vw',
                                        zIndex: -1,
                                    }}
                                    src={ISrightPalm1_desktop}
                                />
                            </Box>
                        </Box>
                    )}
                </Box>
                <Box sx={{ mt: { xs: '40px', md: '70px' } }}>
                    <Typography
                        variant={isMobile ? 'h5' : 'h4'}
                        fontWeight={500}
                        sx={(theme) => ({
                            textTransform: 'uppercase',
                            textAlign: 'center',
                            color: theme.palette.primary.main,
                        })}
                    >
                        ISLAND SOUL РАСТЕТ С КАЖДЫМ ГОДОМ
                    </Typography>
                    <Box
                        component={'img'}
                        sx={{
                            mt: { xs: '40px', md: '70px' },
                            width: { xs: '100vw', md: '100%' },
                            px: { xs: '16px', md: '45px' },
                        }}
                        src={isMobile ? ISGrow_mobile : ISGrow_desktop}
                    />
                </Box>
                {/*<Box sx={{ mt: { xs: '40px', md: '70px' } }}>
                    <Typography
                        variant={isMobile ? 'h5' : 'h4'}
                        fontWeight={500}
                        sx={(theme) => ({
                            textTransform: 'uppercase',
                            textAlign: 'center',
                            color: theme.palette.primary.main,
                        })}
                    >
                        ISLAND SOUL. НОВАЯ МОДЕЛЬ
                    </Typography>
                    <Box
                        component={'img'}
                        sx={{
                            mt: { xs: '40px', md: '70px' },
                            width: { xs: '100vw', md: '100%' },
                            px: { xs: '16px', md: '45px' },
                        }}
                        src={isMobile ? ISnewModel_mobile : ISnewModel_desktop}
                    />
                </Box>*/}
                <Box
                    sx={{
                        mt: { xs: '43px', md: '111px' },
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: { xs: '100vw', md: '100%' },
                    }}
                >
                    <Typography
                        variant={isMobile ? 'h5' : 'h4'}
                        fontWeight={500}
                        sx={{
                            textTransform: 'uppercase',
                            textAlign: 'center',
                            color: theme.palette.primary.main,
                        }}
                    >
                        форматы магазинов island soul
                    </Typography>
                    {/*<Typography
                        variant={isMobile ? 'h6' : 'h5'}
                        fontWeight={500}
                        sx={{
                            textAlign: 'center',
                            fontSize: { xs: '18px', md: '32px' },
                            maxWidth: { xs: '300px', md: '900px' },
                        }}
                    >
                        Мы предлагаем открытие и управление магазинами
                        в&nbsp;совладении и партнерстве с инвестором
                    </Typography>*/}

                    <Box sx={{ mt: { xs: '43px', md: '65px' } }}>
                        <FranchiseTabs></FranchiseTabs>
                    </Box>
                </Box>
                <Box sx={{ mt: { xs: '50px', md: '127px' } }}>
                    <Typography
                        variant={isMobile ? 'h5' : 'h4'}
                        fontWeight={500}
                        sx={(theme) => ({
                            textTransform: 'uppercase',
                            textAlign: 'center',
                            color: theme.palette.primary.main,
                        })}
                    >
                        ISLAND SOUL. НОВИНКА
                    </Typography>
                    <Box
                        component={'img'}
                        sx={{
                            mt: { xs: '17px', md: '27px' },
                            width: { xs: '100vw', md: '100%' },
                            px: { xs: '16px', md: '45px' },
                        }}
                        src={isMobile ? ISnewPopup_mobile : ISnewPopup_desktop}
                    />
                </Box>
            </Container>

            <Box sx={{ mt: { xs: '50px', md: '100px' } }}>
                <FranchiseItemsOnPhoto
                    onButtonClick={handleOpenFranchiseRequestModal}
                />
            </Box>

            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    px: { xs: '0px' },
                }}
            >
                <Box sx={{ mt: { xs: '50px', md: '100px' } }}>
                    <Typography
                        variant={isMobile ? 'h5' : 'h4'}
                        fontWeight={500}
                        sx={(theme) => ({
                            textTransform: 'uppercase',
                            textAlign: 'center',
                            color: theme.palette.primary.main,
                        })}
                    >
                        ПРИОРИТЕТНЫЕ НАПРАВЛЕНИЯ ОТКРЫТИЙ
                    </Typography>
                    <Box
                        component={'img'}
                        sx={{
                            mt: { xs: '17px', md: '27px' },
                            width: { xs: '100vw', md: '100%' },
                            px: { xs: '16px', md: '45px' },
                        }}
                        src={isMobile ? ISpriority_mobile : ISpriority_desktop}
                    />
                </Box>
                <Box
                    sx={{
                        mt: { xs: '70px', md: '115px' },
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        variant={isMobile ? 'h5' : 'h4'}
                        fontWeight={500}
                        sx={(theme) => ({
                            textTransform: 'uppercase',
                            textAlign: 'center',
                            color: theme.palette.primary.main,
                            width: { xs: '200px', md: 'auto' },
                        })}
                    >
                        НАШИ ПЛАНЫ на 2023 год
                    </Typography>
                    <Box
                        component={'img'}
                        sx={{
                            mt: { xs: '17px', md: '27px' },
                            width: { xs: '100vw', md: '100%' },
                            px: { xs: '16px', md: '45px' },
                        }}
                        src={isMobile ? ISourPlans_mobile : ISourPlans_desktop}
                    />
                    {isDesktop && (
                        <Box position="absolute" sx={{ top: 0, width: '100%' }}>
                            <Box position="relative">
                                <Box
                                    component={'img'}
                                    sx={{
                                        position: 'absolute',
                                        left: 0,
                                        top: '-37vw',
                                        width: '51.7vw',
                                        zIndex: -1,
                                    }}
                                    src={ISleftPalm2_desktop}
                                />
                                <Box
                                    component={'img'}
                                    sx={{
                                        position: 'absolute',
                                        right: 0,
                                        top: '-18vw',
                                        width: '43.38vw',
                                        zIndex: -1,
                                    }}
                                    src={ISrightPalm2_desktop}
                                />
                            </Box>
                        </Box>
                    )}
                    {isMobile && (
                        <Box position="absolute" sx={{ top: 0, width: '100%' }}>
                            <Box position="relative">
                                <Box
                                    component={'img'}
                                    sx={{
                                        position: 'absolute',
                                        left: 0,
                                        top: '-61vw',
                                        width: '71.7vw',
                                        zIndex: -1,
                                    }}
                                    src={ISleftPalm1_mobile}
                                />
                                <Box
                                    component={'img'}
                                    sx={{
                                        position: 'absolute',
                                        right: 0,
                                        top: '-25.3vw',
                                        width: '55.2vw',
                                        zIndex: -1,
                                    }}
                                    src={ISrightPalm1_mobile}
                                />
                            </Box>
                        </Box>
                    )}
                </Box>
                <Container
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        variant={isMobile ? 'h5' : 'h4'}
                        fontWeight={500}
                        sx={{
                            textTransform: 'uppercase',
                            textAlign: 'center',
                            mt: 5,
                            maxWidth: '800px',
                        }}
                    >
                        <Typography
                            variant={isMobile ? 'h5' : 'h4'}
                            fontWeight={500}
                            sx={{
                                textTransform: 'uppercase',
                                textAlign: 'center',
                                display: 'inline',
                                color: '#C59314',
                            }}
                        >
                            миссия island soul
                        </Typography>
                        <Typography
                            variant={isMobile ? 'h5' : 'h4'}
                            fontWeight={500}
                            sx={{
                                textTransform: 'uppercase',
                                textAlign: 'center',
                                display: 'inline',
                                color: (theme) => theme.palette.grey[400],
                            }}
                        >
                            {` — мотивировать девушек любить себя, инвестировать в себя
                        время, деньги и повышать качество жизни. `}
                        </Typography>
                        <Typography
                            variant={isMobile ? 'h5' : 'h4'}
                            fontWeight={500}
                            sx={{
                                textTransform: 'uppercase',
                                textAlign: 'center',
                                display: 'inline',
                                color: (theme) => theme.palette.primary.main,
                            }}
                        >
                            Вы с нами?
                        </Typography>
                    </Typography>
                    <Button
                        variant="contained"
                        sx={{
                            borderRadius: '15px',
                            height: '60px',
                            maxWidth: '400px',
                            width: '100%',
                            mt: 4,
                        }}
                        onClick={handleOpenFranchiseRequestModal}
                    >
                        получить консультацию
                    </Button>
                </Container>

                <Box sx={{ mt: { xs: '40px', md: '98px' } }}>
                    <Box
                        component={'img'}
                        sx={{
                            width: { xs: '100vw', md: '100%' },
                            display: 'block',
                        }}
                        src={isMobile ? ISmoreThan_mobile : ISmoreThan_desktop}
                    />
                </Box>
            </Container>

            <Box sx={{ cursor: 'pointer' }}>
                <Box
                    component={'img'}
                    width="100%"
                    height={'56vw'}
                    src={placeholder}
                    sx={{
                        position: 'absolute',
                        objectFit: 'cover',
                        display: videoIsPlaying ? 'none' : 'auto',
                        zIndex: 1,
                        cursor: 'pointer',
                    }}
                    onClick={handlePlaceholderClick}
                />
                <CardMedia
                    sx={{
                        objectFit: 'cover',
                    }}
                    component="video"
                    height="100%"
                    playsInline={true}
                    controls
                    placeholder={image_11}
                    src={franchiseVideo}
                    ref={videoCardMedia}
                />
            </Box>
            <FranchiseRequestModal
                open={showFranchiseRequestModal}
                handleClose={handleCloseFranchiseRequestModal}
            />
        </>
    )
}
