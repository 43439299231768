import { ECountryCode, ShopKind, TOrderState } from 'types'

export const PROJECT_NAME = process.env.REACT_APP_NAME

export const MAIN_PAGE_TITLE =
    'Ювелирные украшения Island Soul. Официальный интернет магазин ювелирной сети'

export const COUNTRY_CODE_PRICE: { [key in ECountryCode]: string } = {
    ru: '₽',
    kz: '₸',
    uz: 'сўм',
    by: 'Br',
}

export const SHOP_KIND_NAMES: { [key in ShopKind]: string } = {
    island: 'Остров',
    boutique: 'бутик',
    main_boutique: 'Флагманский бутик',
    showroom: 'Шоурум',
}

export const ORDER_STATE: { [key in keyof typeof TOrderState]: number } = {
    cart: 0,
    address: 1,
    delivery: 2,
    payment: 3,
    confirm: 4,
    complete: 5,
}

export const DISCOUNT_LEVEL: { [key in number]: string } = {
    0: '',
    5: 'Silver',
    7: 'Gold',
    10: 'Platinum',
    12: 'Diamond',
}

export const NOVELTY_PRODUCTS_COUNTS = 6

export const GiftTooltipText = `Вы можете намекнуть своему близкому человеку о подарке, который вы так давно ждёте. Нажмите сюда, чтобы заполнить информацию о получателе, которому мы отправим электронное письмо с описанием и ссылкой на данный товар.`

export const OutOfStockTooltipText =
    'Один или несколько товаров в корзине отсутствуют в наличии'
