import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'types'
import { ICart } from 'types/ICart'

import { promotionsAdapter } from '.'

const { selectAll, selectById } = promotionsAdapter.getSelectors()

const selectDomain = (state: RootState) => state.promotions

export const selectPromotionss = createSelector([selectDomain], (state) =>
    selectAll(state)
)

export const selectPromotionByCart = createSelector(
    [selectDomain],
    (state) => (cart?: ICart) => {
        return selectById(
            state,
            cart?.relationships.promotions.data[0]?.id ?? ''
        )
    }
)
