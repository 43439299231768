import {
    Box,
    Card,
    CardContent,
    CardMedia,
    Skeleton,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { ICategory } from 'types/ICategory'

interface DesktopCategoryCardProps {
    category: ICategory
    image: string
}

export const DesktopCategoryCard: React.FC<DesktopCategoryCardProps> = ({
    category,
    image,
}) => {
    const theme = useTheme()
    const isTablet = useMediaQuery(theme.breakpoints.between('md', 'lg'))

    return (
        <Card
            elevation={0}
            to={`/${category.attributes.permalink}`}
            component={Link}
        >
            <Box
                sx={{
                    height: {
                        lg: 340,
                        xl: 400,
                    },
                    width: {
                        md: '15vw',
                    },
                    overflow: 'hidden',
                }}
            >
                <Box
                    sx={(theme) => ({
                        height: {
                            md: 280,
                            lg: 340,
                            xl: 400,
                        },
                        transition: theme.transitions.create(['transform'], {
                            duration: theme.transitions.duration.standard,
                        }),
                        '&:hover': { transform: 'scale(1.05)' },
                    })}
                >
                    <CardMedia
                        sx={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            overflow: 'hidden',
                            zIndex: -1,
                        }}
                        src={image}
                        component="img"
                    />
                    {!image && (
                        <Skeleton
                            width="100%"
                            height="100%"
                            variant="rectangular"
                        />
                    )}
                </Box>
            </Box>
            <CardContent sx={{ pt: 3, pb: 3, pl: 2, pr: 2 }}>
                <Typography
                    sx={{ textTransform: 'uppercase' }}
                    variant={isTablet ? 'body3' : 'body1'}
                    textAlign="center"
                    fontWeight="medium"
                >
                    {category.attributes.name}
                </Typography>
            </CardContent>
        </Card>
    )
}
