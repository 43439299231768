import { Box, SxProps, Theme } from '@mui/material'
import React from 'react'

export const MindboxTarget: React.FC<{
    popmechanicId: string
    sx?: SxProps<Theme>
}> = ({ popmechanicId, sx }) => {
    return (
        <Box sx={sx}>
            <div data-popmechanic-embed={popmechanicId}></div>
        </Box>
    )
}
