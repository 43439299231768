import { Box } from '@mui/material'
import React from 'react'

interface BaseImageBannerProps {
    image?: string
    height: number | string
}

export const BaseImageBanner: React.FC<BaseImageBannerProps> = ({
    image,
    height,
    children,
}) => {
    return (
        <Box
            sx={{
                height,
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'center',
                width: '100%',
                position: 'relative',
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    objectFit: 'cover',
                    bgcolor: 'grey.500',
                }}
                src={image}
                component="img"
            />

            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    background:
                        'linear-gradient(179.45deg, rgba(0, 0, 0, 0.46) 0.47%, rgba(0, 0, 0, 0) 99.53%)',
                }}
            />

            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    position: 'relative',
                    pb: 15,
                }}
            >
                {children}
            </Box>
        </Box>
    )
}
