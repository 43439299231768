import { TextField, TextFieldProps } from '@mui/material'
import { toString } from 'lodash'
import React, { memo } from 'react'
import { default as InputMask } from 'react-input-mask'

import { getListOfMasks } from './utils/get-list-of-masks'
import { getMatchedMask } from './utils/get-matched-mask'

export const PhoneField: React.FC<TextFieldProps> = memo(
    ({ name, value, label, error, onChange }) => {
        const masks = getListOfMasks()
        const matchedMask = getMatchedMask(toString(value), masks)

        return (
            <InputMask
                mask={matchedMask || '+99999999999999999999'}
                maskPlaceholder=""
                name={name}
                value={toString(value)}
                onChange={onChange}
            >
                <TextField
                    fullWidth
                    variant="standard"
                    label={label}
                    error={error}
                />
            </InputMask>
        )
    }
)
