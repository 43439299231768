import { Box, FormGroup } from '@mui/material'
import { selectProductsOptionTypes } from 'app/modules/Products/slice/selectors'
import type {
    IProductOptionType,
    IProductOptionValue,
} from 'app/modules/Products/slice/types'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { useCategoryPageContext } from '../../hooks'
import { ProductsFilterKind } from '../../ProductsFilterKind'
import { ProductsFilterFormControlLabel } from './ProductsFilterFormControlLabel'
import { ProductsFilterShowMoreFiltersButton } from './ProductsFilterShowMoreFiltersButton'

const ProductsFilterOption: React.FC<{ optionType: IProductOptionType }> = ({
    optionType,
}) => {
    const [collapsed, setCollapsed] = useState(false)

    const { filterReadAdapter, handleChangeProductOption } =
        useCategoryPageContext()

    useEffect(() => {
        setCollapsed(optionType.option_values.length > 5)
    }, [])

    const checked = (option_value: IProductOptionValue) =>
        Boolean(
            filterReadAdapter.options?.[optionType.presentation]?.find(
                (value) => value === option_value.name
            )
        )

    const optionsValues = collapsed
        ? optionType.option_values.slice(0, 5)
        : optionType.option_values

    return (
        <ProductsFilterKind name={optionType.name}>
            <FormGroup>
                {optionsValues.map((option_value) => (
                    <Box
                        key={option_value.id}
                        sx={{
                            mb: { xs: '12px', lg: '2px' },
                            height: { xs: '24px', md: '28px' },
                        }}
                    >
                        <ProductsFilterFormControlLabel
                            labelText={option_value.name}
                            checked={checked(option_value)}
                            onChange={() =>
                                handleChangeProductOption(
                                    optionType,
                                    option_value,
                                    !checked(option_value)
                                )
                            }
                        />
                    </Box>
                ))}

                <ProductsFilterShowMoreFiltersButton
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}
                />
            </FormGroup>
        </ProductsFilterKind>
    )
}

export const ProductsFilterOptions: React.FC = () => {
    const productsOptionTypes = useSelector(selectProductsOptionTypes)

    return (
        <>
            {productsOptionTypes.map((optionType, index) => {
                return (
                    <React.Fragment key={index}>
                        <ProductsFilterOption optionType={optionType} />
                    </React.Fragment>
                )
            })}
        </>
    )
}
