import {
    ExpandLess as ExpandLessIcon,
    ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material'
import { Box, Button, Collapse, Typography } from '@mui/material'
import { LoyaltyTable } from 'app/components/LoyaltyTable/LoyaltyTable'
import React, { useState } from 'react'

const LoyaltyFAQItem: React.FC<{
    title: string
    lastItem?: true
}> = ({ title, children, lastItem }) => {
    const [open, setOpen] = useState<boolean>(false)

    const handleClick = () => {
        setOpen(!open)
    }
    return (
        <Box
            py={'32px'}
            sx={{
                borderBottom: lastItem ? 'none' : '1px solid #DEDEDE',
                px: { xs: '24px', md: '40px' },
                cursor: 'pointer',
            }}
            onClick={handleClick}
        >
            <Box display={'flex'} justifyContent={'space-between'}>
                <Typography
                    variant="subtitle2"
                    sx={{
                        textTransform: 'uppercase',
                        fontSize: { xs: '14px', md: '18px' },
                        fontWeight: 'medium',
                    }}
                >
                    {title}
                </Typography>
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Box>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Typography mt={3}>{children}</Typography>
            </Collapse>
        </Box>
    )
}

export const LoyaltyFAQ: React.FC = () => {
    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            <Typography
                variant="h1"
                fontWeight="medium"
                textTransform="uppercase"
                sx={{ fontSize: { xs: '24px', md: '42px' } }}
            >
                популярные вопросы
            </Typography>

            <Box
                sx={{
                    mt: { xs: '32px', md: '50px' },
                    borderRadius: '28px',
                    border: '1px solid #DEDEDE',
                    width: '100%',
                    maxWidth: { md: '80%' },
                }}
            >
                <LoyaltyFAQItem
                    title={'Что такое Клуб Привилегий Island Soul Way?'}
                >
                    «Island Soul Way» — бонусная программа лояльности, участники
                    которой могут накапливать бонусные баллы, а также частично
                    оплачивать ими покупки. Каждый участник, в зависимости от
                    ступени, получает доступ к специальным предложениям.
                </LoyaltyFAQItem>
                <LoyaltyFAQItem
                    title={'Где и как зарегистрироваться в Клубе Привилегий?'}
                >
                    Стать участником программы лояльности можно тремя способами:
                    <br />
                    <br />
                    1) регистрация через телеграмм-бота;
                    <br />
                    <br />
                    2) регистрация через проводников в магазинах, участвующих в
                    программе лояльности (полный список смотрите в ответах
                    ниже);
                    <br />
                    <br />
                    3) регистрация в личном кабинете на сайте
                    islandsouljewelry.com.
                    <br />
                    <Button
                        variant="contained"
                        size="large"
                        sx={{
                            mt: { xs: '25px' },
                            px: { xs: '20px', lg: '50px' },
                            fontSize: { xs: '10px', lg: '22px' },
                        }}
                        href="https://t.me/ISJcard_bot"
                        target="blank"
                    >
                        Зарегистрироваться через Telegram-бот
                    </Button>
                </LoyaltyFAQItem>
                <LoyaltyFAQItem
                    title={
                        'Какие существуют ступени участника Клуба Привилегий Island Soul Way?'
                    }
                >
                    <LoyaltyTable />
                </LoyaltyFAQItem>
                <LoyaltyFAQItem
                    title={'Где действует Клуб Привилегий Island Soul Way?'}
                >
                    Привилегии клуба действуют при покупках в официальном
                    интернет-магазине www.islandsouljewelry.com или наших
                    бутиках по адресам:
                    <br />
                    <br />
                    1. Москва, ул.Садовая-Черногрязская 13/3 стр.1
                    <br />
                    <br />
                    2. Москва,ул. Кировоградская, 13а, ТЦ Columbus
                    <br />
                    <br />
                    3. Москва, пр-кт Мира, 211, корп. 2, ТРК Европолис
                    <br />
                    <br />
                    4. Москва, ул.Кутузовский пр-кт, 57, ТЦ Океания
                    <br />
                    <br />
                    5. Москва, Каширское ш., 23, ТРК Vegas
                    <br />
                    <br /> 6. Санкт-Петербург,Невский проспект 114-116, ТЦ
                    Невский
                    <br />
                    <br />
                    7. Сочи, Парковая, 17
                    <br />
                    <br />
                    8. Красная Поляна, ул.Турчинского, 34
                    <br />
                    <br />
                    9. Сочи, Эстосадок, ул. Горная Карусель, 3, ТЦ Горки Молл
                    <br />
                    <br />
                    10. Адлер, ул. Кирова, 58, ТЦ City Plaza
                    <br />
                    <br />
                    11. Обнинск, проспект Ленина, 137к2
                    <br />
                    <br />
                    12. Самара, ул. Дыбенко, 30, ТЦ КОСМОПОРТ
                    <br />
                    <br />
                    13. Томск, Комсомольский проспект 13б, ТЦ Изумрудный город
                    <br />
                    <br />
                    14. Пятигорск, ул. Ессентукская, 31 А, ТЦ Вершина Плаза
                    <br />
                    <br />
                    15. Ярославль, ул. Победы, 41, ТЦ Аура
                    <br />
                    <br />
                    16. Екатеринбург, проспект Космонавтов, д. 108, ТЦ Веер Молл
                    <br />
                    <br />
                    17. Хабаровск, ул. Пионерская, 2в, ТЦ BROSKO MALL
                    <br />
                    <br />
                    18. Хабаровск, переулок Батарейный, д.1
                    <br />
                    <br />
                    19. Казань, ул. Павлюхина, 91, ТРК Kazan Mall
                    <br />
                    <br />
                    20. Казань, ул. Баумана, 82, ТЦ Свита Холл
                    <br />
                    <br />
                    21. Новосибирск, площадь им. Карла Маркса, 7, Сан Сити
                    <br />
                    <br />
                    22. Новосибирск, пр-кт Красный, 101, ТРК Ройял Парк
                    <br />
                    <br />
                    23. Челябинск, ул. Труда, 203, ТЦ Родник
                    <br />
                    <br />
                    24. Уфа, ул. Бакалинская, 27, ТЦ Ультра
                    <br />
                    <br />
                    25. Московская область, Мытищи, ул. Шараповский проезд, 2,
                    ТРК Красный Кит
                    <br />
                    <br />
                    26. г. Ростов-на-Дону, просп.им. М. Нагибина, 32/2, ТРК
                    Горизонт
                    <br />
                    <br />
                    27. г. Екатеринбург, ул. 8 Марта , д. 48, ТЦ Гринвич
                    <br />
                    <br />
                    28. г. Тюмень, ул. Герцена, 94 ТЦ Галерея Вояж
                    <br />
                    <br />
                    29. г. Тюмень, ул. Дмитрия Менделеева, 1А, ТРЦ Кристалл
                </LoyaltyFAQItem>
                <LoyaltyFAQItem title={'Как начисляются бонусные баллы?'}>
                    Бонусные баллы активируются на счету участника на 15-ый день
                    после покупки. Срок действия бонусных баллов - 100 дней с
                    момента активации.
                    <Box sx={{ mt: { lg: '20px' } }}>
                        <LoyaltyTable indexes={[3]} />
                    </Box>
                </LoyaltyFAQItem>
                <LoyaltyFAQItem
                    title={'Как получить бонусные баллы в день рождения?'}
                >
                    При регистрации в программе лояльности вам необходимо
                    указать свою дату рождения. За 7 дней до её наступления вам
                    будут начислены бонусные баллы. Срок действия бонусных
                    баллов 14 дней с момента начисления (7 дней до и 7 дней
                    после вашего дня рождения).
                    <Box sx={{ mt: { lg: '20px' } }}>
                        <LoyaltyTable indexes={[2]} />
                    </Box>
                </LoyaltyFAQItem>
                <LoyaltyFAQItem
                    title={'Какую часть суммы можно оплатить бонусами?'}
                >
                    Бонусами можно оплатить до 15% от суммы чека.
                </LoyaltyFAQItem>
                <LoyaltyFAQItem title={'Что можно оплатить бонусами?'}>
                    Бонусами можно воспользоваться при оплате любых украшений и
                    товаров категории Home, представленных в официальном
                    интернет-магазине www.islandsouljewelry.com, а также при
                    покупке в наших бутиках по адресам:
                    <br />
                    <br />
                    1. Москва, ул.Садовая-Черногрязская 13/3 стр.1
                    <br />
                    <br />
                    2. Москва,ул. Кировоградская, 13а, ТЦ Columbus
                    <br />
                    <br />
                    3. Москва, пр-кт Мира, 211, корп. 2, ТРК Европолис
                    <br />
                    <br />
                    4. Москва, ул.Кутузовский пр-кт, 57, ТЦ Океания
                    <br />
                    <br />
                    5. Москва, Каширское ш., 23, ТРК Vegas
                    <br />
                    <br /> 6. Санкт-Петербург,Невский проспект 114-116, ТЦ
                    Невский
                    <br />
                    <br />
                    7. Сочи, Парковая, 17
                    <br />
                    <br />
                    8. Красная Поляна, ул.Турчинского, 34
                    <br />
                    <br />
                    9. Сочи, Эстосадок, ул. Горная Карусель, 3, ТЦ Горки Молл
                    <br />
                    <br />
                    10. Адлер, ул. Кирова, 58, ТЦ City Plaza
                    <br />
                    <br />
                    11. Обнинск, проспект Ленина, 137к2
                    <br />
                    <br />
                    12. Самара, ул. Дыбенко, 30, ТЦ КОСМОПОРТ
                    <br />
                    <br />
                    13. Томск, Комсомольский проспект 13б, ТЦ Изумрудный город
                    <br />
                    <br />
                    14. Пятигорск, ул. Ессентукская, 31 А, ТЦ Вершина Плаза
                    <br />
                    <br />
                    15. Ярославль, ул. Победы, 41, ТЦ Аура
                    <br />
                    <br />
                    16. Екатеринбург, проспект Космонавтов, д. 108, ТЦ Веер Молл
                    <br />
                    <br />
                    17. Хабаровск, ул. Пионерская, 2в, ТЦ BROSKO MALL
                    <br />
                    <br />
                    18. Хабаровск, переулок Батарейный, д.1
                    <br />
                    <br />
                    19. Казань, ул. Павлюхина, 91, ТРК Kazan Mall
                    <br />
                    <br />
                    20. Казань, ул. Баумана, 82, ТЦ Свита Холл
                    <br />
                    <br />
                    21. Новосибирск, площадь им. Карла Маркса, 7, Сан Сити
                    <br />
                    <br />
                    22. Новосибирск, пр-кт Красный, 101, ТРК Ройял Парк
                    <br />
                    <br />
                    23. Челябинск, ул. Труда, 203, ТЦ Родник
                    <br />
                    <br />
                    24. Уфа, ул. Бакалинская, 27, ТЦ Ультра
                    <br />
                    <br />
                    25. Московская область, Мытищи, ул. Шараповский проезд, 2,
                    ТРК Красный Кит
                    <br />
                    <br />
                    26. г. Ростов-на-Дону, просп.им. М. Нагибина, 32/2, ТРК
                    Горизонт
                    <br />
                    <br />
                    27. г. Екатеринбург, ул. 8 Марта , д. 48, ТЦ Гринвич
                    <br />
                    <br />
                    28. г. Тюмень, ул. Герцена, 94 ТЦ Галерея Вояж
                    <br />
                    <br />
                    29. г. Тюмень, ул. Дмитрия Менделеева, 1А, ТРЦ Кристалл
                </LoyaltyFAQItem>
                <LoyaltyFAQItem
                    title={'Как проверить баланс бонусов?'}
                    lastItem
                >
                    Проверить баланс бонусов можно в электронном кошельке на
                    вашем мобильном устройстве, куда по номеру телефона при
                    регистрации добавляется ваша бонусная карта.
                </LoyaltyFAQItem>
            </Box>
        </Box>
    )
}
