import React from 'react'

interface GiftIconProps {
    color?: string
}

export const GiftIcon: React.FC<GiftIconProps> = ({ color = '#FFFFFF' }) => (
    <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M20.2903 12.3867V22.3867H4.29028V12.3867"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M22.2903 7.38672H2.29028V12.3867H22.2903V7.38672Z"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M12.2903 22.3867V7.38672"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M12.2903 7.38672H7.79028C7.12724 7.38672 6.49136 7.12333 6.02252 6.65449C5.55368 6.18564 5.29028 5.54976 5.29028 4.88672C5.29028 4.22368 5.55368 3.58779 6.02252 3.11895C6.49136 2.65011 7.12724 2.38672 7.79028 2.38672C11.2903 2.38672 12.2903 7.38672 12.2903 7.38672Z"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M12.2903 7.38672H16.7903C17.4533 7.38672 18.0892 7.12333 18.5581 6.65449C19.0269 6.18564 19.2903 5.54976 19.2903 4.88672C19.2903 4.22368 19.0269 3.58779 18.5581 3.11895C18.0892 2.65011 17.4533 2.38672 16.7903 2.38672C13.2903 2.38672 12.2903 7.38672 12.2903 7.38672Z"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
