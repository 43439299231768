import { colors, createTheme, Palette } from '@mui/material'
import { ArrowDownIcon } from 'app/components/Icons/ArrowDownIcon'

const defaultTheme = createTheme()

const palette: Palette = {
    ...defaultTheme.palette,
    primary: {
        light: '',
        contrastText: '#FFFFFF',
        main: '#54A67F',
        dark: '#214A37',
    },
    background: {
        paper: '#FFFFFF',
        default: '#FFFFFF',
    },
    text: {
        primary: '#000000',
        secondary: '#757575',
        disabled: '#757575',
    },
    warning: {
        ...defaultTheme.palette.warning,
        main: '#FF9345',
    },
    grey: {
        ...defaultTheme.palette.grey,
        50: '#F7F7F7',
        100: '#F5F3F3',
        400: '#C1C1C1',
    },
    divider: '#E5E5E5',
}

export const customTheme = createTheme({
    palette,
    components: {
        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize: '14px',
                    textTransform: 'none',
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                },
            },
        },
        MuiSelect: {
            defaultProps: {
                IconComponent: ArrowDownIcon,
            },
            styleOverrides: {
                icon: {
                    fill: 'none',
                },
            },
        },
        MuiFormControl: {
            variants: [
                {
                    props: { variant: 'standard' },
                    style: {
                        ['& .MuiInput-root']: {
                            ['&:before']: {
                                borderBottomColor: '#858585',
                            },
                            ['&:before, &:after']: {
                                borderWidth: '1px !important',
                            },
                        },
                        ['& .MuiInput-input']: {
                            fontSize: '14px',
                            paddingTop: '12px',
                            paddingRight: 0,
                            paddingBottom: '12px',
                            paddingLeft: 0,
                        },
                        ['& .MuiInputLabel-root']: {
                            fontSize: 14,
                            transform: 'translate(0, 29px) scale(1)',
                        },
                        ['& .MuiInputLabel-shrink']: {
                            transform: 'translate(0, 1.5px) scale(0.75)',
                        },
                    },
                },
            ],
        },
        MuiButton: {
            styleOverrides: {
                contained: {
                    borderRadius: '8px',
                    boxShadow: 'none',

                    ['&:hover']: {
                        boxShadow: 'none',
                    },
                },
                outlined: {
                    borderRadius: '8px',
                    boxShadow: 'none',

                    ['&:hover']: {
                        boxShadow: 'none',
                    },
                },
                textSecondary: {
                    color: '#757575',
                },
            },
            variants: [
                {
                    props: { color: 'grey' },
                    style: {
                        backgroundColor: '#F5F3F3',

                        ['&:hover']: {
                            backgroundColor: '#E0E0E0',
                        },
                    },
                },
                {
                    props: { variant: 'checkout' },
                    style: {
                        display: 'flex',
                        justifyContent: 'space-between',
                        paddingTop: 14,
                        paddingRight: 20,
                        paddingBottom: 14,
                        paddingLeft: 20,
                        color: palette.primary.contrastText,
                        backgroundColor: palette.primary.main,

                        ['&:hover']: {
                            backgroundColor: palette.primary.dark,
                        },

                        ['&:disabled']: {
                            backgroundColor: palette.grey[500],
                            color: 'white',
                        },
                    },
                },
                {
                    props: { color: 'light' },
                    style: {
                        color: colors.common.white,
                        border: `1px solid ${colors.common.white}`,
                    },
                },
                {
                    props: { size: 'large' },
                    style: {
                        paddingTop: 18,
                        paddingBottom: 18,
                        paddingLeft: 42,
                        paddingRight: 42,
                    },
                },
                {
                    props: { variant: 'banner' },
                    style: {
                        paddingTop: 18,
                        paddingBottom: 18,
                        paddingLeft: 42,
                        paddingRight: 42,
                    },
                },
                {
                    props: { variant: 'banner', color: 'light' },
                    style: {
                        color: colors.common.white,
                        border: `1px solid ${colors.common.white}`,
                    },
                },
                {
                    props: { variant: 'banner', color: 'dark' },
                    style: {
                        color: colors.common.black,
                        border: `1px solid ${colors.common.black}`,
                    },
                },
            ],
        },
        MuiContainer: {
            defaultProps: {
                maxWidth: false,
            },
        },
        MuiTypography: {
            variants: [
                {
                    props: { variant: 'body1' },
                    style: {
                        fontSize: '1.125rem',
                        lineHeight: 1.35,
                    },
                },
                {
                    props: { variant: 'body2' },
                    style: {
                        lineHeight: 1.75,
                    },
                },
                {
                    props: { variant: 'body3' },
                    style: { fontSize: '0.75rem', lineHeight: 1.35 },
                },
            ],
        },
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                    font-family: 'Gotham Pro';
                    src: url(/fonts/gothampro_black.ttf) format('truetype');
                    font-weight: 900;
                }

                @font-face {
                    font-family: 'Gotham Pro';
                    src: url(/fonts/gothampro_blackitalic.ttf) format('truetype');
                    font-weight: 900;
                    font-style: italic;
                }

                @font-face {
                    font-family: 'Gotham Pro';
                    src: url(/fonts/gothampro_bold.ttf) format('truetype');
                    font-weight: 600;
                    font-style: italic;
                }

                @font-face {
                    font-family: 'Gotham Pro';
                    src: url(/fonts/gothampro_bolditalic.ttf) format('truetype');
                    font-weight: 600;
                    font-style: italic;
                }

                @font-face {
                    font-family: 'Gotham Pro';
                    src: url(/fonts/gothampro_medium.ttf) format('truetype');
                    font-weight: 500;
                }

                @font-face {
                    font-family: 'Gotham Pro';
                    src: url(/fonts/gothampro_mediumitalic.ttf) format('truetype');
                    font-weight: 500;
                    font-style: italic;
                }

                @font-face {
                    font-family: 'Gotham Pro';
                    src: url(/fonts/gothampro_light.ttf) format('truetype');
                    font-weight: 300;
                }

                @font-face {
                    font-family: 'Gotham Pro';
                    src: url(/fonts/gothampro_lightitalic.ttf) format('truetype');
                    font-weight: 300;
                    font-style: italic;
                }

                @font-face {
                    font-family: 'Gotham Pro';
                    src: url(/fonts/gothampro_italic.ttf) format('truetype');
                    font-style: italic;
                }

                @font-face {
                    font-family: 'Gotham Pro';
                    src: url(/fonts/gothampro.ttf) format('truetype');
                }

                a {
                    color: inherit;
                    text-decoration: none;
                }

                input[type="search"]::-webkit-search-decoration,
                input[type="search"]::-webkit-search-cancel-button,
                input[type="search"]::-webkit-search-results-button,
                input[type="search"]::-webkit-search-results-decoration {
                    -webkit-appearance:none;
                }
            `,
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1920,
        },
    },
    typography: {
        fontFamily: ['Gotham Pro'].join(','),
    },
})
