import {
    createEntityAdapter,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit'
import { EStatus } from 'types'
import { ICart } from 'types/ICart'

import { IOrdersState } from './types'

export const ordersAdapter = createEntityAdapter<ICart>()

const slice = createSlice({
    name: 'orders',
    initialState: ordersAdapter.getInitialState<IOrdersState>({
        ids: [],
        entities: {},
        status: EStatus.INITIAL,
    }),
    reducers: {
        loadOrders(state) {
            state.status = EStatus.PENDING
        },
        ordersLoaded(state, action: PayloadAction<ICart[]>) {
            ordersAdapter.setAll(state, action.payload)
            state.status = EStatus.FINISHED
        },
        orderUpdated(state, action: PayloadAction<ICart>) {
            ordersAdapter.setOne(state, action.payload)
        },
        generateOrderPaymentUrl(_state, _action: PayloadAction<string>) {
            //
        },
    },
})

export const { actions: ordersActions, reducer: ordersReducer } = slice
