import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { ReactComponent as RubleDesktop } from 'app/modules/Products/assets/ruble_desktop.svg'
import { ReactComponent as RubleMobile } from 'app/modules/Products/assets/ruble_mobile.svg'
import { selectVariantById } from 'app/modules/Variants/slice/selectors'
import React from 'react'
import { useSelector } from 'react-redux'

import { ProductDetailComponentProps } from '.'

export const ProductPrice: React.FC<
    ProductDetailComponentProps & {
        selectedVariant: string | null
        comparePrice?: boolean
    }
> = ({ product, selectedVariant, comparePrice = true }) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))
    const getVariant = useSelector(selectVariantById)

    const displayPrice = (() => {
        const variant = getVariant(selectedVariant)

        if (variant) {
            return variant.attributes.display_price
        }

        return product.attributes.display_price
    })()

    const displayComparePrice = (() => {
        const variant = getVariant(selectedVariant)

        if (variant) {
            return variant.attributes.display_compare_at_price
        }

        return product.attributes.display_compare_at_price
    })()

    return (
        <Box display="flex" alignItems="baseline" gap="10px">
            <Box display="flex" alignItems="baseline">
                <Typography
                    color={theme.palette.text.primary}
                    variant={isMobile ? 'h5' : 'h4'}
                    fontWeight="400"
                >
                    {displayPrice.slice(0, -2) + ' '}
                </Typography>

                {isMobile ? <RubleMobile /> : <RubleDesktop />}
            </Box>
            {comparePrice && product.attributes.sale_percent && (
                <>
                    <Typography
                        variant="body1"
                        color="grey.400"
                        sx={{
                            textDecoration: 'line-through',
                        }}
                    >
                        {displayComparePrice}
                    </Typography>

                    <Typography
                        variant="body1"
                        color="#F15C3E"
                    >{`-${product.attributes.sale_percent}%`}</Typography>
                </>
            )}
        </Box>
    )
}
