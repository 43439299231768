import 'swiper/swiper.min.css'
import './styles.css'

import { Typography } from '@mui/material'
import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

import {
    ExchangeBanner,
    PackagingBanner,
    PersonalConsultantBanner,
    ServiceDepartmentClientsBanner,
} from '../ExperienceBannerList/ExperienceBannerList'

export const MobileExperienceBanner: React.FC = () => {
    return (
        <>
            <Typography
                sx={{
                    textAlign: 'center',
                    fontWeight: 500,
                    textTransform: 'uppercase',
                    width: '300px',
                    mx: 'auto',
                    mb: '10px',
                }}
                variant="h5"
            >
                Island soul experience
            </Typography>

            <Swiper
                loop
                slidesPerView={'auto'}
                centeredSlides
                spaceBetween={12}
            >
                <SwiperSlide className={'experience-slide'}>
                    <ServiceDepartmentClientsBanner />
                </SwiperSlide>
                <SwiperSlide className={'experience-slide'}>
                    <PersonalConsultantBanner />
                </SwiperSlide>
                <SwiperSlide className={'experience-slide'}>
                    <PackagingBanner />
                </SwiperSlide>
                <SwiperSlide className={'experience-slide'}>
                    <ExchangeBanner />
                </SwiperSlide>
            </Swiper>
        </>
    )
}
