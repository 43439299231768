import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { ReactComponent as DeleteIcon } from 'app/modules/Cart/assets/CartItemDeleteIcon.svg'
import React from 'react'

import { CartItemProps } from './CartItem'

export const CartItemDeleteButton: React.FC<CartItemProps> = ({
    handleDelete,
}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                py: '2px',
            }}
            onClick={handleDelete}
        >
            <DeleteIcon />

            <Typography
                variant="body1"
                sx={{ display: { xs: 'none', md: 'inline' }, ml: '6px' }}
            >
                Удалить
            </Typography>
        </Box>
    )
}
