import { Box, useMediaQuery, useTheme } from '@mui/material'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { ILineItem } from 'types/ILineItem'
import { DolyameID } from 'utils/DolyameID'
import { useYandexMetricaEcommerce } from 'utils/yandexMetrica'

import { cartActions } from '../../slice'
import { DesktopCartItem } from './DesktopCartItem'
import { MobileCartItem } from './MobileCartItem'

interface CartItemContainerProps {
    item: ILineItem
    image?: string
}

export interface CartItemProps {
    item: ILineItem
    image?: string
    handleDelete: () => void
}

export const CartItem: React.FC<CartItemContainerProps> = ({ item, image }) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

    const dispatch = useDispatch()
    const { yandexMetricaEcommerceRemove } = useYandexMetricaEcommerce()

    const handleDelete = useCallback(() => {
        yandexMetricaEcommerceRemove(item)

        dispatch(cartActions.deleteItem(item.id))
    }, [])

    const childProps: CartItemProps = {
        item,
        image,
        handleDelete,
    }
    return (
        <Box>
            <DolyameID lineItem={item} />

            {isMobile && <MobileCartItem {...childProps} />}

            {isDesktop && <DesktopCartItem {...childProps} />}
        </Box>
    )
}
