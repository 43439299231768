import {
    Container,
    Grid,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { chunk } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'

import { ProductSlider } from '../components/ProductSlider'
import { selectSaleProducts } from '../slice/selectors'

interface IProductSaleListSlider {
    subTitle?: string
    title: string
    redirectUrl?: string
}

export const ProductSaleListSlider: React.FC<IProductSaleListSlider> = ({
    subTitle,
    title,
    redirectUrl,
}) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'lg'))

    const products = useSelector(selectSaleProducts)

    const productsPages = React.useMemo(() => {
        const chunkSize = 2
        const chunks = chunk(products, chunkSize)

        if (products.length % chunkSize === 0) {
            return chunks
        } else {
            const lastChunk = chunks.pop() || []
            const firstChunk = chunks.find(Boolean) || []

            chunks.push(
                lastChunk.concat(
                    firstChunk.slice(0, chunkSize - lastChunk.length)
                )
            )

            return chunks
        }
    }, [products])

    if (products.length < 3) return null

    return (
        <Container sx={{ px: { xs: 0, md: '2.34375vw' } }}>
            <Typography
                gutterBottom
                sx={{ textTransform: 'uppercase', mb: 0 }}
                variant="h4"
                textAlign="center"
                fontWeight="medium"
            >
                {title}
            </Typography>

            {subTitle && (
                <Typography variant="subtitle1" textAlign="center">
                    {subTitle}
                </Typography>
            )}

            <Grid container mt={3.75}>
                <Grid item={true} xs={12} md={12} lg={12}>
                    <ProductSlider
                        productsPages={
                            isMobile
                                ? productsPages.slice(
                                      0,
                                      Math.ceil((productsPages.length - 1) / 2)
                                  )
                                : productsPages
                        }
                        isMobile={isMobile}
                        redirectUrl={redirectUrl}
                    />
                </Grid>
            </Grid>
            {isMobile && (
                <Grid container mt={'26px'}>
                    <Grid item={true} xs={12} md={12} lg={12}>
                        <ProductSlider
                            productsPages={productsPages.slice(
                                Math.floor((productsPages.length - 1) / 2),
                                productsPages.length - 1
                            )}
                            isMobile={isMobile}
                            redirectUrl={redirectUrl}
                        />
                    </Grid>
                </Grid>
            )}
        </Container>
    )
}
