import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import FBIcon from 'assets/icons/facebook.svg'
import TwitterIcon from 'assets/icons/twitter.svg'
import VKIcon from 'assets/icons/vk.svg'
import React from 'react'

interface TopBannerProps {
    image: string
    date?: string
    category?: string
    title: string
    description?: string
    smallDescription?: string
    showSocialBlock?: boolean
}

export const TopBanner: React.FC<TopBannerProps> = ({
    image,
    date,
    category,
    title,
    description,
    smallDescription,
    showSocialBlock,
}) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'lg'))
    const ref = React.useRef<HTMLDivElement>(null)

    const height = React.useMemo(
        () =>
            `calc(100vh - 32px - ${
                ref?.current?.getBoundingClientRect().top
            }px)`,
        [ref.current]
    )

    return (
        <Box
            ref={ref}
            sx={{
                m: { xs: 0, md: 4 },
                position: 'relative',
            }}
            height={isMobile ? '260px' : height}
        >
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    objectFit: 'cover',
                    position: {
                        xs: 'absolute',
                        lg: 'relative',
                    },
                }}
                src={image}
                alt={title}
                component="img"
                height={isMobile ? '260px' : height}
            />

            <Box
                sx={{
                    p: {
                        xs: 2,
                        lg: 5,
                    },
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    position: {
                        xs: 'relative',
                        lg: 'absolute',
                    },
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    background:
                        'linear-gradient(0.55deg, rgba(0, 0, 0, 0.46) 0.47%, rgba(0, 0, 0, 0) 99.53%)',
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}></Box>
                <Box
                    sx={{
                        display: 'flex',
                        mt: {
                            xs: 12.75,
                            lg: 0,
                        },
                        flexDirection: {
                            xs: 'column',
                            lg: 'row',
                        },
                        justifyContent: 'space-between',
                    }}
                >
                    <Box>
                        {category && (
                            <Typography
                                gutterBottom
                                color="white"
                                variant={isMobile ? 'body3' : 'body2'}
                                fontWeight={500}
                                sx={{
                                    textTransform: 'uppercase',
                                }}
                                component="span"
                            >
                                {category}&nbsp;&nbsp;&nbsp;&nbsp;{date}
                            </Typography>
                        )}
                        <Typography
                            color="white"
                            variant={isMobile ? 'body1' : 'h4'}
                            fontWeight={400}
                            sx={{
                                mt: {
                                    xs: 0.5,
                                    lg: 2,
                                },
                                width: {
                                    xs: '100%',
                                    lg: '50%',
                                },
                                textTransform: 'uppercase',
                            }}
                            component="span"
                        >
                            {title}
                        </Typography>
                        {description && (
                            <Typography
                                gutterBottom
                                color="white"
                                variant="body2"
                                fontWeight={500}
                                sx={{
                                    textTransform: 'uppercase',
                                }}
                                mt={1}
                                component="span"
                            >
                                {description}
                            </Typography>
                        )}
                        {smallDescription && (
                            <Typography
                                gutterBottom
                                color="white"
                                variant="body2"
                                fontWeight={500}
                                mt={1}
                                component="span"
                            >
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: smallDescription || '',
                                    }}
                                />
                            </Typography>
                        )}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-end',
                        }}
                    >
                        {showSocialBlock && (
                            <>
                                {!isMobile && (
                                    <Typography
                                        gutterBottom
                                        color="white"
                                        variant="body2"
                                        fontWeight={500}
                                    >
                                        Поделиться
                                    </Typography>
                                )}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        mt: {
                                            xs: 1.5,
                                            lg: 1,
                                        },
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: {
                                                xs: '20px',
                                                lg: '32px',
                                            },
                                            height: {
                                                xs: '20px',
                                                lg: '32px',
                                            },
                                        }}
                                        alt={'Facebook'}
                                        src={FBIcon}
                                        component={'img'}
                                    />
                                    <Box
                                        sx={{
                                            width: {
                                                xs: '20px',
                                                lg: '32px',
                                            },
                                            height: {
                                                xs: '20px',
                                                lg: '32px',
                                            },
                                            marginLeft: {
                                                xs: '20px',
                                                lg: '32px',
                                            },
                                        }}
                                        alt={'Twitter'}
                                        src={TwitterIcon}
                                        component={'img'}
                                    />
                                    <Box
                                        sx={{
                                            width: {
                                                xs: '20px',
                                                lg: '32px',
                                            },
                                            height: {
                                                xs: '20px',
                                                lg: '32px',
                                            },
                                            marginLeft: {
                                                xs: '20px',
                                                lg: '32px',
                                            },
                                        }}
                                        alt={'VK'}
                                        src={VKIcon}
                                        component={'img'}
                                    />
                                </Box>
                            </>
                        )}
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
