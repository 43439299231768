import {
    Box,
    Card,
    CardActionArea,
    CardMedia,
    Skeleton,
    Typography,
} from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { ICategory } from 'types/ICategory'

interface MobileCategoryCardProps {
    category: ICategory
    image: string
}

export const MobileCategoryCard: React.FC<MobileCategoryCardProps> = ({
    category,
    image,
}) => (
    <Card elevation={0}>
        <CardActionArea
            to={`/${category.attributes.permalink}`}
            component={Link}
        >
            <Box
                sx={{
                    overflow: 'hidden',
                    pb: '4px',
                }}
            >
                <Box
                    sx={{
                        height: {
                            xs: '44vw',
                            sm: '29vw',
                        },
                        width: {
                            xs: '44vw',
                            sm: '29vw',
                        },
                    }}
                >
                    <CardMedia
                        sx={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            overflow: 'hidden',
                            zIndex: -1,
                        }}
                        src={image}
                        component="img"
                    />
                    {!image && (
                        <Skeleton
                            width="100%"
                            height="100%"
                            variant="rectangular"
                        />
                    )}
                </Box>
            </Box>
            <Typography variant="body3" fontWeight="medium">
                {category.attributes.name}
            </Typography>
        </CardActionArea>
    </Card>
)
