import { createSelector } from '@reduxjs/toolkit'
import { Nullable, RootState } from 'types'

import { variantsAdapter } from '.'

const { selectById } = variantsAdapter.getSelectors()

const selectDomain = (state: RootState) => state.variants

export const selectVariantById = createSelector(
    [selectDomain],
    (state) => (id?: Nullable<string>) => id ? selectById(state, id) : undefined
)
