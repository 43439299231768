import { Box, IconButton, Stack, Typography } from '@mui/material'
import { DeleteIcon } from 'app/components/Icons/DeleteIcon'
import React from 'react'
import { ILineItem } from 'types/ILineItem'

import { QuantityControl } from '../../QuantityControl'

interface DesktopOrderItemProps {
    item: ILineItem
    image?: string
    handleDelete: () => void
}

export const DesktopOrderItem: React.FC<DesktopOrderItemProps> = ({
    item,
    image,
    handleDelete,
}) => {
    return (
        <Stack spacing={2.5} direction="row">
            <Box
                sx={{
                    width: 80,
                    height: 80,
                    flexShrink: 0,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundImage: `url(${image || ''})`,
                }}
            />

            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                    }}
                >
                    <Stack spacing={1}>
                        <Typography variant="body2">
                            {item.attributes.name}
                        </Typography>

                        <Typography variant="body2">
                            {item.attributes.options_text}
                        </Typography>
                    </Stack>
                </Box>

                <Stack
                    sx={{
                        alignItems: 'flex-end',
                    }}
                    spacing={2}
                >
                    <Stack spacing={2.5} direction="row">
                        <QuantityControl item={item} />

                        <Typography
                            noWrap
                            sx={{ fontWeight: 'medium' }}
                            variant="body2"
                        >
                            {item.attributes.display_total}
                        </Typography>
                    </Stack>

                    <Box>
                        <IconButton
                            sx={{ mr: -1, ml: 1 }}
                            onClick={handleDelete}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                </Stack>
            </Box>
        </Stack>
    )
}
