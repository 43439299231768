import {
    Favorite as FavoriteIcon,
    FavoriteBorder as FavoriteBorderIcon,
} from '@mui/icons-material'
import {
    Box,
    IconButton,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import React from 'react'

import { ProductFavoriteProps } from '.'
import { useHandleToggleFavorite } from './ProductHandleToggleFavorite'

interface ProductLikeIcontProps {
    isHover: boolean
}

export const ProductLikeIcon: React.FC<
    ProductFavoriteProps & ProductLikeIcontProps
> = ({ product, isHover }) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))
    const isTablet = useMediaQuery(theme.breakpoints.between('md', 'lg'))

    const { isFavorite, handleToggleFavorite } =
        useHandleToggleFavorite(product)

    return (
        <Box
            sx={{
                borderRadius: '999px',
                backgroundColor: 'rgba(229, 229, 229, 0.3)',
            }}
        >
            <IconButton
                sx={{ color: 'white', borderRadius: 'inherit' }}
                size={
                    (isMobile && 'small') || (isTablet && 'medium') || 'large'
                }
                onClick={handleToggleFavorite}
            >
                {isFavorite ? (
                    <FavoriteIcon color="primary" />
                ) : (
                    <FavoriteBorderIcon />
                )}

                {!!product.attributes.favorite_count && isHover && (
                    <Typography
                        sx={{
                            ml: {
                                xs: 1.5,
                                md: 1.85,
                                lg: 2.25,
                            },
                            mr: 1.75,
                        }}
                        variant="body2"
                        color="white"
                    >
                        {product.attributes.favorite_count}
                    </Typography>
                )}
            </IconButton>
        </Box>
    )
}
