import { Close as ClearIcon } from '@mui/icons-material'
import { Box, Container, IconButton, TextField, useTheme } from '@mui/material'
import { SearchIcon } from 'app/components/Icons/SearchIcon'
import { useHandleSearch } from 'app/modules/Search/hooks/useSearch'
import { useFormik } from 'formik'
import React from 'react'
import * as yup from 'yup'

import { useHeaderContext } from '../HeaderContextProvider/HeaderContextProvider'

export const MobileHeaderSearch: React.FC = () => {
    const { handleSeach } = useHandleSearch()

    const { isMobileSearchOpened, mobileSearchChange } = useHeaderContext()

    const data = {
        query: '',
    }

    const theme = useTheme()

    const validationSchema = yup.object({
        query: yup.string().required(),
    })

    const formik = useFormik({
        validationSchema,
        initialValues: data,
        validateOnBlur: false,
        validateOnChange: false,
        enableReinitialize: true,
        onSubmit: (values) => {
            handleSeach(values.query)
            handleCloseSearch()
        },
    })

    const handleClearSearch = () => {
        formik.setValues({ query: '' })
    }

    const handleCloseSearch = () => {
        mobileSearchChange?.()
    }

    return (
        <>
            {isMobileSearchOpened && (
                <>
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            bgcolor: 'rgba(0,0,0,0.3)',
                            position: 'fixed',
                        }}
                        onClick={handleCloseSearch}
                    />

                    <Box
                        sx={{
                            width: '100%',
                            overflow: 'auto',
                            bgcolor: 'white',
                            position: 'absolute',
                            pt: 1.5,
                            pb: 2,
                        }}
                        noValidate
                        component="form"
                        action="."
                        onSubmit={(e: React.FormEvent) => {
                            e.preventDefault()

                            formik.handleSubmit()
                        }}
                    >
                        <Container>
                            <TextField
                                inputRef={(input) => input && input.focus()}
                                fullWidth
                                variant="outlined"
                                placeholder="Поиск по товарам"
                                name="query"
                                autoFocus
                                type="search"
                                value={formik.values.query}
                                error={!!formik.errors.query}
                                onChange={formik.handleChange}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        formik.handleSubmit()
                                    }
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <>
                                            <Box mr={1.25}>
                                                <SearchIcon
                                                    color={
                                                        theme.palette.grey[400]
                                                    }
                                                />
                                            </Box>

                                            <IconButton
                                                sx={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    right: 0,
                                                    m: 1,
                                                    color: theme.palette
                                                        .grey[400],
                                                    '&:hover': {
                                                        color: theme.palette
                                                            .primary.main,
                                                    },
                                                }}
                                                onClick={handleClearSearch}
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        </>
                                    ),
                                }}
                            />
                        </Container>
                    </Box>
                </>
            )}
        </>
    )
}
