import { Box, Button, Drawer, IconButton, Typography } from '@mui/material'
import { CloseIcon } from 'app/components/Icons/CloseIcon'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { citiesActions } from '../../slice'

interface MobileWelcomeCityDialogProps {
    onCityChange: () => void
}

export const MobileWelcomeCityDialog: React.FC<
    MobileWelcomeCityDialogProps
> = ({ onCityChange }) => {
    const dispatch = useDispatch()

    const [open, setOpen] = useState<boolean>(true)

    const handleMoscowSet = () => {
        dispatch(citiesActions.setActiveCityId('1'))
        setOpen(false)
    }

    const handleCityChange = () => {
        onCityChange()
        setOpen(false)
    }

    const handleClose = () => {
        setOpen(false)
    }
    return (
        <Drawer
            open={open}
            anchor={'bottom'}
            PaperProps={{
                sx: {
                    width: '100%',
                    borderTopLeftRadius: { xs: '10px', md: '0px' },
                    borderTopRightRadius: { xs: '10px', md: '0px' },
                    maxWidth: { xs: '100%' },
                    zIndex: 1,
                },
            }}
            sx={{
                zIndex: 1400,
            }}
        >
            <Box
                sx={{
                    pt: 3.5,
                    pb: 6.25,
                    px: 2,
                    width: {
                        md: '550px',
                    },
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <Box display={'flex'}>
                    <Typography
                        sx={{
                            pt: 2,
                            fontWeight: 500,
                            textTransform: 'uppercase',
                        }}
                        variant="body1"
                    >
                        ближайший к вам город москва?
                    </Typography>

                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Typography
                    mt={1.5}
                    variant="body2"
                    fontWeight={500}
                    color="grey.600"
                >
                    {
                        'От выбранного города зависит наличие товаров и стоимость доставки'
                    }
                </Typography>

                <Button
                    fullWidth
                    variant="contained"
                    size="large"
                    color="primary"
                    sx={{ px: 1.75, mt: 4.5 }}
                    onClick={handleMoscowSet}
                >
                    <Typography variant="body2" fontWeight={500}>
                        Да, МОСКВА
                    </Typography>
                </Button>

                <Button
                    fullWidth
                    variant="outlined"
                    size="large"
                    sx={{ px: 1.75, mt: 1 }}
                    onClick={handleCityChange}
                >
                    <Typography variant="body2" fontWeight={500}>
                        НЕТ, ДРУГОЙ ГОРОД
                    </Typography>
                </Button>
            </Box>
        </Drawer>
    )
}
