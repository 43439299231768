import { LoadingButton } from '@mui/lab'
import { Box, Typography } from '@mui/material'
import { PhoneField } from 'app/components/PhoneField'
import { testPhoneNumber } from 'app/components/PhoneField/utils/validatePhoneNumber'
import { useFormik } from 'formik'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EStatus } from 'types'
import * as yup from 'yup'

import { offlineTradeActions } from '../slice'
import { selectLoyaltyForm } from '../slice/selectors'

export const OfflineTradeGetLoyalty: React.FC = () => {
    const dispatch = useDispatch()

    const { data, status } = useSelector(selectLoyaltyForm)

    const validationSchema = yup.object({
        phone: yup.string().required().test(testPhoneNumber),
    })

    const formik = useFormik({
        validationSchema,
        initialValues: data,
        validateOnBlur: false,
        validateOnChange: false,
        enableReinitialize: true,
        onSubmit: (values) => {
            dispatch(offlineTradeActions.getLoyalty(values.phone))
        },
    })

    return (
        <Box
            noValidate
            component="form"
            autoComplete="off"
            onSubmit={(e: React.FormEvent) => {
                e.preventDefault()

                formik.handleSubmit()
            }}
        >
            <Typography variant="body1" fontWeight={500}>
                Введите номер телефона
            </Typography>

            <Box mt={6}>
                <PhoneField
                    name="phone"
                    label="Номер телефона"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                />
            </Box>

            <LoadingButton
                loading={status === EStatus.PENDING}
                disabled={!formik.values.phone}
                fullWidth
                variant="contained"
                sx={{ mt: 6 }}
                onClick={() => formik.handleSubmit()}
            >
                ПРОДОЛЖИТЬ
            </LoadingButton>
        </Box>
    )
}
