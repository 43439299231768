import {
    createEntityAdapter,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit'
import { IPayment } from 'types/IPayment'

import { IPaymentsState } from './types'

export const paymentsAdapter = createEntityAdapter<IPayment>()

const slice = createSlice({
    name: 'payments',
    initialState: paymentsAdapter.getInitialState<IPaymentsState>({
        ids: [],
        entities: {},
    }),
    reducers: {
        paymentsAdded(state, action: PayloadAction<IPayment[]>) {
            paymentsAdapter.setMany(state, action.payload)
        },
    },
})

export const { actions: paymentsActions, reducer: paymentsReducer } = slice
