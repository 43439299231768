import { Box, Button, Container, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'

import { useCategoryPageContext } from '../hooks'

export const ProductsFilterButton: React.FC = () => {
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

    const { closeFilterModal } = useCategoryPageContext()

    if (isDesktop) return null

    return (
        <Box sx={{ height: '83px' }}>
            <Box
                sx={{
                    position: { xs: 'fixed' },
                    transform: { xs: 'translate3d(0,0,0)' },
                    right: { xs: '0px' },
                    // Это нужно для корректного отображения на safari IOS
                    top: { xs: 'calc(100% - 84px)' },
                    width: { xs: '100%', md: '590px' },
                    height: { xs: '84px' },
                    pb: { xs: '16px' },
                    pt: { xs: '8px' },
                    backgroundColor: { xs: 'white' },
                    zIndex: 5,
                }}
            >
                <Container>
                    <Button
                        fullWidth
                        size="large"
                        variant="contained"
                        onClick={closeFilterModal}
                    >
                        Показать товары
                    </Button>
                </Container>
            </Box>
        </Box>
    )
}
