import { selectPagesSort } from 'app/modules/Articles/slice/selectors'
import React from 'react'
import { useSelector } from 'react-redux'

import { ArticleAdCard } from '../../ArticleAdCard'
import { ArticleBigCard } from '../../ArticleBigCard'
import { ArticleCard } from '../../ArticleCard'

export const MobileArticleCatList: React.FC = () => {
    const pages = useSelector(selectPagesSort)

    return (
        <>
            {pages.map((page, index) => {
                if (index === 0)
                    return <ArticleBigCard key={index} page={page} />

                return (
                    <>
                        {index === 1 && <ArticleAdCard />}

                        <ArticleCard
                            key={index}
                            page={page}
                            photoLayout={'horizontal'}
                        />
                    </>
                )
            })}
        </>
    )
}
