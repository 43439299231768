import { Collapse, Container } from '@mui/material'
import { MobileMenuItem } from 'app/components/MobileMenuItem'
import React, { useState } from 'react'

import { ReactComponent as MinusIcon } from './assets/minus_icon.svg'
import { ReactComponent as PlusIcon } from './assets/plus_icon.svg'

interface MobileToggleListProps {
    text: string
}

export const MobileToggleList: React.FC<MobileToggleListProps> = ({
    text,
    children,
}) => {
    const [isOpen, setOpen] = useState<boolean>(false)

    return (
        <Container
            onClick={() => setOpen(!isOpen)}
            sx={{
                py: 0.625,
            }}
        >
            <MobileMenuItem
                text={text}
                endContent={isOpen ? <MinusIcon /> : <PlusIcon />}
            />

            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                {children}
            </Collapse>
        </Container>
    )
}
